define('jason-frontend/templates/components/public-admission-pin-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 16
            },
            "end": {
              "line": 57,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Scanne den eltiga Tier QR-Code um das Tier aufzunehmen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 text-center");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","placeholder-input");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2,"class","mt20 mb20");
          var el3 = dom.createTextNode("oder ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor is-highlighted");
          var el4 = dom.createTextNode("eltiga\n                      Tier Nr direkt eingeben");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [5, 1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),1,1);
          morphs[1] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [
          ["inline","input",[],["value",["subexpr","@mut",[["get","qrInput",["loc",[null,[50,36],[50,43]]]]],[],[]],"class","","id","qrInput2","name","qrInput"],["loc",[null,[50,22],[50,83]]]],
          ["element","action",["activateDirectInput"],[],["loc",[null,[54,49],[54,81]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 57,
              "column": 16
            },
            "end": {
              "line": 70,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Geben Sie die Tier Nummer ein");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 text-center");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","placeholder-input");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2,"class","mt20 mb20");
          var el3 = dom.createTextNode("oder ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor is-highlighted");
          var el4 = dom.createTextNode("scanne\n                      den Tier QR-Code");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [5, 1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),1,1);
          morphs[1] = dom.createElementMorph(element4);
          return morphs;
        },
        statements: [
          ["inline","input",[],["id","etigaNumber","value",["subexpr","@mut",[["get","etigaNumber",["loc",[null,[63,53],[63,64]]]]],[],[]],"class","","name","etigaNumber","placeholder",""],["loc",[null,[63,22],[63,109]]]],
          ["element","action",["deactivateDirectInput"],[],["loc",[null,[67,49],[67,83]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 194,
                "column": 24
              },
              "end": {
                "line": 207,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","form-field-rework");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","select-placeholder");
            var el3 = dom.createTextNode("Spezies");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","digitalRaces",["loc",[null,[198,38],[198,50]]]]],[],[]],"value",["subexpr","@mut",[["get","digitalRace",["loc",[null,[199,36],[199,47]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"name","digitalRace","placeholder","Bitte wählen"],["loc",[null,[197,28],[205,30]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 155,
              "column": 18
            },
            "end": {
              "line": 239,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","mt-30 grid gutter-fix");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk 24/24 gutter");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","patient-name");
          dom.setAttribute(el4,"class","placeholder required");
          var el5 = dom.createTextNode("Tiername");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk 24/24 gutter");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Geschlecht");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk 24/24 gutter");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"class","select-placeholder");
          var el5 = dom.createTextNode("Spezies");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk 24/24 gutter");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk 24/24 gutter");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","patient-color");
          dom.setAttribute(el4,"class","placeholder");
          var el5 = dom.createTextNode("Farbe");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk 24/24 gutter");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","patient-chipId");
          dom.setAttribute(el4,"class","placeholder");
          var el5 = dom.createTextNode("Chip-Nr");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk 24/24 gutter");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","patient-birthday");
          dom.setAttribute(el4,"class","placeholder");
          var el5 = dom.createTextNode("Geburtstag");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/calendar-dark.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk 24/24 gutter");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","form-field-rework min-h-50px mb-0 d-flex align-items-center");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","toggle");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","switchCastratedPatient");
          dom.setAttribute(el5,"class","switch");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","switchCastratedPatient");
          dom.setAttribute(el4,"class","toggle");
          var el5 = dom.createTextNode("Kastriert");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(8);
          morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [5, 1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [7]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [9, 1]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element3, [11, 1]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element3, [13, 1]),1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element3, [15, 1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","patientName",["loc",[null,[159,55],[159,66]]]]],[],[]],"name","patient-name","autocomplete","off"],["loc",[null,[159,26],[159,107]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientGenders",["loc",[null,[167,36],[167,50]]]]],[],[]],"value",["subexpr","@mut",[["get","patientGender",["loc",[null,[168,34],[168,47]]]]],[],[]],"optionLabelPath","name","allowClear",false,"name","patientGender","placeholder","Bitte wählen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[166,26],[174,28]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","patientCategories",["loc",[null,[182,36],[182,53]]]]],[],[]],"value",["subexpr","@mut",[["get","patientCategory",["loc",[null,[183,34],[183,49]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","name","patientCategory","optionLabelPath","name","allowClear",true,"placeholder","Bitte wählen"],["loc",[null,[181,26],[189,28]]]],
          ["block","if",[["get","digitalRaces",["loc",[null,[194,30],[194,42]]]]],[],0,null,["loc",[null,[194,24],[207,31]]]],
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","patientColor",["loc",[null,[211,55],[211,67]]]]],[],[]],"name","patient-color","autocomplete","off"],["loc",[null,[211,26],[211,109]]]],
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","patientChipId",["loc",[null,[217,55],[217,68]]]]],[],[]],"name","patient-chipId","autocomplete","off"],["loc",[null,[217,26],[217,111]]]],
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","birthdayInput",["loc",[null,[223,55],[223,68]]]]],[],[]],"class","datetimepicker3","name","patient-birthday"],["loc",[null,[223,26],[223,118]]]],
          ["inline","input",[],["type","checkbox","id","switchCastratedPatient","classNames","switch","name","switchCastratedPatient","checked",["subexpr","@mut",[["get","patientCastrated",["loc",[null,[232,74],[232,90]]]]],[],[]]],["loc",[null,[231,28],[232,92]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 241,
              "column": 20
            },
            "end": {
              "line": 254,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","mt10 icon-button");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","mt10 icon-button");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/waitingroom.svg");
          dom.setAttribute(el2,"title","Speichern, Aufnehmen und ins Wartezimmer setzen");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","mt10 icon-button");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/treatment.svg");
          dom.setAttribute(el2,"title","Speichern, Aufnehmen und direkt in Behandlung");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(fragment, [5]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          morphs[2] = dom.createElementMorph(element1);
          morphs[3] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [
          ["element","action",["saveAndGotoPatient"],[],["loc",[null,[242,25],[242,56]]]],
          ["inline","button-add-patient",[],["color","#ffffff","size","62","showStroke",false,"content","Speichern, und zum Tier"],["loc",[null,[243,24],[244,80]]]],
          ["element","action",["saveAndToWaitingRoom"],[],["loc",[null,[246,25],[246,58]]]],
          ["element","action",["saveAndToTreatment"],[],["loc",[null,[250,25],[250,56]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 313,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/public-admission-pin-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-public-admission-pin-panel");
        dom.setAttribute(el1,"class","popup-basic popup-large mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        dom.setAttribute(el3,"style","padding-left: 15px");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode(" Check-In");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","etigaScanForm2");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","js-tabs tabs");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","tabs__head tabs--mobile-block mobile-horizontal");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("ul");
        dom.setAttribute(el7,"class","u-mb0 clearfix");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-admission");
        var el10 = dom.createTextNode("Selbst-Registrierung");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-etiga");
        var el10 = dom.createTextNode("App Check-In");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("li");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"href","#tab-customer");
        var el10 = dom.createTextNode("Neue Kund:in");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        dom.setAttribute(el6,"class","tabs__body");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-admission");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        var el9 = dom.createTextNode("Scanne diesen QR-Code mit einem Smartphone oder Tablet, um die Selbst-Registrierung zu starten.");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"id","result");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("p");
        var el9 = dom.createElement("a");
        dom.setAttribute(el9,"class","is-highlighted");
        dom.setAttribute(el9,"target","_blank");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("img");
        dom.setAttribute(el9,"class","cursor");
        dom.setAttribute(el9,"src","assets/images/icons/copy-to-clipboard.svg");
        dom.setAttribute(el9,"style","width: 17px;display: inline");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-etiga");
        var el8 = dom.createTextNode("\n\n\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","d-flex flex-column");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("button");
        dom.setAttribute(el9,"type","submit");
        dom.setAttribute(el9,"class","mt20 context button-rework min-h-50px");
        var el10 = dom.createTextNode("\n                  Aufnehmen\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","bar");
        var el9 = dom.createTextNode("\n\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"data-for","#tab-customer");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","pb0");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("form");
        dom.setAttribute(el9,"method","post");
        dom.setAttribute(el9,"id","createCustomerAdmissionForm");
        var el10 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","mt-30 grid gutter-fix");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","col l-12/24@desk 24/24 gutter");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","form-field-rework");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"class","select-placeholder");
        var el14 = dom.createTextNode("Anrede");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","col l-12/24@desk 24/24 gutter");
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","col l-12/24@desk 24/24 gutter");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","form-field-rework");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","customer-firstname");
        dom.setAttribute(el13,"class","placeholder required");
        var el14 = dom.createTextNode("Vorname");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","col l-12/24@desk 24/24 gutter");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","form-field-rework");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","customer-lastname");
        dom.setAttribute(el13,"class","placeholder required");
        var el14 = dom.createTextNode("Nachname");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","col l-12/24@desk 24/24 gutter");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","form-field-rework");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","customer-street");
        dom.setAttribute(el13,"class","placeholder");
        var el14 = dom.createTextNode("Adresse");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","col l-12/24@desk 24/24 gutter");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","form-field-rework");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","customer-postalCode");
        dom.setAttribute(el13,"class","placeholder");
        var el14 = dom.createTextNode("PLZ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","col l-12/24@desk 24/24 gutter");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","form-field-rework");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","customer-town");
        dom.setAttribute(el13,"class","placeholder");
        var el14 = dom.createTextNode("Ort");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","col l-12/24@desk 24/24 gutter");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","form-field-rework");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","customer-phone");
        dom.setAttribute(el13,"class","placeholder");
        var el14 = dom.createTextNode("Telefon");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11,"class","col l-12/24@desk 24/24 gutter");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","form-field-rework");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","customer-email");
        dom.setAttribute(el13,"class","placeholder");
        var el14 = dom.createTextNode("E-Mail");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","toggle-container");
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("h2");
        var el13 = dom.createTextNode("Tier anlegen");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("div");
        dom.setAttribute(el12,"class","header-toggle");
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createComment("");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                        ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("label");
        dom.setAttribute(el13,"for","switchAddPatient");
        dom.setAttribute(el13,"class","switch");
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                      ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","bar pb0");
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("a");
        dom.setAttribute(el11,"class","mt10 icon-button");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("img");
        dom.setAttribute(el12,"src","assets/images/icons/rework/user.svg");
        dom.setAttribute(el12,"content","Speichern, und zum Kunden");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("a");
        dom.setAttribute(el11,"class","icon-button icon-button--success");
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("img");
        dom.setAttribute(el12,"src","assets/images/icons/rework/save.svg");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                    ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                  ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(\"body\").delegate(\".datetimepicker3\", \"apply.daterangepicker\", function (ev, picker) {\n    $(this).val(picker.startDate.format('DD. MM. YYYY'));\n    $(this).focus();\n  });\n  $(\"body\").delegate(\".datetimepicker3\", \"focusin\", function () {\n    $(this).daterangepicker({\n      singleDatePicker: true,\n      autoUpdateInput: false,\n      showDropdowns: true,\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0, 1, 3, 1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element7, [1, 1]);
        var element9 = dom.childAt(element8, [1, 1]);
        var element10 = dom.childAt(element8, [3, 1]);
        var element11 = dom.childAt(element8, [5, 1]);
        var element12 = dom.childAt(element7, [3]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element13, [5]);
        var element15 = dom.childAt(element14, [0]);
        var element16 = dom.childAt(element14, [2]);
        var element17 = dom.childAt(element12, [3]);
        var element18 = dom.childAt(element12, [5]);
        var element19 = dom.childAt(element18, [1, 1]);
        var element20 = dom.childAt(element19, [1]);
        var element21 = dom.childAt(element19, [7]);
        var element22 = dom.childAt(element21, [3]);
        var element23 = dom.childAt(element21, [5]);
        var morphs = new Array(28);
        morphs[0] = dom.createElementMorph(element6);
        morphs[1] = dom.createAttrMorph(element9, 'class');
        morphs[2] = dom.createElementMorph(element9);
        morphs[3] = dom.createAttrMorph(element10, 'class');
        morphs[4] = dom.createElementMorph(element10);
        morphs[5] = dom.createAttrMorph(element11, 'class');
        morphs[6] = dom.createElementMorph(element11);
        morphs[7] = dom.createAttrMorph(element13, 'class');
        morphs[8] = dom.createAttrMorph(element15, 'href');
        morphs[9] = dom.createMorphAt(element15,0,0);
        morphs[10] = dom.createElementMorph(element16);
        morphs[11] = dom.createAttrMorph(element17, 'class');
        morphs[12] = dom.createMorphAt(dom.childAt(element17, [1]),1,1);
        morphs[13] = dom.createAttrMorph(element18, 'class');
        morphs[14] = dom.createElementMorph(element19);
        morphs[15] = dom.createMorphAt(dom.childAt(element20, [1, 1]),3,3);
        morphs[16] = dom.createMorphAt(dom.childAt(element20, [5, 1]),1,1);
        morphs[17] = dom.createMorphAt(dom.childAt(element20, [7, 1]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element20, [9, 1]),1,1);
        morphs[19] = dom.createMorphAt(dom.childAt(element20, [11, 1]),1,1);
        morphs[20] = dom.createMorphAt(dom.childAt(element20, [13, 1]),1,1);
        morphs[21] = dom.createMorphAt(dom.childAt(element20, [15, 1]),1,1);
        morphs[22] = dom.createMorphAt(dom.childAt(element20, [17, 1]),1,1);
        morphs[23] = dom.createMorphAt(dom.childAt(element19, [3, 1, 3]),1,1);
        morphs[24] = dom.createMorphAt(element19,5,5);
        morphs[25] = dom.createMorphAt(element21,1,1);
        morphs[26] = dom.createElementMorph(element22);
        morphs[27] = dom.createElementMorph(element23);
        return morphs;
      },
      statements: [
        ["element","action",["confirm"],["on","submit"],["loc",[null,[8,12],[8,44]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[13,84],[13,95]]]],"admission"],[],["loc",[null,[13,80],[13,108]]]],"is-active",""],[],["loc",[null,[13,59],[14,84]]]]]]],
        ["element","action",["selectMode","admission"],[],["loc",[null,[14,108],[15,44]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[18,84],[18,95]]]],"etiga"],[],["loc",[null,[18,80],[18,104]]]],"is-active",""],[],["loc",[null,[18,59],[19,84]]]]]]],
        ["element","action",["selectMode","etiga"],[],["loc",[null,[19,104],[20,40]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[23,84],[23,95]]]],"customer"],[],["loc",[null,[23,80],[23,107]]]],"is-active",""],[],["loc",[null,[23,59],[24,84]]]]]]],
        ["element","action",["selectMode","customer"],[],["loc",[null,[24,107],[25,43]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 mt20 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[31,75],[31,86]]]],"admission"],[],["loc",[null,[31,71],[31,99]]]],"is-active",""],[],["loc",[null,[31,50],[31,116]]]]]]],
        ["attribute","href",["concat",[["get","url",["loc",[null,[35,28],[35,31]]]]]]],
        ["inline","truncate",[["get","url",["loc",[null,[35,85],[35,88]]]],35],[],["loc",[null,[35,74],[35,93]]]],
        ["element","action",["copyToClipboard",["get","url",["loc",[null,[36,34],[36,37]]]]],[],["loc",[null,[35,103],[36,39]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 mt20 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[39,75],[39,86]]]],"etiga"],[],["loc",[null,[39,71],[39,95]]]],"is-active",""],[],["loc",[null,[39,50],[39,112]]]]]]],
        ["block","unless",[["get","directInput",["loc",[null,[44,26],[44,37]]]]],[],0,1,["loc",[null,[44,16],[70,27]]]],
        ["attribute","class",["concat",["tabs__content pl0 pr0 pb0 mt0 mt20 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedTab",["loc",[null,[79,83],[79,94]]]],"customer"],[],["loc",[null,[79,79],[79,106]]]],"is-active",""],[],["loc",[null,[79,58],[80,83]]]]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[82,69],[82,98]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","gendersAll",["loc",[null,[89,34],[89,44]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedGender",["loc",[null,[90,32],[90,46]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",false,"name","customerGender","placeholder","Bitte wählen"],["loc",[null,[88,24],[96,26]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","firstname",["loc",[null,[103,53],[103,62]]]]],[],[]],"name","customer-firstname"],["loc",[null,[103,24],[103,90]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","lastname",["loc",[null,[109,53],[109,61]]]]],[],[]],"name","customer-lastname"],["loc",[null,[109,24],[109,88]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","street",["loc",[null,[115,53],[115,59]]]]],[],[]],"name","customer-street"],["loc",[null,[115,24],[115,84]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","postalCode",["loc",[null,[121,53],[121,63]]]]],[],[]],"name","customer-postalCode"],["loc",[null,[121,24],[121,92]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","town",["loc",[null,[127,53],[127,57]]]]],[],[]],"name","customer-town"],["loc",[null,[127,24],[127,80]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","phone",["loc",[null,[133,53],[133,58]]]]],[],[]],"name","customer-phone"],["loc",[null,[133,24],[133,82]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","email",["loc",[null,[139,53],[139,58]]]]],[],[]],"name","customer-email"],["loc",[null,[139,24],[139,82]]]],
        ["inline","input",[],["type","checkbox","id","switchAddPatient","classNames","switch","name","switchAddPatient","checked",["subexpr","@mut",[["get","addPatient",["loc",[null,[150,40],[150,50]]]]],[],[]]],["loc",[null,[149,24],[150,52]]]],
        ["block","if",[["get","addPatient",["loc",[null,[155,24],[155,34]]]]],[],2,null,["loc",[null,[155,18],[239,25]]]],
        ["block","if",[["get","addPatient",["loc",[null,[241,26],[241,36]]]]],[],3,null,["loc",[null,[241,20],[254,27]]]],
        ["element","action",["saveAndGotoCustomer"],[],["loc",[null,[255,23],[255,55]]]],
        ["element","action",["save"],[],["loc",[null,[258,23],[258,40]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});