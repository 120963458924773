define('jason-frontend/templates/practicemgmt/treatment-journal-new', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment-journal-new.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/download.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Behandlungen im CSV-Format herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["download"],[],["loc",[null,[6,11],[6,32]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 2
            },
            "end": {
              "line": 34,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment-journal-new.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[33,4],[33,25]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 41,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/treatment-journal-new.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Journal");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","site-content card transaction-filter rework-content");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(fragment, [2]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 3]),1,1);
        morphs[2] = dom.createMorphAt(element2,1,1);
        morphs[3] = dom.createMorphAt(element2,3,3);
        morphs[4] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[5] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[6] = dom.createMorphAt(fragment,8,8,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,47],[1,58]]]],"with-filters",""],[],["loc",[null,[1,26],[1,78]]]]]]],
        ["block","if",[["subexpr","has-permission",["d_treatment_history"],[],["loc",[null,[5,12],[5,50]]]]],[],0,null,["loc",[null,[5,6],[9,13]]]],
        ["inline","patient-activities",[],["editNote","editMemo","editTreatment","editFullTreatment","openReportChooser","openReportChooser","openDocument","openDocument","openSlopingInfos","openSlopingInfos","gotoReport","gotoReport","gotoTemplate","gotoTemplate","openLab","openLab","openApp","openEditAppointment","doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[25,14],[25,21]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[26,17],[26,27]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[27,12],[27,17]]]]],[],[]],"stables",["subexpr","@mut",[["get","stables",["loc",[null,[28,14],[28,21]]]]],[],[]],"patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[29,24],[29,41]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","patientActivities",["loc",[null,[30,21],[30,38]]]]],[],[]]],["loc",[null,[15,4],[30,40]]]],
        ["block","if",[["get","showLoading",["loc",[null,[32,8],[32,19]]]]],[],1,null,["loc",[null,[32,2],[34,9]]]],
        ["inline","edit-patient-memo-panel",[],["reload","reload","actionReceiver",["subexpr","@mut",[["get","editPatientMemoPanel",["loc",[null,[38,57],[38,77]]]]],[],[]]],["loc",[null,[38,0],[38,79]]]],
        ["inline","add-appointment-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[39,30],[39,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[39,44],[39,51]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[39,63],[39,73]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[39,84],[39,93]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[39,100],[39,105]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[39,115],[39,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[39,166],[39,185]]]]],[],[]]],["loc",[null,[39,0],[39,187]]]],
        ["inline","sloping-info-panel",[],["hideSave",false,"gotoProduct","gotoProduct","save","saveSlopingInfo","selectBarcode","selectSlopingBarcode","actionReceiver",["subexpr","@mut",[["get","slopingInfoPanel",["loc",[null,[40,137],[40,153]]]]],[],[]]],["loc",[null,[40,0],[40,155]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});