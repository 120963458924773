define('jason-frontend/templates/components/future-add-reminder-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 8
            },
            "end": {
              "line": 48,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/components/future-add-reminder-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-12/24 mb20");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","nextExecution");
          dom.setAttribute(el2,"class","field-label title");
          var el3 = dom.createTextNode("Nächstes Auslösen am");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","date-picker__body");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["id","nextExecutionCustomPicker","value",["subexpr","@mut",[["get","data.nextExecutionCustom",["loc",[null,[45,59],[45,83]]]]],[],[]],"class"," input date-picker__input","name","nextExecution","placeholder","Datum"],["loc",[null,[45,14],[45,160]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 103,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/future-add-reminder-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-future-add-reminder-panel");
        dom.setAttribute(el1,"class","popup-basic popup-large  admin-form mfp-with-anim mfp-hide small--heading");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","addReminderForm");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-titl");
        var el6 = dom.createTextNode("\n                Neue Erinnerung planen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-body p25 pb0 grid");
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","field col l-24/24");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","new-not-title");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Überschrift");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","field");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","reminderText");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Erinnerungstext");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","field col l-24/24");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","reminderClosing");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Abschlusssatz");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 mb20");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","new-not-title");
        dom.setAttribute(el6,"class","field-label title");
        var el7 = dom.createTextNode("Auslösen in");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"for","quickselect");
        dom.setAttribute(el6,"class","inline-block select--inline input-element");
        dom.setAttribute(el6,"style","width: 300px;");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","icon-button icon-button--small");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $(\"body\").delegate(\"#nextExecutionCustomPicker\", \"focusin\", function(){\n      $(this).daterangepicker({\n        singleDatePicker: true,\n        showDropdowns: true,\n        minDate: moment().add(1, 'day').toDate(),\n        \"locale\": {\n          \"format\": \"DD. MM. YYYY\",\n          \"daysOfWeek\": [\n            \"So\",\n            \"Mo\",\n            \"Di\",\n            \"Mi\",\n            \"Do\",\n            \"Fr\",\n            \"Sa\"\n          ],\n          \"monthNames\": [\n            \"Jänner\",\n            \"Februar\",\n            \"März\",\n            \"April\",\n            \"Mai\",\n            \"Juni\",\n            \"Juli\",\n            \"August\",\n            \"September\",\n            \"Oktober\",\n            \"November\",\n            \"Dezember\"\n          ],\n          \"firstDay\": 1\n        },\n      });\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element0, [5]);
        var element3 = dom.childAt(element2, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]),3,3);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]),3,3);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [5]),3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [7, 3]),1,1);
        morphs[5] = dom.createMorphAt(element1,9,9);
        morphs[6] = dom.createElementMorph(element3);
        morphs[7] = dom.createMorphAt(element3,1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["confirm"],["on","submit"],["loc",[null,[3,31],[3,63]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","data.reminderHeadline",["loc",[null,[13,24],[13,45]]]]],[],[]],"class","gui-input","name","new-not-title","autocomplete","off"],["loc",[null,[13,10],[13,105]]]],
        ["inline","textarea",[],["rows",7,"value",["subexpr","@mut",[["get","data.reminderText",["loc",[null,[18,35],[18,52]]]]],[],[]],"class","input textarea__input full","name","reminderText","autocomplete","off"],["loc",[null,[18,10],[18,128]]]],
        ["inline","textarea",[],["rows",3,"value",["subexpr","@mut",[["get","data.reminderClosing",["loc",[null,[23,34],[23,54]]]]],[],[]],"class","input textarea__input full","name","reminderClosing","autocomplete","off"],["loc",[null,[23,10],[23,133]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","intervals",["loc",[null,[30,22],[30,31]]]]],[],[]],"value",["subexpr","@mut",[["get","data.nextExecution",["loc",[null,[31,20],[31,38]]]]],[],[]],"cssClass","custom-select2 newStyle small","optionLabelPath","value","optionValuePath","id","allowClear",true,"placeholder","Bitte wählen","name","intervals"],["loc",[null,[29,12],[38,14]]]],
        ["block","if",[["subexpr","eq",[["get","data.nextExecution",["loc",[null,[41,18],[41,36]]]],"misc"],[],["loc",[null,[41,14],[41,44]]]]],[],0,null,["loc",[null,[41,8],[48,15]]]],
        ["element","action",["cancel"],[],["loc",[null,[53,50],[53,69]]]],
        ["inline","button-prev",[],["content","Abbrechen"],["loc",[null,[54,10],[54,45]]]],
        ["inline","button-save",[],["size","44","color","#ffffff","content","Speichern"],["loc",[null,[57,10],[57,71]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});