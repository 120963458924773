define('jason-frontend/templates/customers/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 22,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/merge.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Kund:in zusammenführen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          dom.setAttribute(el1,"id","customersVcardExportForm");
          dom.setAttribute(el1,"method","post");
          dom.setAttribute(el1,"action","api/customers/exportVcard");
          dom.setAttribute(el1,"target","_blank");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","mandant_id");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","token");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2,"type","hidden");
          dom.setAttribute(el2,"name","filterId");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"class","button-rework context");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/rework/download.svg");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("vCard herunterladen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context ml10");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/graph.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Umsatzauswertung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element29 = dom.childAt(fragment, [1]);
          var element30 = dom.childAt(fragment, [3]);
          var element31 = dom.childAt(element30, [1]);
          var element32 = dom.childAt(element30, [5]);
          var element33 = dom.childAt(element30, [7]);
          var element34 = dom.childAt(fragment, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createElementMorph(element29);
          morphs[1] = dom.createAttrMorph(element31, 'value');
          morphs[2] = dom.createAttrMorph(element32, 'value');
          morphs[3] = dom.createElementMorph(element33);
          morphs[4] = dom.createElementMorph(element34);
          return morphs;
        },
        statements: [
          ["element","action",["openMergeCustomer"],[],["loc",[null,[6,11],[6,41]]]],
          ["attribute","value",["concat",[["get","mandantId",["loc",[null,[11,58],[11,67]]]]]]],
          ["attribute","value",["concat",[["get","model.id",["loc",[null,[13,56],[13,64]]]]]]],
          ["element","action",["checkForm","customersVcardExportForm"],[],["loc",[null,[14,48],[14,97]]]],
          ["element","action",["openRevenue",["get","model.id",["loc",[null,[19,34],[19,42]]]]],[],["loc",[null,[19,11],[19,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 8
              },
              "end": {
                "line": 29,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/download.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Ordnerinhalt herunterladen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element27 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element27);
            return morphs;
          },
          statements: [
            ["element","action",["downloadFolder"],[],["loc",[null,[25,13],[25,40]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 6
            },
            "end": {
              "line": 33,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/upload.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Dokument");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element28 = dom.childAt(fragment, [2]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createElementMorph(element28);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["get","filterName",["loc",[null,[24,19],[24,29]]]]],[],["loc",[null,[24,14],[24,30]]]]],[],0,null,["loc",[null,[24,8],[29,15]]]],
          ["element","action",["openDocumentUpload"],[],["loc",[null,[30,11],[30,42]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 6
            },
            "end": {
              "line": 38,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Tier");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element26 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element26);
          return morphs;
        },
        statements: [
          ["element","action",["showCreatePatient"],[],["loc",[null,[35,11],[35,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 6
            },
            "end": {
              "line": 43,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context  primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Termin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element25 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element25);
          return morphs;
        },
        statements: [
          ["element","action",["openNewAppointment"],[],["loc",[null,[40,11],[40,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 8
              },
              "end": {
                "line": 54,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","button-rework context primary");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Guthaben ausbezahlen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element23);
            morphs[1] = dom.createMorphAt(element23,1,1);
            return morphs;
          },
          statements: [
            ["element","action",["payoutDeposit",["get","model",["loc",[null,[50,38],[50,43]]]]],[],["loc",[null,[50,13],[50,45]]]],
            ["inline","button-cash",[],["color","#fff","size","32","showStroke",false],["loc",[null,[51,12],[51,67]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 6
            },
            "end": {
              "line": 55,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/printer.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Saldoübersicht drucken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element24);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["print",["get","model",["loc",[null,[45,28],[45,33]]]]],[],["loc",[null,[45,11],[45,35]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[49,18],[49,28]]]],0],[],["loc",[null,[49,14],[49,31]]]]],[],0,null,["loc",[null,[49,8],[54,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 6
            },
            "end": {
              "line": 60,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/upload.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element22);
          return morphs;
        },
        statements: [
          ["element","action",["showCreateConsent"],[],["loc",[null,[57,11],[57,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 67,
              "column": 4
            },
            "end": {
              "line": 69,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","gender-icon",[],["classNames","owner-gender me-20","useFilter",false,"size","55","color","#fff","gender",["subexpr","@mut",[["get","model.gender.id",["loc",[null,[68,98],[68,113]]]]],[],[]],"content",""],["loc",[null,[68,6],[68,126]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 87
            },
            "end": {
              "line": 71,
              "column": 208
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","button-ical",[],["size",34,"classNames","ms-10","color","#00AAC6","content","eltiga App BenutzerIn"],["loc",[null,[71,118],[71,208]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 208
              },
              "end": {
                "line": 71,
                "column": 343
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-ical",[],["size",34,"classNames","ms-10","color","#797979","content","eltiga Tier QR Code verschickt"],["loc",[null,[71,244],[71,343]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 208
            },
            "end": {
              "line": 71,
              "column": 343
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.etigaCodeRequested",["loc",[null,[71,218],[71,242]]]]],[],0,null,["loc",[null,[71,208],[71,343]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 6
            },
            "end": {
              "line": 86,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","topcard-badge me-5");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","textBelow");
          var el4 = dom.createElement("b");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Guthaben");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element21 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element21, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element21, [1, 0]),0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",[" ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","depositSum",["loc",[null,[82,48],[82,58]]]],0],[],["loc",[null,[82,44],[82,61]]]],"is-negative","positive"],[],["loc",[null,[82,23],[82,88]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[83,63],[83,73]]]]],[],[]],"classNames","inline-block"],["loc",[null,[83,39],[83,101]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 90,
                  "column": 136
                },
                "end": {
                  "line": 90,
                  "column": 166
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("en");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 90,
                "column": 36
              },
              "end": {
                "line": 90,
                "column": 173
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Ausgangsrechnung");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","invoiceMeta.total_records",["loc",[null,[90,90],[90,119]]]],
            ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[90,146],[90,159]]]],1],[],["loc",[null,[90,142],[90,162]]]]],[],0,null,["loc",[null,[90,136],[90,173]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 87,
              "column": 6
            },
            "end": {
              "line": 93,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","topcard-badge cursor-pointer");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","textBelow");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["block","link-to",["cash.choose"],["classNames","is-highlighted"],0,null,["loc",[null,[90,36],[90,185]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 101,
              "column": 2
            },
            "end": {
              "line": 106,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-accounting");
          var el2 = dom.createTextNode("Verrechnung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-warning");
          var el2 = dom.createTextNode("Mahnwesen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-saldo");
          var el2 = dom.createTextNode("Saldo\n        Informationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [1]);
          var element19 = dom.childAt(fragment, [3]);
          var element20 = dom.childAt(fragment, [5]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element18, 'class');
          morphs[1] = dom.createElementMorph(element18);
          morphs[2] = dom.createAttrMorph(element19, 'class');
          morphs[3] = dom.createElementMorph(element19);
          morphs[4] = dom.createAttrMorph(element20, 'class');
          morphs[5] = dom.createElementMorph(element20);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[102,53],[102,62]]]],"accounting"],[],["loc",[null,[102,49],[102,76]]]],"is-active",""],[],["loc",[null,[102,28],[102,93]]]]]]],
          ["element","action",["selectTab","accounting"],[],["loc",[null,[102,118],[102,153]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[103,53],[103,62]]]],"warning"],[],["loc",[null,[103,49],[103,73]]]],"is-active",""],[],["loc",[null,[103,28],[103,90]]]]]]],
          ["element","action",["selectTab","warning"],[],["loc",[null,[103,112],[103,144]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[104,53],[104,62]]]],"saldo"],[],["loc",[null,[104,49],[104,71]]]],"is-active",""],[],["loc",[null,[104,28],[104,88]]]]]]],
          ["element","action",["selectTab","saldo"],[],["loc",[null,[104,108],[104,138]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 110,
              "column": 2
            },
            "end": {
              "line": 112,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-appointments");
          var el2 = dom.createTextNode("Termine");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element17, 'class');
          morphs[1] = dom.createElementMorph(element17);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[111,53],[111,62]]]],"appointments"],[],["loc",[null,[111,49],[111,78]]]],"is-active",""],[],["loc",[null,[111,28],[111,95]]]]]]],
          ["element","action",["selectTab","appointments"],[],["loc",[null,[111,122],[111,159]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 300,
                "column": 26
              },
              "end": {
                "line": 310,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","select-placeholder");
            var el2 = dom.createTextNode("Produktkategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","productCategories",["loc",[null,[303,46],[303,63]]]]],[],[]],"value",["subexpr","@mut",[["get","reduction.productCategory",["loc",[null,[304,44],[304,69]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[302,30],[309,32]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 311,
                "column": 26
              },
              "end": {
                "line": 321,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","select-placeholder");
            var el2 = dom.createTextNode("Leistungskategorie");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","serviceCategories",["loc",[null,[314,46],[314,63]]]]],[],[]],"value",["subexpr","@mut",[["get","reduction.serviceCategory",["loc",[null,[315,44],[315,69]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[313,30],[320,32]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 332,
                "column": 26
              },
              "end": {
                "line": 337,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element14);
            return morphs;
          },
          statements: [
            ["element","action",["addReduction",["get","reduction",["loc",[null,[333,55],[333,64]]]]],[],["loc",[null,[333,31],[333,66]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 338,
                "column": 26
              },
              "end": {
                "line": 343,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
            var el2 = dom.createTextNode("\n                              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/trash.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element13);
            return morphs;
          },
          statements: [
            ["element","action",["removeReduction",["get","reduction",["loc",[null,[339,58],[339,67]]]]],[],["loc",[null,[339,31],[339,69]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 271,
              "column": 18
            },
            "end": {
              "line": 346,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col  l-6/24@desk 12/24 gutter");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Patient(en)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col  l-6/24@desk 12/24 gutter");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Typ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-6/24@desk 12/24 gutter");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-4/24@desk 12/24 gutter");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-reduction");
          dom.setAttribute(el3,"class","placeholder");
          var el4 = dom.createTextNode("Rabatt in %");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-2/24 gutter");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","line-action-buttons");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [5, 1]);
          var element16 = dom.childAt(fragment, [9, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(element15,1,1);
          morphs[3] = dom.createMorphAt(element15,2,2);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [7, 1]),1,1);
          morphs[5] = dom.createMorphAt(element16,1,1);
          morphs[6] = dom.createMorphAt(element16,2,2);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","model.patients",["loc",[null,[276,36],[276,50]]]]],[],[]],"value",["subexpr","@mut",[["get","reduction.patient",["loc",[null,[277,34],[277,51]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[275,26],[282,28]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","customerReductionTypes",["loc",[null,[289,34],[289,56]]]]],[],[]],"value",["subexpr","@mut",[["get","reduction.type",["loc",[null,[290,32],[290,46]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[288,24],[295,26]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[300,36],[300,53]]]],"product"],[],["loc",[null,[300,32],[300,64]]]]],[],0,null,["loc",[null,[300,26],[310,33]]]],
          ["block","if",[["subexpr","eq",[["get","reduction.type.id",["loc",[null,[311,36],[311,53]]]],"service"],[],["loc",[null,[311,32],[311,64]]]]],[],1,null,["loc",[null,[311,26],[321,33]]]],
          ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","reduction.reduction",["loc",[null,[326,55],[326,74]]]]],[],[]],"name","user-reduction"],["loc",[null,[326,26],[326,98]]]],
          ["block","if",[["subexpr","eq",[["get","reduction",["loc",[null,[332,36],[332,45]]]],["get","model.reductions.lastObject",["loc",[null,[332,46],[332,73]]]]],[],["loc",[null,[332,32],[332,74]]]]],[],2,null,["loc",[null,[332,26],[337,33]]]],
          ["block","if",[["subexpr","not",[["subexpr","eq",[["get","reduction",["loc",[null,[338,41],[338,50]]]],["get","model.reductions.firstObject",["loc",[null,[338,51],[338,79]]]]],[],["loc",[null,[338,37],[338,80]]]]],[],["loc",[null,[338,32],[338,81]]]]],[],3,null,["loc",[null,[338,26],[343,33]]]]
        ],
        locals: ["reduction"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 447,
                "column": 26
              },
              "end": {
                "line": 449,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[448,28],[448,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 460,
                      "column": 40
                    },
                    "end": {
                      "line": 460,
                      "column": 200
                    }
                  },
                  "moduleName": "jason-frontend/templates/customers/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","button__icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[460,110],[460,131]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[460,142],[460,161]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[460,170],[460,194]]]]],[],[]]],["loc",[null,[460,88],[460,196]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 457,
                    "column": 34
                  },
                  "end": {
                    "line": 464,
                    "column": 34
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","patient-selector selected");
                var el2 = dom.createTextNode("\n                                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element7, 'class');
                morphs[1] = dom.createMorphAt(element7,1,1);
                morphs[2] = dom.createMorphAt(element7,3,3);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["button ",["subexpr","value-bool-evaluator",[["get","patient.category",["loc",[null,[459,80],[459,96]]]],"button--has-icon",""],[],["loc",[null,[459,57],[459,120]]]]," button--toggle no-hover  large-confirm"]]],
                ["block","if",[["get","patient.category",["loc",[null,[460,46],[460,62]]]]],[],0,null,["loc",[null,[460,40],[460,207]]]],
                ["content","patient.name",["loc",[null,[461,40],[461,56]]]]
              ],
              locals: ["patient"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 450,
                  "column": 28
                },
                "end": {
                  "line": 478,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-3/24@desk");
              dom.setAttribute(el2,"data-label","Datum");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24 l-13/24@desk");
              dom.setAttribute(el2,"data-label","Leistungsempfänger");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--right@desk table__cell--name l-12/24 l-24/24@tablet l-4/24@desk");
              dom.setAttribute(el2,"data-label","Summe brutto");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-3");
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","exp icon-button icon-button--success list-action-square");
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("Auswählen");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","exp icon-button icon-button--delete list-action-square");
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [1]);
              var element10 = dom.childAt(element8, [7]);
              var element11 = dom.childAt(element10, [1]);
              var element12 = dom.childAt(element10, [3]);
              var morphs = new Array(8);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element8, [5, 1]),0,0);
              morphs[4] = dom.createElementMorph(element11);
              morphs[5] = dom.createMorphAt(element11,1,1);
              morphs[6] = dom.createMorphAt(element12,1,1);
              morphs[7] = dom.createMorphAt(dom.childAt(element12, [2]),0,0);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","invoice.created",["loc",[null,[453,54],[453,69]]]],"DD. MM. YYYY"],[],["loc",[null,[453,40],[453,86]]]],
              ["inline","time-format",[["get","invoice.created",["loc",[null,[454,66],[454,81]]]],"HH:mm"],[],["loc",[null,[454,52],[454,91]]]],
              ["block","each",[["get","invoice.selectedPatients",["loc",[null,[457,42],[457,66]]]]],[],0,null,["loc",[null,[457,34],[464,43]]]],
              ["inline","format-currency",[],["value",["subexpr","@mut",[["get","invoice.total",["loc",[null,[467,64],[467,77]]]]],[],[]]],["loc",[null,[467,40],[467,79]]]],
              ["element","action",["selectInvoice",["get","invoice.id",["loc",[null,[470,62],[470,72]]]]],[],["loc",[null,[470,37],[470,74]]]],
              ["inline","button-select",[],["color","white","showStroke",false],["loc",[null,[471,36],[471,84]]]],
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"item",["subexpr","@mut",[["get","invoice",["loc",[null,[474,101],[474,108]]]]],[],[]],"confirmed","closeInvoice","text","Wollen Sie diese Ausgangsrechnung wirklich löschen?"],["loc",[null,[474,36],[474,194]]]],
              ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","invoice",["loc",[null,[474,225],[474,232]]]]],[],[]],"confirmed","closeInvoice","textToShow","Löschen","text","Wollen Sie diese Ausgangsrechnung wirklich löschen?"],["loc",[null,[474,200],[474,339]]]]
            ],
            locals: ["invoice"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 449,
                "column": 26
              },
              "end": {
                "line": 479,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","invoices",["loc",[null,[450,36],[450,44]]]]],[],0,null,["loc",[null,[450,28],[478,37]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 437,
              "column": 20
            },
            "end": {
              "line": 483,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table-wrapper");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table box no-box@phone grid table--large");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__head");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-3/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Datum");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-13/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Leistungsempfänger");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell table__cell--right l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Summe brutto");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-4/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showLoading",["loc",[null,[447,32],[447,43]]]]],[],0,1,["loc",[null,[447,26],[479,33]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 483,
              "column": 18
            },
            "end": {
              "line": 485,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Keine Entwürfe gefunden");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 500,
              "column": 20
            },
            "end": {
              "line": 500,
              "column": 240
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("label");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [0]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element6, 'class');
          morphs[1] = dom.createAttrMorph(element6, 'style');
          morphs[2] = dom.createAttrMorph(element6, 'data-id');
          morphs[3] = dom.createElementMorph(element6);
          morphs[4] = dom.createMorphAt(element6,0,0);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[500,96],[500,108]]]],"selected",""],[],["loc",[null,[500,75],[500,124]]]]]]],
          ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[500,153],[500,162]]]]]]],
          ["attribute","data-id",["concat",[["get","tag.id",["loc",[null,[500,177],[500,183]]]]]]],
          ["element","action",["selectSearchTag",["get","tag",["loc",[null,[500,214],[500,217]]]]],[],["loc",[null,[500,187],[500,219]]]],
          ["content","tag.name",["loc",[null,[500,220],[500,232]]]]
        ],
        locals: ["tag"],
        templates: []
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 540,
                  "column": 32
                },
                "end": {
                  "line": 542,
                  "column": 32
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"style","width: 27px;");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element4, 'src');
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",["assets/images/icons/",["get","patient.gender.id",["loc",[null,[541,87],[541,104]]]],".svg"]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 545,
                  "column": 34
                },
                "end": {
                  "line": 545,
                  "column": 219
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","animal-icon",[],["evil",["subexpr","@mut",[["get","patient.evil",["loc",[null,[545,77],[545,89]]]]],[],[]],"classNames","inline-icon","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[545,125],[545,144]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[545,153],[545,177]]]]],[],[]],"size","32","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[545,196],[545,217]]]]],[],[]]],["loc",[null,[545,58],[545,219]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 549,
                    "column": 49
                  },
                  "end": {
                    "line": 551,
                    "column": 32
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","icon-etiga",[],["classNames","inline-icon","size","20","width",60,"color","00AAC6","connected",true],["loc",[null,[550,32],[550,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 551,
                    "column": 40
                  },
                  "end": {
                    "line": 551,
                    "column": 158
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[551,60],[551,158]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 548,
                  "column": 30
                },
                "end": {
                  "line": 552,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["content","patient.name",["loc",[null,[549,32],[549,48]]]],
              ["block","if",[["get","patient.etigaActive",["loc",[null,[549,55],[549,74]]]]],[],0,null,["loc",[null,[549,49],[551,39]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[551,46],[551,58]]]]],[],1,null,["loc",[null,[551,40],[551,165]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 553,
                    "column": 32
                  },
                  "end": {
                    "line": 553,
                    "column": 87
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","patient.name",["loc",[null,[553,71],[553,87]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 553,
                    "column": 101
                  },
                  "end": {
                    "line": 555,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                              ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","icon-etiga",[],["classNames","inline-icon","size","20","width",60,"color","00AAC6","connected",true],["loc",[null,[554,32],[554,120]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 555,
                    "column": 38
                  },
                  "end": {
                    "line": 555,
                    "column": 156
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-died",[],["classNames","ml10","size","21","useConfirm",false,"showStroke",false,"content","verstorben"],["loc",[null,[555,58],[555,156]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 552,
                  "column": 30
                },
                "end": {
                  "line": 556,
                  "column": 30
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              return morphs;
            },
            statements: [
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[553,59],[553,69]]]]],[],0,null,["loc",[null,[553,32],[553,99]]]],
              ["block","if",[["get","patient.etigaActive",["loc",[null,[553,107],[553,126]]]]],[],1,null,["loc",[null,[553,101],[555,37]]]],
              ["block","if",[["get","patient.dead",["loc",[null,[555,44],[555,56]]]]],[],2,null,["loc",[null,[555,38],[555,163]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 561,
                  "column": 28
                },
                "end": {
                  "line": 561,
                  "column": 83
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.digitalRace.name",["loc",[null,[561,55],[561,83]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 561,
                  "column": 83
                },
                "end": {
                  "line": 561,
                  "column": 107
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","patient.race",["loc",[null,[561,91],[561,107]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 565,
                  "column": 28
                },
                "end": {
                  "line": 569,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("wiederherstellen");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element3);
              morphs[1] = dom.createMorphAt(element3,1,1);
              return morphs;
            },
            statements: [
              ["element","action",["undeletePatient",["get","patient.id",["loc",[null,[566,124],[566,134]]]]],[],["loc",[null,[566,97],[566,136]]]],
              ["inline","button-checked",[],["content","Gelöschten Datensatz wiederherstellen","color","#fff","showStroke",false],["loc",[null,[567,32],[567,128]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child7 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 574,
                    "column": 30
                  },
                  "end": {
                    "line": 576,
                    "column": 30
                  }
                },
                "moduleName": "jason-frontend/templates/customers/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 569,
                  "column": 28
                },
                "end": {
                  "line": 580,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/customers/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("                              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/barcode.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [5]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createElementMorph(element2);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm-id",[],["color","#fff","showStroke",false,"itemId",["subexpr","@mut",[["get","patient.id",["loc",[null,[571,101],[571,111]]]]],[],[]],"confirmed","deletePatient","text","Wollen Sie dieses Tier wirklich löschen?"],["loc",[null,[571,32],[572,113]]]],
              ["block","link-to",["patients.edit",["get","patient.id",["loc",[null,[574,57],[574,67]]]]],["classNames","exp icon-button icon-button--default list-action-square"],0,null,["loc",[null,[574,30],[576,42]]]],
              ["element","action",["selectBarcode",["get","patient",["loc",[null,[577,58],[577,65]]]]],[],["loc",[null,[577,33],[577,67]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 536,
                "column": 26
              },
              "end": {
                "line": 583,
                "column": 26
              }
            },
            "moduleName": "jason-frontend/templates/customers/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Geschlecht");
            var el3 = dom.createTextNode("\n                              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-1/24@desk");
            dom.setAttribute(el2,"data-label","Art");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24 l-12/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","patient-view");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Spezies");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@tablet l-5/24@desk");
            dom.setAttribute(el2,"data-label","Rasse");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-6/24 l-6/24@tablet l-4/24@desk");
            dom.setAttribute(el2,"data-label","Farbe");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  table__cell--action l-4/24@desk buttons-3");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [2, 1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element5, [3, 1]),1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element5, [4]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element5, [5]),1,1);
            morphs[5] = dom.createMorphAt(dom.childAt(element5, [6]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element5, [7]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","patient.gender",["loc",[null,[540,38],[540,52]]]]],[],0,null,["loc",[null,[540,32],[542,39]]]],
            ["block","if",[["get","patient.category",["loc",[null,[545,40],[545,56]]]]],[],1,null,["loc",[null,[545,34],[545,226]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[548,36],[548,51]]]]],[],2,3,["loc",[null,[548,30],[556,37]]]],
            ["content","patient.category.name",["loc",[null,[559,28],[559,53]]]],
            ["block","if",[["get","patient.digitalRace",["loc",[null,[561,34],[561,53]]]]],[],4,5,["loc",[null,[561,28],[561,114]]]],
            ["content","patient.color",["loc",[null,[563,28],[563,45]]]],
            ["block","if",[["get","patient.deleted",["loc",[null,[565,34],[565,49]]]]],[],6,7,["loc",[null,[565,28],[580,35]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 535,
              "column": 24
            },
            "end": {
              "line": 584,
              "column": 24
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","or",[["subexpr","not",[["get","patient.dead",["loc",[null,[536,41],[536,53]]]]],[],["loc",[null,[536,36],[536,54]]]],["subexpr","and",[["subexpr","or",[["subexpr","eq",[["get","quickFilter",["loc",[null,[536,68],[536,79]]]],"all"],[],["loc",[null,[536,64],[536,86]]]],["subexpr","eq",[["get","quickFilter",["loc",[null,[536,91],[536,102]]]],"deleted"],[],["loc",[null,[536,87],[536,113]]]]],[],["loc",[null,[536,60],[536,114]]]],["get","patient.dead",["loc",[null,[536,115],[536,127]]]]],[],["loc",[null,[536,55],[536,128]]]]],[],["loc",[null,[536,32],[536,129]]]]],[],0,null,["loc",[null,[536,26],[583,33]]]]
        ],
        locals: ["patient"],
        templates: [child0]
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 597,
              "column": 18
            },
            "end": {
              "line": 608,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","field");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"for","patient-consent");
          dom.setAttribute(el2,"class","field-label title required");
          var el3 = dom.createTextNode("Status aus vorherigem Praxismanagementsystem");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","checkbox-custom checkbox-primary field-checkbox");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","patient-consent");
          var el4 = dom.createTextNode("DSGVO akzeptiert");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["disabled",true,"type","checkbox","name","patient.consent","id","patient-consent","checked",true],["loc",[null,[602,24],[602,120]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 620,
              "column": 6
            },
            "end": {
              "line": 624,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[621,11],[621,38]]]],
          ["inline","button-upload",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[622,10],[622,105]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 625,
              "column": 6
            },
            "end": {
              "line": 629,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--cancel");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-delete-with-confirm",[],["item",["subexpr","@mut",[["get","customer",["loc",[null,[627,44],[627,52]]]]],[],[]],"confirmed","delete","size",52,"showStroke",false,"color","#fff","text","Wollen Sie diese/n Kunde/in wirklich löschen?"],["loc",[null,[627,10],[627,164]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 630,
              "column": 6
            },
            "end": {
              "line": 634,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSave"],[],["loc",[null,[631,13],[631,37]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child22 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 657,
              "column": 0
            },
            "end": {
              "line": 659,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/customers/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","document-forward-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardDocumentPanel",["loc",[null,[658,42],[658,62]]]]],[],[]],"confirm","sendDocumentMail"],["loc",[null,[658,2],[658,91]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 703,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/customers/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Kundenstamm");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","card mb-0 min-h-130px customer-card");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex flex-row");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","mb0 d-flex align-items-center");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","mt-0 mb-0");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(", ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ml-auto d-flex align-items-center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","topcard-badge me-5");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","textBelow");
        var el7 = dom.createElement("b");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Saldo");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","rework-content tabs-rework-wrapper grid");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-master-data");
        var el3 = dom.createTextNode("Stammdaten");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-reminder");
        var el3 = dom.createTextNode("Erinnerungen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-documents");
        var el3 = dom.createTextNode("Dokumente");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-patients");
        var el3 = dom.createTextNode("Tiere");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-correspondance");
        var el3 = dom.createTextNode("Korrespondenz");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-consents");
        var el3 = dom.createTextNode("Einverständniserklärungen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","customerEdit");
        dom.setAttribute(el4,"class","admin-form");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-accounting");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Zahlungsinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","select-placeholder");
        var el10 = dom.createTextNode("Zahlungsmoral");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class"," d-flex align-items-center");
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","checkbox-custom checkbox-primary field-checkbox");
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","user-sued");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","user-sued");
        dom.setAttribute(el10,"class","checkbox-label");
        var el11 = dom.createTextNode("Gesperrt weil Nichtzahler");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Rechnungsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-addition");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Briefzusatz");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class"," d-flex align-items-center");
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","checkbox-custom checkbox-primary field-checkbox");
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","user-differentInvoice");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","user-differentInvoice");
        dom.setAttribute(el10,"class","checkbox-label");
        var el11 = dom.createTextNode("Alternative\n                            Rechnungsadresse?");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-firstname");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Vorname");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-lastname");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Nachname");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","select-placeholder");
        var el10 = dom.createTextNode("Titel");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-invoiceStreet");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Straße/Hausnummer");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-postalcode");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("PLZ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-town");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Ort");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoice-uid");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("UID");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Rechnungskonditionen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-invoiceConditions");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Konditionen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class"," d-flex align-items-center");
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","checkbox-custom checkbox-primary field-checkbox");
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("label");
        dom.setAttribute(el11,"for","user-sued");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                          ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                          ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","user-reductionToPurchasePrice");
        dom.setAttribute(el10,"class","checkbox-label");
        var el11 = dom.createTextNode("Rabatt zum Einkaufspreis");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Bankverbindung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-iban");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Iban");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-bic");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("BIC");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Rabattinformationen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","gutter-fix grid");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Anfahrtsadresse");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","grid gutter-fix");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-driveNotice");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Bezeichnung");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-driveStreet");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Straße");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-drivePostalcode");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("PLZ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-distanceKm");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Entfernung in km");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-12/24@desk 24/24 gutter");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","user-distanceMinutes");
        dom.setAttribute(el9,"class","placeholder");
        var el10 = dom.createTextNode("Entfernung in km");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-warning");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Kundenspezifische Mahnzeiten");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex align-items-center mb-10");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework small-number mb-0");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning1");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ms-10");
        var el8 = dom.createTextNode("Tage bis 1. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("b");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(")");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex align-items-center mb-10");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework small-number mb-0");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-warning2");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ms-10");
        var el8 = dom.createTextNode("Tage bis 2. Mahnung verschickt wird. (Allgemeine Einstellung: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("b");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(")");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex align-items-center mb-30");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework small-number mb-0");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","mandant-days-till-nopayer");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","ms-10");
        var el8 = dom.createTextNode("Tage bis Kunde als Nichtzahler markiert wird (nach dem Verschicken der 2. Mahnung).  (Allgemeine Einstellung: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("b");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" Tage");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(")");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","is-highlighted");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Tipp:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Um das Mahnwesen für diesen Kunden zu deaktivieren muss in den entsprechenden Feldern ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("0");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" eingetragen werden.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-reminder");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Übermittlungseinstellung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24@desk 24/24 d-flex align-items-center");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework mb-0 me-20");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","select-placeholder");
        var el9 = dom.createTextNode("Auswahl");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        var el8 = dom.createTextNode("Standardeinstellung: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("b");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-saldo");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Ausgangsrechnungen (Entwürfe)");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Saldoübersicht");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper mt20");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-medias");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-documents");
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","d-flex mt-10");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","ml-auto pt-10 quick-filter");
        dom.setAttribute(el7,"id","tagFilters");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-patients");
        var el6 = dom.createTextNode("\n\n\n\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper-rework");
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","quick-filter-wrapper");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","quick-filter");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("span");
        var el10 = dom.createTextNode("Filter");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        var el10 = dom.createTextNode("Alle anzeigen");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        var el10 = dom.createTextNode("Verstorbene ausblenden");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        var el10 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table grid table--large");
        var el8 = dom.createTextNode("\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__head");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-2/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Name");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Spezies");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-5/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Rasse");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Farbe");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-4/24");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__body");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                      ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-appointments");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-correspondance");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-consents");
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        dom.setAttribute(el3,"onclick","history.back()");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {\n            $(this).val(picker.startDate.format('DD. MM. YYYY'));\n            $(this).focus();\n        });\n        $('.datetimepicker').daterangepicker({\n            singleDatePicker: true,\n            autoUpdateInput: false,\n            \"locale\": {\n                \"format\": \"DD. MM. YYYY\",\n                \"daysOfWeek\": [\n                    \"So\",\n                    \"Mo\",\n                    \"Di\",\n                    \"Mi\",\n                    \"Do\",\n                    \"Fr\",\n                    \"Sa\"\n                ],\n                \"monthNames\": [\n                    \"Jänner\",\n                    \"Februar\",\n                    \"März\",\n                    \"April\",\n                    \"Mai\",\n                    \"Juni\",\n                    \"Juli\",\n                    \"August\",\n                    \"September\",\n                    \"Oktober\",\n                    \"November\",\n                    \"Dezember\"\n                ],\n                \"firstDay\": 1\n            },\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element35 = dom.childAt(fragment, [0, 1, 3]);
        var element36 = dom.childAt(fragment, [2, 1]);
        var element37 = dom.childAt(element36, [3]);
        var element38 = dom.childAt(element37, [1]);
        var element39 = dom.childAt(element37, [3]);
        var element40 = dom.childAt(element36, [5]);
        var element41 = dom.childAt(element40, [1, 1]);
        var element42 = dom.childAt(fragment, [4]);
        var element43 = dom.childAt(element42, [1]);
        var element44 = dom.childAt(element42, [5]);
        var element45 = dom.childAt(element42, [7]);
        var element46 = dom.childAt(element42, [9]);
        var element47 = dom.childAt(element42, [13]);
        var element48 = dom.childAt(element42, [15]);
        var element49 = dom.childAt(fragment, [6]);
        var element50 = dom.childAt(element49, [1, 1, 1]);
        var element51 = dom.childAt(element50, [1]);
        var element52 = dom.childAt(element50, [3]);
        var element53 = dom.childAt(element52, [3]);
        var element54 = dom.childAt(element52, [7]);
        var element55 = dom.childAt(element54, [5]);
        var element56 = dom.childAt(element54, [7]);
        var element57 = dom.childAt(element54, [11]);
        var element58 = dom.childAt(element54, [13]);
        var element59 = dom.childAt(element54, [15]);
        var element60 = dom.childAt(element54, [17]);
        var element61 = dom.childAt(element52, [11]);
        var element62 = dom.childAt(element52, [15]);
        var element63 = dom.childAt(element52, [23]);
        var element64 = dom.childAt(element50, [5]);
        var element65 = dom.childAt(element64, [3]);
        var element66 = dom.childAt(element64, [5]);
        var element67 = dom.childAt(element64, [7]);
        var element68 = dom.childAt(element50, [7]);
        var element69 = dom.childAt(element68, [3]);
        var element70 = dom.childAt(element50, [9]);
        var element71 = dom.childAt(element50, [11]);
        var element72 = dom.childAt(element50, [13]);
        var element73 = dom.childAt(element72, [1]);
        var element74 = dom.childAt(element50, [15]);
        var element75 = dom.childAt(element74, [1]);
        var element76 = dom.childAt(element75, [1, 1]);
        var element77 = dom.childAt(element76, [3]);
        var element78 = dom.childAt(element76, [5]);
        var element79 = dom.childAt(element76, [7]);
        var element80 = dom.childAt(element50, [17]);
        var element81 = dom.childAt(element50, [19]);
        var element82 = dom.childAt(element50, [21]);
        var element83 = dom.childAt(element49, [3]);
        var morphs = new Array(112);
        morphs[0] = dom.createMorphAt(element35,1,1);
        morphs[1] = dom.createMorphAt(element35,2,2);
        morphs[2] = dom.createMorphAt(element35,3,3);
        morphs[3] = dom.createMorphAt(element35,4,4);
        morphs[4] = dom.createMorphAt(element35,5,5);
        morphs[5] = dom.createMorphAt(element35,6,6);
        morphs[6] = dom.createMorphAt(element36,1,1);
        morphs[7] = dom.createMorphAt(element38,0,0);
        morphs[8] = dom.createMorphAt(element38,2,2);
        morphs[9] = dom.createMorphAt(element38,4,4);
        morphs[10] = dom.createMorphAt(element39,0,0);
        morphs[11] = dom.createMorphAt(element39,2,2);
        morphs[12] = dom.createMorphAt(element39,4,4);
        morphs[13] = dom.createAttrMorph(element41, 'class');
        morphs[14] = dom.createMorphAt(dom.childAt(element41, [1, 0]),0,0);
        morphs[15] = dom.createMorphAt(element40,3,3);
        morphs[16] = dom.createMorphAt(element40,4,4);
        morphs[17] = dom.createMorphAt(element40,6,6);
        morphs[18] = dom.createAttrMorph(element43, 'class');
        morphs[19] = dom.createElementMorph(element43);
        morphs[20] = dom.createMorphAt(element42,3,3);
        morphs[21] = dom.createAttrMorph(element44, 'class');
        morphs[22] = dom.createElementMorph(element44);
        morphs[23] = dom.createAttrMorph(element45, 'class');
        morphs[24] = dom.createElementMorph(element45);
        morphs[25] = dom.createAttrMorph(element46, 'class');
        morphs[26] = dom.createElementMorph(element46);
        morphs[27] = dom.createMorphAt(element42,11,11);
        morphs[28] = dom.createAttrMorph(element47, 'class');
        morphs[29] = dom.createElementMorph(element47);
        morphs[30] = dom.createAttrMorph(element48, 'class');
        morphs[31] = dom.createElementMorph(element48);
        morphs[32] = dom.createElementMorph(element50);
        morphs[33] = dom.createAttrMorph(element51, 'class');
        morphs[34] = dom.createMorphAt(element51,1,1);
        morphs[35] = dom.createAttrMorph(element52, 'class');
        morphs[36] = dom.createMorphAt(dom.childAt(element53, [1, 1]),3,3);
        morphs[37] = dom.createMorphAt(dom.childAt(element53, [3, 1, 1, 1]),1,1);
        morphs[38] = dom.createMorphAt(dom.childAt(element54, [1, 1]),1,1);
        morphs[39] = dom.createMorphAt(dom.childAt(element54, [3, 1, 1, 1]),1,1);
        morphs[40] = dom.createAttrMorph(element55, 'class');
        morphs[41] = dom.createMorphAt(dom.childAt(element55, [1]),1,1);
        morphs[42] = dom.createAttrMorph(element56, 'class');
        morphs[43] = dom.createMorphAt(dom.childAt(element56, [1]),1,1);
        morphs[44] = dom.createMorphAt(dom.childAt(element54, [9, 1]),3,3);
        morphs[45] = dom.createAttrMorph(element57, 'class');
        morphs[46] = dom.createMorphAt(dom.childAt(element57, [1]),1,1);
        morphs[47] = dom.createAttrMorph(element58, 'class');
        morphs[48] = dom.createMorphAt(dom.childAt(element58, [1]),1,1);
        morphs[49] = dom.createAttrMorph(element59, 'class');
        morphs[50] = dom.createMorphAt(dom.childAt(element59, [1]),1,1);
        morphs[51] = dom.createAttrMorph(element60, 'class');
        morphs[52] = dom.createMorphAt(dom.childAt(element60, [1]),1,1);
        morphs[53] = dom.createMorphAt(dom.childAt(element61, [1, 1]),1,1);
        morphs[54] = dom.createMorphAt(dom.childAt(element61, [3, 1, 1, 1]),1,1);
        morphs[55] = dom.createMorphAt(dom.childAt(element62, [1, 1]),1,1);
        morphs[56] = dom.createMorphAt(dom.childAt(element62, [3, 1]),1,1);
        morphs[57] = dom.createMorphAt(dom.childAt(element52, [19]),1,1);
        morphs[58] = dom.createMorphAt(dom.childAt(element63, [1, 1]),1,1);
        morphs[59] = dom.createMorphAt(dom.childAt(element63, [3, 1]),1,1);
        morphs[60] = dom.createMorphAt(dom.childAt(element63, [5, 1]),1,1);
        morphs[61] = dom.createMorphAt(dom.childAt(element63, [7, 1]),1,1);
        morphs[62] = dom.createMorphAt(dom.childAt(element63, [9, 1]),1,1);
        morphs[63] = dom.createAttrMorph(element64, 'class');
        morphs[64] = dom.createMorphAt(dom.childAt(element65, [1]),1,1);
        morphs[65] = dom.createMorphAt(dom.childAt(element65, [3, 1]),0,0);
        morphs[66] = dom.createMorphAt(dom.childAt(element66, [1]),1,1);
        morphs[67] = dom.createMorphAt(dom.childAt(element66, [3, 1]),0,0);
        morphs[68] = dom.createMorphAt(dom.childAt(element67, [1]),1,1);
        morphs[69] = dom.createMorphAt(dom.childAt(element67, [3, 1]),0,0);
        morphs[70] = dom.createAttrMorph(element68, 'class');
        morphs[71] = dom.createMorphAt(dom.childAt(element69, [1]),3,3);
        morphs[72] = dom.createMorphAt(dom.childAt(element69, [3, 1]),0,0);
        morphs[73] = dom.createAttrMorph(element70, 'class');
        morphs[74] = dom.createMorphAt(element70,3,3);
        morphs[75] = dom.createMorphAt(dom.childAt(element70, [7]),1,1);
        morphs[76] = dom.createAttrMorph(element71, 'class');
        morphs[77] = dom.createMorphAt(element71,1,1);
        morphs[78] = dom.createAttrMorph(element72, 'class');
        morphs[79] = dom.createMorphAt(element73,1,1);
        morphs[80] = dom.createMorphAt(dom.childAt(element73, [3]),1,1);
        morphs[81] = dom.createMorphAt(element72,3,3);
        morphs[82] = dom.createAttrMorph(element74, 'class');
        morphs[83] = dom.createAttrMorph(element77, 'class');
        morphs[84] = dom.createElementMorph(element77);
        morphs[85] = dom.createAttrMorph(element78, 'class');
        morphs[86] = dom.createElementMorph(element78);
        morphs[87] = dom.createAttrMorph(element79, 'class');
        morphs[88] = dom.createElementMorph(element79);
        morphs[89] = dom.createMorphAt(dom.childAt(element75, [3, 3]),1,1);
        morphs[90] = dom.createAttrMorph(element80, 'class');
        morphs[91] = dom.createMorphAt(element80,1,1);
        morphs[92] = dom.createAttrMorph(element81, 'class');
        morphs[93] = dom.createMorphAt(element81,1,1);
        morphs[94] = dom.createAttrMorph(element82, 'class');
        morphs[95] = dom.createMorphAt(element82,1,1);
        morphs[96] = dom.createMorphAt(element82,3,3);
        morphs[97] = dom.createMorphAt(dom.childAt(element83, [1]),1,1);
        morphs[98] = dom.createMorphAt(element83,3,3);
        morphs[99] = dom.createMorphAt(element83,4,4);
        morphs[100] = dom.createMorphAt(element83,5,5);
        morphs[101] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[102] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[103] = dom.createMorphAt(fragment,13,13,contextualElement);
        morphs[104] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[105] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[106] = dom.createMorphAt(fragment,19,19,contextualElement);
        morphs[107] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[108] = dom.createMorphAt(fragment,23,23,contextualElement);
        morphs[109] = dom.createMorphAt(fragment,25,25,contextualElement);
        morphs[110] = dom.createMorphAt(fragment,27,27,contextualElement);
        morphs[111] = dom.createMorphAt(fragment,29,29,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[5,16],[5,25]]]],"masterdata"],[],["loc",[null,[5,12],[5,39]]]]],[],0,null,["loc",[null,[5,6],[22,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[23,16],[23,25]]]],"documents"],[],["loc",[null,[23,12],[23,38]]]]],[],1,null,["loc",[null,[23,6],[33,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[34,16],[34,25]]]],"patients"],[],["loc",[null,[34,12],[34,37]]]]],[],2,null,["loc",[null,[34,6],[38,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[39,16],[39,25]]]],"appointments"],[],["loc",[null,[39,12],[39,41]]]]],[],3,null,["loc",[null,[39,6],[43,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[44,16],[44,25]]]],"saldo"],[],["loc",[null,[44,12],[44,34]]]]],[],4,null,["loc",[null,[44,6],[55,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[56,16],[56,25]]]],"consents"],[],["loc",[null,[56,12],[56,37]]]]],[],5,null,["loc",[null,[56,6],[60,13]]]],
        ["block","if",[["get","model.gender",["loc",[null,[67,10],[67,22]]]]],[],6,null,["loc",[null,[67,4],[69,11]]]],
        ["content","model.firstname",["loc",[null,[71,48],[71,67]]]],
        ["content","model.lastname",["loc",[null,[71,68],[71,86]]]],
        ["block","if",[["get","model.etigaAppConnected",["loc",[null,[71,93],[71,116]]]]],[],7,8,["loc",[null,[71,87],[71,350]]]],
        ["content","model.street",["loc",[null,[72,29],[72,45]]]],
        ["content","model.postalCode",["loc",[null,[72,47],[72,67]]]],
        ["content","model.town",["loc",[null,[72,68],[72,82]]]],
        ["attribute","class",["concat",[" ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[76,46],[76,56]]]],0],[],["loc",[null,[76,42],[76,59]]]],"is-negative","positive"],[],["loc",[null,[76,21],[76,86]]]]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[77,61],[77,71]]]]],[],[]],"classNames","inline-block"],["loc",[null,[77,37],[77,99]]]],
        ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[80,16],[80,26]]]],0],[],["loc",[null,[80,12],[80,29]]]]],[],9,null,["loc",[null,[80,6],[86,13]]]],
        ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[87,16],[87,29]]]],0],[],["loc",[null,[87,12],[87,32]]]]],[],10,null,["loc",[null,[87,6],[93,13]]]],
        ["inline","payment-moral-icon",[],["moral",["subexpr","get-payment-moral",[["get","model",["loc",[null,[94,52],[94,57]]]],true],[],["loc",[null,[94,33],[94,63]]]]],["loc",[null,[94,6],[94,65]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[100,51],[100,60]]]],"masterdata"],[],["loc",[null,[100,47],[100,74]]]],"is-active",""],[],["loc",[null,[100,26],[100,91]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[100,117],[100,152]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[101,8],[101,21]]]]],[],11,null,["loc",[null,[101,2],[106,9]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[107,51],[107,60]]]],"reminder"],[],["loc",[null,[107,47],[107,72]]]],"is-active",""],[],["loc",[null,[107,26],[107,89]]]]]]],
        ["element","action",["selectTab","reminder"],[],["loc",[null,[107,112],[107,145]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[108,51],[108,60]]]],"documents"],[],["loc",[null,[108,47],[108,73]]]],"is-active",""],[],["loc",[null,[108,26],[108,90]]]]]]],
        ["element","action",["selectTab","documents"],[],["loc",[null,[108,114],[108,148]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[109,51],[109,60]]]],"patients"],[],["loc",[null,[109,47],[109,72]]]],"is-active",""],[],["loc",[null,[109,26],[109,89]]]]]]],
        ["element","action",["selectTab","patients"],[],["loc",[null,[109,112],[109,145]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[110,8],[110,31]]]]],[],12,null,["loc",[null,[110,2],[112,9]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[113,51],[113,60]]]],"correspondance"],[],["loc",[null,[113,47],[113,78]]]],"is-active",""],[],["loc",[null,[113,26],[113,95]]]]]]],
        ["element","action",["selectTab","correspondance"],[],["loc",[null,[113,124],[113,163]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[114,51],[114,60]]]],"consents"],[],["loc",[null,[114,47],[114,72]]]],"is-active",""],[],["loc",[null,[114,26],[114,89]]]]]]],
        ["element","action",["selectTab","consents"],[],["loc",[null,[114,112],[114,145]]]],
        ["element","action",["save",["get","referer",["loc",[null,[120,71],[120,78]]]],["get","refererInvoiceId",["loc",[null,[120,79],[120,95]]]]],["on","submit"],["loc",[null,[120,55],[120,109]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[121,79],[121,88]]]],"masterdata"],[],["loc",[null,[121,75],[121,102]]]],"is-active",""],[],["loc",[null,[121,54],[121,119]]]]]]],
        ["inline","customers-edit-form",[],["edit",true,"model",["subexpr","@mut",[["get","model",["loc",[null,[122,56],[122,61]]]]],[],[]],"genders",["subexpr","@mut",[["get","genders",["loc",[null,[122,70],[122,77]]]]],[],[]],"customerSalutations",["subexpr","@mut",[["get","customerSalutations",["loc",[null,[122,98],[122,117]]]]],[],[]],"countries",["subexpr","@mut",[["get","countries",["loc",[null,[122,128],[122,137]]]]],[],[]],"titles",["subexpr","@mut",[["get","titles",["loc",[null,[122,145],[122,151]]]]],[],[]],"setInputDirty","setInputDirty","addEmail","addEmail","removeEmail","removeEmail","addPhone","addPhone","removePhone","removePhone","addFax","addFax","removeFax","removeFax"],["loc",[null,[122,18],[122,313]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[124,79],[124,88]]]],"accounting"],[],["loc",[null,[124,75],[124,102]]]],"is-active",""],[],["loc",[null,[124,54],[124,119]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","paymentMorals",["loc",[null,[132,34],[132,47]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedPaymentMoral",["loc",[null,[133,32],[133,52]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Bitte wählen","name","user-payment-moral"],["loc",[null,[131,24],[139,26]]]],
        ["inline","input",[],["type","checkbox","name","user-sued","id","user-sued","checked",["subexpr","@mut",[["get","model.sued",["loc",[null,[146,92],[146,102]]]]],[],[]]],["loc",[null,[146,28],[146,104]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceAddition",["loc",[null,[160,53],[160,74]]]]],[],[]],"name","user-invoice-addition"],["loc",[null,[160,24],[160,105]]]],
        ["inline","input",[],["type","checkbox","name","user-differentInvoice","id","user-differentInvoice","checked",["subexpr","@mut",[["get","model.differentInvoice",["loc",[null,[168,116],[168,138]]]]],[],[]]],["loc",[null,[168,28],[168,140]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[176,83],[176,105]]]],"","hidden"],[],["loc",[null,[176,62],[176,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceFirstname",["loc",[null,[178,53],[178,75]]]]],[],[]],"name","user-invoice-firstname"],["loc",[null,[178,24],[178,107]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[182,83],[182,105]]]],"","hidden"],[],["loc",[null,[182,62],[182,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceLastname",["loc",[null,[184,53],[184,74]]]]],[],[]],"name","user-invoice-lastname"],["loc",[null,[184,24],[184,105]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","titles",["loc",[null,[192,34],[192,40]]]]],[],[]],"value",["subexpr","@mut",[["get","model.invoiceTitle",["loc",[null,[193,32],[193,50]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Bitte wählen","name","user-payment-moral"],["loc",[null,[191,24],[199,26]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[202,83],[202,105]]]],"","hidden"],[],["loc",[null,[202,62],[202,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceStreet",["loc",[null,[204,53],[204,72]]]]],[],[]],"name","user-invoice-invoiceStreet"],["loc",[null,[204,24],[204,108]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[208,83],[208,105]]]],"","hidden"],[],["loc",[null,[208,62],[208,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoicePostalcode",["loc",[null,[210,53],[210,76]]]]],[],[]],"name","user-invoice-postalcode"],["loc",[null,[210,24],[210,109]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[214,83],[214,105]]]],"","hidden"],[],["loc",[null,[214,62],[214,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceTown",["loc",[null,[216,53],[216,70]]]]],[],[]],"name","user-invoice-town"],["loc",[null,[216,24],[216,97]]]],
        ["attribute","class",["concat",["col l-12/24@desk 24/24 gutter ",["subexpr","css-bool-evaluator",[["get","model.differentInvoice",["loc",[null,[220,83],[220,105]]]],"","hidden"],[],["loc",[null,[220,62],[220,120]]]]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceUid",["loc",[null,[222,53],[222,69]]]]],[],[]],"name","user-invoice-uid"],["loc",[null,[222,24],[222,95]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.invoiceConditions",["loc",[null,[234,53],[234,76]]]]],[],[]],"name","user-invoiceConditions"],["loc",[null,[234,24],[234,108]]]],
        ["inline","input",[],["type","checkbox","name","user-reductionToPurchasePrice","id","user-reductionToPurchasePrice","checked",["subexpr","@mut",[["get","model.reductionToPurchasePrice",["loc",[null,[242,132],[242,162]]]]],[],[]]],["loc",[null,[242,28],[242,164]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.iban",["loc",[null,[256,53],[256,63]]]]],[],[]],"name","user-iban"],["loc",[null,[256,24],[256,82]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.bic",["loc",[null,[262,53],[262,62]]]]],[],[]],"name","user-iban"],["loc",[null,[262,24],[262,81]]]],
        ["block","each",[["get","model.reductions",["loc",[null,[271,26],[271,42]]]]],[],13,null,["loc",[null,[271,18],[346,27]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.driveNotice",["loc",[null,[354,53],[354,70]]]]],[],[]],"name","user-iban"],["loc",[null,[354,24],[354,89]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.driveStreet",["loc",[null,[360,53],[360,70]]]]],[],[]],"name","user-iban"],["loc",[null,[360,24],[360,89]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.drivePostalcode",["loc",[null,[366,53],[366,74]]]]],[],[]],"name","user-iban"],["loc",[null,[366,24],[366,93]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.distanceKm",["loc",[null,[372,53],[372,69]]]]],[],[]],"name","user-iban"],["loc",[null,[372,24],[372,88]]]],
        ["inline","input",[],["placeholder","","value",["subexpr","@mut",[["get","model.distanceMinutes",["loc",[null,[378,53],[378,74]]]]],[],[]],"name","user-iban"],["loc",[null,[378,24],[378,93]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[384,79],[384,88]]]],"warning"],[],["loc",[null,[384,75],[384,99]]]],"is-active",""],[],["loc",[null,[384,54],[384,116]]]]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning1",["loc",[null,[389,70],[389,92]]]]],[],[]],"autofocus",true,"placeholder","","name","mandant-days-till-warning1"],["loc",[null,[389,22],[389,158]]]],
        ["content","model.defaultDaysTillWarning1",["loc",[null,[392,105],[392,138]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillWarning2",["loc",[null,[397,70],[397,92]]]]],[],[]],"autofocus",true,"placeholder","","name","mandant-days-till-warning2"],["loc",[null,[397,22],[397,158]]]],
        ["content","model.defaultDaysTillWarning2",["loc",[null,[400,105],[400,138]]]],
        ["inline","input",[],["type","number","min",0,"step",1,"max",60,"value",["subexpr","@mut",[["get","model.daysTillNopayer",["loc",[null,[405,70],[405,91]]]]],[],[]],"autofocus",true,"placeholder","","name","mandant-days-till-nopayer"],["loc",[null,[405,22],[405,156]]]],
        ["content","model.defaultDaysTillNopayer",["loc",[null,[408,153],[408,185]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[414,79],[414,88]]]],"reminder"],[],["loc",[null,[414,75],[414,100]]]],"is-active",""],[],["loc",[null,[414,54],[414,117]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","deliveryOptions",["loc",[null,[421,32],[421,47]]]]],[],[]],"value",["subexpr","@mut",[["get","model.reminderSetting",["loc",[null,[422,30],[422,51]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","value","optionValuePath","id","allowClear",true,"placeholder","Standardeinstellungen beibehalten","name","customer.reminderSetting"],["loc",[null,[420,22],[429,24]]]],
        ["content","model.defaultReminderSetting",["loc",[null,[431,50],[431,82]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[435,79],[435,88]]]],"saldo"],[],["loc",[null,[435,75],[435,97]]]],"is-active",""],[],["loc",[null,[435,54],[435,114]]]]]]],
        ["block","if",[["subexpr","gt",[["get","invoicesTotal",["loc",[null,[437,30],[437,43]]]],0],[],["loc",[null,[437,26],[437,46]]]]],[],14,15,["loc",[null,[437,20],[485,25]]]],
        ["inline","customer-saldo",[],["deposits",["subexpr","@mut",[["get","deposits",["loc",[null,[489,50],[489,58]]]]],[],[]],"balanceSum",["subexpr","@mut",[["get","balanceSum",["loc",[null,[489,70],[489,80]]]]],[],[]],"depositSum",["subexpr","@mut",[["get","depositSum",["loc",[null,[489,92],[489,102]]]]],[],[]],"banktransferEnabled",["subexpr","@mut",[["get","banktransferEnabled",["loc",[null,[489,123],[489,142]]]]],[],[]]],["loc",[null,[489,24],[489,144]]]],
        ["attribute","class",["concat",["tabs__content  card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[492,78],[492,87]]]],"medias"],[],["loc",[null,[492,74],[492,97]]]],"is-active",""],[],["loc",[null,[492,53],[492,114]]]]]]],
        ["inline","patient-medias",[],["items",["subexpr","@mut",[["get","medias",["loc",[null,[493,41],[493,47]]]]],[],[]],"deleteMedia","deleteMedia","editable",true,"forwardEmail","openDocumentForwardPanel","reloadMedias","reloadMedias"],["loc",[null,[493,18],[493,158]]]],
        ["attribute","class",["concat",["tabs__content  card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[495,78],[495,87]]]],"documents"],[],["loc",[null,[495,74],[495,100]]]],"is-active",""],[],["loc",[null,[495,53],[495,117]]]]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[498,42],[498,52]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[498,18],[498,108]]]],
        ["block","each",[["get","tags",["loc",[null,[500,28],[500,32]]]]],[],16,null,["loc",[null,[500,20],[500,249]]]],
        ["inline","documents-panel",[],["showSearch",false,"showBreadcrumbs",true,"updateSearch","updateSearch","forwardLab","openEmailForwardPanel","actionReceiver",["subexpr","@mut",[["get","documentsPanel",["loc",[null,[509,33],[509,47]]]]],[],[]]],["loc",[null,[504,16],[509,49]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[511,66],[511,75]]]],"patients"],[],["loc",[null,[511,62],[511,87]]]],"is-active",""],[],["loc",[null,[511,41],[511,104]]]]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[519,79],[519,90]]]],"all"],[],["loc",[null,[519,75],[519,97]]]],"selected",""],[],["loc",[null,[519,54],[519,114]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[519,116],[519,152]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[520,79],[520,90]]]],"hideDead"],[],["loc",[null,[520,75],[520,102]]]],"selected",""],[],["loc",[null,[520,54],[520,119]]]]]]],
        ["element","action",["toggleQuickFilter","hideDead"],[],["loc",[null,[520,121],[520,162]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[521,79],[521,90]]]],"deleted"],[],["loc",[null,[521,75],[521,101]]]],"selected",""],[],["loc",[null,[521,54],[521,118]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[521,120],[521,160]]]],
        ["block","each",[["get","patientList",["loc",[null,[535,32],[535,43]]]]],[],17,null,["loc",[null,[535,24],[584,33]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[589,64],[589,73]]]],"appointments"],[],["loc",[null,[589,60],[589,89]]]],"is-active",""],[],["loc",[null,[589,39],[589,106]]]]]]],
        ["inline","patient-appointments",[],["startTreatment","startTreatment","referer","patient","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","stickyHeader",false,"items",["subexpr","@mut",[["get","appointments",["loc",[null,[590,203],[590,215]]]]],[],[]]],["loc",[null,[590,16],[590,217]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[592,66],[592,75]]]],"correspondance"],[],["loc",[null,[592,62],[592,93]]]],"is-active",""],[],["loc",[null,[592,41],[592,110]]]]]]],
        ["inline","customer-correspondance",[],["items",["subexpr","@mut",[["get","logbookEntries",["loc",[null,[593,50],[593,64]]]]],[],[]],"content",["subexpr","@mut",[["get","content",["loc",[null,[593,73],[593,80]]]]],[],[]]],["loc",[null,[593,18],[593,82]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[595,66],[595,75]]]],"consents"],[],["loc",[null,[595,62],[595,87]]]],"is-active",""],[],["loc",[null,[595,41],[595,104]]]]]]],
        ["block","if",[["get","model.consent",["loc",[null,[597,24],[597,37]]]]],[],18,null,["loc",[null,[597,18],[608,25]]]],
        ["inline","customer-consents",[],["actionReceiver",["subexpr","@mut",[["get","customerConsentsComponent",["loc",[null,[610,53],[610,78]]]]],[],[]]],["loc",[null,[610,18],[610,80]]]],
        ["content","button-prev",["loc",[null,[617,10],[617,25]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[620,16],[620,25]]]],"medias"],[],["loc",[null,[620,12],[620,35]]]]],[],19,null,["loc",[null,[620,6],[624,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[625,16],[625,25]]]],"masterdata"],[],["loc",[null,[625,12],[625,39]]]]],[],20,null,["loc",[null,[625,6],[629,13]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[630,20],[630,29]]]],"masterdata"],[],["loc",[null,[630,16],[630,43]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[630,48],[630,57]]]],"warning"],[],["loc",[null,[630,44],[630,68]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[630,73],[630,82]]]],"accounting"],[],["loc",[null,[630,69],[630,96]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[630,101],[630,110]]]],"reminder"],[],["loc",[null,[630,97],[630,122]]]]],[],["loc",[null,[630,12],[630,123]]]]],[],21,null,["loc",[null,[630,6],[634,13]]]],
        ["inline","email-invoice-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardEmailPanel",["loc",[null,[638,37],[638,54]]]]],[],[]],"confirm","sendEmail"],["loc",[null,[638,0],[638,76]]]],
        ["inline","customer-payout-deposit-panel",[],["actionReceiver",["subexpr","@mut",[["get","depositPanel",["loc",[null,[641,17],[641,29]]]]],[],[]],"totalDeposit",["subexpr","@mut",[["get","depositSum",["loc",[null,[642,15],[642,25]]]]],[],[]],"payout","payout","actionReceiver",["subexpr","@mut",[["get","customerPayoutDepositPanel",["loc",[null,[644,17],[644,43]]]]],[],[]]],["loc",[null,[640,0],[644,45]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[648,73],[648,98]]]]],[],[]]],["loc",[null,[648,0],[648,100]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[649,40],[649,60]]]]],[],[]]],["loc",[null,[649,0],[649,62]]]],
        ["inline","customer-revenue-panel",[],["actionReceiver",["subexpr","@mut",[["get","customerRevenuePanel",["loc",[null,[650,40],[650,60]]]]],[],[]]],["loc",[null,[650,0],[650,62]]]],
        ["inline","consent-upload-panel",[],["uploadDone","consentUploadDone","actionReceiver",["subexpr","@mut",[["get","consentUploadPanel",["loc",[null,[651,69],[651,87]]]]],[],[]]],["loc",[null,[651,0],[651,89]]]],
        ["inline","add-appointment-panel",[],["doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[653,32],[653,39]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[653,51],[653,61]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[653,72],[653,81]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[653,88],[653,93]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[653,103],[653,111]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[653,118],[653,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[653,166],[653,185]]]]],[],[]]],["loc",[null,[653,0],[653,187]]]],
        ["inline","media-upload-panel",[],["actionReceiver",["subexpr","@mut",[["get","mediaPanel",["loc",[null,[655,36],[655,46]]]]],[],[]],"customer",["subexpr","@mut",[["get","model",["loc",[null,[655,56],[655,61]]]]],[],[]],"mediaCategories",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[655,78],[655,93]]]]],[],[]],"reloadMedias","reloadMedias"],["loc",[null,[655,0],[655,123]]]],
        ["block","if",[["subexpr","not",[["get","newMode",["loc",[null,[657,11],[657,18]]]]],[],["loc",[null,[657,6],[657,19]]]]],[],22,null,["loc",[null,[657,0],[659,7]]]],
        ["inline","create-patient-panel",[],["patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[661,41],[661,58]]]]],[],[]],"patientGenders",["subexpr","@mut",[["get","patientGenders",["loc",[null,[661,74],[661,88]]]]],[],[]],"createPatient","createPatient","actionReceiver",["subexpr","@mut",[["get","createPatientPanel",["loc",[null,[661,134],[661,152]]]]],[],[]]],["loc",[null,[661,0],[661,154]]]],
        ["inline","merge-customer-panel",[],["gotoCustomer","gotoCustomer","actionReceiver",["subexpr","@mut",[["get","mergeCustomerPanel",["loc",[null,[662,66],[662,84]]]]],[],[]]],["loc",[null,[662,0],[662,86]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22]
    };
  }()));

});