define('jason-frontend/templates/transactions/cashbook', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 104,
              "column": 4
            },
            "end": {
              "line": 113,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/transactions/cashbook.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__head");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Datum");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Eingang");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Ausgang");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-2/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("UST-Code");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-5/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Kassa");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-7/24");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","table__title title");
          var el4 = dom.createTextNode("Text");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 121,
                "column": 18
              },
              "end": {
                "line": 121,
                "column": 64
              }
            },
            "moduleName": "jason-frontend/templates/transactions/cashbook.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("€ ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.inFormatted",["loc",[null,[121,44],[121,64]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 124,
                "column": 18
              },
              "end": {
                "line": 124,
                "column": 66
              }
            },
            "moduleName": "jason-frontend/templates/transactions/cashbook.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("€ ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.outFormatted",["loc",[null,[124,45],[124,66]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 133,
                  "column": 58
                },
                "end": {
                  "line": 135,
                  "column": 28
                }
              },
              "moduleName": "jason-frontend/templates/transactions/cashbook.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n              #");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.slip",["loc",[null,[135,15],[135,28]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 133,
                "column": 32
              },
              "end": {
                "line": 135,
                "column": 40
              }
            },
            "moduleName": "jason-frontend/templates/transactions/cashbook.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["transactions.show",["get","item.slipId",["loc",[null,[133,89],[133,100]]]]],["class","is-highlighted"],0,null,["loc",[null,[133,58],[135,40]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 115,
              "column": 6
            },
            "end": {
              "line": 138,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/transactions/cashbook.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Eingang");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24 l-3/24@desk");
          dom.setAttribute(el2,"data-label","Ausgang");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24 l-2/24@desk");
          dom.setAttribute(el2,"data-label","UST-Code");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24 l-5/24@desk");
          dom.setAttribute(el2,"data-label","Kassa");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24 l-7/24@desk");
          dom.setAttribute(el2,"data-label","Text");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [11, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [9, 1]),0,0);
          morphs[5] = dom.createMorphAt(element1,0,0);
          morphs[6] = dom.createMorphAt(element1,2,2);
          return morphs;
        },
        statements: [
          ["content","item.formattedCreated",["loc",[null,[118,18],[118,43]]]],
          ["block","if",[["get","item.inFormatted",["loc",[null,[121,24],[121,40]]]]],[],0,null,["loc",[null,[121,18],[121,71]]]],
          ["block","if",[["get","item.outFormatted",["loc",[null,[124,24],[124,41]]]]],[],1,null,["loc",[null,[124,18],[124,73]]]],
          ["content","item.taxCode",["loc",[null,[127,18],[127,34]]]],
          ["content","item.cashRegister",["loc",[null,[130,18],[130,39]]]],
          ["content","item.text",["loc",[null,[133,18],[133,31]]]],
          ["block","if",[["subexpr","gt",[["get","item.slipId",["loc",[null,[133,42],[133,53]]]],0],[],["loc",[null,[133,38],[133,56]]]]],[],2,null,["loc",[null,[133,32],[135,47]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 203,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/transactions/cashbook.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Kassenbuch");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"class","u-mb0");
        dom.setAttribute(el4,"method","post");
        dom.setAttribute(el4,"action","/api/cashbooks/export");
        dom.setAttribute(el4,"target","_blank");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","mandant_id");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","token");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","format");
        dom.setAttribute(el5,"value","bmd");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","button-rework context mr10");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/rework/download.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("BMD Format herunterladen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"class","u-mb0");
        dom.setAttribute(el4,"method","post");
        dom.setAttribute(el4,"action","/api/cashbooks/export");
        dom.setAttribute(el4,"target","_blank");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","mandant_id");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5,"type","hidden");
        dom.setAttribute(el5,"name","token");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","button-rework context");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/rework/download.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("CSV herunterladen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","filter-lane grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","placeholder");
        var el6 = dom.createTextNode("Zeitraum");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/calendar-dark.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","select-placeholder");
        var el6 = dom.createTextNode("Kassa");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid box gutter-fix small-dashboard");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24@tablet gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Summe Eingänge");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24@tablet gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Summe Ausgänge");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24@tablet gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Gesamt");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid quick-filter");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col  l-12/24@tablet l-24/24 ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Eingänge");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Gesamtsumme");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Tagessumme");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Einzeln");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col  l-12/24@tablet l-24/24");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Ausgänge");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Gesamtsumme");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Tagessumme");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Einzeln");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table-wrapper-rework");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table grid table--large");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__body");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","pagination-wrapper is-fixed");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $('#pickDateCashBook').daterangepicker({\n      \"autoApply\": true,\n      \"ranges\": {\n        \"Heute\": [moment().startOf('day'), moment().endOf('day')],\n        \"Gestern\": [moment().startOf('day').subtract(1, 'days'), moment().endOf('day').subtract(1, 'days')],\n        \"Letzten 7 Tage\": [moment().startOf('day').subtract(6, 'days'), moment().endOf('day')],\n        \"Dieses Monat\": [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],\n        \"Letztes Monat\": [moment().startOf('month').subtract(1, 'month').startOf('month'), moment().endOf('month').subtract(1, 'month').endOf('month')],\n        \"Dieses Jahr\": [moment().startOf('year'), moment().endOf('year')],\n        \"Letztes Jahr\": [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],\n      },\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY HH:mm\",\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      \"linkedCalendars\": false,\n      timePicker: true,\n      timePicker24Hour: true\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1, 3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element3, [3]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element7, [3]);
        var element10 = dom.childAt(element2, [3, 1]);
        var element11 = dom.childAt(fragment, [2]);
        var element12 = dom.childAt(fragment, [4]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element13, [3]);
        var element15 = dom.childAt(element13, [5]);
        var element16 = dom.childAt(element13, [7]);
        var element17 = dom.childAt(element12, [3]);
        var element18 = dom.childAt(element17, [3]);
        var element19 = dom.childAt(element17, [5]);
        var element20 = dom.childAt(element17, [7]);
        var element21 = dom.childAt(fragment, [6]);
        var element22 = dom.childAt(element21, [1]);
        var morphs = new Array(37);
        morphs[0] = dom.createAttrMorph(element2, 'class');
        morphs[1] = dom.createAttrMorph(element5, 'value');
        morphs[2] = dom.createAttrMorph(element6, 'value');
        morphs[3] = dom.createMorphAt(element4,7,7);
        morphs[4] = dom.createMorphAt(element4,9,9);
        morphs[5] = dom.createMorphAt(element4,11,11);
        morphs[6] = dom.createMorphAt(element4,13,13);
        morphs[7] = dom.createMorphAt(element4,15,15);
        morphs[8] = dom.createMorphAt(element4,17,17);
        morphs[9] = dom.createAttrMorph(element8, 'value');
        morphs[10] = dom.createAttrMorph(element9, 'value');
        morphs[11] = dom.createMorphAt(element7,5,5);
        morphs[12] = dom.createMorphAt(element7,7,7);
        morphs[13] = dom.createMorphAt(element7,9,9);
        morphs[14] = dom.createMorphAt(element7,11,11);
        morphs[15] = dom.createMorphAt(element7,13,13);
        morphs[16] = dom.createMorphAt(element7,15,15);
        morphs[17] = dom.createMorphAt(dom.childAt(element10, [1]),1,1);
        morphs[18] = dom.createMorphAt(dom.childAt(element10, [3]),3,3);
        morphs[19] = dom.createMorphAt(dom.childAt(element11, [1, 1, 1]),0,0);
        morphs[20] = dom.createMorphAt(dom.childAt(element11, [3, 1, 1]),0,0);
        morphs[21] = dom.createMorphAt(dom.childAt(element11, [5, 1, 1]),0,0);
        morphs[22] = dom.createAttrMorph(element14, 'class');
        morphs[23] = dom.createElementMorph(element14);
        morphs[24] = dom.createAttrMorph(element15, 'class');
        morphs[25] = dom.createElementMorph(element15);
        morphs[26] = dom.createAttrMorph(element16, 'class');
        morphs[27] = dom.createElementMorph(element16);
        morphs[28] = dom.createAttrMorph(element18, 'class');
        morphs[29] = dom.createElementMorph(element18);
        morphs[30] = dom.createAttrMorph(element19, 'class');
        morphs[31] = dom.createElementMorph(element19);
        morphs[32] = dom.createAttrMorph(element20, 'class');
        morphs[33] = dom.createElementMorph(element20);
        morphs[34] = dom.createMorphAt(element22,1,1);
        morphs[35] = dom.createMorphAt(dom.childAt(element22, [3]),1,1);
        morphs[36] = dom.createMorphAt(dom.childAt(element21, [3]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,47],[1,58]]]],"with-filters",""],[],["loc",[null,[1,26],[1,78]]]]]]],
        ["attribute","value",["concat",[["get","mandantId",["loc",[null,[6,56],[6,65]]]]]]],
        ["attribute","value",["concat",[["get","downloadToken",["loc",[null,[7,51],[7,64]]]]]]],
        ["inline","input",[],["name","to","value",["subexpr","@mut",[["get","to",["loc",[null,[9,32],[9,34]]]]],[],[]],"type","hidden"],["loc",[null,[9,8],[9,50]]]],
        ["inline","input",[],["name","filterIn","value",["subexpr","@mut",[["get","filterIn",["loc",[null,[10,38],[10,46]]]]],[],[]],"type","hidden"],["loc",[null,[10,8],[10,62]]]],
        ["inline","input",[],["name","filterOut","value",["subexpr","@mut",[["get","filterOut",["loc",[null,[11,39],[11,48]]]]],[],[]],"type","hidden"],["loc",[null,[11,8],[11,64]]]],
        ["inline","input",[],["name","from","value",["subexpr","@mut",[["get","from",["loc",[null,[12,34],[12,38]]]]],[],[]],"type","hidden"],["loc",[null,[12,8],[12,54]]]],
        ["inline","input",[],["name","selectedRegister","value",["subexpr","@mut",[["get","selectedRegister",["loc",[null,[13,46],[13,62]]]]],[],[]],"type","hidden"],["loc",[null,[13,8],[13,78]]]],
        ["inline","input",[],["name","financialFilter","value",false,"type","hidden"],["loc",[null,[14,8],[14,66]]]],
        ["attribute","value",["concat",[["get","mandantId",["loc",[null,[21,56],[21,65]]]]]]],
        ["attribute","value",["concat",[["get","downloadToken",["loc",[null,[22,51],[22,64]]]]]]],
        ["inline","input",[],["name","to","value",["subexpr","@mut",[["get","to",["loc",[null,[23,32],[23,34]]]]],[],[]],"type","hidden"],["loc",[null,[23,8],[23,50]]]],
        ["inline","input",[],["name","filterIn","value",["subexpr","@mut",[["get","filterIn",["loc",[null,[24,38],[24,46]]]]],[],[]],"type","hidden"],["loc",[null,[24,8],[24,62]]]],
        ["inline","input",[],["name","filterOut","value",["subexpr","@mut",[["get","filterOut",["loc",[null,[25,39],[25,48]]]]],[],[]],"type","hidden"],["loc",[null,[25,8],[25,64]]]],
        ["inline","input",[],["name","from","value",["subexpr","@mut",[["get","from",["loc",[null,[26,34],[26,38]]]]],[],[]],"type","hidden"],["loc",[null,[26,8],[26,54]]]],
        ["inline","input",[],["name","selectedRegister","value",["subexpr","@mut",[["get","selectedRegister",["loc",[null,[27,46],[27,62]]]]],[],[]],"type","hidden"],["loc",[null,[27,8],[27,78]]]],
        ["inline","input",[],["name","financialFilter","value",false,"type","hidden"],["loc",[null,[28,8],[28,66]]]],
        ["inline","input",[],["id","pickDateCashBook","value",["subexpr","@mut",[["get","pickDate",["loc",[null,[39,44],[39,52]]]]],[],[]],"class","","name","pickDate"],["loc",[null,[39,8],[40,34]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","cashRegisters",["loc",[null,[47,18],[47,31]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedRegister",["loc",[null,[48,16],[48,32]]]]],[],[]],"optionLabelPath","name","optionValuePath","id","allowClear","true","placeholder","Alle Kassen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[46,8],[54,10]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","sumIn",["loc",[null,[63,54],[63,59]]]]],[],[]]],["loc",[null,[63,30],[63,61]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","sumOut",["loc",[null,[69,54],[69,60]]]]],[],[]]],["loc",[null,[69,30],[69,62]]]],
        ["inline","format-currency",[],["value",["subexpr","plus",[["get","sumIn",["loc",[null,[75,60],[75,65]]]],["get","sumOut",["loc",[null,[75,66],[75,72]]]]],[],["loc",[null,[75,54],[75,73]]]]],["loc",[null,[75,30],[75,75]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterIn",["loc",[null,[84,59],[84,67]]]],"all"],[],["loc",[null,[84,55],[84,74]]]],"selected",""],[],["loc",[null,[84,34],[84,91]]]]]]],
        ["element","action",["setFilterType","in","all"],[],["loc",[null,[84,109],[85,34]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterIn",["loc",[null,[86,59],[86,67]]]],"day"],[],["loc",[null,[86,55],[86,74]]]],"selected",""],[],["loc",[null,[86,34],[86,91]]]]]]],
        ["element","action",["setFilterType","in","day"],[],["loc",[null,[86,109],[87,34]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterIn",["loc",[null,[88,59],[88,67]]]],"single"],[],["loc",[null,[88,55],[88,77]]]],"selected",""],[],["loc",[null,[88,34],[88,94]]]]]]],
        ["element","action",["setFilterType","in","single"],[],["loc",[null,[88,112],[89,37]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterOut",["loc",[null,[93,59],[93,68]]]],"all"],[],["loc",[null,[93,55],[93,75]]]],"selected",""],[],["loc",[null,[93,34],[93,92]]]]]]],
        ["element","action",["setFilterType","out","all"],[],["loc",[null,[93,110],[94,35]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterOut",["loc",[null,[95,59],[95,68]]]],"day"],[],["loc",[null,[95,55],[95,75]]]],"selected",""],[],["loc",[null,[95,34],[95,92]]]]]]],
        ["element","action",["setFilterType","out","day"],[],["loc",[null,[95,110],[96,35]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterOut",["loc",[null,[97,59],[97,68]]]],"single"],[],["loc",[null,[97,55],[97,78]]]],"selected",""],[],["loc",[null,[97,34],[97,95]]]]]]],
        ["element","action",["setFilterType","out","single"],[],["loc",[null,[98,27],[98,68]]]],
        ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[104,32],[104,56]]]]],[],[]]],0,null,["loc",[null,[104,4],[113,25]]]],
        ["block","each",[["get","model",["loc",[null,[115,14],[115,19]]]]],[],1,null,["loc",[null,[115,6],[138,15]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[143,27],[143,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[143,4],[143,53]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});