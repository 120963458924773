define('jason-frontend/templates/components/start-treatment-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 133
            },
            "end": {
              "line": 11,
              "column": 280
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","button-ical",[],["size",32,"classNames","pt2 verticaltop inline-block","color","#00AAC6","content","eltiga App BenutzerIn"],["loc",[null,[11,167],[11,280]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 280
              },
              "end": {
                "line": 11,
                "column": 441
              }
            },
            "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","button-ical",[],["size",32,"classNames","pt2 verticaltop inline-block","color","#797979","content","eltiga Tier QR Code verschickt"],["loc",[null,[11,319],[11,441]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 280
            },
            "end": {
              "line": 11,
              "column": 441
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","customer.etigaCodeRequested",["loc",[null,[11,290],[11,317]]]]],[],0,null,["loc",[null,[11,280],[11,441]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 448
            },
            "end": {
              "line": 11,
              "column": 531
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","is-error");
          var el2 = dom.createTextNode("(");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("b");
          var el3 = dom.createTextNode("Gesperrt - Nicht Zahler");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(")");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 73
            },
            "end": {
              "line": 27,
              "column": 126
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","title");
          var el2 = dom.createTextNode("Saldo");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 45
            },
            "end": {
              "line": 28,
              "column": 309
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [0]);
          var element16 = dom.childAt(fragment, [2]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element15, 'class');
          morphs[1] = dom.createMorphAt(element15,0,0);
          morphs[2] = dom.createAttrMorph(element16, 'class');
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["title ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[28,127],[28,137]]]],0],[],["loc",[null,[28,123],[28,140]]]],"is-negative",""],[],["loc",[null,[28,102],[28,159]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[28,185],[28,195]]]]],[],[]],"classNames","inline-block"],["loc",[null,[28,161],[28,223]]]],
          ["attribute","class",["concat",["status-badge status-",["subexpr","get-payment-moral",[["get","customer",["loc",[null,[28,284],[28,292]]]],false],[],["loc",[null,[28,264],[28,300]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 20
            },
            "end": {
              "line": 33,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","title");
          var el2 = dom.createTextNode("Termin:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,5,5,contextualElement);
          return morphs;
        },
        statements: [
          ["content","app.date",["loc",[null,[32,57],[32,69]]]],
          ["content","app.dateFrom",["loc",[null,[32,70],[32,86]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 20
            },
            "end": {
              "line": 37,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Termin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Uhr");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","input",[],["readonly",true,"autocomplete","false","name","time","class","readonly-hack input input--editable date-picker__input fixed-timepicker-width","value",["subexpr","@mut",[["get","appointmentTime",["loc",[null,[35,205],[35,220]]]]],[],[]],"id","time"],["loc",[null,[35,58],[35,232]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 195
                },
                "end": {
                  "line": 60,
                  "column": 93
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","title");
              var el2 = dom.createTextNode("(zuletzt gemessen: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" kg)");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["content","patient.currentWeight",["loc",[null,[60,57],[60,82]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 114,
                  "column": 157
                },
                "end": {
                  "line": 115,
                  "column": 283
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted-alternative");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element2, 'id');
              morphs[1] = dom.createAttrMorph(element2, 'data-ids');
              morphs[2] = dom.createUnsafeMorphAt(element2,0,0);
              morphs[3] = dom.createMorphAt(element2,2,2);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",[["get","patient.id",["loc",[null,[115,32],[115,42]]]],"_introReasons"]]],
              ["attribute","data-ids",["concat",[["subexpr","print-list-of-id",[["get","patient.introductionReason",["loc",[null,[115,123],[115,149]]]]],[],["loc",[null,[115,104],[115,151]]]]]]],
              ["inline","print-list-of-name",[["get","patient.introductionReason",["loc",[null,[115,175],[115,201]]]]],[],["loc",[null,[115,153],[115,204]]]],
              ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[115,205],[115,276]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 115,
                  "column": 283
                },
                "end": {
                  "line": 116,
                  "column": 101
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    auswählen ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[116,30],[116,101]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 123,
                  "column": 149
                },
                "end": {
                  "line": 124,
                  "column": 253
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element1, 'id');
              morphs[1] = dom.createAttrMorph(element1, 'data-ids');
              morphs[2] = dom.createUnsafeMorphAt(element1,0,0);
              morphs[3] = dom.createMorphAt(element1,2,2);
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",[["get","patient.id",["loc",[null,[124,32],[124,42]]]],"_adminTasks"]]],
              ["attribute","data-ids",["concat",[["subexpr","print-list-of-id",[["get","patient.adminTasks",["loc",[null,[124,109],[124,127]]]]],[],["loc",[null,[124,90],[124,129]]]]]]],
              ["inline","print-list-of-name",[["get","patient.adminTasks",["loc",[null,[124,153],[124,171]]]]],[],["loc",[null,[124,131],[124,174]]]],
              ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[124,175],[124,246]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 124,
                  "column": 253
                },
                "end": {
                  "line": 125,
                  "column": 101
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    auswählen ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[125,30],[125,101]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 141,
                    "column": 14
                  },
                  "end": {
                    "line": 151,
                    "column": 14
                  }
                },
                "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","treatment-history",[],["showCopy",false,"classNames","pl20 pr20","loadOverlay",false,"showEdit",false,"showChangeUser",false,"showFilter",false,"showToggleAllButton",false,"items",["subexpr","get-latest-patient-info",[["get","historicTreatments",["loc",[null,[150,49],[150,67]]]],["get","patient",["loc",[null,[150,68],[150,75]]]]],[],["loc",[null,[150,24],[150,76]]]]],["loc",[null,[142,16],[150,78]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 131,
                  "column": 12
                },
                "end": {
                  "line": 152,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","toggle-container");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h2");
              var el4 = dom.createTextNode("Letzte Behandlung");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","header-toggle");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("label");
              dom.setAttribute(el4,"for","switchLasttreatment");
              dom.setAttribute(el4,"class","switch");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","input",[],["type","checkbox","id","switchLasttreatment","classNames","switch","name","switchLasttreatment","checked",["subexpr","@mut",[["get","showLastTreatment",["loc",[null,[136,122],[136,139]]]]],[],[]]],["loc",[null,[136,18],[136,141]]]],
              ["block","if",[["get","showLastTreatment",["loc",[null,[141,20],[141,37]]]]],[],0,null,["loc",[null,[141,14],[151,21]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child6 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 155,
                  "column": 12
                },
                "end": {
                  "line": 159,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","ml20 customerBox");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Für dieses Tier gibt es offene Behandlungen:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 12
              },
              "end": {
                "line": 160,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","box pt0 pb0 mb20");
            var el2 = dom.createTextNode("\n\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","toggle-container patient-title");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h2");
            var el5 = dom.createElement("a");
            dom.setAttribute(el5,"class","cursor");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","grid infos");
            var el3 = dom.createTextNode("\n\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col gutter l-24/24");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","flex-center weight-input select--inline input-element");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","title");
            var el6 = dom.createTextNode("Gewicht:");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","title");
            var el6 = dom.createTextNode("kg");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col gutter l-24/24 l-8/24@desk");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col gutter l-24/24 l-8/24@desk");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col gutter l-24/24 l-8/24@desk");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","");
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class"," col gutter l-24/24 l-8/24@desk");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"class","textarea textarea--inline clearfix");
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5,"class","textarea__body");
            var el6 = dom.createTextNode("\n                    ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n                  ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col gutter l-24/24 l-12/24@tablet l-8/24@desk text-center");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","title");
            var el5 = dom.createTextNode("Vorstellungsgründe ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","pick-list");
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("a");
            dom.setAttribute(el5,"class","cursor");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(" ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col gutter l-24/24 l-12/24@tablet l-8/24@desk text-center");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4,"class","title");
            var el5 = dom.createTextNode("Administrative Tätigkeiten");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","pick-list");
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("a");
            dom.setAttribute(el5,"class","cursor");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(" ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [1, 1]);
            var element5 = dom.childAt(element4, [1, 0]);
            var element6 = dom.childAt(element3, [3]);
            var element7 = dom.childAt(element6, [1, 1]);
            var element8 = dom.childAt(element6, [3, 1]);
            var element9 = dom.childAt(element6, [5, 1]);
            var element10 = dom.childAt(element6, [7, 1]);
            var element11 = dom.childAt(element6, [9, 1]);
            var element12 = dom.childAt(element6, [11]);
            var element13 = dom.childAt(element12, [3, 1]);
            var element14 = dom.childAt(element6, [13, 3, 1]);
            var morphs = new Array(21);
            morphs[0] = dom.createElementMorph(element5);
            morphs[1] = dom.createMorphAt(element5,0,0);
            morphs[2] = dom.createMorphAt(element4,3,3);
            morphs[3] = dom.createAttrMorph(element7, 'id');
            morphs[4] = dom.createMorphAt(element7,3,3);
            morphs[5] = dom.createMorphAt(element7,7,7);
            morphs[6] = dom.createAttrMorph(element8, 'id');
            morphs[7] = dom.createMorphAt(element8,1,1);
            morphs[8] = dom.createAttrMorph(element9, 'id');
            morphs[9] = dom.createMorphAt(element9,1,1);
            morphs[10] = dom.createAttrMorph(element10, 'id');
            morphs[11] = dom.createMorphAt(element10,1,1);
            morphs[12] = dom.createAttrMorph(element11, 'id');
            morphs[13] = dom.createMorphAt(dom.childAt(element11, [1]),1,1);
            morphs[14] = dom.createMorphAt(dom.childAt(element12, [1]),1,1);
            morphs[15] = dom.createElementMorph(element13);
            morphs[16] = dom.createMorphAt(element13,0,0);
            morphs[17] = dom.createElementMorph(element14);
            morphs[18] = dom.createMorphAt(element14,0,0);
            morphs[19] = dom.createMorphAt(element3,5,5);
            morphs[20] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["element","action",["gotoPatient",["get","patient.id",["loc",[null,[50,61],[50,71]]]]],[],["loc",[null,[50,38],[50,73]]]],
            ["inline","button-text",[],["text",["subexpr","@mut",[["get","patient.name",["loc",[null,[50,93],[50,105]]]]],[],[]],"content",["subexpr","@mut",[["get","patient.id",["loc",[null,[50,114],[50,124]]]]],[],[]]],["loc",[null,[50,74],[50,126]]]],
            ["inline","animal-icon",[],["color","#999999","animal-id",["subexpr","@mut",[["get","patient.category.id",["loc",[null,[51,56],[51,75]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patient.digitalRace.icon",["loc",[null,[51,84],[51,108]]]]],[],[]],"size","32","classNames","pb3 vertical-middle","content",["subexpr","@mut",[["get","patient.category.name",["loc",[null,[51,160],[51,181]]]]],[],[]]],["loc",[null,[51,16],[51,183]]]],
            ["attribute","id",["concat",[["get","patient.id",["loc",[null,[58,91],[58,101]]]],"_weight"]]],
            ["inline","input",[],["name","weight","class","ml10 mr10 weight--input input new-gui gui-input u-text--right","placeholder",""],["loc",[null,[59,56],[59,164]]]],
            ["block","if",[["get","patient.currentWeight",["loc",[null,[59,201],[59,222]]]]],[],0,null,["loc",[null,[59,195],[60,100]]]],
            ["attribute","id",["concat",[["get","patient.id",["loc",[null,[64,27],[64,37]]]],"_wishDoctor"]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[66,28],[66,35]]]]],[],[]],"value",["subexpr","@mut",[["get","doctor",["loc",[null,[67,26],[67,32]]]]],[],[]],"cssClass","input--dropdown room","optionLabelPath","shortName","allowClear",true,"name","wish-doctor","placeholder","Tierärztin wählen"],["loc",[null,[65,18],[73,20]]]],
            ["attribute","id",["concat",[["get","patient.id",["loc",[null,[77,27],[77,37]]]],"_wishAssistant"]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[79,28],[79,38]]]]],[],[]],"value",["subexpr","@mut",[["get","assistant",["loc",[null,[80,26],[80,35]]]]],[],[]],"cssClass","input--dropdown room","optionLabelPath","shortName","allowClear",true,"name","wishAssistant","placeholder","Assistentin wählen"],["loc",[null,[78,18],[86,20]]]],
            ["attribute","id",["concat",[["get","patient.id",["loc",[null,[90,36],[90,46]]]],"_room"]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","rooms",["loc",[null,[92,28],[92,33]]]]],[],[]],"value",["subexpr","@mut",[["get","room",["loc",[null,[93,26],[93,30]]]]],[],[]],"cssClass","input--dropdown room","optionLabelPath","name","allowClear",true,"name","treatment-room","placeholder","Behandlungslokation wählen"],["loc",[null,[91,18],[99,20]]]],
            ["attribute","id",["concat",[["get","patient.id",["loc",[null,[104,72],[104,82]]]],"_anamnese"]]],
            ["inline","textarea",[],["rows","3","name","symptom","value",["subexpr","@mut",[["get","app.title",["loc",[null,[106,61],[106,70]]]]],[],[]],"class","input textarea__input full","placeholder","Anamnese beschreiben"],["loc",[null,[106,20],[106,142]]]],
            ["inline","button-etiga-small",[],["content","","classNames","pb2 vertical-middle inline-block","size","18","width",34],["loc",[null,[112,55],[112,153]]]],
            ["element","action",["selectTreatmentDetailInfoMulti","introductionReason",["get","patient.introductionReason",["loc",[null,[114,99],[114,125]]]],"Vorstellungsgründe",["get","patient",["loc",[null,[114,147],[114,154]]]]],[],["loc",[null,[114,36],[114,156]]]],
            ["block","if",[["get","patient.introductionReason",["loc",[null,[114,163],[114,189]]]]],[],1,2,["loc",[null,[114,157],[116,108]]]],
            ["element","action",["selectTreatmentDetailInfoMulti","adminTasks",["get","patient.adminTasks",["loc",[null,[123,91],[123,109]]]],"Administrative Tätigkeiten",["get","patient",["loc",[null,[123,139],[123,146]]]]],[],["loc",[null,[123,36],[123,148]]]],
            ["block","if",[["get","patient.adminTasks",["loc",[null,[123,155],[123,173]]]]],[],3,4,["loc",[null,[123,149],[125,108]]]],
            ["block","if",[["get","historicTreatments",["loc",[null,[131,18],[131,36]]]]],[],5,null,["loc",[null,[131,12],[152,19]]]],
            ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[155,18],[155,26]]]]],[],6,null,["loc",[null,[155,12],[159,19]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 10
            },
            "end": {
              "line": 161,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","is-patient-selected",[["get","selectedPatients",["loc",[null,[45,39],[45,55]]]],["get","patient",["loc",[null,[45,56],[45,63]]]]],[],["loc",[null,[45,18],[45,64]]]]],[],0,null,["loc",[null,[45,12],[160,19]]]]
        ],
        locals: ["patient","index"],
        templates: [child0]
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 167,
              "column": 12
            },
            "end": {
              "line": 171,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","mt10 icon-button icon-button--success");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-in-treatment-confirm",[],["item",["subexpr","@mut",[["get","customer",["loc",[null,[169,51],[169,59]]]]],[],[]],"showStroke",false,"confirmed","confirmHelper","color","#fff","content","Aufnehmen und direkt in Behandlung","text","Der/Die ausgewählte Kunde/in ist als 'Gesperrt - Nichtzahler' vermerkt, trotzdem fortfahren?"],["loc",[null,[169,16],[169,262]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 171,
              "column": 12
            },
            "end": {
              "line": 175,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","mt10 icon-button icon-button--success");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/treatment.svg");
          dom.setAttribute(el2,"title","Aufnehmen und direkt in Behandlung");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["confirm",true,["get","customerId",["loc",[null,[172,41],[172,51]]]],["get","selectedPatients",["loc",[null,[172,52],[172,68]]]]],[],["loc",[null,[172,17],[172,70]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 202,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/start-treatment-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-start-treatment-panel");
        dom.setAttribute(el1,"class","small--heading popup-basic popup-extremely-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","treatmentPanelForm");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-title");
        var el6 = dom.createTextNode("\n                Aufnahme");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","box pb0 pb0");
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mt0");
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","cursor");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid");
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter l-12/24@tablet l-8/24@desk");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Straße");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-14/24");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("PLZ / Ort");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-14/24");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Telefon");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-14/24");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Email");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-14/24");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-24/24 gutter l-12/24@tablet l-16/24@desk");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","grid");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24@tablet l-12/24@desk saldo");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col l-12/24 l-8/24@phone u-text--center");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","col l-10/24");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","col l-24/24@tablet l-12/24@desk appointment");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","mt10 icon-button");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/rework/waitingroom.svg");
        dom.setAttribute(el6,"title","Aufnehmen und ins Wartezimmer setzen");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(\"body\").delegate(\"#treatmentPanelForm textarea\", \"keyup\", function() {\n      $(this).height(30);\n      $(this).height(this.scrollHeight + parseFloat($(this).css(\"borderTopWidth\")) + parseFloat($(this).css(\"borderBottomWidth\")));\n    });\n\n    $(\"body\").delegate(\"input.readonly-hack\", \"focusin\", function() {\n      $(this).removeAttr('readonly');\n    });\n\n    setTimeout(function() {\n      $(\"#treatmentPanelForm textarea\").each(function() {\n        $(this).height(this.scrollHeight + parseFloat($(this).css(\"borderTopWidth\")) + parseFloat($(this).css(\"borderBottomWidth\")));\n      });\n    }, 500);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element17 = dom.childAt(fragment, [0, 1, 1]);
        var element18 = dom.childAt(element17, [3]);
        var element19 = dom.childAt(element18, [1]);
        var element20 = dom.childAt(element19, [0]);
        var element21 = dom.childAt(element18, [3]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(element22, [7]);
        var element24 = dom.childAt(element21, [3, 1]);
        var element25 = dom.childAt(element24, [1]);
        var element26 = dom.childAt(element17, [7]);
        var element27 = dom.childAt(element26, [1]);
        var morphs = new Array(17);
        morphs[0] = dom.createElementMorph(element20);
        morphs[1] = dom.createMorphAt(element20,0,0);
        morphs[2] = dom.createMorphAt(element20,2,2);
        morphs[3] = dom.createMorphAt(element19,2,2);
        morphs[4] = dom.createMorphAt(element19,3,3);
        morphs[5] = dom.createMorphAt(dom.childAt(element22, [3]),0,0);
        morphs[6] = dom.createMorphAt(element23,0,0);
        morphs[7] = dom.createMorphAt(element23,2,2);
        morphs[8] = dom.createMorphAt(dom.childAt(element22, [11]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element22, [15]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element25, [3]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element24, [3]),1,1);
        morphs[13] = dom.createMorphAt(element17,5,5);
        morphs[14] = dom.createElementMorph(element27);
        morphs[15] = dom.createMorphAt(element26,3,3);
        morphs[16] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["gotoCustomer",["get","customer.id",["loc",[null,[11,70],[11,81]]]]],[],["loc",[null,[11,46],[11,83]]]],
        ["content","customer.firstname",["loc",[null,[11,84],[11,106]]]],
        ["content","customer.lastname",["loc",[null,[11,107],[11,128]]]],
        ["block","if",[["get","customer.etigaAppConnected",["loc",[null,[11,139],[11,165]]]]],[],0,1,["loc",[null,[11,133],[11,448]]]],
        ["block","if",[["get","customer.sued",["loc",[null,[11,454],[11,467]]]]],[],2,null,["loc",[null,[11,448],[11,538]]]],
        ["content","customer.street",["loc",[null,[16,41],[16,60]]]],
        ["content","customer.postalCode",["loc",[null,[18,41],[18,64]]]],
        ["content","customer.town",["loc",[null,[18,65],[18,82]]]],
        ["content","customer.phone",["loc",[null,[20,41],[20,59]]]],
        ["content","customer.email",["loc",[null,[22,41],[22,59]]]],
        ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[27,79],[27,92]]]]],[],3,null,["loc",[null,[27,73],[27,133]]]],
        ["block","if",[["subexpr","and",[["get","balanceSum",["loc",[null,[28,56],[28,66]]]],["subexpr","show-prices",[],[],["loc",[null,[28,67],[28,80]]]]],[],["loc",[null,[28,51],[28,81]]]]],[],4,null,["loc",[null,[28,45],[28,316]]]],
        ["block","if",[["get","app",["loc",[null,[31,26],[31,29]]]]],[],5,6,["loc",[null,[31,20],[37,27]]]],
        ["block","each",[["get","customer.patients",["loc",[null,[44,18],[44,35]]]]],[],7,null,["loc",[null,[44,10],[161,19]]]],
        ["element","action",["confirm",false,["get","customerId",["loc",[null,[164,42],[164,52]]]],["get","selectedPatients",["loc",[null,[164,53],[164,69]]]]],[],["loc",[null,[164,17],[164,71]]]],
        ["block","if",[["get","customer.sued",["loc",[null,[167,18],[167,31]]]]],[],8,9,["loc",[null,[167,12],[175,19]]]],
        ["inline","treatment-select-panel",[],["confirm","updateSelects","actionReceiver",["subexpr","@mut",[["get","treatmentSelectPanel",["loc",[null,[201,64],[201,84]]]]],[],[]]],["loc",[null,[201,0],[201,86]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
    };
  }()));

});