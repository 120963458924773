define('jason-frontend/templates/practicemgmt/treatment-future-action', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment-future-action.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Vorlage hinzufügen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [
          ["element","action",["addTemplate",["get","selectedFutureId",["loc",[null,[6,34],[6,50]]]]],[],["loc",[null,[6,11],[6,52]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 6
            },
            "end": {
              "line": 14,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/treatment-future-action.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-reworkcontext primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Position hinzufügen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["addArticle",["get","selectedFutureId",["loc",[null,[11,33],[11,49]]]]],[],["loc",[null,[11,11],[11,51]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 49,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/treatment-future-action.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Zukünftige Maßnahme");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","card animated box edit-future-action");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1, 3]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element2, 'class');
        morphs[1] = dom.createMorphAt(element3,1,1);
        morphs[2] = dom.createMorphAt(element3,2,2);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [2]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,47],[1,60]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,26],[1,91]]]]]]],
        ["block","if",[["subexpr","eq",[["get","selectedFuture",["loc",[null,[5,16],[5,30]]]],"add_template"],[],["loc",[null,[5,12],[5,46]]]]],[],0,null,["loc",[null,[5,6],[9,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectedFuture",["loc",[null,[10,16],[10,30]]]],"add_article"],[],["loc",[null,[10,12],[10,45]]]]],[],1,null,["loc",[null,[10,6],[14,13]]]],
        ["inline","treatment-future-action-form",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[21,12],[21,17]]]]],[],[]],"users",["subexpr","@mut",[["get","users",["loc",[null,[22,12],[22,17]]]]],[],[]],"nextExecution",["subexpr","@mut",[["get","nextExecution",["loc",[null,[23,20],[23,33]]]]],[],[]],"nextExecutionTime",["subexpr","@mut",[["get","nextExecutionTime",["loc",[null,[24,24],[24,41]]]]],[],[]],"refererId",["subexpr","@mut",[["get","refererId",["loc",[null,[25,16],[25,25]]]]],[],[]],"future",["subexpr","@mut",[["get","future",["loc",[null,[26,13],[26,19]]]]],[],[]],"timeUnits",["subexpr","@mut",[["get","timeUnits",["loc",[null,[27,16],[27,25]]]]],[],[]],"apiType",["subexpr","@mut",[["get","apiType",["loc",[null,[28,14],[28,21]]]]],[],[]],"patientAppointmentCategories",["subexpr","@mut",[["get","patientAppointmentCategories",["loc",[null,[29,35],[29,63]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[30,12],[30,17]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[31,15],[31,23]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[32,14],[32,21]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[33,17],[33,27]]]]],[],[]],"futureTypes",["subexpr","@mut",[["get","futureTypes",["loc",[null,[34,18],[34,29]]]]],[],[]],"futureTriggerTypes",["subexpr","@mut",[["get","futureTriggerTypes",["loc",[null,[35,25],[35,43]]]]],[],[]],"setFutureType","setFutureType","addArticle","addArticle","addTemplate","addTemplate","clickBack","clickBack","deleteProduct","deleteProduct","changeQuantity","changeQuantity","changeApplied","changeApplied","changeGrossPrice","changeGrossPrice","save","save","actionReceiver",["subexpr","@mut",[["get","treatmentFutureActionForm",["loc",[null,[45,21],[45,46]]]]],[],[]]],["loc",[null,[20,4],[46,6]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});