define('jason-frontend/routes/practice/index', ['exports', 'ember', 'jason-frontend/api', 'ember-cli-pagination/remote/route-mixin'], function (exports, Ember, API, RouteMixin) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend(RouteMixin['default'], {
    model: function model() {},
    setupController: function setupController(controller, model, params) {
      this._super(controller, model);
      var self = this;
      var selectedTab = params.queryParams.selectTab;

      controller.set('mandant', this.store.find('mandant', API['default'].getMandant()));

      if (selectedTab) {
        this.selectTab(selectedTab);
      } else {
        setTimeout(function () {
          if (API['default'].hasPermission('crud_treatment_rooms')) {
            self.selectTab('treatmentrooms');
          } else if (API['default'].hasPermission('crud_treatment_stations')) {
            self.selectTab('treatmentstations');
          } else if (API['default'].hasPermission('ehapo')) {
            self.selectTab('warehouses');
          } else if (API['default'].hasPermission('resources')) {
            self.selectTab('resources');
          }
        }, 500);
      }
      Ember['default'].run.later(this, function () {
        $('.tooltipstered').tooltipster({
          theme: 'tooltipster-punk',
          delay: 0,
          side: 'top',
          touchDevices: false
        });
      }, 500);
    },
    applyFilter: function applyFilter() {
      if (this.get('controller').get('selectTab') === 'treatmentrooms') {
        if (API['default'].hasPermission('crud_treatment_rooms')) {
          this.applyTreatmentrooms();
        }
      }
      if (this.get('controller').get('selectTab') === 'treatmentstations') {
        if (API['default'].hasPermission('crud_treatment_stations')) {
          this.applyTreatmentstations();
        }
      }
      if (this.get('controller').get('selectTab') === 'warehouses') {
        if (API['default'].hasPermission('ehapo')) {
          this.applyWarehouses();
        }
      }
      if (this.get('controller').get('selectTab') === 'resources') {
        this.applyResources();
      }
      if (this.get('controller').get('selectTab') === 'cashregisters') {
        if (API['default'].hasPermission('c_payments') && API['default'].hasPermission('ru_mandant')) {
          this.applyCashregisters();
        }
      }
      if (this.get('controller').get('selectTab') === 'labequipments') {
        if (API['default'].hasPermission('lab')) {
          this.applyEquipments();
        }
      }
      if (this.get('controller').get('selectTab') === 'labprofiles') {
        if (API['default'].hasPermission('lab')) {
          this.applyProfiles();
        }
      }
    },
    applyWarehouses: function applyWarehouses() {
      var controller = this.get('controller');
      this.findPaged('inventory-warehouse', {}).then(function (data) {
        controller.set('model', data);
      });
    },
    applyEquipments: function applyEquipments() {
      var controller = this.get('controller');
      this.findPaged('lab-equipment', {}).then(function (data) {
        controller.set('model', data);
      });
    },
    applyProfiles: function applyProfiles() {
      var controller = this.get('controller');
      this.findPaged('lab-profile', {}).then(function (data) {
        controller.set('model', data);
      });
    },
    applyResources: function applyResources() {
      var controller = this.get('controller');
      this.findPaged('appointment-resource', {}).then(function (data) {
        controller.set('model', data);
      });
    },
    applyCashregisters: function applyCashregisters() {
      var controller = this.get('controller');
      this.store.find('cash-register').then(function (data) {
        controller.set('model', data);
      });
    },
    applyTreatmentrooms: function applyTreatmentrooms() {
      var controller = this.get('controller'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('treatmentRoom', {
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10,
        paged: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    applyTreatmentstations: function applyTreatmentstations() {
      var controller = this.get('controller'),
          sortDir = controller.get('sortDir'),
          sortBy = controller.get('sortBy');

      this.findPaged('treatmentStation', {
        sortDir: sortDir,
        sortBy: sortBy,
        perPage: 10,
        paged: true
      }).then(function (entries) {
        controller.set('model', entries);
      });
    },
    selectTab: function selectTab(selected) {
      var controller = this.get('controller');
      controller.set('selectTab', selected);

      if (selected === 'treatmentrooms') {
        this.applyFilter();
        controller.set('placeholder', 'Behandlungslokation suchen');
      } else if (selected === 'treatmentstations') {
        this.applyFilter();
        controller.set('placeholder', 'Station suchen');
      } else if (selected === 'warehouses') {
        this.applyFilter();
        controller.set('placeholder', 'Lager suchen');
      } else if (selected === 'resources') {
        this.applyFilter();
        controller.set('placeholder', 'Resource suchen');
      } else if (selected === 'cashregisters') {
        this.applyFilter();
        controller.set('placeholder', 'Kassa suchen');
      } else if (selected === 'labequipments') {
        this.applyFilter();
        controller.set('placeholder', 'Gerät suchen');
      } else if (selected === 'labprofiles') {
        this.applyFilter();
        controller.set('placeholder', 'Profil suchen');
      }
    },
    actions: {
      startRksv: function startRksv(id) {
        var register = new Object();
        var self = this;
        register.id = id;

        $.ajax({
          url: "/api/cashRegisters/rksv",
          method: "POST",
          data: JSON.stringify(register),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Aktiviert', 'Elektronische Sicherheitsvorrichtung erfolgreich aktiviert');
          self.selectTab('cashregisters');
        }, function (data) {
          jason.notifiction.error('Fehler', 'Elektronische Sicherheitsvorrichtung konnte nicht aktiviert werden - wenden Sie sich bitte an den Support');
        });
      },
      printNullReciept: function printNullReciept(id) {
        var register = new Object();
        var self = this;
        register.id = id;

        $.ajax({
          url: "/api/cashRegisters/printNullReciept",
          method: "POST",
          data: JSON.stringify(register),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'Nullbeleg erfolgreich erfasst');
        }, function (data) {
          jason.notifiction.error('Fehler', 'Nullbeleg konnte nicht erfasst werden');
        });
      },
      applyFilter: function applyFilter() {
        this.applyFilter();
      },
      selectTab: function selectTab(id) {
        this.selectTab(id);
      },
      deleteStation: function deleteStation(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('treatmentStation', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      },
      deleteLocation: function deleteLocation(id) {
        var controller = this.get('controller'),
            self = this;
        this.get('store').find('treatmentRoom', id).then(function (rec) {
          rec.destroyRecord();
        });

        setTimeout(function () {
          self.applyFilter();
        }, 1000);
      }
    }
  });

});