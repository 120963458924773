define('jason-frontend/templates/transactions/cashdesk', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 127,
                  "column": 46
                },
                "end": {
                  "line": 127,
                  "column": 87
                }
              },
              "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" (Storno)");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 126,
                "column": 20
              },
              "end": {
                "line": 127,
                "column": 94
              }
            },
            "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                #");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","transaction.transactionId",["loc",[null,[127,17],[127,46]]]],
            ["block","if",[["get","transaction.cancellation",["loc",[null,[127,52],[127,76]]]]],[],0,null,["loc",[null,[127,46],[127,94]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 129,
                "column": 14
              },
              "end": {
                "line": 129,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","transaction.recieptDate",["loc",[null,[129,59],[129,82]]]],"DD. MM. YYYY"],[],["loc",[null,[129,45],[129,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 134,
                    "column": 52
                  },
                  "end": {
                    "line": 135,
                    "column": 158
                  }
                },
                "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","transaction.customerFirstname",["loc",[null,[135,92],[135,125]]]],
                ["content","transaction.customerLastname",["loc",[null,[135,126],[135,158]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 134,
                  "column": 15
                },
                "end": {
                  "line": 135,
                  "column": 170
                }
              },
              "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["customers.edit",["get","transaction.customerId",["loc",[null,[134,80],[134,102]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[134,52],[135,170]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 135,
                  "column": 170
                },
                "end": {
                  "line": 136,
                  "column": 27
                }
              },
              "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                 Barverkauf");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 133,
                "column": 19
              },
              "end": {
                "line": 137,
                "column": 13
              }
            },
            "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n               ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n             ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","gt",[["get","transaction.customerId",["loc",[null,[134,25],[134,47]]]],0],[],["loc",[null,[134,21],[134,50]]]]],[],0,1,["loc",[null,[134,15],[136,34]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 141,
                  "column": 49
                },
                "end": {
                  "line": 142,
                  "column": 117
                }
              },
              "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","transaction.invoiceCode",["loc",[null,[142,90],[142,117]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 141,
                "column": 20
              },
              "end": {
                "line": 142,
                "column": 129
              }
            },
            "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["invoices.show",["get","transaction.invoiceId",["loc",[null,[141,76],[141,97]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[141,49],[142,129]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 151,
                "column": 18
              },
              "end": {
                "line": 160,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__cell no-caption l-1/24 l-16/24@desk");
            dom.setAttribute(el1,"data-label","");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__cell no-caption l-17/24 l-4/24@desk");
            dom.setAttribute(el1,"data-label","");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__cell no-caption table__cell--right l-6/24 l-4/24@desk pr0");
            dom.setAttribute(el1,"data-label","");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),1,1);
            return morphs;
          },
          statements: [
            ["content","payment.paymentType.name",["loc",[null,[155,28],[155,56]]]],
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","payment.total",["loc",[null,[158,53],[158,66]]]]],[],[]]],["loc",[null,[158,29],[158,68]]]]
          ],
          locals: ["payment"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 118,
              "column": 6
            },
            "end": {
              "line": 166,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row pr10");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--high l-12/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--high table__cell--name table__cell--right table__cell--left--desktop l-12/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Beleg");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" / ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 l-6/24@desk");
          dom.setAttribute(el2,"data-label","Kunde");
          var el3 = dom.createTextNode("\n             ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--right table__cell--left--desktop l-4/24@desk");
          dom.setAttribute(el2,"data-label","Referenz");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 table__cell--right l-4/24@desk");
          dom.setAttribute(el2,"data-label","Betrag");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createElement("b");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell auto-height mb10 no-padding l-24/24");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table grid table--sub table--transactions");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__row");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element1, [3]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
          morphs[2] = dom.createMorphAt(element2,0,0);
          morphs[3] = dom.createMorphAt(element2,2,2);
          morphs[4] = dom.createMorphAt(element2,4,4);
          morphs[5] = dom.createMorphAt(dom.childAt(element0, [5, 1]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element0, [7, 1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element0, [9, 1, 0]),0,0);
          morphs[8] = dom.createMorphAt(dom.childAt(element0, [11, 1, 1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","transaction.created",["loc",[null,[121,32],[121,51]]]],"DD. MM. YYYY HH:mm:ss"],[],["loc",[null,[121,18],[121,77]]]],
          ["block","link-to",["transactions.show",["get","transaction.id",["loc",[null,[126,51],[126,65]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[126,20],[127,106]]]],
          ["content","transaction.transactionType.name",["loc",[null,[128,30],[128,66]]]],
          ["content","transaction.transactionSubType.name",["loc",[null,[128,69],[128,108]]]],
          ["block","if",[["get","transaction.recieptDate",["loc",[null,[129,20],[129,43]]]]],[],1,null,["loc",[null,[129,14],[129,106]]]],
          ["block","if",[["get","transaction.invoiceId",["loc",[null,[133,25],[133,46]]]]],[],2,null,["loc",[null,[133,19],[137,20]]]],
          ["block","if",[["get","transaction.invoiceId",["loc",[null,[141,26],[141,47]]]]],[],3,null,["loc",[null,[141,20],[142,136]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","transaction.total",["loc",[null,[145,45],[145,62]]]]],[],[]]],["loc",[null,[145,21],[145,64]]]],
          ["block","each",[["get","transaction.transactions",["loc",[null,[151,26],[151,50]]]]],[],4,null,["loc",[null,[151,18],[160,27]]]]
        ],
        locals: ["transaction"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 229,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/transactions/cashdesk.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Kassenjournal");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","filter-lane grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","placeholder");
        var el6 = dom.createTextNode("Zeitraum");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/calendar-dark.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-field-rework");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","select-placeholder");
        var el6 = dom.createTextNode("Kassa");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid box gutter-fix small-dashboard");
        dom.setAttribute(el1,"id","cashDeskTiles");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-24/24 u-show@phone gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title block");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Bar");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title block");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" EC-Karte");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title block");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Kreditkarte");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title block");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Gutschein");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title block mb20");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Guthaben");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col u-hide@phone l-12/24@phone l-8/24@tablet l-8/24@desk gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Bar");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col u-hide@phone l-12/24@phone l-8/24@tablet l-8/24@desk gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("EC-Karte");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col u-hide@phone l-12/24@phone l-8/24@tablet l-8/24@desk gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title is-highlighted");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title is-highlighted");
        var el5 = dom.createTextNode("Gesamt");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col u-hide@phone l-12/24@phone l-8/24@tablet l-8/24@desk gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Kreditkarte");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col u-hide@phone l-12/24@phone l-8/24@tablet l-8/24@desk gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Gutschein");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col u-hide@phone l-12/24@phone l-8/24@tablet l-8/24@desk gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","card u-text--center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","card__title");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Guthaben");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col  l-12/24@tablet l-24/24 quick-filter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Zahlungsströme");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Alle");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Eingänge");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","filterAll");
        var el4 = dom.createTextNode("Ausgänge");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col  l-12/24@tablet l-24/24 quick-filter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode("Zahlungsmittel");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createTextNode("Alle");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createTextNode("Bar");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createTextNode("EC");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createTextNode("Kreditkarte");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createTextNode("Gutschein");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createTextNode("Guthaben");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","table-wrapper-rework");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","grid table table--large");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__head");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-4/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","clickable is-highlighted");
        var el7 = dom.createTextNode("Datum");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-6/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Beleg");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-6/24 pl5");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Kunde");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-4/24 pl0");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Zahlungsmittel");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell table__cell--right l-4/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Summe");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__body");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function () {\n    $('#pickDateCashDesk').daterangepicker({\n      \"autoApply\": true,\n      \"ranges\": {\n        \"Heute\": [moment().startOf('day'), moment().endOf('day')],\n        \"Gestern\": [moment().startOf('day').subtract(1, 'days'), moment().endOf('day').subtract(1, 'days')],\n        \"Letzten 7 Tage\": [moment().startOf('day').subtract(6, 'days'), moment().endOf('day')],\n        \"Dieses Monat\": [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],\n        \"Letztes Monat\": [moment().startOf('month').subtract(1, 'month').startOf('month'), moment().endOf('month').subtract(1, 'month').endOf('month')],\n        \"Dieses Jahr\": [moment().startOf('year'), moment().endOf('year')],\n        \"Letztes Jahr\": [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],\n      },\n      \"locale\": {\n        \"format\": \"DD. MM. YYYY HH:mm\",\n        \"separator\": \" - \",\n        \"applyLabel\": \"Anwenden\",\n        \"cancelLabel\": \"Abbrechen\",\n        \"fromLabel\": \"Von\",\n        \"toLabel\": \"Bis\",\n        \"customRangeLabel\": \"Benutzerdefiniert\",\n        \"daysOfWeek\": [\n          \"So\",\n          \"Mo\",\n          \"Di\",\n          \"Mi\",\n          \"Do\",\n          \"Fr\",\n          \"Sa\"\n        ],\n        \"monthNames\": [\n          \"Jänner\",\n          \"Februar\",\n          \"März\",\n          \"April\",\n          \"Mai\",\n          \"Juni\",\n          \"Juli\",\n          \"August\",\n          \"September\",\n          \"Oktober\",\n          \"November\",\n          \"Dezember\"\n        ],\n        \"firstDay\": 1\n      },\n      \"linkedCalendars\": false,\n      timePicker: true,\n      timePicker24Hour: true\n    });\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [3, 1]);
        var element5 = dom.childAt(fragment, [2]);
        var element6 = dom.childAt(element5, [1, 1]);
        var element7 = dom.childAt(fragment, [4]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element8, [3]);
        var element10 = dom.childAt(element8, [5]);
        var element11 = dom.childAt(element8, [7]);
        var element12 = dom.childAt(element7, [3]);
        var element13 = dom.childAt(element12, [3]);
        var element14 = dom.childAt(element12, [5]);
        var element15 = dom.childAt(element12, [7]);
        var element16 = dom.childAt(element12, [9]);
        var element17 = dom.childAt(element12, [11]);
        var element18 = dom.childAt(element12, [13]);
        var element19 = dom.childAt(fragment, [6, 1]);
        var element20 = dom.childAt(element19, [1, 1, 0, 0]);
        var morphs = new Array(36);
        morphs[0] = dom.createAttrMorph(element3, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]),3,3);
        morphs[3] = dom.createMorphAt(dom.childAt(element6, [1]),0,0);
        morphs[4] = dom.createMorphAt(dom.childAt(element6, [3]),0,0);
        morphs[5] = dom.createMorphAt(dom.childAt(element6, [5]),0,0);
        morphs[6] = dom.createMorphAt(dom.childAt(element6, [7]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(element6, [9]),0,0);
        morphs[8] = dom.createMorphAt(dom.childAt(element6, [11]),0,0);
        morphs[9] = dom.createMorphAt(dom.childAt(element5, [3, 1, 1]),0,0);
        morphs[10] = dom.createMorphAt(dom.childAt(element5, [5, 1, 1]),0,0);
        morphs[11] = dom.createMorphAt(dom.childAt(element5, [7, 1, 1]),0,0);
        morphs[12] = dom.createMorphAt(dom.childAt(element5, [9, 1, 1]),0,0);
        morphs[13] = dom.createMorphAt(dom.childAt(element5, [11, 1, 1]),0,0);
        morphs[14] = dom.createMorphAt(dom.childAt(element5, [13, 1, 1]),0,0);
        morphs[15] = dom.createAttrMorph(element9, 'class');
        morphs[16] = dom.createElementMorph(element9);
        morphs[17] = dom.createAttrMorph(element10, 'class');
        morphs[18] = dom.createElementMorph(element10);
        morphs[19] = dom.createAttrMorph(element11, 'class');
        morphs[20] = dom.createElementMorph(element11);
        morphs[21] = dom.createAttrMorph(element13, 'class');
        morphs[22] = dom.createElementMorph(element13);
        morphs[23] = dom.createAttrMorph(element14, 'class');
        morphs[24] = dom.createElementMorph(element14);
        morphs[25] = dom.createAttrMorph(element15, 'class');
        morphs[26] = dom.createElementMorph(element15);
        morphs[27] = dom.createAttrMorph(element16, 'class');
        morphs[28] = dom.createElementMorph(element16);
        morphs[29] = dom.createAttrMorph(element17, 'class');
        morphs[30] = dom.createElementMorph(element17);
        morphs[31] = dom.createAttrMorph(element18, 'class');
        morphs[32] = dom.createElementMorph(element18);
        morphs[33] = dom.createElementMorph(element20);
        morphs[34] = dom.createMorphAt(dom.childAt(element19, [3]),1,1);
        morphs[35] = dom.createMorphAt(dom.childAt(fragment, [8]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,47],[1,58]]]],"with-filters",""],[],["loc",[null,[1,26],[1,78]]]]]]],
        ["inline","input",[],["id","pickDateCashDesk","value",["subexpr","@mut",[["get","pickDate",["loc",[null,[8,44],[8,52]]]]],[],[]],"class","","name","pickDate"],["loc",[null,[8,8],[9,34]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","cashRegisters",["loc",[null,[16,18],[16,31]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedRegister",["loc",[null,[17,16],[17,32]]]]],[],[]],"optionLabelPath","name","allowClear","true","placeholder","Alle Kassen","cssClass","custom-select2 newStyle tuned"],["loc",[null,[15,8],[22,10]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.cash",["loc",[null,[31,54],[31,70]]]]],[],[]]],["loc",[null,[31,30],[31,72]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.cash",["loc",[null,[32,56],[32,72]]]]],[],[]],"classNames","inline-block"],["loc",[null,[32,32],[32,100]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.cashcard",["loc",[null,[33,56],[33,76]]]]],[],[]],"classNames","inline-block"],["loc",[null,[33,32],[33,104]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.creditcard",["loc",[null,[34,56],[34,78]]]]],[],[]],"classNames","inline-block"],["loc",[null,[34,32],[34,106]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.voucher",["loc",[null,[36,56],[36,75]]]]],[],[]],"classNames","inline-block"],["loc",[null,[36,32],[36,103]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.deposit",["loc",[null,[37,61],[37,80]]]]],[],[]],"classNames","inline-block"],["loc",[null,[37,37],[37,108]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.cash",["loc",[null,[43,54],[43,70]]]]],[],[]]],["loc",[null,[43,30],[43,72]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.cashcard",["loc",[null,[49,54],[49,74]]]]],[],[]]],["loc",[null,[49,30],[49,76]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","total",["loc",[null,[55,69],[55,74]]]]],[],[]]],["loc",[null,[55,45],[55,76]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.creditcard",["loc",[null,[61,54],[61,76]]]]],[],[]]],["loc",[null,[61,30],[61,78]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.voucher",["loc",[null,[67,54],[67,73]]]]],[],[]]],["loc",[null,[67,30],[67,75]]]],
        ["inline","format-currency",[],["value",["subexpr","@mut",[["get","paymentSums.deposit",["loc",[null,[73,54],[73,73]]]]],[],[]]],["loc",[null,[73,30],[73,75]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterType",["loc",[null,[83,59],[83,69]]]],"all"],[],["loc",[null,[83,55],[83,76]]]],"selected",""],[],["loc",[null,[83,34],[83,93]]]]]]],
        ["element","action",["setFilterType","all"],[],["loc",[null,[84,27],[84,59]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterType",["loc",[null,[85,59],[85,69]]]],"in"],[],["loc",[null,[85,55],[85,75]]]],"selected",""],[],["loc",[null,[85,34],[85,92]]]]]]],
        ["element","action",["setFilterType","in"],[],["loc",[null,[86,27],[86,58]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","filterType",["loc",[null,[87,59],[87,69]]]],"out"],[],["loc",[null,[87,55],[87,76]]]],"selected",""],[],["loc",[null,[87,34],[87,93]]]]]]],
        ["element","action",["setFilterType","out"],[],["loc",[null,[88,27],[88,59]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["get","filterPaymentAll",["loc",[null,[92,55],[92,71]]]],"selected",""],[],["loc",[null,[92,34],[92,88]]]]]]],
        ["element","action",["setPaymentType","all"],[],["loc",[null,[92,91],[93,30]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["get","filterPaymentCash",["loc",[null,[94,55],[94,72]]]],"selected",""],[],["loc",[null,[94,34],[94,89]]]]]]],
        ["element","action",["setPaymentType","cash"],[],["loc",[null,[94,92],[95,31]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["get","filterPaymentCashcard",["loc",[null,[96,55],[96,76]]]],"selected",""],[],["loc",[null,[96,34],[96,93]]]]]]],
        ["element","action",["setPaymentType","cashcard"],[],["loc",[null,[96,96],[97,35]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["get","filterPaymentCreditcard",["loc",[null,[98,55],[98,78]]]],"selected",""],[],["loc",[null,[98,34],[98,95]]]]]]],
        ["element","action",["setPaymentType","creditcard"],[],["loc",[null,[98,98],[99,37]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["get","filterPaymentVoucher",["loc",[null,[100,55],[100,75]]]],"selected",""],[],["loc",[null,[100,34],[100,92]]]]]]],
        ["element","action",["setPaymentType","voucher"],[],["loc",[null,[100,95],[101,34]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["get","filterPaymentDeposit",["loc",[null,[102,55],[102,75]]]],"selected",""],[],["loc",[null,[102,34],[102,92]]]]]]],
        ["element","action",["setPaymentType","deposit"],[],["loc",[null,[102,95],[103,34]]]],
        ["element","action",["changeSortDir"],[],["loc",[null,[110,107],[111,25]]]],
        ["block","each",[["get","model",["loc",[null,[118,14],[118,19]]]]],[],0,null,["loc",[null,[118,6],[166,15]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[171,25],[171,32]]]]],[],[]],"numPagesToShow",7],["loc",[null,[171,2],[171,51]]]]
      ],
      locals: [],
      templates: [child0]
    };
  }()));

});