define('jason-frontend/templates/components/button-save', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/button-save.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"{{size}}\" height=\"{{size}}\" viewBox=\"0 0 32 32\">");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("  <g fill=\"none\" fill-rule=\"evenodd\">");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("    {{#if showStroke}}<circle stroke=\"{{color}}\" cx=\"16\" cy=\"16\" r=\"14\"/>{{/if}}");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("    <g>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("      <path fill=\"{{color}}\" d=\"M11.8116,1.7016 L10.299,0.189 C10.242,0.132 10.1652,0.1002 10.0848,0.1002 L8.8746,0.1002 L3.1254,0.1002 L1.0074,0.1002 C0.507,0.1002 0.0996,0.5076 0.0996,1.008 L0.0996,10.9926 C0.0996,11.493 0.507,11.9004 1.0074,11.9004 L2.52,11.9004 L9.4788,11.9004 L10.9914,11.9004 C11.4918,11.9004 11.8992,11.493 11.8992,10.9926 L11.8992,1.9152 C11.8998,1.8354 11.868,1.758 11.8116,1.7016 Z M7.059,0.705 L8.5716,0.705 L8.5716,3.4278 C8.5716,3.5946 8.436,3.7302 8.2692,3.7302 L7.059,3.7302 L7.059,0.705 L7.059,0.705 Z M3.4284,0.705 L6.4542,0.705 L6.4542,3.7308 L3.7308,3.7308 C3.564,3.7308 3.4284,3.5952 3.4284,3.4284 L3.4284,0.705 Z M9.177,11.295 L2.823,11.295 L2.823,7.6644 L9.177,7.6644 L9.177,11.295 Z M11.295,10.9926 C11.295,11.1594 11.1594,11.295 10.9926,11.295 L9.7824,11.295 L9.7824,7.3614 C9.7824,7.1946 9.6468,7.059 9.48,7.059 L2.5206,7.059 C2.3538,7.059 2.2182,7.1946 2.2182,7.3614 L2.2182,11.295 L1.0074,11.295 C0.8406,11.295 0.705,11.1594 0.705,10.9926 L0.705,1.0074 C0.705,0.8406 0.8406,0.705 1.0074,0.705 L2.823,0.705 L2.823,3.4278 C2.823,3.9282 3.2304,4.3356 3.7308,4.3356 L8.2692,4.3356 C8.7696,4.3356 9.177,3.9282 9.177,3.4278 L9.177,0.705 L9.9594,0.705 L11.295,2.0406 L11.295,10.9926 Z\" transform=\"translate(10 10)\"/>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("    </g>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("  </g>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("</svg>");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"viewBox","0 0 24 24");
        dom.setAttribute(el1,"fill","none");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2,"fill-rule","evenodd");
        dom.setAttribute(el2,"clip-rule","evenodd");
        dom.setAttribute(el2,"d","M3 9C3 6.17157 3 4.75736 3.87868 3.87868C4.75736 3 6.17157 3 9 3H15.3431C16.1606 3 16.5694 3 16.9369 3.15224C17.3045 3.30448 17.5935 3.59351 18.1716 4.17157L19.8284 5.82843C20.4065 6.40649 20.6955 6.69552 20.8478 7.06306C21 7.4306 21 7.83935 21 8.65685V15C21 17.8284 21 19.2426 20.1213 20.1213C19.48 20.7626 18.5534 20.9359 17 20.9827V18L17 17.9384C17.0001 17.2843 17.0001 16.6965 16.9362 16.2208C16.8663 15.7015 16.7042 15.1687 16.2678 14.7322C15.8313 14.2958 15.2985 14.1337 14.7792 14.0638C14.3034 13.9999 13.7157 13.9999 13.0616 14L13 14H10L9.93839 14C9.28427 13.9999 8.69655 13.9999 8.22084 14.0638C7.70149 14.1337 7.16867 14.2958 6.73223 14.7322C6.29579 15.1687 6.13366 15.7015 6.06383 16.2208C5.99988 16.6965 5.99993 17.2843 6 17.9384L6 18V20.9239C5.02491 20.828 4.36857 20.6112 3.87868 20.1213C3 19.2426 3 17.8284 3 15V9ZM15 18V21H9C8.64496 21 8.31221 21 8 20.9983V18C8 17.2646 8.00212 16.8137 8.046 16.4873C8.08457 16.2005 8.13942 16.1526 8.14592 16.1469L8.14645 16.1464L8.14692 16.1459C8.1526 16.1394 8.20049 16.0846 8.48734 16.046C8.81369 16.0021 9.26462 16 10 16H13C13.7354 16 14.1863 16.0021 14.5127 16.046C14.7995 16.0846 14.8474 16.1394 14.8531 16.1459L14.8536 16.1464L14.8541 16.1469C14.8606 16.1526 14.9154 16.2005 14.954 16.4873C14.9979 16.8137 15 17.2646 15 18ZM7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H12C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7H7Z");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [16]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element1, 'fill');
        return morphs;
      },
      statements: [
        ["attribute","width",["concat",[["get","size",["loc",[null,[11,14],[11,18]]]]]]],
        ["attribute","height",["concat",[["get","size",["loc",[null,[11,32],[11,36]]]]]]],
        ["attribute","fill",["concat",[["get","color",["loc",[null,[12,16],[12,21]]]]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});