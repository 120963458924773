define('jason-frontend/templates/templates/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 7,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/email-white.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Test-Email verschicken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element44 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element44);
          return morphs;
        },
        statements: [
          ["element","action",["openTestmailPanel","warning"],[],["loc",[null,[6,11],[6,51]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 10,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/email-white.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Test-Email verschicken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element43 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element43);
          return morphs;
        },
        statements: [
          ["element","action",["openTestmailPanel","reminder"],[],["loc",[null,[9,11],[9,52]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 6
            },
            "end": {
              "line": 13,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Textvorlage");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element42 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element42);
          return morphs;
        },
        statements: [
          ["element","action",["createTemplate"],[],["loc",[null,[12,11],[12,38]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 6
            },
            "end": {
              "line": 16,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Textbaustein");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element41 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element41);
          return morphs;
        },
        statements: [
          ["element","action",["createTextmodule"],[],["loc",[null,[15,11],[15,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 6
            },
            "end": {
              "line": 19,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Email-Vorlage");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element40 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element40);
          return morphs;
        },
        statements: [
          ["element","action",["createTextmoduleForMail"],[],["loc",[null,[18,11],[18,47]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 6
            },
            "end": {
              "line": 22,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Bericht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element39 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element39);
          return morphs;
        },
        statements: [
          ["element","action",["createTextmoduleForReport"],[],["loc",[null,[21,11],[21,49]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 6
            },
            "end": {
              "line": 25,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Rechnungsvorlage");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element38 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element38);
          return morphs;
        },
        statements: [
          ["element","action",["createTextmoduleForInvoice"],[],["loc",[null,[24,11],[24,50]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 6
            },
            "end": {
              "line": 33,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[32,32],[32,42]]]]],[],[]],"placeHolder",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[32,79],[32,88]]]],"templates"],[],["loc",[null,[32,75],[32,101]]]],"Vorlage suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[32,143],[32,152]]]],"connectedArticles"],[],["loc",[null,[32,139],[32,173]]]],"Erinnerung suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[32,218],[32,227]]]],"textmodules"],[],["loc",[null,[32,214],[32,242]]]],"Textbaustein suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[32,289],[32,298]]]],"reports"],[],["loc",[null,[32,285],[32,309]]]],"Bericht suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[32,351],[32,360]]]],"invoices"],[],["loc",[null,[32,347],[32,372]]]],"Rechnungsvorlage suchen",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[32,423],[32,432]]]],"emails"],[],["loc",[null,[32,419],[32,442]]]],"Email-Vorlage suchen",""],[],["loc",[null,[32,399],[32,469]]]]],[],["loc",[null,[32,327],[32,470]]]]],[],["loc",[null,[32,265],[32,471]]]]],[],["loc",[null,[32,194],[32,472]]]]],[],["loc",[null,[32,119],[32,473]]]]],[],["loc",[null,[32,55],[32,474]]]],"search","applyFilter"],["loc",[null,[32,8],[32,497]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 2
            },
            "end": {
              "line": 45,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-textmodules");
          var el2 = dom.createTextNode("Textbausteine");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-reports");
          var el2 = dom.createTextNode("Berichte");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-reminder");
          var el2 = dom.createTextNode("Erinnerungen allgemein");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-connectedArticles");
          var el2 = dom.createTextNode("Erinnerungen speziell");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element34 = dom.childAt(fragment, [1]);
          var element35 = dom.childAt(fragment, [3]);
          var element36 = dom.childAt(fragment, [5]);
          var element37 = dom.childAt(fragment, [7]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element34, 'class');
          morphs[1] = dom.createElementMorph(element34);
          morphs[2] = dom.createAttrMorph(element35, 'class');
          morphs[3] = dom.createElementMorph(element35);
          morphs[4] = dom.createAttrMorph(element36, 'class');
          morphs[5] = dom.createElementMorph(element36);
          morphs[6] = dom.createAttrMorph(element37, 'class');
          morphs[7] = dom.createElementMorph(element37);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[41,53],[41,62]]]],"textmodules"],[],["loc",[null,[41,49],[41,77]]]],"is-active",""],[],["loc",[null,[41,28],[41,94]]]]]]],
          ["element","action",["selectTab","textmodules"],[],["loc",[null,[41,120],[41,156]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[42,53],[42,62]]]],"reports"],[],["loc",[null,[42,49],[42,73]]]],"is-active",""],[],["loc",[null,[42,28],[42,90]]]]]]],
          ["element","action",["selectTab","reports"],[],["loc",[null,[42,112],[42,144]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[43,53],[43,62]]]],"reminder"],[],["loc",[null,[43,49],[43,74]]]],"is-active",""],[],["loc",[null,[43,28],[43,91]]]]]]],
          ["element","action",["selectTab","reminder"],[],["loc",[null,[43,114],[43,147]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[44,53],[44,62]]]],"connectedArticles"],[],["loc",[null,[44,49],[44,83]]]],"is-active",""],[],["loc",[null,[44,28],[44,100]]]]]]],
          ["element","action",["selectTab","connectedArticles"],[],["loc",[null,[44,132],[44,174]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 4
              },
              "end": {
                "line": 49,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#tab-invoices");
            var el2 = dom.createTextNode("Rechnungen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element31 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element31, 'class');
            morphs[1] = dom.createElementMorph(element31);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[48,55],[48,64]]]],"invoices"],[],["loc",[null,[48,51],[48,76]]]],"is-active",""],[],["loc",[null,[48,30],[48,93]]]]]]],
            ["element","action",["selectTab","invoices"],[],["loc",[null,[48,116],[48,149]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 2
            },
            "end": {
              "line": 52,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-emails");
          var el2 = dom.createTextNode("Emails");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-warning");
          var el2 = dom.createTextNode("Mahnungen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element32 = dom.childAt(fragment, [2]);
          var element33 = dom.childAt(fragment, [4]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createAttrMorph(element32, 'class');
          morphs[2] = dom.createElementMorph(element32);
          morphs[3] = dom.createAttrMorph(element33, 'class');
          morphs[4] = dom.createElementMorph(element33);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[47,10],[47,18]]]]],[],0,null,["loc",[null,[47,4],[49,11]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[50,53],[50,62]]]],"emails"],[],["loc",[null,[50,49],[50,72]]]],"is-active",""],[],["loc",[null,[50,28],[50,89]]]]]]],
          ["element","action",["selectTab","emails"],[],["loc",[null,[50,110],[50,141]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[51,53],[51,62]]]],"warning"],[],["loc",[null,[51,49],[51,73]]]],"is-active",""],[],["loc",[null,[51,28],[51,90]]]]]]],
          ["element","action",["selectTab","warning"],[],["loc",[null,[51,112],[51,144]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 2
            },
            "end": {
              "line": 56,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-app");
          var el2 = dom.createTextNode("App");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-settings");
          var el2 = dom.createTextNode("Einstellungen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element29 = dom.childAt(fragment, [1]);
          var element30 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element29, 'class');
          morphs[1] = dom.createElementMorph(element29);
          morphs[2] = dom.createAttrMorph(element30, 'class');
          morphs[3] = dom.createElementMorph(element30);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[54,53],[54,62]]]],"app"],[],["loc",[null,[54,49],[54,69]]]],"is-active",""],[],["loc",[null,[54,28],[54,86]]]]]]],
          ["element","action",["selectTab","app"],[],["loc",[null,[54,104],[54,132]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[55,53],[55,62]]]],"settings"],[],["loc",[null,[55,49],[55,74]]]],"is-active",""],[],["loc",[null,[55,28],[55,91]]]]]]],
          ["element","action",["selectTab","settings"],[],["loc",[null,[55,114],[55,147]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 120,
              "column": 0
            },
            "end": {
              "line": 144,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"data-for","#tab-emails");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","mt0 mb20");
          var el3 = dom.createTextNode("Weiterleitung per Email");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","mandant-emaildata-invoiceSubject");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Email-Betreff");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","mandant-emaildata-invoiceHeadline");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Überschrift");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 gutter");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","mandant-emaildata-invoiceClosing");
          dom.setAttribute(el4,"class","field-label title");
          var el5 = dom.createTextNode("Abschlusssatz");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element28 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element28, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element28, [3, 1]),3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element28, [6, 1]),3,3);
          morphs[3] = dom.createMorphAt(dom.childAt(element28, [8, 1]),3,3);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__content card ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[121,63],[121,72]]]],"emails"],[],["loc",[null,[121,59],[121,82]]]],"is-active",""],[],["loc",[null,[121,38],[121,99]]]]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.invoiceSubject",["loc",[null,[127,26],[127,50]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-invoiceSubject"],["loc",[null,[127,12],[127,143]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.invoiceHeadline",["loc",[null,[134,26],[134,51]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-invoiceHeadline"],["loc",[null,[134,12],[134,145]]]],
          ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.invoiceClosing",["loc",[null,[140,26],[140,50]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-invoiceClosing"],["loc",[null,[140,12],[140,143]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 288,
                  "column": 24
                },
                "end": {
                  "line": 290,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","is-highlighted cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element25 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element25);
              morphs[1] = dom.createMorphAt(element25,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[289,85],[289,94]]]],"def"],[],["loc",[null,[289,59],[289,102]]]],
              ["content","module.title",["loc",[null,[289,103],[289,119]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 290,
                  "column": 24
                },
                "end": {
                  "line": 292,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","module.title",["loc",[null,[291,26],[291,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 297,
                  "column": 22
                },
                "end": {
                  "line": 307,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/edit.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element24 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createElementMorph(element24);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","module",["loc",[null,[299,100],[299,106]]]]],[],[]],"confirmed","deleteTextModule","content","","text","Wollen Sie diesen Textbaustein wirklich löschen?"],["loc",[null,[299,26],[301,112]]]],
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[304,96],[304,105]]]],"def"],[],["loc",[null,[303,87],[304,113]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 281,
                "column": 16
              },
              "end": {
                "line": 310,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/textmodule-dark.svg");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element26 = dom.childAt(fragment, [1]);
            var element27 = dom.childAt(element26, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element27, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element27, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element26, [5]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","module.editable",["loc",[null,[288,30],[288,45]]]]],[],0,1,["loc",[null,[288,24],[292,31]]]],
            ["content","module.desc",["loc",[null,[294,40],[294,55]]]],
            ["block","if",[["get","module.editable",["loc",[null,[297,28],[297,43]]]]],[],2,null,["loc",[null,[297,22],[307,29]]]]
          ],
          locals: ["module"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 280,
              "column": 14
            },
            "end": {
              "line": 311,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","textModules",["loc",[null,[281,24],[281,35]]]]],[],0,null,["loc",[null,[281,16],[310,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 336,
                  "column": 24
                },
                "end": {
                  "line": 338,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","is-highlighted cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element21 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element21);
              morphs[1] = dom.createMorphAt(element21,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[337,85],[337,94]]]],"report"],[],["loc",[null,[337,59],[337,105]]]],
              ["content","module.title",["loc",[null,[337,106],[337,122]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 338,
                  "column": 24
                },
                "end": {
                  "line": 340,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","module.title",["loc",[null,[339,26],[339,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 345,
                  "column": 22
                },
                "end": {
                  "line": 352,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","exp icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/edit.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element20 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createElementMorph(element20);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","module",["loc",[null,[347,100],[347,106]]]]],[],[]],"confirmed","deleteTextModule","content","","text","Wollen Sie diesen Textbaustein wirklich löschen?"],["loc",[null,[347,26],[347,204]]]],
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[349,113],[349,122]]]],"report"],[],["loc",[null,[349,87],[349,133]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 329,
                "column": 16
              },
              "end": {
                "line": 355,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/textmodule-dark.svg");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element22 = dom.childAt(fragment, [1]);
            var element23 = dom.childAt(element22, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element23, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element23, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element22, [5]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","module.editable",["loc",[null,[336,30],[336,45]]]]],[],0,1,["loc",[null,[336,24],[340,31]]]],
            ["content","module.desc",["loc",[null,[342,40],[342,55]]]],
            ["block","if",[["get","module.editable",["loc",[null,[345,28],[345,43]]]]],[],2,null,["loc",[null,[345,22],[352,29]]]]
          ],
          locals: ["module"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 328,
              "column": 14
            },
            "end": {
              "line": 356,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","textModules",["loc",[null,[329,24],[329,35]]]]],[],0,null,["loc",[null,[329,16],[355,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 382,
                  "column": 24
                },
                "end": {
                  "line": 385,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","is-highlighted cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element17);
              morphs[1] = dom.createMorphAt(element17,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[383,85],[383,94]]]],"email"],[],["loc",[null,[383,59],[384,77]]]],
              ["content","module.title",["loc",[null,[384,78],[384,94]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 385,
                  "column": 24
                },
                "end": {
                  "line": 387,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","module.title",["loc",[null,[386,26],[386,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 392,
                  "column": 22
                },
                "end": {
                  "line": 402,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/edit.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element16 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createElementMorph(element16);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","module",["loc",[null,[394,100],[394,106]]]]],[],[]],"confirmed","deleteTextModuleForEmail","content","","text","Wollen Sie diese Email-Vorlage wirklich löschen?"],["loc",[null,[394,26],[396,112]]]],
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[399,100],[399,109]]]],"email"],[],["loc",[null,[398,87],[399,119]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 375,
                "column": 16
              },
              "end": {
                "line": 405,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/textmodule-dark.svg");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var element19 = dom.childAt(element18, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element19, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element19, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element18, [5]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","module.editable",["loc",[null,[382,30],[382,45]]]]],[],0,1,["loc",[null,[382,24],[387,31]]]],
            ["content","module.desc",["loc",[null,[389,40],[389,55]]]],
            ["block","if",[["get","module.editable",["loc",[null,[392,28],[392,43]]]]],[],2,null,["loc",[null,[392,22],[402,29]]]]
          ],
          locals: ["module"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 374,
              "column": 14
            },
            "end": {
              "line": 406,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","textModules",["loc",[null,[375,24],[375,35]]]]],[],0,null,["loc",[null,[375,16],[405,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child15 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 431,
                  "column": 24
                },
                "end": {
                  "line": 433,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","is-highlighted cursor");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element13);
              morphs[1] = dom.createMorphAt(element13,0,0);
              return morphs;
            },
            statements: [
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[432,85],[432,94]]]],"invoice"],[],["loc",[null,[432,59],[432,106]]]],
              ["content","module.title",["loc",[null,[432,107],[432,123]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 433,
                  "column": 24
                },
                "end": {
                  "line": 435,
                  "column": 24
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","module.title",["loc",[null,[434,26],[434,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 440,
                  "column": 22
                },
                "end": {
                  "line": 447,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class"," icon-button icon-button--delete list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class"," icon-button icon-button--default list-action-square");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/edit.svg");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createElementMorph(element12);
              return morphs;
            },
            statements: [
              ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","module",["loc",[null,[442,100],[442,106]]]]],[],[]],"confirmed","deleteTextModule","content","","text","Wollen Sie diesen Textbaustein wirklich löschen?"],["loc",[null,[442,26],[442,204]]]],
              ["element","action",["openTextModule",["get","module.id",["loc",[null,[444,114],[444,123]]]],"invoice"],[],["loc",[null,[444,88],[444,135]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 424,
                "column": 16
              },
              "end": {
                "line": 450,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/textmodule-dark.svg");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var element15 = dom.childAt(element14, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element15, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element15, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element14, [5]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","module.editable",["loc",[null,[431,30],[431,45]]]]],[],0,1,["loc",[null,[431,24],[435,31]]]],
            ["content","module.desc",["loc",[null,[437,40],[437,55]]]],
            ["block","if",[["get","module.editable",["loc",[null,[440,28],[440,43]]]]],[],2,null,["loc",[null,[440,22],[447,29]]]]
          ],
          locals: ["module"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 423,
              "column": 14
            },
            "end": {
              "line": 451,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","textModules",["loc",[null,[424,24],[424,35]]]]],[],0,null,["loc",[null,[424,16],[450,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 481,
                    "column": 24
                  },
                  "end": {
                    "line": 487,
                    "column": 24
                  }
                },
                "moduleName": "jason-frontend/templates/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"size","36","item",["subexpr","@mut",[["get","template",["loc",[null,[483,102],[483,110]]]]],[],[]],"confirmed","deleteTemplate","content","","text","Wollen Sie diese Textvorlage wirklich löschen?"],["loc",[null,[483,28],[485,112]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 487,
                    "column": 24
                  },
                  "end": {
                    "line": 490,
                    "column": 24
                  }
                },
                "moduleName": "jason-frontend/templates/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","ml5 float-right icon-button--placeholder");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 470,
                  "column": 18
                },
                "end": {
                  "line": 497,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"src","assets/images/icons/template-dark.svg");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--name l-24/24@phone l-19/24");
              dom.setAttribute(el2,"data-label","Name");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"class","is-highlighted cursor");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","block");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-2");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square");
              var el4 = dom.createTextNode("\n                          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"src","assets/images/icons/rework/edit.svg");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var element8 = dom.childAt(element7, [3]);
              var element9 = dom.childAt(element8, [1, 0]);
              var element10 = dom.childAt(element7, [5]);
              var element11 = dom.childAt(element10, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createElementMorph(element9);
              morphs[1] = dom.createMorphAt(element9,0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
              morphs[3] = dom.createMorphAt(element10,1,1);
              morphs[4] = dom.createElementMorph(element11);
              return morphs;
            },
            statements: [
              ["element","action",["openEditTemplate",["get","template.id",["loc",[null,[477,72],[477,83]]]]],[],["loc",[null,[476,63],[477,85]]]],
              ["content","template.name",["loc",[null,[477,86],[477,103]]]],
              ["content","template.category.name",["loc",[null,[478,44],[478,70]]]],
              ["block","if",[["get","template.editable",["loc",[null,[481,30],[481,47]]]]],[],0,1,["loc",[null,[481,24],[490,31]]]],
              ["element","action",["openEditTemplate",["get","template.id",["loc",[null,[492,100],[492,111]]]]],[],["loc",[null,[491,87],[492,113]]]]
            ],
            locals: ["template"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 469,
                "column": 16
              },
              "end": {
                "line": 498,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","templates",["loc",[null,[470,26],[470,35]]]]],[],0,null,["loc",[null,[470,18],[497,27]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 460,
              "column": 8
            },
            "end": {
              "line": 502,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table-wrapper-rework");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table table--large grid");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__head");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-1/24");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-19/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Name");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-4/24");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","templates",["loc",[null,[469,22],[469,31]]]]],[],0,null,["loc",[null,[469,16],[498,23]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 523,
                      "column": 64
                    },
                    "end": {
                      "line": 523,
                      "column": 153
                    }
                  },
                  "moduleName": "jason-frontend/templates/templates/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","product.name",["loc",[null,[523,137],[523,153]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 523,
                      "column": 166
                    },
                    "end": {
                      "line": 523,
                      "column": 343
                    }
                  },
                  "moduleName": "jason-frontend/templates/templates/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1,"class","ml10");
                  dom.setAttribute(el1,"src","assets/images/icons/external-link.svg");
                  dom.setAttribute(el1,"style","max-width: 20px;");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 522,
                    "column": 22
                  },
                  "end": {
                    "line": 524,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","d-flex align-items-center");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element3,0,0);
                morphs[1] = dom.createMorphAt(element3,2,2);
                return morphs;
              },
              statements: [
                ["block","link-to",["products.edit",["get","product.id",["loc",[null,[523,91],[523,101]]]],["subexpr","query-params",[],["selectTab","future"],["loc",[null,[523,102],[523,135]]]]],[],0,null,["loc",[null,[523,64],[523,165]]]],
                ["block","link-to",["products.edit",["get","product.id",["loc",[null,[523,193],[523,203]]]],["subexpr","query-params",[],["selectTab","future"],["loc",[null,[523,204],[523,237]]]]],["target","_blank"],1,null,["loc",[null,[523,166],[523,355]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 525,
                        "column": 64
                      },
                      "end": {
                        "line": 525,
                        "column": 153
                      }
                    },
                    "moduleName": "jason-frontend/templates/templates/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","product.name",["loc",[null,[525,137],[525,153]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 525,
                        "column": 166
                      },
                      "end": {
                        "line": 525,
                        "column": 343
                      }
                    },
                    "moduleName": "jason-frontend/templates/templates/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1,"class","ml10");
                    dom.setAttribute(el1,"src","assets/images/icons/external-link.svg");
                    dom.setAttribute(el1,"style","max-width: 20px;");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 524,
                      "column": 22
                    },
                    "end": {
                      "line": 526,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/templates/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","d-flex align-items-center");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element2,0,0);
                  morphs[1] = dom.createMorphAt(element2,2,2);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["services.edit",["get","product.id",["loc",[null,[525,91],[525,101]]]],["subexpr","query-params",[],["selectTab","future"],["loc",[null,[525,102],[525,135]]]]],[],0,null,["loc",[null,[525,64],[525,165]]]],
                  ["block","link-to",["services.edit",["get","product.id",["loc",[null,[525,193],[525,203]]]],["subexpr","query-params",[],["selectTab","future"],["loc",[null,[525,204],[525,237]]]]],["target","_blank"],1,null,["loc",[null,[525,166],[525,355]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 527,
                          "column": 64
                        },
                        "end": {
                          "line": 527,
                          "column": 162
                        }
                      },
                      "moduleName": "jason-frontend/templates/templates/index.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","product.name",["loc",[null,[527,146],[527,162]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 527,
                          "column": 175
                        },
                        "end": {
                          "line": 527,
                          "column": 361
                        }
                      },
                      "moduleName": "jason-frontend/templates/templates/index.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1,"class","ml10");
                      dom.setAttribute(el1,"src","assets/images/icons/external-link.svg");
                      dom.setAttribute(el1,"style","max-width: 20px;");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 526,
                        "column": 22
                      },
                      "end": {
                        "line": 528,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/templates/index.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","d-flex align-items-center");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element1,0,0);
                    morphs[1] = dom.createMorphAt(element1,2,2);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["compositeproducts.edit",["get","product.id",["loc",[null,[527,100],[527,110]]]],["subexpr","query-params",[],["selectTab","future"],["loc",[null,[527,111],[527,144]]]]],[],0,null,["loc",[null,[527,64],[527,174]]]],
                    ["block","link-to",["compositeproducts.edit",["get","product.id",["loc",[null,[527,211],[527,221]]]],["subexpr","query-params",[],["selectTab","future"],["loc",[null,[527,222],[527,255]]]]],["target","_blank"],1,null,["loc",[null,[527,175],[527,373]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 526,
                      "column": 22
                    },
                    "end": {
                      "line": 528,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/templates/index.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","product.type",["loc",[null,[526,36],[526,48]]]],"package"],[],["loc",[null,[526,32],[526,59]]]]],[],0,null,["loc",[null,[526,22],[528,22]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 524,
                    "column": 22
                  },
                  "end": {
                    "line": 528,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/templates/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","product.type",["loc",[null,[524,36],[524,48]]]],"service"],[],["loc",[null,[524,32],[524,59]]]]],[],0,1,["loc",[null,[524,22],[528,22]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 516,
                  "column": 16
                },
                "end": {
                  "line": 536,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/templates/index.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24@phone l-1/24");
              dom.setAttribute(el2,"data-label","");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("img");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-20/24@phone l-19/24 table__cell--name");
              dom.setAttribute(el2,"data-label","Name");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk buttons-1");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3,"class","exp icon-button icon-button--default list-action-square");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"src","assets/images/icons/rework/edit.svg");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                      ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(element4, [1, 1, 0]);
              var element6 = dom.childAt(element4, [5, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element5, 'src');
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
              morphs[2] = dom.createElementMorph(element6);
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",["assets/images/icons/",["get","product.type",["loc",[null,[519,60],[519,72]]]],"-dark.svg"]]],
              ["block","if",[["subexpr","eq",[["get","product.type",["loc",[null,[522,32],[522,44]]]],"product"],[],["loc",[null,[522,28],[522,55]]]]],[],0,1,["loc",[null,[522,22],[528,29]]]],
              ["element","action",["gotoProductFuture",["get","product.id",["loc",[null,[531,118],[531,128]]]],["get","product.type",["loc",[null,[531,129],[531,141]]]]],[],["loc",[null,[531,89],[531,143]]]]
            ],
            locals: ["product"],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 515,
                "column": 14
              },
              "end": {
                "line": 537,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/templates/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","products",["loc",[null,[516,24],[516,32]]]]],[],0,null,["loc",[null,[516,16],[536,25]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 505,
              "column": 8
            },
            "end": {
              "line": 544,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table-wrapper-rework");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table grid table--large");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__head");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-1/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-19/24");
          var el5 = dom.createElement("span");
          dom.setAttribute(el5,"class","table__title title");
          var el6 = dom.createTextNode("Tierärztliche Leistung mit Erinnerungen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-4/24");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","products",["loc",[null,[515,20],[515,28]]]]],[],0,null,["loc",[null,[515,14],[537,21]]]],
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","products",["loc",[null,[542,35],[542,43]]]]],[],[]],"numPagesToShow",7],["loc",[null,[542,12],[542,62]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 549,
              "column": 6
            },
            "end": {
              "line": 553,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/templates/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [
          ["element","action",["save"],[],["loc",[null,[550,9],[550,26]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 609,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/templates/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Meine Vorlagen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","tabs-rework-wrapper grid");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-templates");
        var el3 = dom.createTextNode("Textvorlagen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","js-tabs tabs");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2,"class","tabs__body");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","userEdit");
        dom.setAttribute(el3,"class","admin-form");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-reminder");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mt0 mb20");
        var el6 = dom.createTextNode("Erinnerungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","patient-category");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("Übermittlungseinstellung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","app-internal-person2");
        dom.setAttribute(el7,"class","field select");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("i");
        dom.setAttribute(el8,"class","arrow");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-emaildata-reminderSubject");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("Email-Betreff");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n                        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("Vorschau");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","full--height");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" Wauzi: ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n                        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-emaildata-reminderHeader");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("Überschrift");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n                        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24 gutter");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("Vorschau");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","full--height");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24 gutter");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-default-reminderText");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("Erinnerungstext");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-24/24 gutter");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-emaildata-reminderClosing");
        dom.setAttribute(el7,"class","field-label title required");
        var el8 = dom.createTextNode("Abschlusssatz");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-settings");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Vorlagen & Berichte");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid gutter-fix");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24@desk 24/24 gutter");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","select-placeholder");
        var el9 = dom.createTextNode("Vorausgewähltes Briefpapier");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-warning");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("1. Mahnung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid gutter-fix");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24@desk 24/24 gutter");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Email-Betreff");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24@desk 24/24 gutter");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Überschrift");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col 24/24 gutter");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","textarea-wrapper");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","placeholder");
        dom.setAttribute(el9,"for","mandant-emaildata-warning1text");
        var el10 = dom.createTextNode("Text");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col 24/24 gutter");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Abschlusssatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("2. Mahnung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid gutter-fix");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24@desk 24/24 gutter");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Email-Betreff");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24@desk 24/24 gutter");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Überschrift");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col 24/24 gutter");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","textarea-wrapper");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"class","placeholder");
        dom.setAttribute(el9,"for","mandant-emaildata-warning1text");
        var el10 = dom.createTextNode("Text");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col 24/24 gutter");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","placeholder");
        var el9 = dom.createTextNode("Abschlusssatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-app");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mt0 mb20");
        var el6 = dom.createTextNode("Standardtexte");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-18/24 gutter");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-emaildata-appReorderConfirm");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("Bestellung abholbereit");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"id","appReorderConfirm");
        dom.setAttribute(el7,"class","droppable editable-input");
        dom.setAttribute(el7,"contenteditable","true");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-6/24 gutter placeholders");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Textelemente");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"data-id","patient");
        dom.setAttribute(el6,"data-label","Tiername");
        dom.setAttribute(el6,"class","selectable-item dragable");
        var el7 = dom.createTextNode("Tiername ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","block small");
        var el8 = dom.createTextNode("Flocki");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"data-id","mandantname");
        dom.setAttribute(el6,"data-label","Praxisname");
        dom.setAttribute(el6,"class","selectable-item dragable");
        var el7 = dom.createTextNode("Praxisname ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","block small");
        var el8 = dom.createTextNode("Tierarztpraxis Mustermann");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"data-id","operators");
        dom.setAttribute(el6,"data-label","Betreibername");
        dom.setAttribute(el6,"class","selectable-item dragable");
        var el7 = dom.createTextNode("Betreibername ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","block small");
        var el8 = dom.createTextNode("Dr. Max Mustermann");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-18/24 gutter");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-emaildata-appDefaultAppTitle");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("Standardanmerkung für Termine");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"id","appDefaultAppTitle");
        dom.setAttribute(el7,"class","droppable editable-input");
        dom.setAttribute(el7,"contenteditable","true");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-6/24 gutter placeholders");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Textelemente");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"data-id","patient");
        dom.setAttribute(el6,"data-label","Tiername");
        dom.setAttribute(el6,"class","selectable-item dragable");
        var el7 = dom.createTextNode("Tiername ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","block small");
        var el8 = dom.createTextNode("Flocki");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"data-id","mandantname");
        dom.setAttribute(el6,"data-label","Praxisname");
        dom.setAttribute(el6,"class","selectable-item dragable");
        var el7 = dom.createTextNode("Praxisname ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","block small");
        var el8 = dom.createTextNode("Tierarztpraxis Mustermann");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"data-id","operators");
        dom.setAttribute(el6,"data-label","Betreibername");
        dom.setAttribute(el6,"class","selectable-item dragable");
        var el7 = dom.createTextNode("Betreibername ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","block small");
        var el8 = dom.createTextNode("Dr. Max Mustermann");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-18/24 gutter");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","field");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"for","mandant-emaildata-appDefaultAppDeclineTitle");
        dom.setAttribute(el7,"class","field-label title");
        var el8 = dom.createTextNode("Standardtext für Terminabsage");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"id","appDefaultAppDeclineTitle");
        dom.setAttribute(el7,"class","droppable editable-input");
        dom.setAttribute(el7,"contenteditable","true");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-6/24 gutter placeholders");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Textelemente");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"data-id","patient");
        dom.setAttribute(el6,"data-label","Tiername");
        dom.setAttribute(el6,"class","selectable-item dragable");
        var el7 = dom.createTextNode("Tiername ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","block small");
        var el8 = dom.createTextNode("Flocki");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"data-id","mandantname");
        dom.setAttribute(el6,"data-label","Praxisname");
        dom.setAttribute(el6,"class","selectable-item dragable");
        var el7 = dom.createTextNode("Praxisname ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","block small");
        var el8 = dom.createTextNode("Tierarztpraxis Mustermann");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"data-id","operators");
        dom.setAttribute(el6,"data-label","Betreibername");
        dom.setAttribute(el6,"class","selectable-item dragable");
        var el7 = dom.createTextNode("Betreibername ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","block small");
        var el8 = dom.createTextNode("Dr. Max Mustermann");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-textmodules");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table table--large  grid");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-19/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","pagination-wrapper is-fixed");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-reports");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table  table--large  grid");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-19/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","pagination-wrapper is-fixed");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-emails");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table  table--large grid");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-19/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","pagination-wrapper is-fixed");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-invoices");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table  table--large grid");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-1/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-19/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","pagination-wrapper is-fixed");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-templates");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-connectedArticles");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(\".dragable\").draggable({revert: true, helper: \"clone\"});\n\n    $(\".droppable\").droppable({\n      over: function(event, ui) {\n        $(event.target).focus();\n      },\n      out: function(event, ui) {\n      },\n      drop: function(event, ui) {\n        let html = \"<span data-id='\"+ui.draggable.attr('data-id')+\"' contenteditable='false' class='placeholder'>\"+ui.draggable.attr('data-label')+\"</span>\";\n        $(event.target).focus();\n        pasteHtmlAtCaret(html);\n      }\n    });\n\n    function pasteHtmlAtCaret(html) {\n      var sel, range;\n      if (window.getSelection) {\n        // IE9 and non-IE\n        sel = window.getSelection();\n        if (sel.getRangeAt && sel.rangeCount) {\n          range = sel.getRangeAt(0);\n          range.deleteContents();\n\n          // Range.createContextualFragment() would be useful here but is\n          // non-standard and not supported in all browsers (IE9, for one)\n          var el = document.createElement(\"div\");\n          el.innerHTML = html;\n          var frag = document.createDocumentFragment(), node, lastNode;\n          while ( (node = el.firstChild) ) {\n            lastNode = frag.appendChild(node);\n          }\n          range.insertNode(frag);\n\n          // Preserve the selection\n          if (lastNode) {\n            range = range.cloneRange();\n            range.setStartAfter(lastNode);\n            range.collapse(true);\n            sel.removeAllRanges();\n            sel.addRange(range);\n          }\n        }\n      }\n    }\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element45 = dom.childAt(fragment, [0]);
        var element46 = dom.childAt(element45, [1, 3]);
        var element47 = dom.childAt(fragment, [2]);
        var element48 = dom.childAt(element47, [1]);
        var element49 = dom.childAt(fragment, [4, 1]);
        var element50 = dom.childAt(element49, [1]);
        var element51 = dom.childAt(element50, [1]);
        var element52 = dom.childAt(element51, [7, 1, 3]);
        var element53 = dom.childAt(element50, [5]);
        var element54 = dom.childAt(element50, [7]);
        var element55 = dom.childAt(element54, [3]);
        var element56 = dom.childAt(element54, [7]);
        var element57 = dom.childAt(element50, [9]);
        var element58 = dom.childAt(element49, [3]);
        var element59 = dom.childAt(element49, [5]);
        var element60 = dom.childAt(element49, [7]);
        var element61 = dom.childAt(element49, [9]);
        var element62 = dom.childAt(element49, [11]);
        var element63 = dom.childAt(element49, [13]);
        var morphs = new Array(59);
        morphs[0] = dom.createAttrMorph(element45, 'class');
        morphs[1] = dom.createMorphAt(element46,1,1);
        morphs[2] = dom.createMorphAt(element46,2,2);
        morphs[3] = dom.createMorphAt(element46,3,3);
        morphs[4] = dom.createMorphAt(element46,4,4);
        morphs[5] = dom.createMorphAt(element46,5,5);
        morphs[6] = dom.createMorphAt(element46,6,6);
        morphs[7] = dom.createMorphAt(element46,7,7);
        morphs[8] = dom.createMorphAt(dom.childAt(element45, [3, 1]),1,1);
        morphs[9] = dom.createAttrMorph(element48, 'class');
        morphs[10] = dom.createElementMorph(element48);
        morphs[11] = dom.createMorphAt(element47,3,3);
        morphs[12] = dom.createMorphAt(element47,4,4);
        morphs[13] = dom.createMorphAt(element47,5,5);
        morphs[14] = dom.createElementMorph(element50);
        morphs[15] = dom.createAttrMorph(element51, 'class');
        morphs[16] = dom.createMorphAt(dom.childAt(element51, [3, 1, 3]),1,1);
        morphs[17] = dom.createMorphAt(dom.childAt(element51, [5, 1]),3,3);
        morphs[18] = dom.createMorphAt(element52,0,0);
        morphs[19] = dom.createMorphAt(element52,2,2);
        morphs[20] = dom.createMorphAt(dom.childAt(element51, [10, 1]),3,3);
        morphs[21] = dom.createMorphAt(dom.childAt(element51, [12, 1, 3]),0,0);
        morphs[22] = dom.createMorphAt(dom.childAt(element51, [14, 1]),3,3);
        morphs[23] = dom.createMorphAt(dom.childAt(element51, [16, 1]),3,3);
        morphs[24] = dom.createMorphAt(element50,3,3);
        morphs[25] = dom.createAttrMorph(element53, 'class');
        morphs[26] = dom.createMorphAt(dom.childAt(element53, [3, 1, 1]),3,3);
        morphs[27] = dom.createAttrMorph(element54, 'class');
        morphs[28] = dom.createMorphAt(dom.childAt(element55, [1, 1]),1,1);
        morphs[29] = dom.createMorphAt(dom.childAt(element55, [3, 1]),1,1);
        morphs[30] = dom.createMorphAt(dom.childAt(element55, [5, 1, 1]),1,1);
        morphs[31] = dom.createMorphAt(dom.childAt(element55, [7, 1]),1,1);
        morphs[32] = dom.createMorphAt(dom.childAt(element56, [1, 1]),1,1);
        morphs[33] = dom.createMorphAt(dom.childAt(element56, [3, 1]),1,1);
        morphs[34] = dom.createMorphAt(dom.childAt(element56, [5, 1, 1]),1,1);
        morphs[35] = dom.createMorphAt(dom.childAt(element56, [7, 1]),1,1);
        morphs[36] = dom.createAttrMorph(element57, 'class');
        morphs[37] = dom.createUnsafeMorphAt(dom.childAt(element57, [3, 1, 3]),0,0);
        morphs[38] = dom.createUnsafeMorphAt(dom.childAt(element57, [7, 1, 3]),0,0);
        morphs[39] = dom.createUnsafeMorphAt(dom.childAt(element57, [11, 1, 3]),0,0);
        morphs[40] = dom.createAttrMorph(element58, 'class');
        morphs[41] = dom.createMorphAt(dom.childAt(element58, [1, 1, 3]),1,1);
        morphs[42] = dom.createMorphAt(dom.childAt(element58, [3]),1,1);
        morphs[43] = dom.createAttrMorph(element59, 'class');
        morphs[44] = dom.createMorphAt(dom.childAt(element59, [1, 1, 3]),1,1);
        morphs[45] = dom.createMorphAt(dom.childAt(element59, [3]),1,1);
        morphs[46] = dom.createAttrMorph(element60, 'class');
        morphs[47] = dom.createMorphAt(dom.childAt(element60, [1, 1, 3]),1,1);
        morphs[48] = dom.createMorphAt(dom.childAt(element60, [3]),1,1);
        morphs[49] = dom.createAttrMorph(element61, 'class');
        morphs[50] = dom.createMorphAt(dom.childAt(element61, [1, 1, 3]),1,1);
        morphs[51] = dom.createMorphAt(dom.childAt(element61, [3]),1,1);
        morphs[52] = dom.createAttrMorph(element62, 'class');
        morphs[53] = dom.createMorphAt(element62,1,1);
        morphs[54] = dom.createAttrMorph(element63, 'class');
        morphs[55] = dom.createMorphAt(element63,1,1);
        morphs[56] = dom.createMorphAt(dom.childAt(fragment, [6]),1,1);
        morphs[57] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[58] = dom.createMorphAt(fragment,12,12,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,47],[1,58]]]],"with-filters",""],[],["loc",[null,[1,26],[1,78]]]]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[5,16],[5,25]]]],"warning"],[],["loc",[null,[5,12],[5,36]]]]],[],0,null,["loc",[null,[5,6],[7,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[8,16],[8,25]]]],"reminder"],[],["loc",[null,[8,12],[8,37]]]]],[],1,null,["loc",[null,[8,6],[10,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[11,16],[11,25]]]],"templates"],[],["loc",[null,[11,12],[11,38]]]]],[],2,null,["loc",[null,[11,6],[13,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[14,16],[14,25]]]],"textmodules"],[],["loc",[null,[14,12],[14,40]]]]],[],3,null,["loc",[null,[14,6],[16,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[17,16],[17,25]]]],"emails"],[],["loc",[null,[17,12],[17,35]]]]],[],4,null,["loc",[null,[17,6],[19,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[20,16],[20,25]]]],"reports"],[],["loc",[null,[20,12],[20,36]]]]],[],5,null,["loc",[null,[20,6],[22,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[23,16],[23,25]]]],"invoices"],[],["loc",[null,[23,12],[23,37]]]]],[],6,null,["loc",[null,[23,6],[25,13]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[31,20],[31,29]]]],"templates"],[],["loc",[null,[31,16],[31,42]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[31,47],[31,56]]]],"connectedArticles"],[],["loc",[null,[31,43],[31,77]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[31,82],[31,91]]]],"textmodules"],[],["loc",[null,[31,78],[31,106]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[31,111],[31,120]]]],"invoices"],[],["loc",[null,[31,107],[31,132]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[31,137],[31,146]]]],"emails"],[],["loc",[null,[31,133],[31,156]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[31,161],[31,170]]]],"reports"],[],["loc",[null,[31,157],[31,181]]]]],[],["loc",[null,[31,12],[31,182]]]]],[],7,null,["loc",[null,[31,6],[33,13]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[39,51],[39,60]]]],"templates"],[],["loc",[null,[39,47],[39,73]]]],"is-active",""],[],["loc",[null,[39,26],[39,90]]]]]]],
        ["element","action",["selectTab","templates"],[],["loc",[null,[39,114],[39,148]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[40,8],[40,36]]]]],[],8,null,["loc",[null,[40,2],[45,9]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[46,8],[46,33]]]]],[],9,null,["loc",[null,[46,2],[52,9]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[53,8],[53,36]]]]],[],10,null,["loc",[null,[53,2],[56,9]]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[61,45],[61,74]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[62,71],[62,80]]]],"reminder"],[],["loc",[null,[62,67],[62,92]]]],"is-active",""],[],["loc",[null,[62,46],[62,109]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","deliveryOptions",["loc",[null,[70,26],[70,41]]]]],[],[]],"value",["subexpr","@mut",[["get","emailData.defaultReminderSetting",["loc",[null,[71,24],[71,56]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","value","optionValuePath","id","name","mandant.defaultReminderSetting"],["loc",[null,[69,16],[76,18]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.defaultReminderSubject",["loc",[null,[85,28],[85,60]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-reminderSubject"],["loc",[null,[85,14],[85,154]]]],
        ["content","emailData.defaultReminderSubject",["loc",[null,[91,39],[91,75]]]],
        ["content","emailData.mandantName",["loc",[null,[91,83],[91,108]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.defaultReminderHeader",["loc",[null,[98,28],[98,59]]]]],[],[]],"autofocus",true,"class","gui-input newStyle","title","","name","mandant-emaildata-reminderHeader"],["loc",[null,[98,14],[98,152]]]],
        ["content","emailData.defaultReminderHeader",["loc",[null,[104,39],[104,74]]]],
        ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","emailData.defaultReminderText",["loc",[null,[110,40],[110,69]]]]],[],[]],"class","input textarea__input full","name","mandant-default-reminderText"],["loc",[null,[110,14],[110,142]]]],
        ["inline","textarea",[],["rows","5","value",["subexpr","@mut",[["get","emailData.defaultReminderClosing",["loc",[null,[116,40],[116,72]]]]],[],[]],"class","input textarea__input full","name","mandant-default-reminderClosing"],["loc",[null,[116,14],[116,148]]]],
        ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[120,6],[120,14]]]]],[],11,null,["loc",[null,[120,0],[144,7]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[145,71],[145,80]]]],"settings"],[],["loc",[null,[145,67],[145,92]]]],"is-active",""],[],["loc",[null,[145,46],[145,109]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","styles",["loc",[null,[153,26],[153,32]]]]],[],[]],"value",["subexpr","@mut",[["get","defaultTemplateStyle",["loc",[null,[154,24],[154,44]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","value","optionValuePath","id","allowClear",false],["loc",[null,[152,16],[159,18]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[164,71],[164,80]]]],"warning"],[],["loc",[null,[164,67],[164,91]]]],"is-active",""],[],["loc",[null,[164,46],[164,108]]]]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning1subject",["loc",[null,[170,30],[170,55]]]]],[],[]],"class","input input--editable","name","mandant-emaildata-warning1subject","placeholder",""],["loc",[null,[170,16],[170,143]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning1headline",["loc",[null,[176,30],[176,56]]]]],[],[]],"class","input input--editable","name","mandant-emaildata-warning1headline","placeholder",""],["loc",[null,[176,16],[176,145]]]],
        ["inline","textarea",[],["rows","4","value",["subexpr","@mut",[["get","emailData.warning1text",["loc",[null,[183,44],[183,66]]]]],[],[]],"class","","name","mandant-emaildata-warning1text","placeholder",""],["loc",[null,[183,18],[184,45]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning1closing",["loc",[null,[191,30],[191,55]]]]],[],[]],"class","input input--editable","name","mandant-emaildata-warning1closure","placeholder",""],["loc",[null,[191,16],[191,143]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning2subject",["loc",[null,[202,30],[202,55]]]]],[],[]],"class","input input--editable","name","mandant-emaildata-warning2subject","placeholder",""],["loc",[null,[202,16],[202,143]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning2headline",["loc",[null,[208,30],[208,56]]]]],[],[]],"class","input input--editable","name","mandant-emaildata-warning2headline","placeholder",""],["loc",[null,[208,16],[208,145]]]],
        ["inline","textarea",[],["rows","4","value",["subexpr","@mut",[["get","emailData.warning2text",["loc",[null,[215,44],[215,66]]]]],[],[]],"class","","name","mandant-emaildata-warning2text","placeholder",""],["loc",[null,[215,18],[216,45]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","emailData.warning2closing",["loc",[null,[223,30],[223,55]]]]],[],[]],"class","input input--editable","name","mandant-emaildata-warning2closure","placeholder",""],["loc",[null,[223,16],[223,143]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[229,71],[229,80]]]],"app"],[],["loc",[null,[229,67],[229,87]]]],"is-active",""],[],["loc",[null,[229,46],[229,104]]]]]]],
        ["content","emailData.appReorderConfirm",["loc",[null,[234,98],[234,131]]]],
        ["content","emailData.appDefaultAppTitle",["loc",[null,[247,99],[247,133]]]],
        ["content","emailData.appDefaultAppDeclineTitle",["loc",[null,[260,106],[260,147]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[271,56],[271,65]]]],"textmodules"],[],["loc",[null,[271,52],[271,80]]]],"is-active",""],[],["loc",[null,[271,31],[271,97]]]]]]],
        ["block","if",[["get","textModules",["loc",[null,[280,20],[280,31]]]]],[],12,null,["loc",[null,[280,14],[311,21]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","textModules",["loc",[null,[316,33],[316,44]]]]],[],[]],"numPagesToShow",7],["loc",[null,[316,10],[316,63]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[319,56],[319,65]]]],"reports"],[],["loc",[null,[319,52],[319,76]]]],"is-active",""],[],["loc",[null,[319,31],[319,93]]]]]]],
        ["block","if",[["get","textModules",["loc",[null,[328,20],[328,31]]]]],[],13,null,["loc",[null,[328,14],[356,21]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","textModules",["loc",[null,[361,33],[361,44]]]]],[],[]],"numPagesToShow",7],["loc",[null,[361,10],[361,63]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[364,56],[364,65]]]],"emails"],[],["loc",[null,[364,52],[364,75]]]],"is-active",""],[],["loc",[null,[364,31],[364,92]]]]]]],
        ["block","if",[["get","textModules",["loc",[null,[374,20],[374,31]]]]],[],14,null,["loc",[null,[374,14],[406,21]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","textModules",["loc",[null,[411,33],[411,44]]]]],[],[]],"numPagesToShow",7],["loc",[null,[411,10],[411,63]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[414,56],[414,65]]]],"invoices"],[],["loc",[null,[414,52],[414,77]]]],"is-active",""],[],["loc",[null,[414,31],[414,94]]]]]]],
        ["block","if",[["get","textModules",["loc",[null,[423,20],[423,31]]]]],[],15,null,["loc",[null,[423,14],[451,21]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","textModules",["loc",[null,[456,33],[456,44]]]]],[],[]],"numPagesToShow",7],["loc",[null,[456,10],[456,63]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[459,56],[459,65]]]],"templates"],[],["loc",[null,[459,52],[459,78]]]],"is-active",""],[],["loc",[null,[459,31],[459,95]]]]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[460,14],[460,42]]]]],[],16,null,["loc",[null,[460,8],[502,15]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[504,56],[504,65]]]],"connectedArticles"],[],["loc",[null,[504,52],[504,86]]]],"is-active",""],[],["loc",[null,[504,31],[504,103]]]]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[505,14],[505,42]]]]],[],17,null,["loc",[null,[505,8],[544,15]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[549,20],[549,29]]]],"invoice"],[],["loc",[null,[549,16],[549,40]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[549,45],[549,54]]]],"settings"],[],["loc",[null,[549,41],[549,66]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[549,71],[549,80]]]],"reminder"],[],["loc",[null,[549,67],[549,92]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[549,97],[549,106]]]],"app"],[],["loc",[null,[549,93],[549,113]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[549,118],[549,127]]]],"warning"],[],["loc",[null,[549,114],[549,138]]]]],[],["loc",[null,[549,12],[549,139]]]]],[],18,null,["loc",[null,[549,6],[553,13]]]],
        ["inline","template-edit-panel",[],["save","saveTemplate","actionReceiver",["subexpr","@mut",[["get","templateEditPanel",["loc",[null,[606,57],[606,74]]]]],[],[]]],["loc",[null,[606,0],[606,76]]]],
        ["inline","send-testmail-panel",[],["forwarded","sendTestmail","actionReceiver",["subexpr","@mut",[["get","sendTestmailPanel",["loc",[null,[607,63],[607,80]]]]],[],[]]],["loc",[null,[607,0],[607,82]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18]
    };
  }()));

});