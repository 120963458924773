define('jason-frontend/templates/cash/add-article', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("style");
          var el2 = dom.createTextNode("\n    #breadcrumbs-bar {\n      visibility: hidden;\n      display: none !important;\n    }\n    #breadcrumbs-bar-inventory {\n      visibility: visible !important;\n      display: block !important;\n    }\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 10
              },
              "end": {
                "line": 30,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 10
              },
              "end": {
                "line": 32,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1,"type","button");
            var el2 = dom.createTextNode("Paket");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element16, 'class');
            morphs[1] = dom.createElementMorph(element16);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[31,110],[31,122]]]],"composite"],[],["loc",[null,[31,106],[31,135]]]],"selected",""],[],["loc",[null,[31,85],[31,151]]]]]]],
            ["element","action",["setType","composite"],[],["loc",[null,[31,20],[31,52]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 10
              },
              "end": {
                "line": 33,
                "column": 82
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 12
                },
                "end": {
                  "line": 35,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 12
                },
                "end": {
                  "line": 37,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1,"type","button");
              var el2 = dom.createTextNode("Vorlage");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element15, 'class');
              morphs[1] = dom.createElementMorph(element15);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[36,107],[36,119]]]],"text"],[],["loc",[null,[36,103],[36,127]]]],"selected",""],[],["loc",[null,[36,82],[36,143]]]]]]],
              ["element","action",["setType","text"],[],["loc",[null,[36,22],[36,49]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 82
              },
              "end": {
                "line": 38,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","and",[["subexpr","eq",[["get","referer",["loc",[null,[34,27],[34,34]]]],"invoices"],[],["loc",[null,[34,23],[34,46]]]],["subexpr","eq",[["get","selectedPatient",["loc",[null,[34,51],[34,66]]]],"0"],[],["loc",[null,[34,47],[34,71]]]]],[],["loc",[null,[34,18],[34,72]]]]],[],0,1,["loc",[null,[34,12],[37,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 8
            },
            "end": {
              "line": 39,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","button");
          var el2 = dom.createTextNode("Produkt");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1,"type","button");
          var el2 = dom.createTextNode("Leistung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var element18 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element17, 'class');
          morphs[1] = dom.createElementMorph(element17);
          morphs[2] = dom.createAttrMorph(element18, 'class');
          morphs[3] = dom.createElementMorph(element18);
          morphs[4] = dom.createMorphAt(fragment,5,5,contextualElement);
          morphs[5] = dom.createMorphAt(fragment,7,7,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["btn button  ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[27,107],[27,119]]]],"product"],[],["loc",[null,[27,103],[27,130]]]],"selected",""],[],["loc",[null,[27,82],[27,146]]]]]]],
          ["element","action",["setType","product"],[],["loc",[null,[27,18],[27,48]]]],
          ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectedType",["loc",[null,[28,106],[28,118]]]],"service"],[],["loc",[null,[28,102],[28,129]]]],"selected",""],[],["loc",[null,[28,81],[28,145]]]]]]],
          ["element","action",["setType","service"],[],["loc",[null,[28,18],[28,48]]]],
          ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[29,24],[29,28]]]],"compositeProduct"],[],["loc",[null,[29,20],[29,48]]]],["subexpr","eq",[["get","type",["loc",[null,[29,53],[29,57]]]],"compositeService"],[],["loc",[null,[29,49],[29,77]]]],["subexpr","eq",[["get","type",["loc",[null,[29,82],[29,86]]]],"compositeTemplate"],[],["loc",[null,[29,78],[29,107]]]]],[],["loc",[null,[29,16],[29,108]]]]],[],0,1,["loc",[null,[29,10],[32,17]]]],
          ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[33,24],[33,28]]]],"futureAction"],[],["loc",[null,[33,20],[33,44]]]],["subexpr","eq",[["get","type",["loc",[null,[33,49],[33,53]]]],"futureActionBlueprints"],[],["loc",[null,[33,45],[33,79]]]]],[],["loc",[null,[33,16],[33,80]]]]],[],2,3,["loc",[null,[33,10],[38,17]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 12
                },
                "end": {
                  "line": 60,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","form-field-rework");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","select-placeholder");
              var el3 = dom.createTextNode("Firma");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","suppliers",["loc",[null,[51,26],[51,35]]]]],[],[]],"value",["subexpr","@mut",[["get","filterSupplier",["loc",[null,[52,24],[52,38]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",false,"searchEnabled",true],["loc",[null,[50,16],[58,18]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 12
                },
                "end": {
                  "line": 93,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","form-field-rework");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2,"class","select-placeholder");
              var el3 = dom.createTextNode("Kategorie");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
              return morphs;
            },
            statements: [
              ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[83,28],[83,38]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilters",["loc",[null,[84,26],[84,49]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",false,"searchEnabled",false,"placeholder","Alle Kategorien"],["loc",[null,[82,18],[91,20]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 6
              },
              "end": {
                "line": 95,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","div-horizontal");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","filter-lane grid");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Hersteller");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element14,1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element14, [3]),3,3);
            morphs[2] = dom.createMorphAt(element14,5,5);
            return morphs;
          },
          statements: [
            ["block","if",[["get","suppliers",["loc",[null,[47,18],[47,27]]]]],[],0,null,["loc",[null,[47,12],[60,19]]]],
            ["inline","select-2",[],["value",["subexpr","@mut",[["get","filterManufacturer",["loc",[null,[65,22],[65,40]]]]],[],[]],"optionLabelPath","name","placeholder","Alle","allowClear",true,"typeaheadSearchingText","Suche Hersteller","typeaheadNoMatchesText","Keine Hersteller gefunden für '%@'","typeaheadErrorText","Ladevorgang fehlerhaft: %@","minimumInputLength",3,"maximumInputLength",15,"query","queryManufacturer","cssClass","custom-select2 newStyle tuned"],["loc",[null,[64,14],[76,16]]]],
            ["block","if",[["get","partnerCategories",["loc",[null,[79,18],[79,35]]]]],[],1,null,["loc",[null,[79,12],[93,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 4
            },
            "end": {
              "line": 96,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showFilters",["loc",[null,[43,12],[43,23]]]]],[],0,null,["loc",[null,[43,6],[95,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 98,
                "column": 6
              },
              "end": {
                "line": 128,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","div-horizontal");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","filter-lane grid");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Kategorie");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","form-field-rework");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3,"class","select-placeholder");
            var el4 = dom.createTextNode("Quelle");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element13, [1]),3,3);
            morphs[1] = dom.createMorphAt(dom.childAt(element13, [3]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","serviceCategories",["loc",[null,[105,24],[105,41]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilters",["loc",[null,[106,22],[106,45]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","optionValuePath","id","allowClear",false,"searchEnabled",false,"placeholder","Alle Kategorien"],["loc",[null,[104,14],[113,16]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","distributors",["loc",[null,[118,24],[118,36]]]]],[],[]],"value",["subexpr","@mut",[["get","filterDistributor",["loc",[null,[119,22],[119,39]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",false,"searchEnabled",false,"placeholder","Alle"],["loc",[null,[117,14],[125,16]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 97,
              "column": 4
            },
            "end": {
              "line": 129,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","showFilters",["loc",[null,[98,12],[98,23]]]]],[],0,null,["loc",[null,[98,6],[128,13]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 152,
                  "column": 10
                },
                "end": {
                  "line": 156,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","selectable-item has-icon");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createAttrMorph(element12, 'for');
              morphs[2] = dom.createElementMorph(element12);
              morphs[3] = dom.createMorphAt(element12,0,0);
              return morphs;
            },
            statements: [
              ["inline","input",[],["type","checkbox","id",["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[153,54],[153,68]]]]],[],["loc",[null,[153,39],[153,69]]]],"checked",["subexpr","@mut",[["get","tag.selected",["loc",[null,[153,78],[153,90]]]]],[],[]]],["loc",[null,[153,12],[153,92]]]],
              ["attribute","for",["concat",[["subexpr","concat",["tag_",["get","tag.internalId",["loc",[null,[154,73],[154,87]]]]],[],["loc",[null,[154,57],[154,89]]]]]]],
              ["element","action",["selectTag",["get","tag",["loc",[null,[155,100],[155,103]]]]],[],["loc",[null,[154,91],[155,105]]]],
              ["content","tag.name",["loc",[null,[155,106],[155,118]]]]
            ],
            locals: ["tag"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 151,
                "column": 8
              },
              "end": {
                "line": 157,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","each",[["get","articleTags",["loc",[null,[152,18],[152,29]]]]],[],0,null,["loc",[null,[152,10],[156,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 149,
              "column": 6
            },
            "end": {
              "line": 159,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("Tags");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["block","if",[["get","articleTags",["loc",[null,[151,14],[151,25]]]]],[],0,null,["loc",[null,[151,8],[157,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 172,
                "column": 8
              },
              "end": {
                "line": 180,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-4/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Bezeichnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-6/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Tags");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-4/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Lager");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 181,
                "column": 8
              },
              "end": {
                "line": 183,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[182,10],[182,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 185,
                  "column": 10
                },
                "end": {
                  "line": 187,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-product",[],["newStyle",true,"inclusiveVpes",true,"linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[186,85],[186,102]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[186,113],[186,128]]]]],[],[]],"filterFavorites",["subexpr","@mut",[["get","filterFavs",["loc",[null,[186,145],[186,155]]]]],[],[]],"filterFavs",["subexpr","@mut",[["get","filterFavs",["loc",[null,[186,167],[186,177]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[186,186],[186,193]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[186,201],[186,207]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[186,214],[186,218]]]]],[],[]],"addProduct","addProduct","typeaddProduct","addProduct","toggleProductFavorite","toggleProductFavorite","togglePriceFavorite","togglePriceFavorite"],["loc",[null,[186,12],[186,361]]]]
            ],
            locals: ["product"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 183,
                "column": 8
              },
              "end": {
                "line": 189,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            dom.setAttribute(el1,"id","product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[185,18],[185,23]]]]],[],0,null,["loc",[null,[185,10],[187,19]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 170,
              "column": 2
            },
            "end": {
              "line": 191,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large table--with-sub");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element11,1,1);
          morphs[1] = dom.createMorphAt(element11,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[172,36],[172,60]]]]],[],[]]],0,null,["loc",[null,[172,8],[180,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[181,14],[181,25]]]]],[],1,2,["loc",[null,[181,8],[189,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 200,
                  "column": 70
                },
                "end": {
                  "line": 201,
                  "column": 63
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","table__title title");
              var el2 = dom.createTextNode("VK brutto");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 194,
                "column": 8
              },
              "end": {
                "line": 205,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-7/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Bezeichnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Tags");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Einheit");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--right@desk l-3/24");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Menge");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 9]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[200,76],[200,89]]]]],[],0,null,["loc",[null,[200,70],[201,70]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 206,
                "column": 8
              },
              "end": {
                "line": 208,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[207,10],[207,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 210,
                  "column": 12
                },
                "end": {
                  "line": 212,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-service",[],["classNames",["subexpr","concat",["table__row"," ",["get","service.type",["loc",[null,[211,72],[211,84]]]]],[],["loc",[null,[211,47],[211,85]]]],"linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[211,104],[211,121]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[211,132],[211,147]]]]],[],[]],"service",["subexpr","@mut",[["get","service",["loc",[null,[211,156],[211,163]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[211,171],[211,177]]]]],[],[]],"addService","addService","toggleServiceFavorite","toggleServiceFavorite"],["loc",[null,[211,14],[211,249]]]]
            ],
            locals: ["service"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 208,
                "column": 8
              },
              "end": {
                "line": 214,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[210,20],[210,25]]]]],[],0,null,["loc",[null,[210,12],[212,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 192,
              "column": 2
            },
            "end": {
              "line": 216,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element10,1,1);
          morphs[1] = dom.createMorphAt(element10,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[194,36],[194,60]]]]],[],[]]],0,null,["loc",[null,[194,8],[205,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[206,14],[206,25]]]]],[],1,2,["loc",[null,[206,8],[214,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 223,
                  "column": 63
                },
                "end": {
                  "line": 223,
                  "column": 138
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","table__title title");
              var el2 = dom.createTextNode("VK brutto");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 219,
                "column": 6
              },
              "end": {
                "line": 227,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-11/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Bezeichnung");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--right l-5/24");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-3/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Menge");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 5]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[223,69],[223,82]]]]],[],0,null,["loc",[null,[223,63],[223,145]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 228,
                "column": 8
              },
              "end": {
                "line": 230,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[229,10],[229,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 232,
                  "column": 12
                },
                "end": {
                  "line": 234,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-composite",[],["linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[233,56],[233,73]]]]],[],[]],"editable",["subexpr","@mut",[["get","editable",["loc",[null,[233,83],[233,91]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[233,102],[233,117]]]]],[],[]],"filterFavorites",["subexpr","@mut",[["get","filterFavorites",["loc",[null,[233,134],[233,149]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[233,158],[233,165]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[233,173],[233,179]]]]],[],[]],"addProduct","addComposite","toggleCompositeFavorite","toggleCompositeFavorite"],["loc",[null,[233,14],[233,257]]]]
            ],
            locals: ["product"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 230,
                "column": 8
              },
              "end": {
                "line": 236,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[232,20],[232,25]]]]],[],0,null,["loc",[null,[232,12],[234,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 217,
              "column": 2
            },
            "end": {
              "line": 238,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large table--with-sub");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element9,1,1);
          morphs[1] = dom.createMorphAt(element9,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[219,34],[219,58]]]]],[],[]]],0,null,["loc",[null,[219,6],[227,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[228,14],[228,25]]]]],[],1,2,["loc",[null,[228,8],[236,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 242,
                "column": 8
              },
              "end": {
                "line": 249,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head sticker");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-10/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Name");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--center l-9/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Unterschrift");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 250,
                "column": 8
              },
              "end": {
                "line": 252,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","loading-animation",["loc",[null,[251,10],[251,31]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 254,
                  "column": 12
                },
                "end": {
                  "line": 256,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","invoice-add-text",[],["classNames",["subexpr","concat",["table__row"],[],["loc",[null,[255,44],[255,65]]]],"linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[255,84],[255,101]]]]],[],[]],"editable",["subexpr","@mut",[["get","editable",["loc",[null,[255,111],[255,119]]]]],[],[]],"patientId",["subexpr","@mut",[["get","selectedPatient",["loc",[null,[255,130],[255,145]]]]],[],[]],"text",["subexpr","@mut",[["get","text",["loc",[null,[255,151],[255,155]]]]],[],[]],"typeId",["subexpr","@mut",[["get","typeId",["loc",[null,[255,163],[255,169]]]]],[],[]],"addText","addText"],["loc",[null,[255,14],[255,189]]]]
            ],
            locals: ["text"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 252,
                "column": 8
              },
              "end": {
                "line": 258,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__body product-list");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model",["loc",[null,[254,20],[254,25]]]]],[],0,null,["loc",[null,[254,12],[256,21]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 239,
              "column": 2
            },
            "end": {
              "line": 260,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element8,1,1);
          morphs[1] = dom.createMorphAt(element8,2,2);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[242,36],[242,60]]]]],[],[]]],0,null,["loc",[null,[242,8],[249,29]]]],
          ["block","if",[["get","showLoading",["loc",[null,[250,14],[250,25]]]]],[],1,2,["loc",[null,[250,8],[258,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 268,
                "column": 6
              },
              "end": {
                "line": 270,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zum Paket"],["loc",[null,[269,8],[269,50]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 267,
              "column": 4
            },
            "end": {
              "line": 271,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["compositeproducts.edit",["get","typeId",["loc",[null,[268,42],[268,48]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[268,6],[270,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 273,
                "column": 6
              },
              "end": {
                "line": 275,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zum Lieferschein"],["loc",[null,[274,8],[274,57]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 272,
              "column": 4
            },
            "end": {
              "line": 276,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["inventory.delivery-note-details",["get","typeId",["loc",[null,[273,51],[273,57]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[273,6],[275,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 278,
                "column": 6
              },
              "end": {
                "line": 280,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zur Ausgangsrechnung"],["loc",[null,[279,8],[279,61]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 277,
              "column": 4
            },
            "end": {
              "line": 281,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["invoices.create",["get","typeId",["loc",[null,[278,35],[278,41]]]],["subexpr","query-params",[],["patientId",["get","selectedPatient",["loc",[null,[278,66],[278,81]]]]],["loc",[null,[278,42],[278,82]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[278,6],[280,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 283,
                "column": 6
              },
              "end": {
                "line": 285,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-prev",[],["content","Zurück zur Behandlung"],["loc",[null,[284,8],[284,55]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 282,
              "column": 4
            },
            "end": {
              "line": 286,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["practicemgmt.treatment-details",["get","typeId",["loc",[null,[283,50],[283,56]]]],["subexpr","query-params",[],["scrollTo","positions"],["loc",[null,[283,57],[283,92]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[283,6],[285,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 289,
                  "column": 8
                },
                "end": {
                  "line": 291,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-prev",[],["content","Zurück"],["loc",[null,[290,10],[290,42]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 292,
                  "column": 8
                },
                "end": {
                  "line": 294,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-inventory2",[],["size",40,"color","#fff","content","Lagerbestand buchen"],["loc",[null,[293,10],[293,82]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 288,
                "column": 6
              },
              "end": {
                "line": 295,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["inventory.stocktaking-detail",["get","stocktakingId",["loc",[null,[289,50],[289,63]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[289,8],[291,20]]]],
            ["block","link-to",["inventory.bookin-request",["get","typeId",["loc",[null,[292,46],[292,52]]]],["subexpr","query-params",[],["stocktakingId",["get","stocktakingId",["loc",[null,[292,81],[292,94]]]]],["loc",[null,[292,53],[292,95]]]]],["class","icon-button icon-button--success"],1,null,["loc",[null,[292,8],[294,20]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 297,
                    "column": 10
                  },
                  "end": {
                    "line": 299,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-prev",[],["content","Zurück"],["loc",[null,[298,12],[298,44]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 296,
                  "column": 8
                },
                "end": {
                  "line": 300,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["inventory.corrections",["get","typeId",["loc",[null,[297,45],[297,51]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[297,10],[299,22]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 301,
                    "column": 10
                  },
                  "end": {
                    "line": 303,
                    "column": 10
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","button-prev",["loc",[null,[302,12],[302,27]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 300,
                  "column": 8
                },
                "end": {
                  "line": 304,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["inventory.select-order"],["class","icon-button icon-button--small"],0,null,["loc",[null,[301,10],[303,22]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 295,
                "column": 6
              },
              "end": {
                "line": 305,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","subType",["loc",[null,[296,18],[296,25]]]],"inventoryCorrections"],[],["loc",[null,[296,14],[296,49]]]]],[],0,1,["loc",[null,[296,8],[304,15]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 287,
              "column": 4
            },
            "end": {
              "line": 306,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","stocktakingId",["loc",[null,[288,12],[288,25]]]]],[],0,1,["loc",[null,[288,6],[305,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 307,
              "column": 4
            },
            "end": {
              "line": 311,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[309,8],[309,53]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 312,
                  "column": 6
                },
                "end": {
                  "line": 314,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[313,8],[313,53]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 311,
                "column": 4
              },
              "end": {
                "line": 315,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["practicemgmt.treatment-future-action","treatment",["get","selectedPatient",["loc",[null,[312,68],[312,83]]]],["get","typeId",["loc",[null,[312,84],[312,90]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[312,6],[314,18]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 316,
                    "column": 6
                  },
                  "end": {
                    "line": 318,
                    "column": 6
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","button-prev",[],["content","Zurück"],["loc",[null,[317,8],[317,40]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 315,
                  "column": 4
                },
                "end": {
                  "line": 319,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","link-to",["practicemgmt.treatment-future-action","compositeproducts",["get","selectedPatient",["loc",[null,[316,76],[316,91]]]],["get","typeId",["loc",[null,[316,92],[316,98]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[316,6],[318,18]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 320,
                      "column": 6
                    },
                    "end": {
                      "line": 322,
                      "column": 6
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","button-prev",[],["content","Zurück"],["loc",[null,[321,8],[321,40]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 319,
                    "column": 4
                  },
                  "end": {
                    "line": 323,
                    "column": 4
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","link-to",["practicemgmt.treatment-future-action","services",["get","selectedPatient",["loc",[null,[320,67],[320,82]]]],["get","typeId",["loc",[null,[320,83],[320,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[320,6],[322,18]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 324,
                        "column": 6
                      },
                      "end": {
                        "line": 326,
                        "column": 6
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-prev",[],["content","Zurück"],["loc",[null,[325,8],[325,40]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 323,
                      "column": 4
                    },
                    "end": {
                      "line": 327,
                      "column": 4
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["practicemgmt.treatment-future-action","products",["get","selectedPatient",["loc",[null,[324,67],[324,82]]]],["get","typeId",["loc",[null,[324,83],[324,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[324,6],[326,18]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 328,
                          "column": 6
                        },
                        "end": {
                          "line": 330,
                          "column": 6
                        }
                      },
                      "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[329,8],[329,53]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 327,
                        "column": 4
                      },
                      "end": {
                        "line": 331,
                        "column": 4
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["practicemgmt.treatment-future-action","treatment",["get","selectedPatient",["loc",[null,[328,68],[328,83]]]],["get","typeId",["loc",[null,[328,84],[328,90]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[328,6],[330,18]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 332,
                            "column": 6
                          },
                          "end": {
                            "line": 334,
                            "column": 6
                          }
                        },
                        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("        ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[333,8],[333,53]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 331,
                          "column": 4
                        },
                        "end": {
                          "line": 335,
                          "column": 4
                        }
                      },
                      "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","link-to",["practicemgmt.treatment-future-action","compositeproducts",["get","selectedPatient",["loc",[null,[332,76],[332,91]]]],["get","typeId",["loc",[null,[332,92],[332,98]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[332,6],[334,18]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 336,
                              "column": 6
                            },
                            "end": {
                              "line": 338,
                              "column": 6
                            }
                          },
                          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("        ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                          return morphs;
                        },
                        statements: [
                          ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[337,8],[337,53]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 335,
                            "column": 4
                          },
                          "end": {
                            "line": 339,
                            "column": 4
                          }
                        },
                        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","link-to",["practicemgmt.treatment-future-action","products",["get","selectedPatient",["loc",[null,[336,67],[336,82]]]],["get","typeId",["loc",[null,[336,83],[336,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[336,6],[338,18]]]]
                      ],
                      locals: [],
                      templates: [child0]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      var child0 = (function() {
                        return {
                          meta: {
                            "revision": "Ember@1.13.5",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 340,
                                "column": 6
                              },
                              "end": {
                                "line": 342,
                                "column": 6
                              }
                            },
                            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                          },
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("        ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                            return morphs;
                          },
                          statements: [
                            ["inline","button-prev",[],["content","Zurück zur Maßnahme"],["loc",[null,[341,8],[341,53]]]]
                          ],
                          locals: [],
                          templates: []
                        };
                      }());
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 339,
                              "column": 4
                            },
                            "end": {
                              "line": 343,
                              "column": 4
                            }
                          },
                          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("    ");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                          dom.insertBoundary(fragment, 0);
                          return morphs;
                        },
                        statements: [
                          ["block","link-to",["practicemgmt.treatment-future-action","services",["get","selectedPatient",["loc",[null,[340,67],[340,82]]]],["get","typeId",["loc",[null,[340,83],[340,89]]]]],["class","icon-button icon-button--small"],0,null,["loc",[null,[340,6],[342,18]]]]
                        ],
                        locals: [],
                        templates: [child0]
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 339,
                            "column": 4
                          },
                          "end": {
                            "line": 343,
                            "column": 4
                          }
                        },
                        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[339,18],[339,29]]]],"services"],[],["loc",[null,[339,14],[339,41]]]]],[],0,null,["loc",[null,[339,4],[343,4]]]]
                      ],
                      locals: [],
                      templates: [child0]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 335,
                          "column": 4
                        },
                        "end": {
                          "line": 343,
                          "column": 4
                        }
                      },
                      "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[335,18],[335,29]]]],"products"],[],["loc",[null,[335,14],[335,41]]]]],[],0,1,["loc",[null,[335,4],[343,4]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 331,
                        "column": 4
                      },
                      "end": {
                        "line": 343,
                        "column": 4
                      }
                    },
                    "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[331,18],[331,29]]]],"compositeproducts"],[],["loc",[null,[331,14],[331,50]]]]],[],0,1,["loc",[null,[331,4],[343,4]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 327,
                      "column": 4
                    },
                    "end": {
                      "line": 343,
                      "column": 4
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[327,18],[327,29]]]],"treatment"],[],["loc",[null,[327,14],[327,42]]]]],[],0,1,["loc",[null,[327,4],[343,4]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 323,
                    "column": 4
                  },
                  "end": {
                    "line": 343,
                    "column": 4
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[323,18],[323,29]]]],"templateProduct"],[],["loc",[null,[323,14],[323,48]]]]],[],0,1,["loc",[null,[323,4],[343,4]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 319,
                  "column": 4
                },
                "end": {
                  "line": 343,
                  "column": 4
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[319,18],[319,29]]]],"templateService"],[],["loc",[null,[319,14],[319,48]]]]],[],0,1,["loc",[null,[319,4],[343,4]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 315,
                "column": 4
              },
              "end": {
                "line": 343,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[315,18],[315,29]]]],"templateComposite"],[],["loc",[null,[315,14],[315,50]]]]],[],0,1,["loc",[null,[315,4],[343,4]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 311,
              "column": 4
            },
            "end": {
              "line": 343,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[311,18],[311,29]]]],"templateTreatment"],[],["loc",[null,[311,14],[311,50]]]]],[],0,1,["loc",[null,[311,4],[343,4]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child16 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 362,
                      "column": 18
                    },
                    "end": {
                      "line": 363,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","cursor is-highlighted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [0]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element0);
                  morphs[1] = dom.createMorphAt(element0,0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["toItem",["get","entry.article.id",["loc",[null,[362,90],[362,106]]]],["get","entry.articleType",["loc",[null,[362,107],[362,124]]]]],[],["loc",[null,[362,72],[362,126]]]],
                  ["content","entry.article.name",["loc",[null,[362,127],[362,149]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 363,
                      "column": 18
                    },
                    "end": {
                      "line": 365,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","entry.articleText",["loc",[null,[364,20],[364,41]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 367,
                      "column": 104
                    },
                    "end": {
                      "line": 367,
                      "column": 203
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode(" (");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(") ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.price",["loc",[null,[367,168],[367,179]]]]],[],[]],"classNames","inline"],["loc",[null,[367,144],[367,201]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 370,
                      "column": 22
                    },
                    "end": {
                      "line": 372,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/cash/add-article.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.total",["loc",[null,[371,46],[371,57]]]]],[],[]]],["loc",[null,[371,22],[371,59]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 358,
                    "column": 12
                  },
                  "end": {
                    "line": 381,
                    "column": 12
                  }
                },
                "moduleName": "jason-frontend/templates/cash/add-article.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","entry grid");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","name col l-24/24");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","name mb10");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","sub");
                var el4 = dom.createElement("b");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" x ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("b");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","price");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","delete");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","input input--action icon-button icon-button--delete list-action-square");
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                      ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var element2 = dom.childAt(element1, [1]);
                var element3 = dom.childAt(element2, [3]);
                var element4 = dom.childAt(element1, [5, 1]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),1,1);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [0]),0,0);
                morphs[2] = dom.createMorphAt(dom.childAt(element3, [2]),0,0);
                morphs[3] = dom.createMorphAt(element3,3,3);
                morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
                morphs[5] = dom.createElementMorph(element4);
                morphs[6] = dom.createMorphAt(element4,1,1);
                return morphs;
              },
              statements: [
                ["block","if",[["get","entry.article",["loc",[null,[362,24],[362,37]]]]],[],0,1,["loc",[null,[362,18],[365,25]]]],
                ["content","entry.quantity",["loc",[null,[367,43],[367,61]]]],
                ["content","entry.unit.name",["loc",[null,[367,81],[367,100]]]],
                ["block","unless",[["subexpr","eq",[["get","entry.type",["loc",[null,[367,118],[367,128]]]],"template"],[],["loc",[null,[367,114],[367,140]]]]],[],2,null,["loc",[null,[367,104],[367,214]]]],
                ["block","unless",[["subexpr","eq",[["get","entry.type",["loc",[null,[370,36],[370,46]]]],"template"],[],["loc",[null,[370,32],[370,58]]]]],[],3,null,["loc",[null,[370,22],[372,33]]]],
                ["element","action",["deleteMinicartEntry",["get","entry.id",["loc",[null,[375,56],[375,64]]]],["get","treatment.id",["loc",[null,[375,65],[375,77]]]],["get","typeId",["loc",[null,[375,78],[375,84]]]]],[],["loc",[null,[375,25],[375,86]]]],
                ["inline","button-delete-list",[],["showStroke",false,"color","#ffffff","content",""],["loc",[null,[377,24],[377,90]]]]
              ],
              locals: ["entry"],
              templates: [child0, child1, child2, child3]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 356,
                  "column": 10
                },
                "end": {
                  "line": 383,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","supplier-entries");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              return morphs;
            },
            statements: [
              ["block","each",[["get","treatment.entries",["loc",[null,[358,20],[358,37]]]]],[],0,null,["loc",[null,[358,12],[381,21]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 383,
                  "column": 10
                },
                "end": {
                  "line": 385,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/cash/add-article.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1,"class","u-text--center mt20");
              var el2 = dom.createTextNode("Ihre Behandlung hat derzeit noch keine Positionen.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 355,
                "column": 8
              },
              "end": {
                "line": 386,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","list-not-empty",[["get","treatment.entries",["loc",[null,[356,32],[356,49]]]]],[],["loc",[null,[356,16],[356,50]]]]],[],0,1,["loc",[null,[356,10],[385,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 390,
                "column": 4
              },
              "end": {
                "line": 391,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 346,
              "column": 3
            },
            "end": {
              "line": 393,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","minicartSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","cursor");
          var el3 = dom.createTextNode("Behandlung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","label-container");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","scrollable");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"id","miniBasket");
          dom.setAttribute(el3,"class","treatment");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element5, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createElementMorph(element7);
          morphs[2] = dom.createMorphAt(dom.childAt(element5, [7, 1]),1,1);
          morphs[3] = dom.createMorphAt(element5,9,9);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[348,9],[348,40]]]],
          ["element","action",["toCart",["get","order.id",["loc",[null,[349,41],[349,49]]]]],[],["loc",[null,[349,23],[349,51]]]],
          ["block","if",[["get","treatment",["loc",[null,[355,14],[355,23]]]]],[],0,null,["loc",[null,[355,8],[386,15]]]],
          ["block","if",[["get","treatment",["loc",[null,[390,10],[390,19]]]]],[],1,null,["loc",[null,[390,4],[391,11]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child17 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 394,
                "column": 2
              },
              "end": {
                "line": 398,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/cash/add-article.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[396,61],[396,196]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 393,
              "column": 0
            },
            "end": {
              "line": 399,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/cash/add-article.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","sidebarVisible",["loc",[null,[394,8],[394,22]]]]],[],0,null,["loc",[null,[394,2],[398,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 435,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/cash/add-article.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Position hinzufügen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","ml-auto tab-container btn-group");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","quick-filter-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createTextNode("Filter");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon");
        dom.setAttribute(el4,"for","filterAll");
        var el5 = dom.createTextNode("Alle");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon favs");
        dom.setAttribute(el4,"for","filterFavs");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"class","me-10");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("Favoriten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" -\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon");
        dom.setAttribute(el4,"for","filterTiny");
        var el5 = dom.createTextNode("Kleintiere");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon");
        dom.setAttribute(el4,"for","filterHorse");
        var el5 = dom.createTextNode("Pferde");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon");
        dom.setAttribute(el4,"for","filterUse");
        var el5 = dom.createTextNode("Nutztiere");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","pagination-wrapper is-fixed");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons ");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $(document).off('click','.spinner__minus');\n        $(document).on('click','.spinner__minus', function() {\n            var targetId = $(this).data('target'),\n                    target = $('#'+targetId),\n                    oldValue = parseFloat(target.val());\n\n            if(oldValue > 1) {\n                target.val(oldValue-1);\n            }\n        });\n        $(document).off('click','.spinner__plus');\n        $(document).on('click','.spinner__plus', function() {\n            var targetId = $(this).data('target'),\n                    target = $('#'+targetId),\n                    oldValue = parseFloat(target.val());\n            target.val(oldValue+1);\n        });\n\n      $( window ).resize(function() {\n        $('aside.side-bar').css('min-height', $(window).height()-80);\n        $('aside.side-bar div.scrollable').css('max-height', $(window).height()-200);\n      });\n      $('aside.side-bar').css('min-height', $(window).height()-80);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-200);\n    })\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element19 = dom.childAt(fragment, [2]);
        var element20 = dom.childAt(element19, [1]);
        var element21 = dom.childAt(element19, [3]);
        var element22 = dom.childAt(element21, [1]);
        var element23 = dom.childAt(fragment, [4, 1]);
        var element24 = dom.childAt(element23, [1]);
        var element25 = dom.childAt(element24, [9, 0]);
        var element26 = dom.childAt(element23, [3]);
        var element27 = dom.childAt(fragment, [6]);
        var element28 = dom.childAt(fragment, [8]);
        var morphs = new Array(35);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createAttrMorph(element19, 'class');
        morphs[2] = dom.createAttrMorph(element20, 'class');
        morphs[3] = dom.createAttrMorph(element21, 'class');
        morphs[4] = dom.createMorphAt(element22,1,1);
        morphs[5] = dom.createMorphAt(element22,3,3);
        morphs[6] = dom.createMorphAt(dom.childAt(element22, [5]),1,1);
        morphs[7] = dom.createMorphAt(element21,3,3);
        morphs[8] = dom.createMorphAt(element21,4,4);
        morphs[9] = dom.createAttrMorph(element24, 'class');
        morphs[10] = dom.createMorphAt(element24,3,3);
        morphs[11] = dom.createMorphAt(element24,7,7);
        morphs[12] = dom.createAttrMorph(element25, 'src');
        morphs[13] = dom.createMorphAt(element24,11,11);
        morphs[14] = dom.createMorphAt(element24,15,15);
        morphs[15] = dom.createMorphAt(element24,19,19);
        morphs[16] = dom.createAttrMorph(element26, 'class');
        morphs[17] = dom.createMorphAt(element26,1,1);
        morphs[18] = dom.createAttrMorph(element27, 'class');
        morphs[19] = dom.createMorphAt(element27,1,1);
        morphs[20] = dom.createMorphAt(element27,2,2);
        morphs[21] = dom.createMorphAt(element27,3,3);
        morphs[22] = dom.createMorphAt(element27,4,4);
        morphs[23] = dom.createMorphAt(dom.childAt(element27, [6]),1,1);
        morphs[24] = dom.createMorphAt(element28,1,1);
        morphs[25] = dom.createMorphAt(element28,2,2);
        morphs[26] = dom.createMorphAt(element28,3,3);
        morphs[27] = dom.createMorphAt(element28,4,4);
        morphs[28] = dom.createMorphAt(element28,5,5);
        morphs[29] = dom.createMorphAt(element28,6,6);
        morphs[30] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[31] = dom.createMorphAt(fragment,13,13,contextualElement);
        morphs[32] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[33] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[34] = dom.createMorphAt(fragment,19,19,contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[1,10],[1,14]]]],"inventoryRequests"],[],["loc",[null,[1,6],[1,35]]]]],[],0,null,["loc",[null,[1,0],[12,7]]]],
        ["attribute","class",["concat",["rework-navbar with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[14,62],[14,73]]]],"with-filters",""],[],["loc",[null,[14,41],[14,93]]]]]]],
        ["attribute","class",["concat",["d-flex align-items-center col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[15,72],[15,85]]]]],[],["loc",[null,[15,67],[15,86]]]],"24","18"],[],["loc",[null,[15,46],[15,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["attribute","class",["concat",["search-container card-rework  col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[20,76],[20,89]]]]],[],["loc",[null,[20,71],[20,90]]]],"24","18"],[],["loc",[null,[20,50],[20,102]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[22,30],[22,40]]]]],[],[]],"placeHolder",["subexpr","@mut",[["get","placeholder",["loc",[null,[22,53],[22,64]]]]],[],[]],"search","applyFilter"],["loc",[null,[22,6],[22,87]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[23,31],[23,42]]]]],[],[]]],["loc",[null,[23,6],[23,44]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,24],[26,28]]]],"templates"],[],["loc",[null,[26,20],[26,41]]]]],[],["loc",[null,[26,15],[26,42]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,52],[26,56]]]],"inventoryRequests"],[],["loc",[null,[26,48],[26,77]]]]],[],["loc",[null,[26,43],[26,78]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,88],[26,92]]]],"deliveryNote"],[],["loc",[null,[26,84],[26,108]]]]],[],["loc",[null,[26,79],[26,109]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,119],[26,123]]]],"compositeTemplate"],[],["loc",[null,[26,115],[26,144]]]]],[],["loc",[null,[26,110],[26,145]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,155],[26,159]]]],"compositeProduct"],[],["loc",[null,[26,151],[26,179]]]]],[],["loc",[null,[26,146],[26,180]]]],["subexpr","not",[["subexpr","eq",[["get","type",["loc",[null,[26,190],[26,194]]]],"compositeService"],[],["loc",[null,[26,186],[26,214]]]]],[],["loc",[null,[26,181],[26,215]]]]],[],["loc",[null,[26,10],[26,216]]]]],[],1,null,["loc",[null,[25,8],[39,15]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[42,14],[42,26]]]],"product"],[],["loc",[null,[42,10],[42,37]]]]],[],2,null,["loc",[null,[42,4],[96,11]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[97,14],[97,26]]]],"service"],[],["loc",[null,[97,10],[97,37]]]]],[],3,null,["loc",[null,[97,4],[129,11]]]],
        ["attribute","class",["concat",["quick-filter col  col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[135,66],[135,79]]]]],[],["loc",[null,[135,61],[135,80]]]],"12","9"],[],["loc",[null,[135,40],[135,91]]]],"/24"]]],
        ["inline","input",[],["type","checkbox","id","filterAll","name","filterAll","checked",["subexpr","@mut",[["get","filterAll",["loc",[null,[137,70],[137,79]]]]],[],[]]],["loc",[null,[137,6],[137,81]]]],
        ["inline","input",[],["type","checkbox","id","filterFavs","name","filterFavs","checked",["subexpr","@mut",[["get","filterFavs",["loc",[null,[139,72],[139,82]]]]],[],[]]],["loc",[null,[139,6],[139,84]]]],
        ["attribute","src",["concat",["assets/images/icons/rework/favorite",["subexpr","css-bool-evaluator",[["get","filterFavs",["loc",[null,[140,148],[140,158]]]],"-white",""],[],["loc",[null,[140,127],[140,172]]]],".svg"]]],
        ["inline","input",[],["type","checkbox","id","filterTiny","name","filterTiny","checked",["subexpr","@mut",[["get","filterTiny",["loc",[null,[141,72],[141,82]]]]],[],[]]],["loc",[null,[141,6],[141,84]]]],
        ["inline","input",[],["type","checkbox","id","filterHorse","name","filterHorse","checked",["subexpr","@mut",[["get","filterHorse",["loc",[null,[143,74],[143,85]]]]],[],[]]],["loc",[null,[143,6],[143,87]]]],
        ["inline","input",[],["type","checkbox","id","filterUse","name","filterUse","checked",["subexpr","@mut",[["get","filterUse",["loc",[null,[145,70],[145,79]]]]],[],[]]],["loc",[null,[145,6],[145,81]]]],
        ["attribute","class",["concat",["quick-filter col  col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[148,66],[148,79]]]]],[],["loc",[null,[148,61],[148,80]]]],"12","9"],[],["loc",[null,[148,40],[148,91]]]],"/24"]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectedType",["loc",[null,[149,20],[149,32]]]],"product"],[],["loc",[null,[149,16],[149,43]]]],["subexpr","eq",[["get","selectedType",["loc",[null,[149,48],[149,60]]]],"service"],[],["loc",[null,[149,44],[149,71]]]]],[],["loc",[null,[149,12],[149,72]]]]],[],4,null,["loc",[null,[149,6],[159,13]]]],
        ["attribute","class",["concat",["table-wrapper-rework col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[166,67],[166,80]]]]],[],["loc",[null,[166,62],[166,81]]]],"24","18"],[],["loc",[null,[166,41],[166,93]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[170,12],[170,24]]]],"product"],[],["loc",[null,[170,8],[170,35]]]]],[],5,null,["loc",[null,[170,2],[191,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[192,12],[192,24]]]],"service"],[],["loc",[null,[192,8],[192,35]]]]],[],6,null,["loc",[null,[192,2],[216,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[217,12],[217,24]]]],"composite"],[],["loc",[null,[217,8],[217,37]]]]],[],7,null,["loc",[null,[217,2],[238,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectedType",["loc",[null,[239,12],[239,24]]]],"text"],[],["loc",[null,[239,8],[239,32]]]]],[],8,null,["loc",[null,[239,2],[260,9]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[262,29],[262,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[262,6],[262,55]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","type",["loc",[null,[267,18],[267,22]]]],"compositeProduct"],[],["loc",[null,[267,14],[267,42]]]],["subexpr","eq",[["get","type",["loc",[null,[267,47],[267,51]]]],"compositeService"],[],["loc",[null,[267,43],[267,71]]]],["subexpr","eq",[["get","type",["loc",[null,[267,76],[267,80]]]],"compositeTemplate"],[],["loc",[null,[267,72],[267,101]]]]],[],["loc",[null,[267,10],[267,102]]]]],[],9,null,["loc",[null,[267,4],[271,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[272,14],[272,18]]]],"deliveryNote"],[],["loc",[null,[272,10],[272,34]]]]],[],10,null,["loc",[null,[272,4],[276,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[277,14],[277,18]]]],"invoices"],[],["loc",[null,[277,10],[277,30]]]]],[],11,null,["loc",[null,[277,4],[281,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[282,14],[282,18]]]],"treatmentOffers"],[],["loc",[null,[282,10],[282,37]]]]],[],12,null,["loc",[null,[282,4],[286,11]]]],
        ["block","if",[["subexpr","eq",[["get","type",["loc",[null,[287,14],[287,18]]]],"inventoryRequests"],[],["loc",[null,[287,10],[287,39]]]]],[],13,null,["loc",[null,[287,4],[306,11]]]],
        ["block","if",[["subexpr","eq",[["get","refererType",["loc",[null,[307,14],[307,25]]]],"futureActionBlueprints"],[],["loc",[null,[307,10],[307,51]]]]],[],14,15,["loc",[null,[307,4],[343,11]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[346,9],[346,22]]]]],[],16,17,["loc",[null,[346,3],[399,7]]]],
        ["inline","add-task-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[401,23],[401,28]]]]],[],[]],"create","connectTask","reloadPatients","reloadPatients","actionReceiver",["subexpr","@mut",[["get","addTaskPanel",["loc",[null,[401,97],[401,109]]]]],[],[]]],["loc",[null,[401,0],[401,111]]]],
        ["inline","add-reminder-panel",[],["create","createReminder","actionReceiver",["subexpr","@mut",[["get","addReminderPanel",["loc",[null,[402,60],[402,76]]]]],[],[]]],["loc",[null,[402,0],[402,78]]]],
        ["inline","add-notification-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[403,31],[403,36]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[403,46],[403,54]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[403,63],[403,70]]]]],[],[]],"create","createNotification","forward","sendForward","actionReceiver",["subexpr","@mut",[["get","addNotificationPanel",["loc",[null,[403,136],[403,156]]]]],[],[]]],["loc",[null,[403,0],[403,158]]]],
        ["inline","add-appointment-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[404,30],[404,35]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[404,44],[404,51]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[404,62],[404,71]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[404,83],[404,93]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[404,100],[404,105]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[404,115],[404,123]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[404,166],[404,185]]]]],[],[]]],["loc",[null,[404,0],[404,187]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17]
    };
  }()));

});