define('jason-frontend/templates/components/treatment-patient-list', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 22
                },
                "end": {
                  "line": 21,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","sub");
              dom.setAttribute(el1,"style","display: inline");
              var el2 = dom.createTextNode("Termin: ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"for","time");
              dom.setAttribute(el1,"class","input-element");
              var el2 = dom.createTextNode("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("input");
              dom.setAttribute(el2,"style","font-size: 15px");
              dom.setAttribute(el2,"autocomplete","false");
              dom.setAttribute(el2,"type","text");
              dom.setAttribute(el2,"placeholder","");
              dom.setAttribute(el2,"class","input date-picker__input time time-small");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element15, 'id');
              morphs[1] = dom.createAttrMorph(element15, 'value');
              morphs[2] = dom.createAttrMorph(element15, 'onclick');
              return morphs;
            },
            statements: [
              ["attribute","id",["concat",["time",["get","treatment.id",["loc",[null,[19,99],[19,111]]]]]]],
              ["attribute","value",["concat",[["get","treatment.time",["loc",[null,[19,139],[19,153]]]]]]],
              ["attribute","onclick",["subexpr","action",["openTime",["get","treatment",["loc",[null,[19,234],[19,243]]]]],[],["loc",[null,[19,214],[19,245]]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 69
                },
                "end": {
                  "line": 25,
                  "column": 181
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","treatment.customer.firstname",["loc",[null,[25,117],[25,149]]]],
              ["content","treatment.customer.lastname",["loc",[null,[25,150],[25,181]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child2 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 194
                },
                "end": {
                  "line": 25,
                  "column": 355
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","button-ical",[],["size",32,"classNames","ml5 inline-block vertical-bottom","color","#00AAC6","content","eltiga App BenutzerIn"],["loc",[null,[25,238],[25,355]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child3 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 355
                  },
                  "end": {
                    "line": 25,
                    "column": 530
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-ical",[],["size",32,"classNames","ml5 inline-block vertical-bottom","color","#797979","content","eltiga Tier QR Code verschickt"],["loc",[null,[25,404],[25,530]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 355
                },
                "end": {
                  "line": 25,
                  "column": 530
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","treatment.customer.etigaCodeRequested",["loc",[null,[25,365],[25,402]]]]],[],0,null,["loc",[null,[25,355],[25,530]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child4 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 584
                },
                "end": {
                  "line": 25,
                  "column": 647
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(",");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","treatment.customer.street",["loc",[null,[25,617],[25,646]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child5 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 24
                },
                "end": {
                  "line": 28,
                  "column": 87
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(",");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [
              ["content","treatment.customer.street",["loc",[null,[28,57],[28,86]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child6 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 35,
                      "column": 24
                    },
                    "end": {
                      "line": 35,
                      "column": 248
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1,"class","animal-icon");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","animal-icon",[],["animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[35,107],[35,138]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[35,147],[35,183]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[35,192],[35,225]]]]],[],[]],"classNames","mr5"],["loc",[null,[35,83],[35,244]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 36,
                      "column": 24
                    },
                    "end": {
                      "line": 36,
                      "column": 151
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[36,94],[36,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[36,127],[36,149]]]]],[],[]]],["loc",[null,[36,75],[36,151]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child2 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 37,
                      "column": 24
                    },
                    "end": {
                      "line": 37,
                      "column": 193
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","20","width",60,"color","00AAC6","classNames","ml10","connected",true],["loc",[null,[37,63],[37,193]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child3 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 42,
                      "column": 20
                    },
                    "end": {
                      "line": 42,
                      "column": 130
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","block mt5 mb5");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","patientInfo.station.name",["loc",[null,[42,94],[42,122]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child4 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 43,
                      "column": 22
                    },
                    "end": {
                      "line": 43,
                      "column": 171
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","block");
                  var el2 = dom.createElement("small");
                  var el3 = dom.createTextNode("seit ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","get-formatted-timespan",[["get","patientInfo.lastStateChange",["loc",[null,[43,127],[43,154]]]]],[],["loc",[null,[43,102],[43,156]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child5 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 47,
                      "column": 22
                    },
                    "end": {
                      "line": 49,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-currency",[],["value",["subexpr","@mut",[["get","patientInfo.totalOfferSum",["loc",[null,[48,48],[48,73]]]]],[],[]]],["loc",[null,[48,24],[48,75]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child6 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 53,
                      "column": 22
                    },
                    "end": {
                      "line": 57,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("Abbrechen");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","patientInfo",["loc",[null,[55,104],[55,115]]]]],[],[]],"confirmed","abort","content","Behandlung abbrechen","text","Wollen Sie die Behandlung dieses Patienten wirklich abbrechen?"],["loc",[null,[55,26],[55,236]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child7 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 22
                    },
                    "end": {
                      "line": 62,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"class","tooltipstered");
                  dom.setAttribute(el2,"title","Behandeln");
                  dom.setAttribute(el2,"src","assets/images/icons/rework/treatment.svg");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element9 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element9);
                  return morphs;
                },
                statements: [
                  ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[59,129],[59,143]]]]],[],["loc",[null,[59,91],[59,145]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child8 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 64,
                        "column": 24
                      },
                      "end": {
                        "line": 68,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"class","tooltipstered");
                    dom.setAttribute(el2,"title","Kassieren");
                    dom.setAttribute(el2,"src","assets/images/icons/rework/cashdesk.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 68,
                        "column": 24
                      },
                      "end": {
                        "line": 72,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"class","tooltipstered");
                    dom.setAttribute(el2,"title","Kassieren");
                    dom.setAttribute(el2,"src","assets/images/icons/rework/cashdesk.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createElementMorph(element8);
                    return morphs;
                  },
                  statements: [
                    ["element","action",["exportInvoice",["get","patientInfo.id",["loc",[null,[69,118],[69,132]]]],["get","patientInfo.info.invoiceId",["loc",[null,[69,133],[69,159]]]]],[],["loc",[null,[69,93],[69,161]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 63,
                      "column": 22
                    },
                    "end": {
                      "line": 73,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","inProgress",["loc",[null,[64,30],[64,40]]]]],[],0,1,["loc",[null,[64,24],[72,31]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child9 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 75,
                        "column": 24
                      },
                      "end": {
                        "line": 77,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1,"class","tooltipstered");
                    dom.setAttribute(el1,"title","Behandlungsdetails");
                    dom.setAttribute(el1,"src","assets/images/icons/rework/treatment.svg");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 74,
                      "column": 22
                    },
                    "end": {
                      "line": 78,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[75,68],[75,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[75,83],[75,110]]]]],["class","exp icon-button icon-button--default list-action-square"],0,null,["loc",[null,[75,24],[77,36]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            var child10 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 79,
                      "column": 22
                    },
                    "end": {
                      "line": 83,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"class","tooltipstered");
                  dom.setAttribute(el2,"title","Aufnehmen");
                  dom.setAttribute(el2,"src","assets/images/icons/rework/waitingroom.svg");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element7 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element7);
                  return morphs;
                },
                statements: [
                  ["element","action",["movePatientToWaitingRoom",["get","patientInfo.id",["loc",[null,[80,127],[80,141]]]]],[],["loc",[null,[80,91],[80,143]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child11 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 84,
                      "column": 22
                    },
                    "end": {
                      "line": 88,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","exp icon-button icon-button--default list-action-square");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"class","tooltipstered");
                  dom.setAttribute(el2,"title","Auf Station legen");
                  dom.setAttribute(el2,"src","assets/images/icons/rework/station.svg");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element6);
                  return morphs;
                },
                statements: [
                  ["element","action",["openStationPanel",["get","patientInfo.id",["loc",[null,[85,119],[85,133]]]]],[],["loc",[null,[85,91],[85,135]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child12 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 94,
                      "column": 24
                    },
                    "end": {
                      "line": 95,
                      "column": 104
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","medium block mt10");
                  var el2 = dom.createTextNode("Diagnose: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","patientInfo.info.diagnose",["loc",[null,[95,68],[95,97]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child13 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 96,
                      "column": 24
                    },
                    "end": {
                      "line": 97,
                      "column": 112
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","medium block mt10");
                  var el2 = dom.createTextNode("Behandlungs-Lokation: ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  return morphs;
                },
                statements: [
                  ["content","patientInfo.room.name",["loc",[null,[97,80],[97,105]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child14 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 102,
                        "column": 32
                      },
                      "end": {
                        "line": 107,
                        "column": 32
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                    var el2 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element4, 'title');
                    morphs[1] = dom.createAttrMorph(element4, 'style');
                    morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[103,48],[103,76]]]]]]],
                    ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[103,166],[103,190]]]]," !important;"]]],
                    ["content","patientInfo.doctor.capitals",["loc",[null,[104,62],[104,93]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 107,
                        "column": 32
                      },
                      "end": {
                        "line": 112,
                        "column": 32
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element3, 'title');
                    morphs[1] = dom.createAttrMorph(element3, 'class');
                    morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[108,48],[108,76]]]]]]],
                    ["attribute","class",["concat",["tooltipstered mr2 symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[108,140],[108,164]]]]]]],
                    ["content","patientInfo.doctor.capitals",["loc",[null,[109,62],[109,93]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 98,
                      "column": 24
                    },
                    "end": {
                      "line": 116,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","clickable align-items-center d-flex mt5 mb5");
                  var el2 = dom.createTextNode("\n                              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","medium");
                  var el3 = dom.createTextNode("Tierärzt:in:");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                              ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-group");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                              ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                               ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","text--editable");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element5);
                  morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),1,1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element5, [5]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["editTreatmentInfo",["get","patientInfo",["loc",[null,[99,112],[99,123]]]]],[],["loc",[null,[99,83],[99,125]]]],
                  ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[102,38],[102,65]]]]],[],0,1,["loc",[null,[102,32],[112,39]]]],
                  ["content","patientInfo.doctor.shortName",["loc",[null,[114,60],[114,92]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child15 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 122,
                        "column": 28
                      },
                      "end": {
                        "line": 127,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element1, 'title');
                    morphs[1] = dom.createAttrMorph(element1, 'style');
                    morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[123,44],[123,75]]]]]]],
                    ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[123,165],[123,192]]]]," !important;"]]],
                    ["content","patientInfo.assistant.capitals",["loc",[null,[124,58],[124,92]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 127,
                        "column": 28
                      },
                      "end": {
                        "line": 132,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element0 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element0, 'title');
                    morphs[1] = dom.createAttrMorph(element0, 'class');
                    morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[128,44],[128,75]]]]]]],
                    ["attribute","class",["concat",["tooltipstered mr2 symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[128,139],[128,166]]]]]]],
                    ["content","patientInfo.assistant.capitals",["loc",[null,[129,58],[129,92]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 117,
                      "column": 24
                    },
                    "end": {
                      "line": 136,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","clickable align-items-center d-flex mt5 mb5");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","medium");
                  var el3 = dom.createTextNode("Assistent:in: ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2,"class","symbol-group");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                          ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","text--editable");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element2 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createElementMorph(element2);
                  morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]),1,1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]),0,0);
                  return morphs;
                },
                statements: [
                  ["element","action",["editTreatmentInfo",["get","patientInfo",["loc",[null,[118,110],[118,121]]]]],[],["loc",[null,[118,81],[118,123]]]],
                  ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[122,34],[122,64]]]]],[],0,1,["loc",[null,[122,28],[132,35]]]],
                  ["content","patientInfo.assistant.shortName",["loc",[null,[134,55],[134,90]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 18
                  },
                  "end": {
                    "line": 138,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell table__cell--name verticaltop l-9/24");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","flex-center");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell l-5/24 treatment-status table__cell--center");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                dom.setAttribute(el2,"class","info-badge");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell table__cell--name l-3/24 table__cell--right");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell  table__cell--action l-7/24 buttons-4 pr10");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__cell l-24/24 pt0");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","medium block");
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3,"class","clickable");
                var el4 = dom.createTextNode("Anamnese: ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("span");
                dom.setAttribute(el4,"class","text--editable");
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1, 1]);
                var element11 = dom.childAt(fragment, [3]);
                var element12 = dom.childAt(fragment, [7]);
                var element13 = dom.childAt(fragment, [9]);
                var element14 = dom.childAt(element13, [1, 1]);
                var morphs = new Array(19);
                morphs[0] = dom.createMorphAt(element10,1,1);
                morphs[1] = dom.createMorphAt(element10,3,3);
                morphs[2] = dom.createMorphAt(element10,5,5);
                morphs[3] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
                morphs[4] = dom.createMorphAt(element11,3,3);
                morphs[5] = dom.createMorphAt(element11,5,5);
                morphs[6] = dom.createMorphAt(dom.childAt(fragment, [5, 1]),1,1);
                morphs[7] = dom.createMorphAt(element12,1,1);
                morphs[8] = dom.createMorphAt(element12,2,2);
                morphs[9] = dom.createMorphAt(element12,3,3);
                morphs[10] = dom.createMorphAt(element12,4,4);
                morphs[11] = dom.createMorphAt(element12,5,5);
                morphs[12] = dom.createMorphAt(element12,6,6);
                morphs[13] = dom.createElementMorph(element14);
                morphs[14] = dom.createMorphAt(dom.childAt(element14, [1]),0,0);
                morphs[15] = dom.createMorphAt(element13,3,3);
                morphs[16] = dom.createMorphAt(element13,5,5);
                morphs[17] = dom.createMorphAt(element13,7,7);
                morphs[18] = dom.createMorphAt(element13,8,8);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.patient.category",["loc",[null,[35,30],[35,58]]]]],[],0,null,["loc",[null,[35,24],[35,255]]]],
                ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[36,51],[36,73]]]]],[],1,null,["loc",[null,[36,24],[36,163]]]],
                ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[37,30],[37,61]]]]],[],2,null,["loc",[null,[37,24],[37,200]]]],
                ["content","patientInfo.state.name",["loc",[null,[41,48],[41,74]]]],
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[42,30],[42,50]]]],"in_station"],[],["loc",[null,[42,26],[42,64]]]]],[],3,null,["loc",[null,[42,20],[42,137]]]],
                ["block","if",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[43,37],[43,57]]]],"closed"],[],["loc",[null,[43,33],[43,67]]]]],[],["loc",[null,[43,28],[43,68]]]]],[],4,null,["loc",[null,[43,22],[43,178]]]],
                ["block","if",[["subexpr","show-prices",[],[],["loc",[null,[47,28],[47,41]]]]],[],5,null,["loc",[null,[47,22],[49,29]]]],
                ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[53,42],[53,62]]]],"closed"],[],["loc",[null,[53,38],[53,72]]]]],[],["loc",[null,[53,33],[53,73]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[53,83],[53,103]]]],"ready_for_payment"],[],["loc",[null,[53,79],[53,124]]]]],[],["loc",[null,[53,74],[53,125]]]]],[],["loc",[null,[53,28],[53,126]]]]],[],6,null,["loc",[null,[53,22],[57,29]]]],
                ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[58,42],[58,62]]]],"in_treatment"],[],["loc",[null,[58,38],[58,78]]]]],[],["loc",[null,[58,33],[58,79]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[58,89],[58,109]]]],"closed"],[],["loc",[null,[58,85],[58,119]]]]],[],["loc",[null,[58,80],[58,120]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[58,130],[58,150]]]],"ready_for_payment"],[],["loc",[null,[58,126],[58,171]]]]],[],["loc",[null,[58,121],[58,172]]]]],[],["loc",[null,[58,28],[58,173]]]]],[],7,null,["loc",[null,[58,22],[62,29]]]],
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[63,32],[63,52]]]],"ready_for_payment"],[],["loc",[null,[63,28],[63,73]]]]],[],8,null,["loc",[null,[63,22],[73,29]]]],
                ["block","if",[["subexpr","or",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[74,36],[74,56]]]],"ready_for_payment"],[],["loc",[null,[74,32],[74,77]]]],["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[74,82],[74,102]]]],"in_treatment"],[],["loc",[null,[74,78],[74,118]]]]],[],["loc",[null,[74,28],[74,119]]]]],[],9,null,["loc",[null,[74,22],[78,29]]]],
                ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[79,42],[79,62]]]],"in_waitingroom"],[],["loc",[null,[79,38],[79,80]]]]],[],["loc",[null,[79,33],[79,81]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[79,91],[79,111]]]],"closed"],[],["loc",[null,[79,87],[79,121]]]]],[],["loc",[null,[79,82],[79,122]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[79,132],[79,152]]]],"ready_for_payment"],[],["loc",[null,[79,128],[79,173]]]]],[],["loc",[null,[79,123],[79,174]]]]],[],["loc",[null,[79,28],[79,175]]]]],[],10,null,["loc",[null,[79,22],[83,29]]]],
                ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[84,42],[84,62]]]],"in_station"],[],["loc",[null,[84,38],[84,76]]]]],[],["loc",[null,[84,33],[84,77]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[84,87],[84,107]]]],"closed"],[],["loc",[null,[84,83],[84,117]]]]],[],["loc",[null,[84,78],[84,118]]]],["subexpr","not",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[84,128],[84,148]]]],"ready_for_payment"],[],["loc",[null,[84,124],[84,169]]]]],[],["loc",[null,[84,119],[84,170]]]]],[],["loc",[null,[84,28],[84,171]]]]],[],11,null,["loc",[null,[84,22],[88,29]]]],
                ["element","action",["editTreatmentInfo",["get","patientInfo",["loc",[null,[91,82],[91,93]]]]],[],["loc",[null,[91,53],[91,95]]]],
                ["content","patientInfo.info.medicalHistory",["loc",[null,[93,47],[93,82]]]],
                ["block","if",[["get","patientInfo.info.diagnose",["loc",[null,[94,30],[94,55]]]]],[],12,null,["loc",[null,[94,24],[95,111]]]],
                ["block","if",[["get","patientInfo.room",["loc",[null,[96,30],[96,46]]]]],[],13,null,["loc",[null,[96,24],[97,119]]]],
                ["block","if",[["get","patientInfo.doctor",["loc",[null,[98,30],[98,48]]]]],[],14,null,["loc",[null,[98,24],[116,31]]]],
                ["block","if",[["get","patientInfo.assistant",["loc",[null,[117,30],[117,51]]]]],[],15,null,["loc",[null,[117,24],[136,31]]]]
              ],
              locals: [],
              templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 16
                },
                "end": {
                  "line": 139,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","show-treatment-patient",[["get","patientInfo.state.id",["loc",[null,[32,48],[32,68]]]],["get","showAll",["loc",[null,[32,69],[32,76]]]],["get","showAdmission",["loc",[null,[32,77],[32,90]]]],["get","showWaiting",["loc",[null,[32,91],[32,102]]]],["get","showTreatment",["loc",[null,[32,103],[32,116]]]],["get","showPayment",["loc",[null,[32,117],[32,128]]]],["get","showStation",["loc",[null,[32,129],[32,140]]]],["get","showStationOverride",["loc",[null,[32,141],[32,160]]]],["get","filteredStations",["loc",[null,[32,161],[32,177]]]],["get","patientInfo",["loc",[null,[32,178],[32,189]]]],["get","selectedUsers",["loc",[null,[32,190],[32,203]]]],["get","filterName",["loc",[null,[32,204],[32,214]]]]],[],["loc",[null,[32,24],[32,215]]]]],[],0,null,["loc",[null,[32,18],[138,25]]]]
            ],
            locals: ["patientInfo"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 12
              },
              "end": {
                "line": 142,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 table__cell--name table__cell--center l-8/24@tablet l-3/24@desk verticaltop");
            dom.setAttribute(el2,"data-label","Aufgenommen");
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"style","display: block;margin-top: 6px;margin-bottom: 3px;");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" Uhr");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","block mt20");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-12/24 l-8/24@tablet table__cell--name  l-4/24@desk verticaltop");
            dom.setAttribute(el2,"data-label","Name");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","flex-center");
            dom.setAttribute(el3,"style","line-height:32px");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","block small u-hide@tablet");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 l-8/24@tablet l-6/24@desk u-hide@desk");
            dom.setAttribute(el2,"data-label","Adresse");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-24/24 l-24/24@tablet pr0 l-24/24@phone l-17/24@desk patient-treatments");
            dom.setAttribute(el2,"data-label","Tiere");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var element17 = dom.childAt(element16, [1]);
            var element18 = dom.childAt(element16, [3]);
            var element19 = dom.childAt(element18, [1]);
            var element20 = dom.childAt(element18, [2]);
            var element21 = dom.childAt(element16, [5, 1]);
            var element22 = dom.childAt(element16, [7]);
            var morphs = new Array(14);
            morphs[0] = dom.createAttrMorph(element17, 'data-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element17, [1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element17, [3]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element17, [5]),1,1);
            morphs[4] = dom.createMorphAt(element19,0,0);
            morphs[5] = dom.createMorphAt(element19,2,2);
            morphs[6] = dom.createMorphAt(element20,0,0);
            morphs[7] = dom.createMorphAt(element20,2,2);
            morphs[8] = dom.createMorphAt(element20,4,4);
            morphs[9] = dom.createMorphAt(element21,0,0);
            morphs[10] = dom.createMorphAt(element21,2,2);
            morphs[11] = dom.createMorphAt(element21,4,4);
            morphs[12] = dom.createAttrMorph(element22, 'id');
            morphs[13] = dom.createMorphAt(element22,1,1);
            return morphs;
          },
          statements: [
            ["attribute","data-id",["concat",[["get","treatment.id",["loc",[null,[12,138],[12,150]]]]]]],
            ["inline","time-format",[["get","treatment.created",["loc",[null,[13,101],[13,118]]]],"DD. MM. YYYY"],[],["loc",[null,[13,87],[13,135]]]],
            ["inline","time-format",[["get","treatment.created",["loc",[null,[14,54],[14,71]]]],"HH:mm"],[],["loc",[null,[14,40],[14,81]]]],
            ["block","if",[["get","treatment.time",["loc",[null,[16,28],[16,42]]]]],[],0,null,["loc",[null,[16,22],[21,29]]]],
            ["block","link-to",["customers.edit",["get","treatment.customer",["loc",[null,[25,97],[25,115]]]]],[],1,null,["loc",[null,[25,69],[25,193]]]],
            ["block","if",[["get","treatment.customer.etigaAppConnected",["loc",[null,[25,200],[25,236]]]]],[],2,3,["loc",[null,[25,194],[25,537]]]],
            ["block","if",[["get","treatment.customer.street",["loc",[null,[25,590],[25,615]]]]],[],4,null,["loc",[null,[25,584],[25,654]]]],
            ["content","treatment.customer.postalCode",["loc",[null,[25,655],[25,688]]]],
            ["content","treatment.customer.town",["loc",[null,[25,689],[25,716]]]],
            ["block","if",[["get","treatment.customer.street",["loc",[null,[28,30],[28,55]]]]],[],5,null,["loc",[null,[28,24],[28,94]]]],
            ["content","treatment.customer.postalCode",["loc",[null,[28,95],[28,128]]]],
            ["content","treatment.customer.town",["loc",[null,[28,129],[28,156]]]],
            ["attribute","id",["concat",["patientsForCustomer",["get","treatment.customer.id",["loc",[null,[30,158],[30,179]]]]]]],
            ["block","each",[["get","treatment.treatmentPatients",["loc",[null,[31,24],[31,51]]]]],[],6,null,["loc",[null,[31,16],[139,25]]]]
          ],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 10
            },
            "end": {
              "line": 143,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","show-treatment",[["get","treatment",["loc",[null,[10,34],[10,43]]]],["get","showAll",["loc",[null,[10,44],[10,51]]]],["get","showAdmission",["loc",[null,[10,52],[10,65]]]],["get","showWaiting",["loc",[null,[10,66],[10,77]]]],["get","showTreatment",["loc",[null,[10,78],[10,91]]]],["get","showPayment",["loc",[null,[10,92],[10,103]]]],["get","showStation",["loc",[null,[10,104],[10,115]]]],["get","showStationOverride",["loc",[null,[10,116],[10,135]]]],["get","filteredStations",["loc",[null,[10,136],[10,152]]]],["get","selectedUsers",["loc",[null,[10,153],[10,166]]]],["get","filterName",["loc",[null,[10,167],[10,177]]]]],[],["loc",[null,[10,18],[10,178]]]]],[],0,null,["loc",[null,[10,12],[142,19]]]]
        ],
        locals: ["treatment"],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 146,
              "column": 2
            },
            "end": {
              "line": 150,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","pagination-wrapper is-fixed");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[148,29],[148,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[148,6],[148,55]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 154,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/treatment-patient-list.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","treatment-patient-list table-wrapper-rework");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","grid table table--large");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__head");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell table__cell--center l-3/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Aufgenommen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-4/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Kund:in");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table__cell l-10/24");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","table__title title");
        var el6 = dom.createTextNode("Details");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table__body");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element23 = dom.childAt(fragment, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element23, [1, 3]),1,1);
        morphs[1] = dom.createMorphAt(element23,3,3);
        morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
        return morphs;
      },
      statements: [
        ["block","each",[["get","model",["loc",[null,[9,18],[9,23]]]]],[],0,null,["loc",[null,[9,10],[143,19]]]],
        ["block","if",[["subexpr","and",[["get","model",["loc",[null,[146,13],[146,18]]]],["get","showPager",["loc",[null,[146,19],[146,28]]]]],[],["loc",[null,[146,8],[146,29]]]]],[],1,null,["loc",[null,[146,2],[150,9]]]],
        ["inline","select-station-panel",[],["movePatientToStation","movePatientToStation","actionReceiver",["subexpr","@mut",[["get","setStationPanel",["loc",[null,[153,82],[153,97]]]]],[],[]]],["loc",[null,[153,0],[153,99]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});