define('jason-frontend/templates/products/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 12,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context primary");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Maßnahme");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element53 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element53);
          return morphs;
        },
        statements: [
          ["element","action",["addFutureAction",["get","model.id",["loc",[null,[9,38],[9,46]]]]],[],["loc",[null,[9,11],[9,48]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 8
            },
            "end": {
              "line": 23,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","max-height: 300px");
          dom.setAttribute(el1,"class","media-object");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element52 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element52, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["subexpr","get-host-path",[["get","model.picture.path",["loc",[null,[22,83],[22,101]]]]],[],["loc",[null,[22,67],[22,103]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 8
            },
            "end": {
              "line": 27,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","max-height: 300px;margin-top:30px");
          dom.setAttribute(el1,"class","media-object");
          dom.setAttribute(el1,"src","assets/images/icons/noimage.svg");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 42
            },
            "end": {
              "line": 32,
              "column": 108
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","info-badge mr5 mb5 tag12");
          dom.setAttribute(el1,"style","position: absolute;margin-left: 10px");
          var el2 = dom.createTextNode("Inaktiv");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 33
            },
            "end": {
              "line": 34,
              "column": 66
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("Art.-Nr:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.rpaId",["loc",[null,[34,50],[34,61]]]],["get","-",["loc",[null,[34,62],[34,63]]]]],[],["loc",[null,[34,24],[34,66]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 74
            },
            "end": {
              "line": 35,
              "column": 60
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("PZN:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.pzn",["loc",[null,[35,46],[35,55]]]],["get","-",["loc",[null,[35,56],[35,57]]]]],[],["loc",[null,[35,20],[35,60]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 68
            },
            "end": {
              "line": 36,
              "column": 61
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("EAN:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.gtin",["loc",[null,[36,46],[36,56]]]],["get","-",["loc",[null,[36,57],[36,58]]]]],[],["loc",[null,[36,20],[36,61]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 12
            },
            "end": {
              "line": 46,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Verpackungseinheit");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element51 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element51,0,0);
          morphs[1] = dom.createMorphAt(element51,2,2);
          return morphs;
        },
        statements: [
          ["content","purchaseProduct.packageQuantity",["loc",[null,[45,17],[45,52]]]],
          ["content","model.altUnit.name",["loc",[null,[45,53],[45,75]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 15
            },
            "end": {
              "line": 50,
              "column": 39
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Ja");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 39
            },
            "end": {
              "line": 50,
              "column": 51
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Nein");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 15
            },
            "end": {
              "line": 53,
              "column": 72
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.licenceNumber",["loc",[null,[52,68],[52,87]]]],["get","-",["loc",[null,[53,68],[53,69]]]]],[],["loc",[null,[52,42],[53,72]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 72
            },
            "end": {
              "line": 54,
              "column": 47
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.individualLicencNumber",["loc",[null,[54,14],[54,42]]]],["get","-",["loc",[null,[54,43],[54,44]]]]],[],["loc",[null,[53,80],[54,47]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 18
                },
                "end": {
                  "line": 66,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label stock-label label-danger");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["content","stock.amount",["loc",[null,[64,22],[64,38]]]],
              ["content","stock.warehouseName",["loc",[null,[65,28],[65,51]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 66,
                    "column": 18
                  },
                  "end": {
                    "line": 68,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/products/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","label stock-label label-warning");
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                return morphs;
              },
              statements: [
                ["content","stock.amount",["loc",[null,[67,67],[67,83]]]],
                ["content","stock.warehouseName",["loc",[null,[67,90],[67,113]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 68,
                      "column": 18
                    },
                    "end": {
                      "line": 70,
                      "column": 18
                    }
                  },
                  "moduleName": "jason-frontend/templates/products/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","label stock-label label-success");
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","stock.amount",["loc",[null,[69,67],[69,83]]]],
                  ["content","stock.warehouseName",["loc",[null,[69,90],[69,113]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 68,
                    "column": 18
                  },
                  "end": {
                    "line": 70,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/products/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","gt",[["get","stock.amount",["loc",[null,[68,32],[68,44]]]],2],[],["loc",[null,[68,28],[68,48]]]]],[],0,null,["loc",[null,[68,18],[70,18]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 66,
                  "column": 18
                },
                "end": {
                  "line": 70,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","lte",[["get","stock.amount",["loc",[null,[66,33],[66,45]]]],2],[],["loc",[null,[66,28],[66,49]]]]],[],0,1,["loc",[null,[66,18],[70,18]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 14
              },
              "end": {
                "line": 72,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","block mb5");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","lte",[["get","stock.amount",["loc",[null,[62,29],[62,41]]]],0],[],["loc",[null,[62,24],[62,44]]]]],[],0,1,["loc",[null,[62,18],[70,25]]]]
          ],
          locals: ["stock"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 12
            },
            "end": {
              "line": 73,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","mb10");
          var el2 = dom.createTextNode("Lagerstand");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","purchaseProduct.stockLevels",["loc",[null,[60,22],[60,49]]]]],[],0,null,["loc",[null,[60,14],[72,23]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 32
              },
              "end": {
                "line": 77,
                "column": 84
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-very-small");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","tag.name",["loc",[null,[77,64],[77,76]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 12
            },
            "end": {
              "line": 78,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","mb10 mt20");
          var el2 = dom.createTextNode("Tags");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","tags");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.systemTags",["loc",[null,[76,40],[76,56]]]]],[],0,null,["loc",[null,[76,32],[77,93]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 86,
              "column": 4
            },
            "end": {
              "line": 88,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","tabs__label");
          dom.setAttribute(el1,"href","#tab-purchase");
          var el2 = dom.createTextNode("Einkauf");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element50 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element50);
          return morphs;
        },
        statements: [
          ["element","action",["toPurchase",["get","model",["loc",[null,[87,74],[87,79]]]]],[],["loc",[null,[87,52],[87,81]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 91,
              "column": 4
            },
            "end": {
              "line": 93,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-treatment");
          var el2 = dom.createTextNode("Behandlung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element49 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element49, 'class');
          morphs[1] = dom.createElementMorph(element49);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[92,55],[92,64]]]],"treatment"],[],["loc",[null,[92,51],[92,77]]]],"is-active",""],[],["loc",[null,[92,30],[92,94]]]]]]],
          ["element","action",["selectTab","treatment"],[],["loc",[null,[92,118],[92,152]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 94,
              "column": 4
            },
            "end": {
              "line": 96,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-future");
          var el2 = dom.createTextNode("Zukunft");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element48 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element48, 'class');
          morphs[1] = dom.createElementMorph(element48);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[95,55],[95,64]]]],"future"],[],["loc",[null,[95,51],[95,74]]]],"is-active",""],[],["loc",[null,[95,30],[95,91]]]]]]],
          ["element","action",["selectTab","future"],[],["loc",[null,[95,112],[95,143]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 98,
              "column": 4
            },
            "end": {
              "line": 101,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-6");
          var el2 = dom.createTextNode("Nachbestellung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-inventory");
          var el2 = dom.createTextNode("Lager");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element46 = dom.childAt(fragment, [1]);
          var element47 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element46, 'class');
          morphs[1] = dom.createElementMorph(element46);
          morphs[2] = dom.createAttrMorph(element47, 'class');
          morphs[3] = dom.createElementMorph(element47);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[99,55],[99,64]]]],"reorder"],[],["loc",[null,[99,51],[99,75]]]],"is-active",""],[],["loc",[null,[99,30],[99,92]]]]]]],
          ["element","action",["selectTab","reorder"],[],["loc",[null,[99,108],[99,140]]]],
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[100,55],[100,64]]]],"inventory"],[],["loc",[null,[100,51],[100,77]]]],"is-active",""],[],["loc",[null,[100,30],[100,94]]]]]]],
          ["element","action",["selectTab","inventory"],[],["loc",[null,[100,118],[100,152]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 102,
              "column": 4
            },
            "end": {
              "line": 104,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-debug");
          var el2 = dom.createTextNode("Debug");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element45 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element45, 'class');
          morphs[1] = dom.createElementMorph(element45);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[103,55],[103,64]]]],"debug"],[],["loc",[null,[103,51],[103,73]]]],"is-active",""],[],["loc",[null,[103,30],[103,90]]]]]]],
          ["element","action",["selectTab","debug"],[],["loc",[null,[103,110],[103,140]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 142,
                "column": 16
              },
              "end": {
                "line": 150,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element41 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element41, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element41, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element41, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[143,69],[143,77]]]]]]],
            ["content","entry.title",["loc",[null,[147,24],[147,39]]]],
            ["content","entry.description",["loc",[null,[148,38],[148,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 159,
                "column": 164
              },
              "end": {
                "line": 160,
                "column": 206
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element40 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(element40,0,0);
            morphs[1] = dom.createMorphAt(element40,2,2);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.introductionReasons",["loc",[null,[160,81],[160,124]]]]],[],["loc",[null,[160,59],[160,127]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[160,128],[160,199]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 160,
                "column": 206
              },
              "end": {
                "line": 161,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[161,28],[161,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 167,
                "column": 155
              },
              "end": {
                "line": 168,
                "column": 185
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]),0,0);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.adminTasks",["loc",[null,[168,69],[168,103]]]]],[],["loc",[null,[168,47],[168,106]]]],
            ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[168,114],[168,185]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 168,
                "column": 185
              },
              "end": {
                "line": 169,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[169,28],[169,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 136,
              "column": 8
            },
            "end": {
              "line": 173,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Anamnese");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableMedicalHistory");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Vorstellungsgründe");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Administrative Tätigkeiten");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element42 = dom.childAt(fragment, [3]);
          var element43 = dom.childAt(element42, [1, 3, 1]);
          var element44 = dom.childAt(element42, [3, 3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createElementMorph(element43);
          morphs[2] = dom.createMorphAt(element43,0,0);
          morphs[3] = dom.createElementMorph(element44);
          morphs[4] = dom.createMorphAt(element44,0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.medicalHistoryEntries",["loc",[null,[142,24],[142,65]]]]],[],0,null,["loc",[null,[142,16],[150,25]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","introductionReason",["get","selectedTreatmentValues.introductionReasons",["loc",[null,[159,97],[159,140]]]],"Vorstellungsgründe"],[],["loc",[null,[159,34],[159,163]]]],
          ["block","if",[["get","selectedTreatmentValues.introductionReasons",["loc",[null,[159,170],[159,213]]]]],[],1,2,["loc",[null,[159,164],[161,106]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","adminTasks",["get","selectedTreatmentValues.adminTasks",["loc",[null,[167,89],[167,123]]]],"Administrative Tätigkeiten"],[],["loc",[null,[167,34],[167,154]]]],
          ["block","if",[["get","selectedTreatmentValues.adminTasks",["loc",[null,[167,161],[167,195]]]]],[],3,4,["loc",[null,[167,155],[169,106]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child20 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 189,
                "column": 16
              },
              "end": {
                "line": 197,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element37 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element37, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element37, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element37, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[190,69],[190,77]]]]]]],
            ["content","entry.title",["loc",[null,[194,24],[194,39]]]],
            ["content","entry.description",["loc",[null,[195,38],[195,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 206,
                "column": 133
              },
              "end": {
                "line": 206,
                "column": 350
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.symptoms",["loc",[null,[206,236],[206,268]]]]],[],["loc",[null,[206,214],[206,271]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[206,279],[206,350]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 206,
                "column": 350
              },
              "end": {
                "line": 207,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[207,28],[207,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 214,
                  "column": 144
                },
                "end": {
                  "line": 214,
                  "column": 363
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","print-list-of-name",[["get","selectedTreatmentValues.diagnosticTests",["loc",[null,[214,242],[214,281]]]]],[],["loc",[null,[214,220],[214,284]]]],
              ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[214,292],[214,363]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 214,
                  "column": 363
                },
                "end": {
                  "line": 215,
                  "column": 101
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    auswählen ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[215,30],[215,101]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 210,
                "column": 12
              },
              "end": {
                "line": 218,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h3");
            var el3 = dom.createTextNode("Diagnostische Untersuchungen");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","pick-list");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","cursor");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element36 = dom.childAt(fragment, [1, 3, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element36);
            morphs[1] = dom.createMorphAt(element36,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["addServiceCategory","un","Diagnostische Untersuchungen",["get","selectedTreatmentValues.diagnosticTests",["loc",[null,[214,102],[214,141]]]]],[],["loc",[null,[214,36],[214,143]]]],
            ["block","if",[["get","selectedTreatmentValues.diagnosticTests",["loc",[null,[214,150],[214,189]]]]],[],0,1,["loc",[null,[214,144],[215,108]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 183,
              "column": 8
            },
            "end": {
              "line": 220,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Symptome");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableSymptoms");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Klinische Symptomatik");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element38 = dom.childAt(fragment, [3]);
          var element39 = dom.childAt(element38, [1, 3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createElementMorph(element39);
          morphs[2] = dom.createMorphAt(element39,0,0);
          morphs[3] = dom.createMorphAt(element38,3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.symptomsEntries",["loc",[null,[189,24],[189,59]]]]],[],0,null,["loc",[null,[189,16],[197,25]]]],
          ["element","action",["selectTreatmentDetailInfoMulti","symptoms",["get","selectedTreatmentValues.symptoms",["loc",[null,[206,87],[206,119]]]],"Symptome"],[],["loc",[null,[206,34],[206,132]]]],
          ["block","if",[["get","selectedTreatmentValues.symptoms",["loc",[null,[206,139],[206,171]]]]],[],1,2,["loc",[null,[206,133],[207,106]]]],
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[210,18],[210,26]]]]],[],3,null,["loc",[null,[210,12],[218,19]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child21 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 237,
                "column": 16
              },
              "end": {
                "line": 245,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element32 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element32, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element32, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element32, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[238,69],[238,77]]]]]]],
            ["content","entry.title",["loc",[null,[242,24],[242,39]]]],
            ["content","entry.description",["loc",[null,[243,38],[243,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 254,
                "column": 153
              },
              "end": {
                "line": 254,
                "column": 386
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.digitalDiagnoses",["loc",[null,[254,264],[254,304]]]]],[],["loc",[null,[254,242],[254,307]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[254,315],[254,386]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 254,
                "column": 386
              },
              "end": {
                "line": 255,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[255,28],[255,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 261,
                "column": 161
              },
              "end": {
                "line": 261,
                "column": 404
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","is-highlighted-alternative");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [0]),0,0);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","print-list-of-name",[["get","selectedTreatmentValues.finalDigitalDiagnoses",["loc",[null,[261,277],[261,322]]]]],[],["loc",[null,[261,255],[261,325]]]],
            ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[261,333],[261,404]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 261,
                "column": 404
              },
              "end": {
                "line": 262,
                "column": 99
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                  auswählen ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[262,28],[262,99]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 232,
              "column": 8
            },
            "end": {
              "line": 266,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Diagnose");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableDiagnoses");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Verdachtsdiagnosen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24@phone l-12/24@tablet l-12/24@desk gutter");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Finale Diagnosen");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","pick-list");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4,"class","cursor");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element33 = dom.childAt(fragment, [3]);
          var element34 = dom.childAt(element33, [1, 3, 1]);
          var element35 = dom.childAt(element33, [3, 3, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createElementMorph(element34);
          morphs[2] = dom.createMorphAt(element34,0,0);
          morphs[3] = dom.createElementMorph(element35);
          morphs[4] = dom.createMorphAt(element35,0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.diagnosesEntries",["loc",[null,[237,24],[237,60]]]]],[],0,null,["loc",[null,[237,16],[245,25]]]],
          ["element","action",["selectMultistageTreatmentDetail","diagnoses",["get","selectedTreatmentValues.digitalDiagnoses",["loc",[null,[254,89],[254,129]]]],"Verdachtsdiagnosen"],[],["loc",[null,[254,34],[254,152]]]],
          ["block","if",[["get","selectedTreatmentValues.digitalDiagnoses",["loc",[null,[254,159],[254,199]]]]],[],1,2,["loc",[null,[254,153],[255,106]]]],
          ["element","action",["selectMultistageTreatmentDetail","finalDiagnoses",["get","selectedTreatmentValues.finalDigitalDiagnoses",["loc",[null,[261,94],[261,139]]]],"Finale Diagnosen"],[],["loc",[null,[261,34],[261,160]]]],
          ["block","if",[["get","selectedTreatmentValues.finalDigitalDiagnoses",["loc",[null,[261,167],[261,212]]]]],[],3,4,["loc",[null,[261,161],[262,106]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child22 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 283,
                "column": 16
              },
              "end": {
                "line": 291,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element31 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element31, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element31, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element31, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[284,69],[284,77]]]]]]],
            ["content","entry.title",["loc",[null,[288,24],[288,39]]]],
            ["content","entry.description",["loc",[null,[289,38],[289,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 300,
                  "column": 144
                },
                "end": {
                  "line": 301,
                  "column": 191
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","is-highlighted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","print-list-of-name",[["get","selectedTreatmentValues.digitalTherapies",["loc",[null,[301,70],[301,110]]]]],[],["loc",[null,[301,49],[301,112]]]],
              ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[301,120],[301,191]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 301,
                  "column": 191
                },
                "end": {
                  "line": 302,
                  "column": 101
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                    auswählen ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[302,30],[302,101]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 295,
                "column": 10
              },
              "end": {
                "line": 306,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","grid");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-24/24@phone l-24/24@tablet l-8/24@desk gutter");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h3");
            var el4 = dom.createTextNode("Therapeutische Behandlungen");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","pick-list");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            dom.setAttribute(el4,"class","cursor");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element30 = dom.childAt(fragment, [1, 1, 3, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element30);
            morphs[1] = dom.createMorphAt(element30,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["addServiceCategory","tb","Therapeutische Behandlungen",["get","selectedTreatmentValues.digitalTherapies",["loc",[null,[300,101],[300,141]]]]],[],["loc",[null,[300,36],[300,143]]]],
            ["block","if",[["get","selectedTreatmentValues.digitalTherapies",["loc",[null,[300,150],[300,190]]]]],[],0,1,["loc",[null,[300,144],[302,108]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 278,
              "column": 8
            },
            "end": {
              "line": 307,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Therapie");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableTherapies");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.therapiesEntries",["loc",[null,[283,24],[283,60]]]]],[],0,null,["loc",[null,[283,16],[291,25]]]],
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[295,16],[295,24]]]]],[],1,null,["loc",[null,[295,10],[306,17]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child23 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 324,
                "column": 16
              },
              "end": {
                "line": 332,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","sortable-item fixed");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","delete");
            dom.setAttribute(el2,"onclick","this.parentNode.remove()");
            var el3 = dom.createTextNode("\n                ×\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","sub");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element29 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element29, 'data-module-id');
            morphs[1] = dom.createMorphAt(dom.childAt(element29, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element29, [5]),0,0);
            return morphs;
          },
          statements: [
            ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[325,69],[325,77]]]]]]],
            ["content","entry.title",["loc",[null,[329,24],[329,39]]]],
            ["content","entry.description",["loc",[null,[330,38],[330,59]]]]
          ],
          locals: ["entry"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 319,
              "column": 8
            },
            "end": {
              "line": 336,
              "column": 8
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid wizzard equal");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-24/24 main");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("Beratung");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","card sortable");
          dom.setAttribute(el3,"id","sortableReports");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","selectedTextModules.reportEntries",["loc",[null,[324,24],[324,57]]]]],[],0,null,["loc",[null,[324,16],[332,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child24 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 345,
                "column": 16
              },
              "end": {
                "line": 347,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.slopingInfo",["loc",[null,[346,24],[346,45]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 347,
                "column": 16
              },
              "end": {
                "line": 349,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Derzeit keine Hersteller Dosierungsinformationen hinterlegt");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 341,
              "column": 12
            },
            "end": {
              "line": 351,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          dom.setAttribute(el1,"class","mb20");
          var el2 = dom.createTextNode("Hersteller Dosierungsinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col l-24/24 gutter");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.slopingInfo",["loc",[null,[345,22],[345,39]]]]],[],0,1,["loc",[null,[345,16],[349,23]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child25 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 378,
                "column": 58
              },
              "end": {
                "line": 379,
                "column": 57
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                        , ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","entry.patientCategory2.name",["loc",[null,[379,26],[379,57]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 379,
                "column": 64
              },
              "end": {
                "line": 380,
                "column": 57
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n                        , ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","entry.patientCategory3.name",["loc",[null,[380,26],[380,57]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 387,
                  "column": 68
                },
                "end": {
                  "line": 388,
                  "column": 41
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                        , Dauermedikation");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 387,
                "column": 28
              },
              "end": {
                "line": 388,
                "column": 48
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","entry.override",["loc",[null,[387,50],[387,68]]]],
            ["block","if",[["get","entry.infinity",["loc",[null,[387,74],[387,88]]]]],[],0,null,["loc",[null,[387,68],[388,48]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 389,
                  "column": 61
                },
                "end": {
                  "line": 390,
                  "column": 52
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                          über ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" Tag(e)");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["content","entry.days",["loc",[null,[390,31],[390,45]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 390,
                  "column": 52
                },
                "end": {
                  "line": 390,
                  "column": 75
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Dauermedikation");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 388,
                "column": 48
              },
              "end": {
                "line": 390,
                "column": 86
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                        , ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" x täglich, ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,6,6,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","entry.unitsPerDay",["loc",[null,[388,56],[388,77]]]],
            ["content","entry.serviceunit.name",["loc",[null,[388,78],[388,104]]]],
            ["content","entry.dosingsPerDay",["loc",[null,[389,26],[389,49]]]],
            ["block","unless",[["get","entry.infinity",["loc",[null,[389,71],[389,85]]]]],[],0,1,["loc",[null,[389,61],[390,86]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 391,
                "column": 22
              },
              "end": {
                "line": 391,
                "column": 112
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","small block");
            var el2 = dom.createElement("b");
            var el3 = dom.createTextNode("Interne Notiz:");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
            return morphs;
          },
          statements: [
            ["content","entry.notice",["loc",[null,[391,89],[391,105]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 375,
              "column": 16
            },
            "end": {
              "line": 407,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-24/24@phone l-24/24@tablet l-8/24@desk");
          dom.setAttribute(el2,"data-label","Spezies");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-12/24@phone l-12/24@tablet l-6/24@desk text-center");
          dom.setAttribute(el2,"data-label","Gewichtsklasse von/bis");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" - ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" kg");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-23/24@phone l-23/24@tablet l-6/24@desk");
          dom.setAttribute(el2,"data-label","Anwendung");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24 table__cell--action");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","cursor ml10");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/edit.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"style","margin-bottom: 2px");
          dom.setAttribute(el3,"class","mr10 cursor");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4,"src","assets/images/icons/rework/barcode.svg");
          dom.setAttribute(el4,"class","tooltipstered");
          dom.setAttribute(el4,"title","Dosierungsetikett herunterladen");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element22 = dom.childAt(fragment, [1]);
          var element23 = dom.childAt(element22, [1, 1]);
          var element24 = dom.childAt(element22, [3, 1]);
          var element25 = dom.childAt(element22, [5]);
          var element26 = dom.childAt(element22, [7]);
          var element27 = dom.childAt(element26, [3]);
          var element28 = dom.childAt(element26, [5]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(element23,0,0);
          morphs[1] = dom.createMorphAt(element23,1,1);
          morphs[2] = dom.createMorphAt(element23,2,2);
          morphs[3] = dom.createMorphAt(element24,0,0);
          morphs[4] = dom.createMorphAt(element24,2,2);
          morphs[5] = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
          morphs[6] = dom.createMorphAt(element25,3,3);
          morphs[7] = dom.createMorphAt(dom.childAt(element26, [1]),1,1);
          morphs[8] = dom.createElementMorph(element27);
          morphs[9] = dom.createElementMorph(element28);
          return morphs;
        },
        statements: [
          ["content","entry.patientCategory.name",["loc",[null,[378,28],[378,58]]]],
          ["block","if",[["get","entry.patientCategory2",["loc",[null,[378,64],[378,86]]]]],[],0,null,["loc",[null,[378,58],[379,64]]]],
          ["block","if",[["get","entry.patientCategory3",["loc",[null,[379,70],[379,92]]]]],[],1,null,["loc",[null,[379,64],[380,64]]]],
          ["content","entry.weightFrom",["loc",[null,[384,28],[384,48]]]],
          ["content","entry.weightTo",["loc",[null,[384,51],[384,69]]]],
          ["block","if",[["get","entry.override",["loc",[null,[387,34],[387,48]]]]],[],2,3,["loc",[null,[387,28],[390,93]]]],
          ["block","if",[["get","entry.notice",["loc",[null,[391,28],[391,40]]]]],[],4,null,["loc",[null,[391,22],[391,119]]]],
          ["inline","button-delete-with-confirm",[],["size","32","item",["subexpr","@mut",[["get","entry",["loc",[null,[395,68],[395,73]]]]],[],[]],"confirmed","deleteSlopping","text","Wollen Sie diese Dosierungsinformation wirklich enternen?","content","Dosieerungsinformation entfernen"],["loc",[null,[395,24],[397,97]]]],
          ["element","action",["editSloping",["get","entry",["loc",[null,[399,68],[399,73]]]]],[],["loc",[null,[399,45],[399,75]]]],
          ["element","action",["openBarcodeSelection",["get","entry.id",["loc",[null,[402,104],[402,112]]]]],[],["loc",[null,[402,72],[402,114]]]]
        ],
        locals: ["entry"],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 423,
              "column": 78
            },
            "end": {
              "line": 423,
              "column": 173
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Automatische Nachbestellung bei");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child27 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 424,
              "column": 78
            },
            "end": {
              "line": 424,
              "column": 160
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Nachbestellung von");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child28 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 431,
                "column": 26
              },
              "end": {
                "line": 431,
                "column": 77
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","reorder.warehouse.name",["loc",[null,[431,51],[431,77]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 431,
                "column": 77
              },
              "end": {
                "line": 431,
                "column": 95
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Alle Lager");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 451,
                "column": 20
              },
              "end": {
                "line": 465,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","field number-input");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","product-reorderMinimalServiceunit");
            dom.setAttribute(el1,"class","select--inline input-element");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.reorderMinimalAmount",["loc",[null,[453,52],[453,80]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.reorderSpinnerId",["loc",[null,[453,84],[453,108]]]]],[],[]]],["loc",[null,[453,22],[453,110]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","matchedUnits",["loc",[null,[456,30],[456,42]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.reorderMinimalServiceunit",["loc",[null,[457,28],[457,61]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Einheit wählen","name","product-reorderMinimalServiceunit"],["loc",[null,[455,20],[463,22]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 468,
                "column": 16
              },
              "end": {
                "line": 485,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","field number-input");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","product-priceLine");
            dom.setAttribute(el1,"class","select--inline input-element");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","block mtm5");
            var el2 = dom.createTextNode("\n                      Einzelpreis: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("b");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(", Gesamtpreis: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("b");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [5]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
            morphs[3] = dom.createMorphAt(dom.childAt(element19, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.reorderAmount",["loc",[null,[470,52],[470,73]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.amountSpinnerId",["loc",[null,[470,77],[470,100]]]]],[],[]]],["loc",[null,[470,22],[470,102]]]],
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","purchaseProduct.reorderPurchaseSupplierInfos",["loc",[null,[473,30],[473,74]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.reorderPurchaseSupplierInfo",["loc",[null,[474,28],[474,63]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","title","allowClear",true,"placeholder","Lieferant & VE wählen","name","product-priceLine"],["loc",[null,[472,20],[480,22]]]],
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","reorder.reorderPurchaseSupplierInfo.jasonPrice",["loc",[null,[483,62],[483,108]]]]],[],[]],"classNames","inline"],["loc",[null,[483,38],[483,130]]]],
            ["inline","format-currency",[],["classNames","inline","value",["subexpr","multiply",[["get","reorder.reorderPurchaseSupplierInfo.jasonPrice",["loc",[null,[483,206],[483,252]]]],["get","reorder.reorderAmount",["loc",[null,[483,253],[483,274]]]]],[],["loc",[null,[483,196],[483,275]]]]],["loc",[null,[483,152],[483,277]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 488,
                "column": 20
              },
              "end": {
                "line": 493,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","switch switch-info round switch-inline mt5");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"checked","checked");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var element17 = dom.childAt(element16, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element17,[],true); }
            var element18 = dom.childAt(element16, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element17, 'id');
            morphs[1] = dom.createElementMorph(element17);
            morphs[2] = dom.createAttrMorph(element18, 'for');
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[490,49],[490,72]]]]]]],
            ["element","action",["setReorderActive",["get","reorder",["loc",[null,[490,138],[490,145]]]]],["on","change"],["loc",[null,[490,110],[490,159]]]],
            ["attribute","for",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[491,50],[491,73]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 493,
                "column": 20
              },
              "end": {
                "line": 498,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","switch switch-info round switch-inline mt5");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var element14 = dom.childAt(element13, [1]);
            var element15 = dom.childAt(element13, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element14, 'id');
            morphs[1] = dom.createElementMorph(element14);
            morphs[2] = dom.createAttrMorph(element15, 'for');
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[495,49],[495,72]]]]]]],
            ["element","action",["setReorderActive",["get","reorder",["loc",[null,[495,120],[495,127]]]]],["on","change"],["loc",[null,[495,92],[495,141]]]],
            ["attribute","for",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[496,50],[496,73]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 428,
              "column": 14
            },
            "end": {
              "line": 501,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24@desk");
          dom.setAttribute(el2,"data-label","Lager");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24@desk");
          dom.setAttribute(el2,"data-label","Benachrichtigung");
          var el3 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field number-input");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","product-infoMinimalServiceunit");
          dom.setAttribute(el3,"class","select--inline input-element");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24@desk");
          dom.setAttribute(el2,"data-label","Automatisch Nachbestellen bei");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-7/24@desk pb5");
          dom.setAttribute(el2,"data-label","Nachbestellen von");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-2/24@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [1]);
          var element21 = dom.childAt(element20, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element20, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element20, [1, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element21, [1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element21, [3]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element20, [5]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element20, [7]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element20, [9]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__row ",["subexpr","css-bool-evaluator",[["get","reorder.deleted",["loc",[null,[429,60],[429,75]]]],"hidden",""],[],["loc",[null,[429,39],[429,89]]]]]]],
          ["block","if",[["get","reorder.warehouse",["loc",[null,[431,32],[431,49]]]]],[],0,1,["loc",[null,[431,26],[431,102]]]],
          ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.infoMinimalAmount",["loc",[null,[436,52],[436,77]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.infoSpinnerId",["loc",[null,[436,81],[436,102]]]]],[],[]]],["loc",[null,[436,22],[436,104]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","matchedUnits",["loc",[null,[440,32],[440,44]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.infoMinimalServiceunit",["loc",[null,[441,30],[441,60]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Einheit wählen","name","product-infoMinimalServiceunit"],["loc",[null,[439,22],[447,24]]]],
          ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[451,31],[451,58]]]],["get","model.purchaseRelevant",["loc",[null,[451,59],[451,81]]]]],[],["loc",[null,[451,26],[451,82]]]]],[],2,null,["loc",[null,[451,20],[465,25]]]],
          ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[468,27],[468,54]]]],["get","model.purchaseRelevant",["loc",[null,[468,55],[468,77]]]]],[],["loc",[null,[468,22],[468,78]]]]],[],3,null,["loc",[null,[468,16],[485,23]]]],
          ["block","if",[["get","reorder.active",["loc",[null,[488,26],[488,40]]]]],[],4,5,["loc",[null,[488,20],[498,27]]]]
        ],
        locals: ["reorder"],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }());
    var child29 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 506,
              "column": 0
            },
            "end": {
              "line": 524,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Automatische Nachbestellung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col mt10 l-12/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","checkbox-custom checkbox-primary field-checkbox");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","productAutosendReorder");
          dom.setAttribute(el3,"for","product-autosendReorder");
          var el4 = dom.createTextNode("Warenkorb automatisch abschicken");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col block mt20 l-12/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2,"class","textarea textarea--inline clearfix");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","title");
          var el4 = dom.createTextNode("Nachbestellungs-Notiz");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n                   ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","textarea__body input--full-width pl0");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [5, 1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","input",[],["type","checkbox","id","product-autosendReorder","name","product.autosendReorder","checked",["subexpr","@mut",[["get","purchaseProduct.autosendReorder",["loc",[null,[511,104],[511,135]]]]],[],[]]],["loc",[null,[511,12],[511,137]]]],
          ["inline","textarea",[],["rows","3","name","orderNotice","value",["subexpr","@mut",[["get","purchaseProduct.orderNotice",["loc",[null,[520,57],[520,84]]]]],[],[]],"class","input textarea__input full","placeholder",""],["loc",[null,[520,12],[520,136]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child30 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 560,
              "column": 10
            },
            "end": {
              "line": 563,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (EU): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberEu",["loc",[null,[562,15],[562,40]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child31 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 564,
              "column": 10
            },
            "end": {
              "line": 567,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (Ages): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberAges",["loc",[null,[566,15],[566,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child32 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 568,
              "column": 10
            },
            "end": {
              "line": 571,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (Apothekerverlag): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberApo",["loc",[null,[570,15],[570,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child33 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 572,
              "column": 10
            },
            "end": {
              "line": 575,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Dosierungsinformationen / Analytische Bestandteile");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link",[["get","model.slopingInfo",["loc",[null,[574,23],[574,40]]]],"Link"],[],["loc",[null,[574,15],[574,50]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child34 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 579,
                "column": 19
              },
              "end": {
                "line": 579,
                "column": 134
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("li");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [0]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(element12,0,0);
            morphs[1] = dom.createMorphAt(element12,2,2);
            morphs[2] = dom.createMorphAt(element12,4,4);
            return morphs;
          },
          statements: [
            ["content","substance.name",["loc",[null,[579,64],[579,82]]]],
            ["content","substance.concentration",["loc",[null,[579,83],[579,110]]]],
            ["content","substance.unit",["loc",[null,[579,111],[579,129]]]]
          ],
          locals: ["substance"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 577,
              "column": 10
            },
            "end": {
              "line": 580,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Wirkstoffe: ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("ul");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 0]),0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.substances",["loc",[null,[579,27],[579,43]]]]],[],0,null,["loc",[null,[579,19],[579,143]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child35 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 582,
              "column": 10
            },
            "end": {
              "line": 585,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Fachinformationen / Zusammensetzung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link",[["get","model.domainInfo",["loc",[null,[584,23],[584,39]]]],"Link",["get","model.supplier.id",["loc",[null,[584,47],[584,64]]]]],[],["loc",[null,[584,15],[584,67]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child36 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 587,
              "column": 10
            },
            "end": {
              "line": 590,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Infos");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element11,0,0);
          morphs[1] = dom.createUnsafeMorphAt(element11,1,1);
          return morphs;
        },
        statements: [
          ["content","model.info",["loc",[null,[589,15],[589,29]]]],
          ["inline","link",[["get","model.detailUrl",["loc",[null,[589,37],[589,52]]]],"Download"],[],["loc",[null,[589,29],[589,66]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child37 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 591,
              "column": 10
            },
            "end": {
              "line": 597,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Fachinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          dom.setAttribute(el2,"class","link2");
          var el3 = dom.createTextNode("Download");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Gebrauchsinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          dom.setAttribute(el2,"class","link2");
          var el3 = dom.createTextNode("Download");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [3, 0]);
          var element10 = dom.childAt(fragment, [7, 0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element9, 'href');
          morphs[1] = dom.createAttrMorph(element10, 'href');
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://aspregister.basg.gv.at/document/servlet?action=show&zulnr=",["get","model.licenceNumber",["loc",[null,[593,92],[593,111]]]],"&type=DOTC_FACH_INFO"]]],
          ["attribute","href",["concat",["https://aspregister.basg.gv.at/document/servlet?action=show&zulnr=",["get","model.licenceNumber",["loc",[null,[596,92],[596,111]]]],"&type=DOTC_GEBR_INFO"]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child38 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 599,
              "column": 10
            },
            "end": {
              "line": 602,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zielspezies");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.targetRace",["loc",[null,[601,15],[601,35]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child39 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 613,
              "column": 11
            },
            "end": {
              "line": 613,
              "column": 102
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/icon-check.svg");
          dom.setAttribute(el1,"style","height: 24px");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child40 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 613,
              "column": 102
            },
            "end": {
              "line": 613,
              "column": 111
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("-");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child41 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 613,
              "column": 119
            },
            "end": {
              "line": 613,
              "column": 172
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("(case ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(")");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","model.richterCase",["loc",[null,[613,150],[613,171]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child42 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 641,
                  "column": 8
                },
                "end": {
                  "line": 649,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","sortable-item draggable");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","delete");
              dom.setAttribute(el2,"onclick","this.parentNode.remove()");
              var el3 = dom.createTextNode("\n                ×\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h2");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2,"class","sub");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element4, 'data-module-id');
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element4, [5]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","data-module-id",["concat",[["get","entry.id",["loc",[null,[642,65],[642,73]]]]]]],
              ["content","entry.title",["loc",[null,[646,16],[646,31]]]],
              ["content","entry.description",["loc",[null,[647,30],[647,51]]]]
            ],
            locals: ["entry"],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 639,
                "column": 4
              },
              "end": {
                "line": 654,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","box scrollable");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","box");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","block small is-highlighted-alternative");
            var el3 = dom.createElement("b");
            var el4 = dom.createTextNode("Tipp:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("Ziehen Sie die Textbausteine an die gewünschte Stelle");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","allItems",["loc",[null,[641,16],[641,24]]]]],[],0,null,["loc",[null,[641,8],[649,17]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 656,
                  "column": 8
                },
                "end": {
                  "line": 657,
                  "column": 49
                }
              },
              "moduleName": "jason-frontend/templates/products/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          Derzeit keine Textbausteine hinterlegt.");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 654,
                "column": 4
              },
              "end": {
                "line": 659,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","box");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","link-to",["templates",["subexpr","query-params",[],["selectTab","textmodules"],["loc",[null,[656,31],[656,69]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[656,8],[657,61]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 620,
              "column": 0
            },
            "end": {
              "line": 662,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","editorSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","inner wizzard");
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h2");
          dom.setAttribute(el3,"class","ml10 mb10");
          var el4 = dom.createTextNode("Textbausteine");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","box");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("form");
          dom.setAttribute(el4,"class","search-box");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"for","search");
          dom.setAttribute(el5,"class","search-box");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("button");
          dom.setAttribute(el6,"class","input search__button");
          dom.setAttribute(el6,"type","submit");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/search-icon-white.svg");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element5, [3]);
          var element8 = dom.childAt(element7, [3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createElementMorph(element8);
          morphs[2] = dom.createMorphAt(dom.childAt(element8, [1]),3,3);
          morphs[3] = dom.createMorphAt(element7,5,5);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[622,9],[622,40]]]],
          ["element","action",["searchTextModules"],["on","submit"],["loc",[null,[629,33],[629,75]]]],
          ["inline","input",[],["name","search","value",["subexpr","@mut",[["get","filterName",["loc",[null,[634,40],[634,50]]]]],[],[]],"class","gui-input input input--search search__input","placeholder","Textbaustein suchen","autocomplete","off"],["loc",[null,[634,12],[634,157]]]],
          ["block","if",[["get","allItems",["loc",[null,[639,10],[639,18]]]]],[],0,1,["loc",[null,[639,4],[659,11]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child43 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 663,
                "column": 2
              },
              "end": {
                "line": 667,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[665,63],[665,198]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 662,
              "column": 0
            },
            "end": {
              "line": 668,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[663,12],[663,21]]]],"treatment"],[],["loc",[null,[663,8],[663,34]]]]],[],0,null,["loc",[null,[663,2],[667,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child44 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 672,
              "column": 2
            },
            "end": {
              "line": 676,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          dom.setAttribute(el1,"id","add-price");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","button-add-entry",[],["content","Weitere Verkaufseinheit hinzufügen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[674,6],[674,114]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child45 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 677,
              "column": 2
            },
            "end": {
              "line": 681,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element3);
          morphs[1] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["addSloping"],[],["loc",[null,[678,27],[678,50]]]],
          ["inline","button-add-entry",[],["content","Weitere Dosierungsinformation hinzufügen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[679,6],[679,120]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child46 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 682,
              "column": 2
            },
            "end": {
              "line": 686,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [
          ["element","action",["saveReorder",["get","model",["loc",[null,[683,71],[683,76]]]]],[],["loc",[null,[683,48],[683,78]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child47 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 686,
                "column": 2
              },
              "end": {
                "line": 690,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--success");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["element","action",["saveTextModules"],[],["loc",[null,[687,48],[687,76]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 690,
                "column": 2
              },
              "end": {
                "line": 694,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/products/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","icon-button icon-button--success input");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [
            ["element","action",["triggerSubmit"],[],["loc",[null,[691,7],[691,33]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 686,
              "column": 2
            },
            "end": {
              "line": 694,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/products/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[686,16],[686,25]]]],"treatment"],[],["loc",[null,[686,12],[686,38]]]]],[],0,1,["loc",[null,[686,2],[694,2]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 705,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/products/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Produkt bearbeiten");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/qr.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("QR-Code drucken");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","productEditPage");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","card mb-0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-8/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","u-text--center mt10 pr30");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("\n     ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-16/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4,"class","headline");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","sub-headline");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","product-description");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","dataList grid");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Verpackungsmenge");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Kategorie");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","");
        var el8 = dom.createTextNode("Suchtgiftmittel");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","");
        var el8 = dom.createTextNode("Zulassungsnummer");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("p");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","rework-content tabs-rework-wrapper grid");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-master-data");
        var el4 = dom.createTextNode("Verkauf");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-add-data");
        var el4 = dom.createTextNode("Artikelinformationen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","#tab-sloping");
        var el4 = dom.createTextNode("Dosierung");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body mt20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-master-data");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"id","productEdit");
        dom.setAttribute(el5,"class","admin-form");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-future");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-treatment");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5,"class","is-highlighted-alternative");
        var el6 = dom.createTextNode("Die ausgewählten Textbausteine werden beim Hinzufügen zu einer Behandlung automatisch ergänzt");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Anamnese");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchAnamnese");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Symptome");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchSymptoms");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Diagnosen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchDiagnose");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Therapien");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchTherapy");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Beratung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","header-toggle");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","switchReport");
        dom.setAttribute(el8,"class","switch");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-sloping");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5,"id","productSlopginEdit");
        dom.setAttribute(el5,"class","admin-form");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card--no-padding");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createTextNode("Dosierung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","grid gutter-fix");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","form-field-rework");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","textarea-wrapper");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"class","placeholder");
        dom.setAttribute(el10,"for","product-doseMethod");
        var el11 = dom.createTextNode("Verabreichungsmethode");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        dom.setAttribute(el7,"class","mb20 mt20");
        var el8 = dom.createTextNode("Meine Dosierungsinformationen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table-wrapper-rework");
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table table--large");
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__head");
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-8/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Spezies");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title text-center");
        var el12 = dom.createTextNode("Gewichtsklasse von/bis");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-6/24 u-text--left");
        var el11 = dom.createElement("span");
        dom.setAttribute(el11,"class","table__title title");
        var el12 = dom.createTextNode("Anwendung");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10,"class","table__cell l-3/24");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__body");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("              ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-6");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Nachbestellungs-Regeln");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table-wrapper mb40");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table mt20 table--ve  table--small");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__head");
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-3/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Lager");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-6/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Benachrichtigung");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode(" für Nachbestellungen bei");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-6/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-7/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n          ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell  table__cell--right l-2/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Aktiv");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__body");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-inventory");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","u-mb0 mb20");
        var el6 = dom.createTextNode("Lagerverwaltung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","card--no-padding");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","card__body clearfix");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","col l-24/24");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field mb0");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productEhapo");
        dom.setAttribute(el9,"class","mt30 field-label title");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","checkbox-custom checkbox-primary field-checkbox");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","productEhapo");
        var el11 = dom.createTextNode("Lagerverwaltung für dieses Produkt aktivieren");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","field mb0");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9,"for","productAutoBooking");
        dom.setAttribute(el9,"class","mt30 field-label title");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","checkbox-custom checkbox-primary field-checkbox");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("label");
        dom.setAttribute(el10,"for","productAutoBooking");
        var el11 = dom.createTextNode("Automatisch aus ältester Lagereinheit ausbuchen");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mt30 mb20");
        var el6 = dom.createTextNode("Lagerbestände");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-add-data");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","dataList");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-debug");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Aktualisiert");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","mt20");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Datenlieferant");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"class","");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("RPA-Migration");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","buttons action-icons clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        dom.setAttribute(el2,"onclick","history.back()");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element54 = dom.childAt(fragment, [0]);
        var element55 = dom.childAt(element54, [1]);
        var element56 = dom.childAt(element55, [3]);
        var element57 = dom.childAt(element56, [1]);
        var element58 = dom.childAt(fragment, [2]);
        var element59 = dom.childAt(element58, [1]);
        var element60 = dom.childAt(element59, [3]);
        var element61 = dom.childAt(element60, [1]);
        var element62 = dom.childAt(element60, [3]);
        var element63 = dom.childAt(element60, [5]);
        var element64 = dom.childAt(element63, [3]);
        var element65 = dom.childAt(element64, [1]);
        var element66 = dom.childAt(element65, [3]);
        var element67 = dom.childAt(element64, [3]);
        var element68 = dom.childAt(element58, [3]);
        var element69 = dom.childAt(element68, [3]);
        var element70 = dom.childAt(element68, [5]);
        var element71 = dom.childAt(element68, [10]);
        var element72 = dom.childAt(element58, [5, 1]);
        var element73 = dom.childAt(element72, [1]);
        var element74 = dom.childAt(element73, [1]);
        var element75 = dom.childAt(element72, [3]);
        var element76 = dom.childAt(element72, [5]);
        var element77 = dom.childAt(element72, [7]);
        var element78 = dom.childAt(element77, [1, 1]);
        var element79 = dom.childAt(element72, [9]);
        var element80 = dom.childAt(element79, [3, 1]);
        var element81 = dom.childAt(element80, [1]);
        var element82 = dom.childAt(element72, [11]);
        var element83 = dom.childAt(element82, [3, 1]);
        var element84 = dom.childAt(element83, [3]);
        var element85 = dom.childAt(element72, [13]);
        var element86 = dom.childAt(element85, [3]);
        var element87 = dom.childAt(element72, [15]);
        var element88 = dom.childAt(element87, [7]);
        var element89 = dom.childAt(element87, [11]);
        var element90 = dom.childAt(fragment, [6]);
        var morphs = new Array(88);
        morphs[0] = dom.createAttrMorph(element54, 'class');
        morphs[1] = dom.createAttrMorph(element55, 'class');
        morphs[2] = dom.createElementMorph(element57);
        morphs[3] = dom.createMorphAt(element56,3,3);
        morphs[4] = dom.createAttrMorph(element58, 'class');
        morphs[5] = dom.createMorphAt(dom.childAt(element59, [1, 1]),1,1);
        morphs[6] = dom.createMorphAt(element61,0,0);
        morphs[7] = dom.createMorphAt(element61,2,2);
        morphs[8] = dom.createMorphAt(element62,0,0);
        morphs[9] = dom.createMorphAt(element62,2,2);
        morphs[10] = dom.createMorphAt(element62,4,4);
        morphs[11] = dom.createUnsafeMorphAt(dom.childAt(element63, [1]),0,0);
        morphs[12] = dom.createMorphAt(element66,0,0);
        morphs[13] = dom.createMorphAt(element66,2,2);
        morphs[14] = dom.createMorphAt(element65,5,5);
        morphs[15] = dom.createMorphAt(dom.childAt(element65, [9]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element65, [13]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element65, [17]),0,0);
        morphs[18] = dom.createMorphAt(element67,1,1);
        morphs[19] = dom.createMorphAt(element67,2,2);
        morphs[20] = dom.createMorphAt(element68,1,1);
        morphs[21] = dom.createAttrMorph(element69, 'class');
        morphs[22] = dom.createElementMorph(element69);
        morphs[23] = dom.createAttrMorph(element70, 'class');
        morphs[24] = dom.createElementMorph(element70);
        morphs[25] = dom.createMorphAt(element68,7,7);
        morphs[26] = dom.createMorphAt(element68,8,8);
        morphs[27] = dom.createAttrMorph(element71, 'class');
        morphs[28] = dom.createElementMorph(element71);
        morphs[29] = dom.createMorphAt(element68,12,12);
        morphs[30] = dom.createMorphAt(element68,13,13);
        morphs[31] = dom.createAttrMorph(element73, 'class');
        morphs[32] = dom.createElementMorph(element74);
        morphs[33] = dom.createMorphAt(element74,1,1);
        morphs[34] = dom.createAttrMorph(element75, 'class');
        morphs[35] = dom.createMorphAt(element75,1,1);
        morphs[36] = dom.createAttrMorph(element76, 'class');
        morphs[37] = dom.createMorphAt(dom.childAt(element76, [3, 1, 3]),1,1);
        morphs[38] = dom.createMorphAt(element76,5,5);
        morphs[39] = dom.createMorphAt(dom.childAt(element76, [7, 1, 3]),1,1);
        morphs[40] = dom.createMorphAt(element76,9,9);
        morphs[41] = dom.createMorphAt(dom.childAt(element76, [11, 1, 3]),1,1);
        morphs[42] = dom.createMorphAt(element76,13,13);
        morphs[43] = dom.createMorphAt(dom.childAt(element76, [15, 1, 3]),1,1);
        morphs[44] = dom.createMorphAt(element76,17,17);
        morphs[45] = dom.createMorphAt(dom.childAt(element76, [19, 1, 3]),1,1);
        morphs[46] = dom.createMorphAt(element76,21,21);
        morphs[47] = dom.createAttrMorph(element77, 'class');
        morphs[48] = dom.createMorphAt(element78,1,1);
        morphs[49] = dom.createMorphAt(dom.childAt(element78, [5, 1, 1]),1,1);
        morphs[50] = dom.createMorphAt(dom.childAt(element78, [9, 1, 3]),1,1);
        morphs[51] = dom.createAttrMorph(element79, 'class');
        morphs[52] = dom.createMorphAt(dom.childAt(element81, [5, 0]),0,0);
        morphs[53] = dom.createMorphAt(dom.childAt(element81, [7, 0]),0,0);
        morphs[54] = dom.createMorphAt(dom.childAt(element80, [3]),1,1);
        morphs[55] = dom.createMorphAt(element79,5,5);
        morphs[56] = dom.createAttrMorph(element82, 'class');
        morphs[57] = dom.createMorphAt(dom.childAt(element83, [1, 1, 3]),1,1);
        morphs[58] = dom.createAttrMorph(element84, 'class');
        morphs[59] = dom.createMorphAt(dom.childAt(element84, [1, 3]),1,1);
        morphs[60] = dom.createMorphAt(element82,7,7);
        morphs[61] = dom.createAttrMorph(element85, 'class');
        morphs[62] = dom.createMorphAt(dom.childAt(element85, [1]),0,0);
        morphs[63] = dom.createMorphAt(element86,1,1);
        morphs[64] = dom.createMorphAt(element86,2,2);
        morphs[65] = dom.createMorphAt(element86,3,3);
        morphs[66] = dom.createMorphAt(element86,4,4);
        morphs[67] = dom.createMorphAt(element86,6,6);
        morphs[68] = dom.createMorphAt(element86,8,8);
        morphs[69] = dom.createMorphAt(element86,10,10);
        morphs[70] = dom.createMorphAt(element86,11,11);
        morphs[71] = dom.createMorphAt(element86,13,13);
        morphs[72] = dom.createAttrMorph(element87, 'class');
        morphs[73] = dom.createMorphAt(dom.childAt(element87, [3]),0,0);
        morphs[74] = dom.createMorphAt(element88,0,0);
        morphs[75] = dom.createMorphAt(element88,2,2);
        morphs[76] = dom.createMorphAt(element89,0,0);
        morphs[77] = dom.createMorphAt(element89,2,2);
        morphs[78] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[79] = dom.createMorphAt(dom.childAt(element90, [1]),0,0);
        morphs[80] = dom.createMorphAt(element90,3,3);
        morphs[81] = dom.createMorphAt(element90,4,4);
        morphs[82] = dom.createMorphAt(element90,5,5);
        morphs[83] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[84] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[85] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[86] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[87] = dom.createMorphAt(fragment,16,16,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar  ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,48],[1,61]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,27],[1,92]]]]]]],
        ["attribute","class",["concat",["d-flex align-items-center col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[2,72],[2,85]]]]],[],["loc",[null,[2,67],[2,86]]]],"24","18"],[],["loc",[null,[2,46],[2,98]]]],"/24@desk l-24/24@phone l-24/24@tablet"]]],
        ["element","action",["printQr",["get","model.id",["loc",[null,[5,28],[5,36]]]]],[],["loc",[null,[5,9],[5,38]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[8,16],[8,25]]]],"future"],[],["loc",[null,[8,12],[8,35]]]]],[],0,null,["loc",[null,[8,6],[12,13]]]],
        ["attribute","class",["concat",["edit-product rework-content l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[17,72],[17,85]]]]],[],["loc",[null,[17,67],[17,86]]]],"24","18"],[],["loc",[null,[17,46],[17,98]]]],"/24@desk ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[17,128],[17,141]]]],"sidebar-active",""],[],["loc",[null,[17,107],[17,163]]]]]]],
        ["block","if",[["get","model.picture",["loc",[null,[21,14],[21,27]]]]],[],1,2,["loc",[null,[21,8],[27,15]]]],
        ["content","model.name",["loc",[null,[31,27],[31,41]]]],
        ["block","if",[["get","model.deleted",["loc",[null,[31,48],[31,61]]]]],[],3,null,["loc",[null,[31,42],[32,115]]]],
        ["block","if",[["get","model.rpaId",["loc",[null,[33,39],[33,50]]]]],[],4,null,["loc",[null,[33,33],[34,73]]]],
        ["block","if",[["get","model.pzn",["loc",[null,[34,80],[34,89]]]]],[],5,null,["loc",[null,[34,74],[35,67]]]],
        ["block","if",[["subexpr","or",[["get","model.gtin",["loc",[null,[35,78],[35,88]]]],["get","model.ean",["loc",[null,[35,89],[35,98]]]]],[],["loc",[null,[35,74],[35,99]]]]],[],6,null,["loc",[null,[35,68],[36,68]]]],
        ["inline","truncate",[["get","model.description",["loc",[null,[38,23],[38,40]]]],300],[],["loc",[null,[38,11],[38,47]]]],
        ["content","purchaseProduct.quantity",["loc",[null,[42,15],[42,43]]]],
        ["content","model.unit.name",["loc",[null,[42,44],[42,63]]]],
        ["block","if",[["get","model.altUnit",["loc",[null,[43,18],[43,31]]]]],[],7,null,["loc",[null,[43,12],[46,19]]]],
        ["inline","get-value-or-empty-sign",[["get","model.category.name",["loc",[null,[48,41],[48,60]]]],"-"],[],["loc",[null,[48,15],[48,66]]]],
        ["block","if",[["get","model.narcotic",["loc",[null,[50,21],[50,35]]]]],[],8,9,["loc",[null,[50,15],[50,58]]]],
        ["block","if",[["get","model.licenceNumber",["loc",[null,[52,21],[52,40]]]]],[],10,11,["loc",[null,[52,15],[54,54]]]],
        ["block","if",[["subexpr","is-ehapo-active",[],[],["loc",[null,[58,18],[58,35]]]]],[],12,null,["loc",[null,[58,12],[73,19]]]],
        ["block","if",[["get","model.systemTags",["loc",[null,[74,18],[74,34]]]]],[],13,null,["loc",[null,[74,12],[78,19]]]],
        ["block","if",[["subexpr","and",[["subexpr","has-permission",["purchases"],[],["loc",[null,[86,15],[86,43]]]],["get","model.purchaseRelevant",["loc",[null,[86,44],[86,66]]]]],[],["loc",[null,[86,10],[86,67]]]]],[],14,null,["loc",[null,[86,4],[88,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[89,53],[89,62]]]],"masterdata"],[],["loc",[null,[89,49],[89,76]]]],"is-active",""],[],["loc",[null,[89,28],[89,93]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[89,119],[89,154]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[90,53],[90,62]]]],"adddata"],[],["loc",[null,[90,49],[90,73]]]],"is-active",""],[],["loc",[null,[90,28],[90,90]]]]]]],
        ["element","action",["selectTab","adddata"],[],["loc",[null,[90,113],[90,145]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[91,10],[91,35]]]]],[],15,null,["loc",[null,[91,4],[93,11]]]],
        ["block","if",[["subexpr","has-permission",["crud_treatment_future_blueprints"],[],["loc",[null,[94,10],[94,61]]]]],[],16,null,["loc",[null,[94,4],[96,11]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[97,53],[97,62]]]],"sloping"],[],["loc",[null,[97,49],[97,73]]]],"is-active",""],[],["loc",[null,[97,28],[97,90]]]]]]],
        ["element","action",["selectTab","sloping"],[],["loc",[null,[97,112],[97,144]]]],
        ["block","if",[["subexpr","is-ehapo-active",[],[],["loc",[null,[98,10],[98,27]]]]],[],17,null,["loc",[null,[98,4],[101,11]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-test-mandant",[],[],["loc",[null,[102,14],[102,31]]]],["subexpr","is-debug-user",[],[],["loc",[null,[102,32],[102,47]]]]],[],["loc",[null,[102,10],[102,48]]]]],[],18,null,["loc",[null,[102,4],[104,11]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[110,69],[110,78]]]],"masterdata"],[],["loc",[null,[110,65],[110,92]]]],"is-active",""],[],["loc",[null,[110,44],[110,109]]]]]]],
        ["element","action",["saveCheck",["get","referer",["loc",[null,[111,71],[111,78]]]],["get","refererInvoiceId",["loc",[null,[111,79],[111,95]]]],["get","refererPatientId",["loc",[null,[111,96],[111,112]]]]],["on","submit"],["loc",[null,[111,50],[111,126]]]],
        ["inline","products-edit-form",[],["history",["subexpr","@mut",[["get","history",["loc",[null,[112,39],[112,46]]]]],[],[]],"articleTags",["subexpr","@mut",[["get","articleTags",["loc",[null,[112,59],[112,70]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[112,77],[112,82]]]]],[],[]],"mode","edit","editable",["subexpr","@mut",[["get","model.editable",["loc",[null,[112,104],[112,118]]]]],[],[]],"labProfiles",["subexpr","@mut",[["get","labProfiles",["loc",[null,[112,131],[112,142]]]]],[],[]],"taxes",["subexpr","@mut",[["get","taxes",["loc",[null,[112,149],[112,154]]]]],[],[]],"allUnits",["subexpr","@mut",[["get","allUnits",["loc",[null,[112,164],[112,172]]]]],[],[]],"distributors",["subexpr","@mut",[["get","distributors",["loc",[null,[112,186],[112,198]]]]],[],[]],"productCategories",["subexpr","@mut",[["get","productCategories",["loc",[null,[112,217],[112,234]]]]],[],[]],"categories",["subexpr","@mut",[["get","categories",["loc",[null,[112,246],[112,256]]]]],[],[]],"manufacturers",["subexpr","@mut",[["get","manufacturers",["loc",[null,[112,271],[112,284]]]]],[],[]],"patientCategories",["subexpr","@mut",[["get","patientCategories",["loc",[null,[112,303],[112,320]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","productEditForm",["loc",[null,[112,336],[112,351]]]]],[],[]]],["loc",[null,[112,10],[112,353]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[115,56],[115,65]]]],"future"],[],["loc",[null,[115,52],[115,75]]]],"is-active",""],[],["loc",[null,[115,31],[115,92]]]]]]],
        ["inline","future-blueprint-panel",[],["edit","editEntry","delete","deleteEntry","items",["subexpr","@mut",[["get","futureItems",["loc",[null,[120,16],[120,27]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[121,16],[121,21]]]]],[],[]]],["loc",[null,[117,8],[121,23]]]],
        ["attribute","class",["concat",["card-content tabs__content article-wizzard ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[123,85],[123,94]]]],"treatment"],[],["loc",[null,[123,81],[123,107]]]],"is-active",""],[],["loc",[null,[123,60],[123,124]]]]]]],
        ["inline","input",[],["type","checkbox","id","switchAnamnese","classNames","switch","name","switchAnamnese","checked",["subexpr","@mut",[["get","showAnamnese",["loc",[null,[131,108],[131,120]]]]],[],[]]],["loc",[null,[131,14],[131,122]]]],
        ["block","if",[["get","showAnamnese",["loc",[null,[136,14],[136,26]]]]],[],19,null,["loc",[null,[136,8],[173,15]]]],
        ["inline","input",[],["type","checkbox","id","switchSymptoms","classNames","switch","name","switchSymptoms","checked",["subexpr","@mut",[["get","showSymptoms",["loc",[null,[178,108],[178,120]]]]],[],[]]],["loc",[null,[178,14],[178,122]]]],
        ["block","if",[["get","showSymptoms",["loc",[null,[183,14],[183,26]]]]],[],20,null,["loc",[null,[183,8],[220,15]]]],
        ["inline","input",[],["type","checkbox","id","switchDiagnose","classNames","switch","name","switchDiagnose","checked",["subexpr","@mut",[["get","showDiagnoses",["loc",[null,[226,108],[226,121]]]]],[],[]]],["loc",[null,[226,14],[226,123]]]],
        ["block","if",[["get","showDiagnoses",["loc",[null,[232,14],[232,27]]]]],[],21,null,["loc",[null,[232,8],[266,15]]]],
        ["inline","input",[],["type","checkbox","id","switchTherapy","classNames","switch","name","switchTherapy","checked",["subexpr","@mut",[["get","showTherapies",["loc",[null,[272,106],[272,119]]]]],[],[]]],["loc",[null,[272,14],[272,121]]]],
        ["block","if",[["get","showTherapies",["loc",[null,[278,14],[278,27]]]]],[],22,null,["loc",[null,[278,8],[307,15]]]],
        ["inline","input",[],["type","checkbox","id","switchReport","classNames","switch","name","switchReport","checked",["subexpr","@mut",[["get","showReport",["loc",[null,[313,104],[313,114]]]]],[],[]]],["loc",[null,[313,14],[313,116]]]],
        ["block","if",[["get","showReport",["loc",[null,[319,14],[319,24]]]]],[],23,null,["loc",[null,[319,8],[336,15]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[338,69],[338,78]]]],"sloping"],[],["loc",[null,[338,65],[338,89]]]],"is-active",""],[],["loc",[null,[338,44],[338,106]]]]," "]]],
        ["block","if",[["get","model.imported",["loc",[null,[341,18],[341,32]]]]],[],24,null,["loc",[null,[341,12],[351,19]]]],
        ["inline","textarea",[],["rows","3","value",["subexpr","@mut",[["get","model.doseMethod",["loc",[null,[357,44],[357,60]]]]],[],[]],"class","","name","product-doseMethod","placeholder",""],["loc",[null,[357,18],[358,45]]]],
        ["block","each",[["get","dosings",["loc",[null,[375,24],[375,31]]]]],[],25,null,["loc",[null,[375,16],[407,25]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[414,69],[414,78]]]],"reorder"],[],["loc",[null,[414,65],[414,89]]]],"is-active",""],[],["loc",[null,[414,44],[414,106]]]]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[423,89],[423,116]]]],["get","model.purchaseRelevant",["loc",[null,[423,117],[423,139]]]]],[],["loc",[null,[423,84],[423,140]]]]],[],26,null,["loc",[null,[423,78],[423,180]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[424,89],[424,116]]]],["get","model.purchaseRelevant",["loc",[null,[424,117],[424,139]]]]],[],["loc",[null,[424,84],[424,140]]]]],[],27,null,["loc",[null,[424,78],[424,167]]]],
        ["block","each",[["get","purchaseProduct.reorderSettings",["loc",[null,[428,22],[428,53]]]]],[],28,null,["loc",[null,[428,14],[501,23]]]],
        ["block","if",[["subexpr","and",[["get","model.purchaseRelevant",["loc",[null,[506,11],[506,33]]]],["subexpr","is-operating-country",["AT"],[],["loc",[null,[506,34],[506,61]]]]],[],["loc",[null,[506,6],[506,62]]]]],[],29,null,["loc",[null,[506,0],[524,7]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[526,69],[526,78]]]],"inventory"],[],["loc",[null,[526,65],[526,91]]]],"is-active",""],[],["loc",[null,[526,44],[526,108]]]]," "]]],
        ["inline","input",[],["type","checkbox","name","productEhapo","id","productEhapo","checked",["subexpr","@mut",[["get","model.ehapoActive",["loc",[null,[535,88],[535,105]]]]],[],[]]],["loc",[null,[535,18],[535,107]]]],
        ["attribute","class",["concat",["col l-24/24 ",["subexpr","css-bool-evaluator",[["get","model.globalAutoBookingActive",["loc",[null,[540,57],[540,86]]]],"hidden",""],[],["loc",[null,[540,36],[540,100]]]]]]],
        ["inline","input",[],["type","checkbox","name","productAutoBooking","id","productAutoBooking","checked",["subexpr","@mut",[["get","model.ehapoAutoBookingActive",["loc",[null,[544,100],[544,128]]]]],[],[]]],["loc",[null,[544,18],[544,130]]]],
        ["inline","stock-details",[],["updateBatchNumber","updateBatchNumber","updateExpiry","updateExpiry","updateLocation","updateLocation","showActionButtons",false,"showInventoryDetailsButton",true,"showInvoice","showInvoice","showPatient","showPatient","showHeader",false,"printBarcode","printBarcode","showStock","showStock","bookoutMovement","bookoutMovement","transfer","transfer","bookout","bookout","actionReceiver",["subexpr","@mut",[["get","stockDetailPanel",["loc",[null,[554,386],[554,402]]]]],[],[]]],["loc",[null,[554,8],[554,404]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[556,69],[556,78]]]],"adddata"],[],["loc",[null,[556,65],[556,89]]]],"is-active",""],[],["loc",[null,[556,44],[556,106]]]]," "]]],
        ["content","model.description",["loc",[null,[557,11],[557,32]]]],
        ["block","if",[["get","model.licenceNumberEu",["loc",[null,[560,16],[560,37]]]]],[],30,null,["loc",[null,[560,10],[563,17]]]],
        ["block","if",[["get","model.licenceNumberAges",["loc",[null,[564,16],[564,39]]]]],[],31,null,["loc",[null,[564,10],[567,17]]]],
        ["block","if",[["get","model.licenceNumberApo",["loc",[null,[568,16],[568,38]]]]],[],32,null,["loc",[null,[568,10],[571,17]]]],
        ["block","if",[["get","model.slopingInfo",["loc",[null,[572,16],[572,33]]]]],[],33,null,["loc",[null,[572,10],[575,17]]]],
        ["block","if",[["get","model.substances",["loc",[null,[577,16],[577,32]]]]],[],34,null,["loc",[null,[577,10],[580,17]]]],
        ["block","if",[["get","model.domainInfo",["loc",[null,[582,16],[582,32]]]]],[],35,null,["loc",[null,[582,10],[585,17]]]],
        ["block","if",[["subexpr","or",[["get","model.info",["loc",[null,[587,20],[587,30]]]],["get","model.detailUrl",["loc",[null,[587,31],[587,46]]]]],[],["loc",[null,[587,16],[587,47]]]]],[],36,null,["loc",[null,[587,10],[590,17]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","model.supplier.id",["loc",[null,[591,25],[591,42]]]],"jac"],[],["loc",[null,[591,21],[591,49]]]],["get","model.licenceNumber",["loc",[null,[591,50],[591,69]]]]],[],["loc",[null,[591,16],[591,70]]]]],[],37,null,["loc",[null,[591,10],[597,17]]]],
        ["block","if",[["get","model.targetRace",["loc",[null,[599,16],[599,32]]]]],[],38,null,["loc",[null,[599,10],[602,17]]]],
        ["attribute","class",["concat",["card-content  tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[607,70],[607,79]]]],"debug"],[],["loc",[null,[607,66],[607,88]]]],"is-active",""],[],["loc",[null,[607,45],[607,105]]]]," "]]],
        ["inline","time-format",[["get","model.lastImport",["loc",[null,[609,25],[609,41]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[609,11],[609,64]]]],
        ["content","model.primarySupplierId",["loc",[null,[611,11],[611,38]]]],
        ["content","model.importDetails",["loc",[null,[611,39],[611,62]]]],
        ["block","if",[["get","model.migrated",["loc",[null,[613,17],[613,31]]]]],[],39,40,["loc",[null,[613,11],[613,118]]]],
        ["block","if",[["get","model.richterCase",["loc",[null,[613,125],[613,142]]]]],[],41,null,["loc",[null,[613,119],[613,179]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[620,6],[620,19]]]]],[],42,43,["loc",[null,[620,0],[668,7]]]],
        ["content","button-prev",["loc",[null,[671,69],[671,84]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[672,12],[672,21]]]],"masterdata"],[],["loc",[null,[672,8],[672,35]]]]],[],44,null,["loc",[null,[672,2],[676,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[677,12],[677,21]]]],"sloping"],[],["loc",[null,[677,8],[677,32]]]]],[],45,null,["loc",[null,[677,2],[681,9]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[682,16],[682,25]]]],"inventory"],[],["loc",[null,[682,12],[682,38]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[682,43],[682,52]]]],"reorder"],[],["loc",[null,[682,39],[682,63]]]]],[],["loc",[null,[682,8],[682,64]]]]],[],46,47,["loc",[null,[682,2],[694,9]]]],
        ["inline","edit-sloping-panel",[],["save","saveSlopingInfo","actionReceiver",["subexpr","@mut",[["get","editSlopingPanel",["loc",[null,[697,59],[697,75]]]]],[],[]]],["loc",[null,[697,0],[697,77]]]],
        ["inline","product-sloping-select-barcode-panel",[],["actionReceiver",["subexpr","@mut",[["get","productSlopingSelectBarcodePanel",["loc",[null,[698,54],[698,86]]]]],[],[]]],["loc",[null,[698,0],[698,88]]]],
        ["inline","treatment-select-panel",[],["actionReceiver",["subexpr","@mut",[["get","treatmentSelectPanel",["loc",[null,[700,17],[700,37]]]]],[],[]]],["loc",[null,[699,0],[700,39]]]],
        ["inline","treatment-multistage-select-panel",[],["actionReceiver",["subexpr","@mut",[["get","treatmentMultiStageSelectPanel",["loc",[null,[702,17],[702,47]]]]],[],[]]],["loc",[null,[701,0],[702,49]]]],
        ["inline","treatment-select-service-panel",[],["actionReceiver",["subexpr","@mut",[["get","treatmentSelectServicePanel",["loc",[null,[704,17],[704,44]]]]],[],[]]],["loc",[null,[703,0],[704,46]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31, child32, child33, child34, child35, child36, child37, child38, child39, child40, child41, child42, child43, child44, child45, child46, child47]
    };
  }()));

});