define('jason-frontend/templates/components/edit-thresholds-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 140,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/edit-thresholds-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-edit-threshold-panel");
        dom.setAttribute(el1,"class","popup-basic popup-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","editThresholdForm");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","box");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        dom.setAttribute(el5,"class","mb20");
        var el6 = dom.createTextNode("Cashback Einstellungen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","box");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","threshold");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("gültig ab Umsatz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","priceEuroLabel");
        var el9 = dom.createTextNode("EUR");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","cashback");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Cashback in Euro");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","priceEuroLabel");
        var el9 = dom.createTextNode("EUR");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-12/24");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","percentageWarning");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("Email-Benachrichtung ab");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","priceEuroLabel");
        var el9 = dom.createTextNode("%");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col l-16/24");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","validFrom");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("gültig ab");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","validFrom");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","validFrom");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","select hidden");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","select hidden");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","field");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","validFrom");
        dom.setAttribute(el8,"class","field-label title");
        var el9 = dom.createTextNode("gültig bis");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","validTo");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"for","validTo");
        dom.setAttribute(el8,"class","field select");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","select hidden");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","select hidden");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("i");
        dom.setAttribute(el9,"class","arrow");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","submit");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var element1 = dom.childAt(element0, [1, 3]);
        var element2 = dom.childAt(element1, [7]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var morphs = new Array(13);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1]),3,3);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3, 1]),3,3);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [5, 1]),3,3);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element3, [7]),1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element3, [9]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element4, [3]),1,1);
        morphs[9] = dom.createMorphAt(dom.childAt(element4, [5]),1,1);
        morphs[10] = dom.createMorphAt(dom.childAt(element4, [7]),1,1);
        morphs[11] = dom.createMorphAt(dom.childAt(element4, [9]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element0, [3, 1]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["confirm"],["on","submit"],["loc",[null,[3,33],[3,65]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.threshold",["loc",[null,[12,30],[12,44]]]]],[],[]],"class","col l-6/24 gui-input price","name","threshold"],["loc",[null,[12,16],[12,98]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.cashback",["loc",[null,[19,30],[19,43]]]]],[],[]],"class","col l-6/24 gui-input price","name","cashback"],["loc",[null,[19,16],[19,96]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","item.percentageWarning",["loc",[null,[26,30],[26,52]]]]],[],[]],"class","col l-6/24 gui-input price","name","percentageWarning"],["loc",[null,[26,16],[26,114]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","months",["loc",[null,[35,28],[35,34]]]]],[],[]],"value",["subexpr","@mut",[["get","item.validFromMonth",["loc",[null,[36,26],[36,45]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","allowClear",true,"placeholder","bitte wählen","name","validFrom"],["loc",[null,[34,18],[42,20]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","years",["loc",[null,[47,28],[47,33]]]]],[],[]],"value",["subexpr","@mut",[["get","item.validFromYear",["loc",[null,[48,26],[48,44]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","allowClear",true,"placeholder","bitte wählen","name","validFrom"],["loc",[null,[46,18],[54,20]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[58,25],[58,37]]]]],["content",["subexpr","@mut",[["get","months",["loc",[null,[59,33],[59,39]]]]],[],[]],"name","validFromMonths","optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","item.validFromMonths",["loc",[null,[63,35],[63,55]]]]],[],[]]],["loc",[null,[58,18],[64,20]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[68,25],[68,37]]]]],["content",["subexpr","@mut",[["get","years",["loc",[null,[69,33],[69,38]]]]],[],[]],"name","validFromYears","optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","item.validFromYears",["loc",[null,[73,35],[73,54]]]]],[],[]]],["loc",[null,[68,18],[74,20]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","months",["loc",[null,[82,28],[82,34]]]]],[],[]],"value",["subexpr","@mut",[["get","item.validToMonth",["loc",[null,[83,26],[83,43]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","allowClear",true,"placeholder","bitte wählen","name","validTo"],["loc",[null,[81,18],[89,20]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","years",["loc",[null,[94,28],[94,33]]]]],[],[]],"value",["subexpr","@mut",[["get","item.validToYear",["loc",[null,[95,26],[95,42]]]]],[],[]],"cssClass","custom-select2 newStyle","optionLabelPath","name","allowClear",true,"placeholder","bitte wählen","name","validFrom"],["loc",[null,[93,18],[101,20]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[105,25],[105,37]]]]],["content",["subexpr","@mut",[["get","months",["loc",[null,[106,33],[106,39]]]]],[],[]],"name","validToMonths","optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","item.validToMonths",["loc",[null,[110,35],[110,53]]]]],[],[]]],["loc",[null,[105,18],[111,20]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[115,25],[115,37]]]]],["content",["subexpr","@mut",[["get","years",["loc",[null,[116,33],[116,38]]]]],[],[]],"name","validToYears","optionValuePath","content.id","optionLabelPath","content.name","selection",["subexpr","@mut",[["get","item.validToYears",["loc",[null,[120,35],[120,52]]]]],[],[]]],["loc",[null,[115,18],[121,20]]]],
        ["inline","button-save",[],["size","44","color","#ffffff","content","Speichern"],["loc",[null,[132,10],[132,71]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});