define('jason-frontend/components/patient-activities', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    pickDateJournal: moment('2016-01-01T00:00:00.000').format("DD. MM. YYYY HH:mm") + " - " + moment().format("DD. MM. YYYY HH:mm"),
    patientId: null,
    externalContext: null,
    typeAllSelected: true,
    typeTreatmentSelected: false,
    typeComSelected: false,
    typeVaccSelected: false,
    typeDocSelected: false,
    typeInvoiceSelected: false,
    typeAppSelected: false,
    typeRemSelected: false,
    typeLabSelected: false,
    typeImgSelected: false,
    typeNotSelected: false,
    typeMailSelected: false,
    availableTags: null,
    loading: true,
    showPatientInfo: null,
    selectedTypes: new Array(),
    loadAndSaveSettings: true,

    deSelectAll: function deSelectAll() {
      Ember['default'].set(this, 'typeAllSelected', false);
      Ember['default'].set(this, 'typeTreatmentSelected', false);
      Ember['default'].set(this, 'typeComSelected', false);
      Ember['default'].set(this, 'typeVaccSelected', false);
      Ember['default'].set(this, 'typeDocSelected', false);
      Ember['default'].set(this, 'typeNotSelected', false);
      Ember['default'].set(this, 'typeRemSelected', false);
      Ember['default'].set(this, 'typeLabSelected', false);
      Ember['default'].set(this, 'typeImgSelected', false);
      Ember['default'].set(this, 'typeAppSelected', false);
      Ember['default'].set(this, 'typeMailSelected', false);
      Ember['default'].set(this, 'typeInvoiceSelected', false);
      this.selectedTypes = new Array();
    },
    loadData: function loadData() {
      var loadTags = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

      var self = this;
      var selectedTag = new Array();
      this.set('loading', true);
      if (this.availableTags) {
        this.availableTags.forEach(function (tag) {
          if (tag.selected) {
            selectedTag.push(tag.id);
          }
        });
      }

      var doctorId = this.get('filterDoctor');
      var assistantId = this.get('filterAssistant');
      var roomId = this.get('filterRoom');
      var articleId = this.get('filterArticle');
      var customerId = this.get('filterCustomer');
      var patientCategory = this.get('filterPatientCategory');
      var filterStable = this.get('filterStable');

      if (articleId) {
        articleId = articleId.id;
      }
      if (customerId) {
        customerId = customerId.id;
      }

      var dateRange = this.get('pickDateJournal'),
          dateFromTo = dateRange.split('-');

      var from = moment(dateFromTo[0], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
          to = moment(dateFromTo[1], 'DD. MM. YYYY HH:mm').format('YYYY-MM-DD HH:mm');

      this.externalContext.findPaged('patient-journal', {
        patientId: this.get('patientId'),
        filterTypes: this.get('selectedTypes').toString(),
        filterTags: selectedTag.toString(),
        filterCustomerId: customerId,
        filterDoctorId: doctorId,
        filterAssistantId: assistantId,
        filterArticleId: articleId,
        filterRoomId: roomId,
        filterPatientCategory: patientCategory,
        filterStable: filterStable,
        filterFrom: from,
        filterTo: to
      }).then(function (entries) {
        self.set('items', entries);
        self.set('loading', false);
      });

      if (loadTags) {
        $.ajax({
          url: "/api/patientJournals/tags",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('availableTags', data.patientJournalTag);
        });
      }
    },
    toggleTypeFilter: function toggleTypeFilter(type) {
      var loadAndSave = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

      if (type === 'all') {
        this.deSelectAll();
        Ember['default'].set(this, 'typeAllSelected', true);
        this.selectedTypes.push(type);
      } else if (type === 'treat') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeTreatmentSelected) {
          this.removeItemOnce(this.selectedTypes, 'treat');
        } else {
          this.selectedTypes.push('treat');
        }
        Ember['default'].set(this, 'typeTreatmentSelected', !this.typeTreatmentSelected);
      } else if (type === 'vacc') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeVaccSelected) {
          this.removeItemOnce(this.selectedTypes, 'vacc');
        } else {
          this.selectedTypes.push('vacc');
        }
        Ember['default'].set(this, 'typeVaccSelected', !this.typeVaccSelected);
      } else if (type === 'doc') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeDocSelected) {
          this.removeItemOnce(this.selectedTypes, 'doc');
        } else {
          this.selectedTypes.push('doc');
        }
        Ember['default'].set(this, 'typeDocSelected', !this.typeDocSelected);
      } else if (type === 'com') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeComSelected) {
          this.removeItemOnce(this.selectedTypes, 'com');
        } else {
          this.selectedTypes.push('com');
        }
        Ember['default'].set(this, 'typeComSelected', !this.typeComSelected);
      } else if (type === 'app') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeAppSelected) {
          this.removeItemOnce(this.selectedTypes, 'app');
        } else {
          this.selectedTypes.push('app');
        }
        Ember['default'].set(this, 'typeAppSelected', !this.typeAppSelected);
      } else if (type === 'invoice') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeInvoiceSelected) {
          this.removeItemOnce(this.selectedTypes, 'invoice');
        } else {
          this.selectedTypes.push('invoice');
        }
        Ember['default'].set(this, 'typeInvoiceSelected', !this.typeInvoiceSelected);
      } else if (type === 'rem') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeRemSelected) {
          this.removeItemOnce(this.selectedTypes, 'rem');
        } else {
          this.selectedTypes.push('rem');
        }
        Ember['default'].set(this, 'typeRemSelected', !this.typeRemSelected);
      } else if (type === 'img') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeImgSelected) {
          this.removeItemOnce(this.selectedTypes, 'img');
        } else {
          this.selectedTypes.push('img');
        }
        Ember['default'].set(this, 'typeImgSelected', !this.typeImgSelected);
      } else if (type === 'lab') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeLabSelected) {
          this.removeItemOnce(this.selectedTypes, 'lab');
        } else {
          this.selectedTypes.push('lab');
        }
        Ember['default'].set(this, 'typeLabSelected', !this.typeLabSelected);
      } else if (type === 'not') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeNotSelected) {
          this.removeItemOnce(this.selectedTypes, 'not');
        } else {
          this.selectedTypes.push('not');
        }
        Ember['default'].set(this, 'typeNotSelected', !this.typeNotSelected);
      } else if (type === 'mail') {
        Ember['default'].set(this, 'typeAllSelected', false);
        this.removeItemOnce(this.selectedTypes, 'all');
        if (this.typeMailSelected) {
          this.removeItemOnce(this.selectedTypes, 'mail');
        } else {
          this.selectedTypes.push('mail');
        }
        Ember['default'].set(this, 'typeMailSelected', !this.typeMailSelected);
      }

      if (!this.typeAllSelected && this.selectedTypes.length === 0) {
        Ember['default'].set(this, 'typeAllSelected', true);
      }
      if (loadAndSave) {
        this.loadData(false);
        if (this.get('loadAndSaveSettings')) {
          this.saveUserSettings();
        }
      }
    },
    updateUserSettings: function updateUserSettings() {
      var controller = this.get('controller');
      var self = this;
      $.ajax({
        url: "/api/users/activitiesSettings",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        var settings = data.userCalSettings;
        if (settings.activityTypeIds) {
          settings.activityTypeIds.forEach(function (item) {
            self.toggleTypeFilter(item, false);
          });
        }
      }, function (data) {});
    },
    saveUserSettings: function saveUserSettings() {
      var self = this;
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        self.saveUserSettingsIntern();
      }, 2000);
    },
    saveUserSettingsIntern: function saveUserSettingsIntern() {
      var controller = this.get('controller');

      var data = {
        activityTypeIds: this.get('selectedTypes')
      };

      $.ajax({
        url: "/api/users/activitiesSettings",
        method: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data2) {}, function (data2) {});
    },
    removeItemOnce: function removeItemOnce(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
    actions: {
      openSlopingInfos: function openSlopingInfos(entryId, articleId) {
        var self = this;
        $.ajax({
          url: "/api/treatmentOffers/entryDetails/" + entryId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.sendAction('openSlopingInfos', data.treatmentOfferEntry);
        }, function (data) {});
      },
      download: function download() {
        var controller = this,
            dateRange = controller.get('pickDateJournal'),
            dateFromTo = dateRange.split('-');

        var from = moment(dateFromTo[0], 'DD. MM. YYYY').format('YYYY-MM-DD'),
            to = moment(dateFromTo[1], 'DD. MM. YYYY').format('YYYY-MM-DD');

        if (moment(dateFromTo[0], 'DD. MM. YYYY').isBefore(moment().subtract(1, 'years'), 'DD. MM. YYYY')) {
          from = moment(moment().subtract(1, 'years'), 'DD. MM. YYYY').format('YYYY-MM-DD');
        }

        var appx = "&per_page=100000&filterFrom=" + from + "&filterTo=" + to;

        var doctorId = this.get('filterDoctor');
        var patientId = this.get('filterPatient');
        var assistantId = this.get('filterAssistant');
        var roomId = this.get('filterRoom');
        var articleId = this.get('filterArticle');
        var customerId = this.get('filterCustomer');
        var patientCategory = this.get('filterPatientCategory');

        if (articleId) {
          articleId = articleId.id;
        }
        if (customerId) {
          customerId = customerId.id;
        }

        if (customerId) {
          appx += "&filterCustomer=" + customerId;
        }
        if (patientId) {
          appx += "&filterPatient=" + patientId;
        }
        if (doctorId) {
          appx += "&filterDoctor=" + doctorId;
        }
        if (roomId) {
          appx += "&filterRoom=" + roomId;
        }
        if (assistantId) {
          appx += "&filterAssistant=" + assistantId;
        }
        if (patientCategory) {
          appx += "&filterPatientCategory=" + patientCategory;
        }
        if (articleId) {
          appx += "&filterArticle=" + articleId;
        }

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: API['default'].getMandant()
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          window.open('/api/treatmentPatients/downloadHistoryData?' + appx + '&mandantId=' + API['default'].getMandant() + '&rat=' + data.responseText);
        });
      },
      queryCustomer: function queryCustomer(query, deferred) {
        this.externalContext.findPaged('customer', { filterName: query.term }).then(deferred.resolve, deferred.reject);
      },
      queryArticle: function queryArticle(query, deferred) {
        this.externalContext.findPaged('article', {
          searchTerm: query.term
        }).then(deferred.resolve, deferred.reject);
      },
      copy: function copy(treatmentPatientId) {
        this.sendAction('copy', treatmentPatientId);
      },
      deleteTreatment: function deleteTreatment(treatment) {
        var controller = this.get('controller'),
            self = this;

        var deferred = $.ajax({
          url: "/api/treatmentPatients/" + treatment.get('referenceId'),
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Behandlung entfernt', '');
          self.loadData();
        }, function (error) {});
        return Ember['default'].RSVP.resolve(deferred);
      },
      deleteReport: function deleteReport(id) {
        var self = this;

        $.ajax({
          url: "/api/treatmentReports/" + id,
          method: "DELETE",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          jason.notifiction.notifiy('Behandlungsbericht', 'erfolgreich gelöscht');
          self.loadData();
        });
      },
      unhideTreatment: function unhideTreatment(id) {
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/" + id + '/toggleAppShare/true',
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.loadData();
        }, function (data) {
          self.loadData();
        });
      },
      unhideTreatmentNotAllowed: function unhideTreatmentNotAllowed(id) {
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-eltiga-sharenotallowed-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      hideTreatment: function hideTreatment(id) {
        var self = this;
        $.ajax({
          url: "/api/treatmentPatients/" + id + '/toggleAppShare/false',
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.loadData();
        }, function (data) {
          self.loadData();
        });
      },
      editNote: function editNote(id, patientId) {
        this.sendAction('editNote', id, patientId);
      },
      openApp: function openApp(id) {
        this.sendAction('openApp', id);
      },
      gotoReport: function gotoReport(tpId, id) {
        this.sendAction('gotoReport', tpId, id);
      },
      gotoTemplate: function gotoTemplate(tpId, id, reference, patientId) {
        this.sendAction('gotoTemplate', tpId, id, reference, patientId);
      },
      editTreatment: function editTreatment(id) {
        this.sendAction('editTreatment', id);
      },
      openReportWizard: function openReportWizard(tpId) {
        var self = this;
        $.ajax({
          url: "/api/treatmentReports/" + tpId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          if (data.treatmentReport.empty == true) {
            self.get('treatmentReportWizzardJournal').send('load', data.treatmentReport, tpId);

            $.magnificPopup.open({
              removalDelay: 500,
              closeOnBgClick: false,
              items: {
                src: '#modal-treatment-report-wizzard-journal'
              },
              callbacks: {
                close: function close() {},
                beforeOpen: function beforeOpen() {
                  var Animation = "mfp-with-fade";
                  this.st.mainClass = Animation;
                }
              },
              midClick: true
            });
          } else {
            self.sendAction('gotoReport', tpId, 0);
          }
        });
      },
      openReportPreview: function openReportPreview(tpId, reportId) {
        this.sendAction('gotoReport', tpId, reportId);
      },
      openReportChooser: function openReportChooser(tpId) {

        var self = this;

        $.ajax({
          url: "/api/treatmentReports/" + tpId + "/all",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('treatmentHistoryReportChooserActivities').send('load', data.treatmentReports, tpId);
        });
        $.magnificPopup.open({
          removalDelay: 500,
          closeOnBgClick: false,
          items: {
            src: '#modal-treatment-history-report-chooser-journal'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openLab: function openLab(id, patientId) {
        this.sendAction('openLab', id, patientId);
      },
      openDocument: function openDocument(id, patientId) {
        var self = this;
        if (id) {
          $.ajax({
            url: "/api/documents/" + id + "/folder",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.sendAction('openDocument', data.documentItem.rootFolderId, patientId);
          });
        } else {
          self.sendAction('openDocument', null, patientId);
        }
      },
      toggleTag: function toggleTag(tag) {
        Ember['default'].set(tag, 'selected', !tag.selected);
        this.loadData(false);
      },
      toggleTypeFilter: function toggleTypeFilter(type) {
        var loadAndSave = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        this.toggleTypeFilter(type, loadAndSave);
      },
      load: function load(externalContext, patientId) {
        var self = this;
        if (patientId && patientId > 0) {
          this.set('loadAndSaveSettings', true);
          this.set('patientId', patientId);
          this.set('showPatientInfo', false);
        } else {
          this.set('loadAndSaveSettings', false);
          this.set('showPatientInfo', true);
        }
        this.set('externalContext', externalContext);
        this.deSelectAll();
        Ember['default'].set(this, 'typeAllSelected', true);
        if (this.get('loadAndSaveSettings')) {
          this.updateUserSettings();
        } else {
          self.toggleTypeFilter('treat', false);
          self.toggleTypeFilter('invoice', false);
        }
        setTimeout(function () {
          self.loadData();
        }, 1000);
      },
      reload: function reload() {
        this.loadData();
      }
    },
    watchDateChange: (function () {
      this.loadData();
    }).observes('pickDateJournal'),
    watchPatient: (function () {
      this.set('patientId', this.get('filterPatient'));
      this.loadData();
    }).observes('filterPatient'),
    watchDoctor: (function () {
      this.loadData(false);
    }).observes('filterDoctor'),
    watchRoom: (function () {
      this.loadData(false);
    }).observes('filterRoom'),
    watchAssistant: (function () {
      this.loadData(false);
    }).observes('filterAssistant'),
    watchArticle: (function () {
      this.loadData(false);
    }).observes('filterArticle'),
    watchCustomerSelection: (function () {
      var self = this;
      if (this.get('filterCustomer')) {
        $.ajax({
          url: "/api/patients/forcustomer/" + this.get('filterCustomer').id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('patients', data.patient);
        });
      } else {
        this.set('filterPatient', null);
        this.set('patients', null);
      }
      this.loadData(false);
    }).observes('filterCustomer'),
    watchPatientCategory: (function () {
      this.loadData(false);
    }).observes('filterPatientCategory'),
    watchStable: (function () {
      this.loadData(false);
    }).observes('filterStable')
  });

});