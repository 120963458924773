define('jason-frontend/templates/components/treatment-weight-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/treatment-weight-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-treatment-weight-panel");
        dom.setAttribute(el1,"class","popup-basic popup-very-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","panel-heading");
        var el4 = dom.createTextNode("\n              ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","panel-title");
        var el5 = dom.createTextNode("\n                Gewichtsverlauf");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","buttons-box");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","button-rework context primary");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("img");
        dom.setAttribute(el6,"src","assets/images/icons/rework/add.svg");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Neuer Eintrag");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","box");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4,"class","mt0");
        var el5 = dom.createTextNode("Normalgewicht von ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" bis ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" kg");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [1, 3, 1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element2, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createElementMorph(element1);
        morphs[1] = dom.createMorphAt(element3,1,1);
        morphs[2] = dom.createMorphAt(element3,3,3);
        morphs[3] = dom.createMorphAt(element2,3,3);
        morphs[4] = dom.createMorphAt(element2,5,5);
        morphs[5] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[6] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["openWeight",null,["get","patient.id",["loc",[null,[8,76],[8,86]]]]],[],["loc",[null,[8,49],[8,88]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","patient.normalWeightLower",["loc",[null,[15,54],[15,79]]]]],[],[]],"class","input input--editable","name","lower","autocomplete","off"],["loc",[null,[15,40],[15,143]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","patient.normalWeightUpper",["loc",[null,[15,162],[15,187]]]]],[],[]],"class","input input--editable","name","lower","autocomplete","off"],["loc",[null,[15,148],[15,251]]]],
        ["inline","patient-weight-chart",[],["patientId",["subexpr","@mut",[["get","patient.id",["loc",[null,[16,39],[16,49]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","weightChart",["loc",[null,[16,65],[16,76]]]]],[],[]]],["loc",[null,[16,6],[16,78]]]],
        ["inline","patient-weight",[],["weightInfos",["subexpr","@mut",[["get","weightInfos",["loc",[null,[17,35],[17,46]]]]],[],[]],"edit","openWeight","delete","deleteWeight","editable",true,"setSortDir","sortDir","patientId",["subexpr","@mut",[["get","patient.id",["loc",[null,[17,132],[17,142]]]]],[],[]]],["loc",[null,[17,6],[17,144]]]],
        ["inline","patient-weight-info-form",[],["weightSaved","saveWeight","data",["subexpr","@mut",[["get","weightData.patientWeightInfo",["loc",[null,[22,57],[22,85]]]]],[],[]]],["loc",[null,[22,0],[22,87]]]],
        ["content","js-datepicker-script",["loc",[null,[23,0],[23,24]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});