define('jason-frontend/templates/components/users-edit-form', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 4
            },
            "end": {
              "line": 58,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","cloudinary-tag",[["get","tmpUserImage",["loc",[null,[57,23],[57,35]]]]],["width",300,"crop","fit","class","mainImage"],["loc",[null,[57,6],[57,76]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 4
              },
              "end": {
                "line": 60,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","cloudinary-tag",[["get","model.picture.cdnId",["loc",[null,[59,23],[59,42]]]]],["width",300,"crop","fit","class","mainImage"],["loc",[null,[59,6],[59,83]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 4
            },
            "end": {
              "line": 60,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.picture",["loc",[null,[58,14],[58,27]]]]],[],0,null,["loc",[null,[58,4],[60,4]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 57
            },
            "end": {
              "line": 80,
              "column": 78
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Neues");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 83,
              "column": 6
            },
            "end": {
              "line": 85,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","small mt5 is-highlighted-alternative");
          var el2 = dom.createTextNode("Bitte nur ausfüllen, wenn Sie das bestehende Passwort überschreiben wollen!");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 137,
                "column": 49
              },
              "end": {
                "line": 137,
                "column": 105
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Profil");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 131,
              "column": 0
            },
            "end": {
              "line": 140,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Registrierkassa");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-gender");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Vorausgewählte Registrierkassa");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    Kann jede/r Benutzer:in über das persönliche ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" auswählen.\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 1]),3,3);
          return morphs;
        },
        statements: [
          ["block","link-to",["profile"],["classNames","is-highlighted"],0,null,["loc",[null,[137,49],[137,117]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 141,
              "column": 0
            },
            "end": {
              "line": 162,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Elektronische Hausapotheke");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-warehouse");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Vorauswahl");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","user-warehouse");
          dom.setAttribute(el4,"class","field select");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("i");
          dom.setAttribute(el5,"class","arrow");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 1, 3, 1]),1,1);
          return morphs;
        },
        statements: [
          ["inline","view",[["get","Ember.Select",["loc",[null,[149,15],[149,27]]]]],["content",["subexpr","@mut",[["get","inventoryWarehouses",["loc",[null,[150,23],[150,42]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","name","user-primary-cash-register","selection",["subexpr","@mut",[["get","model.primaryInventoryWarehouse",["loc",[null,[154,25],[154,56]]]]],[],[]],"prompt","Alle Lager summieren"],["loc",[null,[149,8],[156,10]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 171,
                "column": 6
              },
              "end": {
                "line": 183,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"for","user-role");
            dom.setAttribute(el1,"class","field select");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2,"class","arrow");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","view",[["get","Ember.Select",["loc",[null,[173,15],[173,27]]]]],["content",["subexpr","@mut",[["get","userRoles",["loc",[null,[174,23],[174,32]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","name","user-role","selection",["subexpr","@mut",[["get","model.userRole",["loc",[null,[178,25],[178,39]]]]],[],[]],"prompt","Bitte wählen"],["loc",[null,[173,8],[180,10]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 183,
                "column": 6
              },
              "end": {
                "line": 185,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","pt10");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.userRole.name",["loc",[null,[184,26],[184,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 197,
                "column": 4
              },
              "end": {
                "line": 500,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-24/24 gutter");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"for","name");
            dom.setAttribute(el2,"class","field-label title required");
            var el3 = dom.createTextNode("Farbe");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor50");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor50");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor51");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor51");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor53");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor53");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor54");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor54");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor55");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor55");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor56");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor56");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor57");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor57");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor58");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor58");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor59");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor59");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor60");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor60");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor61");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor61");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor62");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor62");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor63");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor63");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor64");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor64");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor65");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor65");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor66");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor66");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor67");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor67");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor68");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor68");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor69");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor69");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor70");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor70");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor71");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor71");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor72");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor72");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor73");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor73");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor74");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor74");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor75");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor75");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor76");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor76");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor77");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor77");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor78");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor78");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor79");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor79");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor80");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor80");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor81");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor81");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor82");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor82");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","col l-2/24@desk l-4/24 radio-custom radio-primary mb10");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            dom.setAttribute(el4,"for","calColor83");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","colorTile calColor83");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1, 3]);
            var morphs = new Array(33);
            morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
            morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]),1,1);
            morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]),1,1);
            morphs[3] = dom.createMorphAt(dom.childAt(element1, [7]),1,1);
            morphs[4] = dom.createMorphAt(dom.childAt(element1, [9]),1,1);
            morphs[5] = dom.createMorphAt(dom.childAt(element1, [11]),1,1);
            morphs[6] = dom.createMorphAt(dom.childAt(element1, [13]),1,1);
            morphs[7] = dom.createMorphAt(dom.childAt(element1, [15]),1,1);
            morphs[8] = dom.createMorphAt(dom.childAt(element1, [17]),1,1);
            morphs[9] = dom.createMorphAt(dom.childAt(element1, [19]),1,1);
            morphs[10] = dom.createMorphAt(dom.childAt(element1, [21]),1,1);
            morphs[11] = dom.createMorphAt(dom.childAt(element1, [23]),1,1);
            morphs[12] = dom.createMorphAt(dom.childAt(element1, [25]),1,1);
            morphs[13] = dom.createMorphAt(dom.childAt(element1, [27]),1,1);
            morphs[14] = dom.createMorphAt(dom.childAt(element1, [29]),1,1);
            morphs[15] = dom.createMorphAt(dom.childAt(element1, [31]),1,1);
            morphs[16] = dom.createMorphAt(dom.childAt(element1, [33]),1,1);
            morphs[17] = dom.createMorphAt(dom.childAt(element1, [35]),1,1);
            morphs[18] = dom.createMorphAt(dom.childAt(element1, [37]),1,1);
            morphs[19] = dom.createMorphAt(dom.childAt(element1, [39]),1,1);
            morphs[20] = dom.createMorphAt(dom.childAt(element1, [41]),1,1);
            morphs[21] = dom.createMorphAt(dom.childAt(element1, [43]),1,1);
            morphs[22] = dom.createMorphAt(dom.childAt(element1, [45]),1,1);
            morphs[23] = dom.createMorphAt(dom.childAt(element1, [47]),1,1);
            morphs[24] = dom.createMorphAt(dom.childAt(element1, [49]),1,1);
            morphs[25] = dom.createMorphAt(dom.childAt(element1, [51]),1,1);
            morphs[26] = dom.createMorphAt(dom.childAt(element1, [53]),1,1);
            morphs[27] = dom.createMorphAt(dom.childAt(element1, [55]),1,1);
            morphs[28] = dom.createMorphAt(dom.childAt(element1, [57]),1,1);
            morphs[29] = dom.createMorphAt(dom.childAt(element1, [59]),1,1);
            morphs[30] = dom.createMorphAt(dom.childAt(element1, [61]),1,1);
            morphs[31] = dom.createMorphAt(dom.childAt(element1, [63]),1,1);
            morphs[32] = dom.createMorphAt(dom.childAt(element1, [65]),1,1);
            return morphs;
          },
          statements: [
            ["inline","radio-button",[],["value","calColor50","name","color","radioId","calColor50","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[206,33],[206,44]]]]],[],[]]],["loc",[null,[202,20],[207,22]]]],
            ["inline","radio-button",[],["value","calColor51","name","color","radioId","calColor51","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[215,33],[215,44]]]]],[],[]]],["loc",[null,[211,20],[216,22]]]],
            ["inline","radio-button",[],["value","calColor53","name","color","radioId","calColor53","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[224,33],[224,44]]]]],[],[]]],["loc",[null,[220,20],[225,22]]]],
            ["inline","radio-button",[],["value","calColor54","name","color","radioId","calColor54","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[233,33],[233,44]]]]],[],[]]],["loc",[null,[229,20],[234,22]]]],
            ["inline","radio-button",[],["value","calColor55","name","color","radioId","calColor55","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[242,33],[242,44]]]]],[],[]]],["loc",[null,[238,20],[243,22]]]],
            ["inline","radio-button",[],["value","calColor56","name","color","radioId","calColor56","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[251,33],[251,44]]]]],[],[]]],["loc",[null,[247,20],[252,22]]]],
            ["inline","radio-button",[],["value","calColor57","name","color","radioId","calColor57","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[260,33],[260,44]]]]],[],[]]],["loc",[null,[256,20],[261,22]]]],
            ["inline","radio-button",[],["value","calColor58","name","color","radioId","calColor58","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[269,33],[269,44]]]]],[],[]]],["loc",[null,[265,20],[270,22]]]],
            ["inline","radio-button",[],["value","calColor59","name","color","radioId","calColor59","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[278,33],[278,44]]]]],[],[]]],["loc",[null,[274,20],[279,22]]]],
            ["inline","radio-button",[],["value","calColor60","name","color","radioId","calColor60","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[287,33],[287,44]]]]],[],[]]],["loc",[null,[283,20],[288,22]]]],
            ["inline","radio-button",[],["value","calColor61","name","color","radioId","calColor61","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[296,33],[296,44]]]]],[],[]]],["loc",[null,[292,20],[297,22]]]],
            ["inline","radio-button",[],["value","calColor62","name","color","radioId","calColor62","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[305,33],[305,44]]]]],[],[]]],["loc",[null,[301,20],[306,22]]]],
            ["inline","radio-button",[],["value","calColor63","name","color","radioId","calColor63","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[314,33],[314,44]]]]],[],[]]],["loc",[null,[310,20],[315,22]]]],
            ["inline","radio-button",[],["value","calColor64","name","color","radioId","calColor64","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[323,33],[323,44]]]]],[],[]]],["loc",[null,[319,20],[324,22]]]],
            ["inline","radio-button",[],["value","calColor65","name","color","radioId","calColor65","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[332,33],[332,44]]]]],[],[]]],["loc",[null,[328,20],[333,22]]]],
            ["inline","radio-button",[],["value","calColor66","name","color","radioId","calColor66","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[341,33],[341,44]]]]],[],[]]],["loc",[null,[337,20],[342,22]]]],
            ["inline","radio-button",[],["value","calColor67","name","color","radioId","calColor67","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[350,33],[350,44]]]]],[],[]]],["loc",[null,[346,20],[351,22]]]],
            ["inline","radio-button",[],["value","calColor68","name","color","radioId","calColor68","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[359,33],[359,44]]]]],[],[]]],["loc",[null,[355,20],[360,22]]]],
            ["inline","radio-button",[],["value","calColor69","name","color","radioId","calColor69","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[368,33],[368,44]]]]],[],[]]],["loc",[null,[364,20],[369,22]]]],
            ["inline","radio-button",[],["value","calColor70","name","color","radioId","calColor70","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[377,33],[377,44]]]]],[],[]]],["loc",[null,[373,20],[378,22]]]],
            ["inline","radio-button",[],["value","calColor71","name","color","radioId","calColor71","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[386,33],[386,44]]]]],[],[]]],["loc",[null,[382,20],[387,22]]]],
            ["inline","radio-button",[],["value","calColor72","name","color","radioId","calColor72","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[395,33],[395,44]]]]],[],[]]],["loc",[null,[391,20],[396,22]]]],
            ["inline","radio-button",[],["value","calColor73","name","color","radioId","calColor73","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[404,33],[404,44]]]]],[],[]]],["loc",[null,[400,20],[405,22]]]],
            ["inline","radio-button",[],["value","calColor74","name","color","radioId","calColor74","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[413,33],[413,44]]]]],[],[]]],["loc",[null,[409,20],[414,22]]]],
            ["inline","radio-button",[],["value","calColor75","name","color","radioId","calColor75","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[422,33],[422,44]]]]],[],[]]],["loc",[null,[418,20],[423,22]]]],
            ["inline","radio-button",[],["value","calColor76","name","color","radioId","calColor76","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[431,33],[431,44]]]]],[],[]]],["loc",[null,[427,20],[432,22]]]],
            ["inline","radio-button",[],["value","calColor77","name","color","radioId","calColor77","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[440,33],[440,44]]]]],[],[]]],["loc",[null,[436,20],[441,22]]]],
            ["inline","radio-button",[],["value","calColor78","name","color","radioId","calColor78","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[449,33],[449,44]]]]],[],[]]],["loc",[null,[445,20],[450,22]]]],
            ["inline","radio-button",[],["value","calColor79","name","color","radioId","calColor79","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[458,33],[458,44]]]]],[],[]]],["loc",[null,[454,20],[459,22]]]],
            ["inline","radio-button",[],["value","calColor80","name","color","radioId","calColor80","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[467,33],[467,44]]]]],[],[]]],["loc",[null,[463,20],[468,22]]]],
            ["inline","radio-button",[],["value","calColor81","name","color","radioId","calColor81","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[476,33],[476,44]]]]],[],[]]],["loc",[null,[472,20],[477,22]]]],
            ["inline","radio-button",[],["value","calColor82","name","color","radioId","calColor82","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[485,33],[485,44]]]]],[],[]]],["loc",[null,[481,20],[486,22]]]],
            ["inline","radio-button",[],["value","calColor83","name","color","radioId","calColor83","groupValue",["subexpr","@mut",[["get","model.color",["loc",[null,[494,33],[494,44]]]]],[],[]]],["loc",[null,[490,20],[495,22]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 503,
                  "column": 8
                },
                "end": {
                  "line": 505,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","colorTile");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element0, 'style');
              return morphs;
            },
            statements: [
              ["attribute","style",["concat",["background-color:",["get","model.color",["loc",[null,[504,60],[504,71]]]]," "]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 505,
                  "column": 8
                },
                "end": {
                  "line": 507,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","colorTile calColor83");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 500,
                "column": 4
              },
              "end": {
                "line": 512,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","col l-24/24 gutter");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"for","name");
            dom.setAttribute(el2,"class","field-label title required");
            var el3 = dom.createTextNode("Farbe");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2,"class","mt30 mb0");
            var el3 = dom.createTextNode("Die Farbe und Rolle der Mitarbeiter:in kann im ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"target","_blank");
            dom.setAttribute(el3,"class","is-highlighted-alternative");
            dom.setAttribute(el3,"href","https://portal.vetnative.com/users/list");
            var el4 = dom.createTextNode("VetNative Portal");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" definiert werden.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.colorHex",["loc",[null,[503,14],[503,28]]]]],[],0,1,["loc",[null,[503,8],[507,15]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 164,
              "column": 0
            },
            "end": {
              "line": 516,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Praxismanagement");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","user-role");
          dom.setAttribute(el3,"class","field-label title");
          var el4 = dom.createTextNode("Rolle");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","col pt30 l-12/24@desk l-12/24@tablet l-24/24 gutter");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","header-toggle");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4,"for","useRoleInCashDesk");
          dom.setAttribute(el4,"class","switch");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","description");
          var el4 = dom.createTextNode("\n        In Verrechnung automatisch zuweisen?\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]),3,3);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 1]),1,1);
          morphs[2] = dom.createMorphAt(element2,5,5);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[171,12],[171,20]]]]],[],0,1,["loc",[null,[171,6],[185,13]]]],
          ["inline","input",[],["type","checkbox","id","useRoleInCashDesk","classNames","switch","name","useRoleInCashDesk","checked",["subexpr","@mut",[["get","model.useRoleInCashDesk",["loc",[null,[189,108],[189,131]]]]],[],[]]],["loc",[null,[189,8],[189,133]]]],
          ["block","if",[["subexpr","eq",[1,2],[],["loc",[null,[197,10],[197,18]]]]],[],2,3,["loc",[null,[197,4],[512,11]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 540,
              "column": 18
            },
            "end": {
              "line": 542,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    required: true,\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 582,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/users-edit-form.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("Stammdaten");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-24/24 l-12/24@desk l-12/24@tablet gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 mb20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","user-gender");
        dom.setAttribute(el4,"class","field-label title");
        var el5 = dom.createTextNode("Anrede");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","user-gender");
        dom.setAttribute(el5,"class","field select");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("i");
        dom.setAttribute(el6,"class","arrow");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 mb20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","account-title");
        dom.setAttribute(el4,"class","field-label title");
        var el5 = dom.createTextNode("Titel");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5,"for","account-title");
        dom.setAttribute(el5,"class","field select");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("i");
        dom.setAttribute(el6,"class","arrow");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 mb20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","user-firstname");
        dom.setAttribute(el4,"class","field-label title");
        var el5 = dom.createTextNode("Vorname");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24 mb20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","user-lastname");
        dom.setAttribute(el4,"class","field-label title");
        var el5 = dom.createTextNode("Nachname");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","col l-24/24");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","user-phone");
        dom.setAttribute(el4,"class","field-label title");
        var el5 = dom.createTextNode("Telefon");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24 gutter text-center");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","context-buttons-onsite mt20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","button-rework context");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Bild hochladen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("Anmeldung");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","user-username");
        dom.setAttribute(el3,"class","field-label title");
        var el4 = dom.createTextNode("Email");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","user-password");
        dom.setAttribute(el3,"class","field-label title");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Passwort");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header-toggle");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","userActive");
        dom.setAttribute(el4,"class","switch");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","description");
        var el4 = dom.createTextNode("\n      Ist aktiv?\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","small");
        var el4 = dom.createTextNode("\n      Das neue Passwort muss die folgenden Kriterien erfüllen:\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"style","margin-top:10px;margin-bottom:0");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"style","list-style: disc inside;");
        var el6 = dom.createTextNode("Mindestens 8 Zeichen lang");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"style","list-style: disc inside;");
        var el6 = dom.createTextNode("Mindestens 1 Klein- und Großbuchstabe");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"style","list-style: disc inside;");
        var el6 = dom.createTextNode("Mindestens 1 Zahl");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"style","list-style: disc inside;");
        var el6 = dom.createTextNode("Erlaubte Sonderzeichen: @ * _ - . ! #");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("Sicherheit");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3,"for","user-username");
        dom.setAttribute(el3,"class","field-label title");
        var el4 = dom.createTextNode("Automatisch Abmelden nach");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"style","display: inline-block;vertical-align: middle;padding-top: 12px;");
        var el5 = dom.createTextNode("Minuten. ( 0 Minuten für ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("b");
        var el6 = dom.createTextNode("Deaktivierung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" dieser Funktion)");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col pt30 l-12/24@desk l-12/24@tablet l-24/24 gutter");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","header-toggle");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"for","showInactivity");
        dom.setAttribute(el4,"class","switch");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","description");
        var el4 = dom.createTextNode("\n      Warnhinweis bei bevorstehender Abmeldung anzeigen?\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function () {\n        $(\"#userEdit\").validate({\n            errorClass: \"state-error\",\n            validClass: \"state-success\",\n            errorElement: \"em\",\n            rules: {\n                'user-firstname': {\n                    required: true\n                },\n                'user-gender': {\n                    required: true\n                },\n                'user-lastname': {\n                    required: true\n                },\n                'user-username': {\n                    email: true,\n                },\n                'user-password': {\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("                    pwcheck: true\n                },\n                'user-phone': {\n                    pattern: /^\\+?[\\s\\d]+$/\n                },\n            },\n            messages: {\n              password: {\n                pwcheck: \"Das Passwort entspricht nicht den Kriterien!\"\n              }\n            },\n            highlight: function (element, errorClass, validClass) {\n                $(element).closest('.field').addClass(errorClass).removeClass(validClass);\n            },\n            unhighlight: function (element, errorClass, validClass) {\n                $(element).closest('.field').removeClass(errorClass).addClass(validClass);\n            },\n            errorPlacement: function (error, element) {\n                if (element.is(\":radio\") || element.is(\":checkbox\")) {\n                    element.closest('.option-group').after(error);\n                } else {\n                    error.insertAfter(element.parent());\n                }\n            }\n        });\n\n        $.validator.addMethod(\"pwcheck\",\n          function(value, element) {\n            if(!value) {\n              return true;\n            }\n            return /^[A-Za-z0-9\\d=!\\-@._*#]*$/.(value) // consists of only these\n              && /[a-z]/.(value) // has a lowercase letter\n              && /[A-Z]/.(value) // has a lowercase letter\n              && /\\d/.(value) // has a digit\n              && value.length >= 8  // at least 8\n          });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element5, [3, 1, 1]);
        var element7 = dom.childAt(fragment, [6]);
        var element8 = dom.childAt(element7, [3]);
        var element9 = dom.childAt(element8, [3]);
        var element10 = dom.childAt(fragment, [10]);
        var morphs = new Array(19);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 3, 1]),1,1);
        morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 3, 1]),1,1);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [5, 3]),1,1);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [7, 3]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [9, 3]),1,1);
        morphs[5] = dom.createMorphAt(element5,1,1);
        morphs[6] = dom.createElementMorph(element6);
        morphs[7] = dom.createMorphAt(dom.childAt(element7, [1, 3]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
        morphs[9] = dom.createMorphAt(element9,1,1);
        morphs[10] = dom.createMorphAt(element9,3,3);
        morphs[11] = dom.createMorphAt(dom.childAt(element7, [5, 1]),1,1);
        morphs[12] = dom.createMorphAt(dom.childAt(element10, [1, 3]),1,1);
        morphs[13] = dom.createMorphAt(dom.childAt(element10, [3, 1]),1,1);
        morphs[14] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[15] = dom.createMorphAt(fragment,13,13,contextualElement);
        morphs[16] = dom.createMorphAt(fragment,15,15,contextualElement);
        morphs[17] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[18] = dom.createMorphAt(dom.childAt(fragment, [19]),1,1);
        return morphs;
      },
      statements: [
        ["inline","view",[["get","Ember.Select",["loc",[null,[9,17],[9,29]]]]],["content",["subexpr","@mut",[["get","genders",["loc",[null,[10,25],[10,32]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","name","user-gender","selection",["subexpr","@mut",[["get","model.gender",["loc",[null,[14,27],[14,39]]]]],[],[]],"prompt","Bitte wählen"],["loc",[null,[9,10],[16,12]]]],
        ["inline","view",[["get","Ember.Select",["loc",[null,[25,17],[25,29]]]]],["content",["subexpr","@mut",[["get","titles",["loc",[null,[26,25],[26,31]]]]],[],[]],"optionValuePath","content.id","optionLabelPath","content.name","prompt","Bitte wählen","selection",["subexpr","@mut",[["get","model.title",["loc",[null,[30,27],[30,38]]]]],[],[]]],["loc",[null,[25,10],[31,12]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.firstname",["loc",[null,[39,22],[39,37]]]]],[],[]],"class","gui-input","name","user-firstname"],["loc",[null,[39,8],[39,79]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.lastname",["loc",[null,[45,22],[45,36]]]]],[],[]],"class","gui-input","name","user-lastname"],["loc",[null,[45,8],[45,77]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.phone",["loc",[null,[51,22],[51,33]]]]],[],[]],"class","gui-input","name","user-phone","title","Nur Nummern und optionales + erlaubt"],["loc",[null,[51,8],[51,116]]]],
        ["block","if",[["get","tmpUserImage",["loc",[null,[56,11],[56,23]]]]],[],0,1,["loc",[null,[56,4],[60,11]]]],
        ["element","action",["showImagePanel"],[],["loc",[null,[64,11],[64,38]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.username",["loc",[null,[76,20],[76,34]]]]],[],[]],"autofocus",true,"required",true,"class","gui-input","title","Bitte geben Sie eine gültige Email-Adresse an","name","user-username"],["loc",[null,[76,6],[76,158]]]],
        ["block","if",[["get","editMode",["loc",[null,[80,63],[80,71]]]]],[],2,null,["loc",[null,[80,57],[80,85]]]],
        ["inline","input",[],["type","password","value",["subexpr","@mut",[["get","model.password",["loc",[null,[82,36],[82,50]]]]],[],[]],"autofocus",true,"class","gui-input","title","Das Passwort entspricht nicht den Kriterien","name","user-password"],["loc",[null,[82,6],[82,158]]]],
        ["block","if",[["get","editMode",["loc",[null,[83,12],[83,20]]]]],[],3,null,["loc",[null,[83,6],[85,13]]]],
        ["inline","input",[],["type","checkbox","id","userActive","classNames","switch","name","userActive","checked",["subexpr","@mut",[["get","model.active",["loc",[null,[91,92],[91,104]]]]],[],[]]],["loc",[null,[91,6],[91,106]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","model.maxInactivityTime",["loc",[null,[117,20],[117,43]]]]],[],[]],"class","mw50 gui-input","name","mandant-maxInactivityTime"],["loc",[null,[117,6],[117,101]]]],
        ["inline","input",[],["type","checkbox","id","showInactivity","classNames","switch","name","showInactivity","checked",["subexpr","@mut",[["get","model.showInactivityLabel",["loc",[null,[122,100],[122,125]]]]],[],[]]],["loc",[null,[122,6],[122,127]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-at-least",["essential"],[],["loc",[null,[131,11],[131,36]]]],["subexpr","has-permission",["c_payments"],[],["loc",[null,[131,37],[131,66]]]]],[],["loc",[null,[131,6],[131,67]]]]],[],4,null,["loc",[null,[131,0],[140,7]]]],
        ["block","if",[["subexpr","has-permission",["ehapo"],[],["loc",[null,[141,6],[141,30]]]]],[],5,null,["loc",[null,[141,0],[162,7]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[164,6],[164,34]]]]],[],6,null,["loc",[null,[164,0],[516,7]]]],
        ["inline","image-upload-and-select-panel",[],["insertImage","imageChanged","actionReceiver",["subexpr","@mut",[["get","imageUploadAndSelectPanel",["loc",[null,[518,74],[518,99]]]]],[],[]]],["loc",[null,[518,0],[518,101]]]],
        ["block","unless",[["get","editMode",["loc",[null,[540,28],[540,36]]]]],[],7,null,["loc",[null,[540,18],[542,29]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  }()));

});