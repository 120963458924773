define('jason-frontend/templates/purchases/add-product', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 8
              },
              "end": {
                "line": 38,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","form-field-rework");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","select-placeholder");
            var el3 = dom.createTextNode("Firma");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","suppliers",["loc",[null,[30,24],[30,33]]]]],[],[]],"value",["subexpr","@mut",[["get","filterSupplier",["loc",[null,[31,22],[31,36]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionValuePath","id","optionLabelPath","name","allowClear",["subexpr","@mut",[["get","falses",["loc",[null,[35,27],[35,33]]]]],[],[]]],["loc",[null,[29,14],[36,16]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 6
              },
              "end": {
                "line": 51,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","form-field-rework");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2,"class","select-placeholder");
            var el3 = dom.createTextNode("Kategorie");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
            return morphs;
          },
          statements: [
            ["inline","select-2",[],["content",["subexpr","@mut",[["get","partnerCategories",["loc",[null,[43,24],[43,41]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedPartnerCategoryFilter",["loc",[null,[44,22],[44,51]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","name","allowClear",true,"placeholder","Alle"],["loc",[null,[42,14],[49,16]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 53,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","div-horizontal");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","filter-lane grid");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Hauptkategorie");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]),3,3);
          morphs[1] = dom.createMorphAt(element11,3,3);
          morphs[2] = dom.createMorphAt(element11,4,4);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","categories",["loc",[null,[18,20],[18,30]]]]],[],[]],"value",["subexpr","@mut",[["get","selectedCategoryFilter",["loc",[null,[19,18],[19,40]]]]],[],[]],"optionLabelPath","name","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[17,10],[24,12]]]],
          ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[26,19],[26,32]]]]],[],["loc",[null,[26,14],[26,33]]]]],[],0,null,["loc",[null,[26,8],[38,15]]]],
          ["block","if",[["get","partnerCategoriesThere",["loc",[null,[39,12],[39,34]]]]],[],1,null,["loc",[null,[39,6],[51,13]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 0
              },
              "end": {
                "line": 66,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Produkte\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element10, 'class');
            morphs[1] = dom.createMorphAt(element10,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",[" pt40 pr20 u-text--right col l-24/24@phone l-24/24@tablet l-",["subexpr","css-bool-evaluator",[["get","partnerCategoriesThere",["loc",[null,[63,95],[63,117]]]],"6","12"],[],["loc",[null,[63,74],[63,129]]]],"/24@desk gutter like-h3 u-text--right"]]],
            ["inline","format-number-german-dots",[["get","totalEntries",["loc",[null,[64,32],[64,44]]]]],[],["loc",[null,[64,4],[64,46]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 66,
                "column": 0
              },
              "end": {
                "line": 70,
                "column": 0
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Produkte\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element9, 'class');
            morphs[1] = dom.createMorphAt(element9,1,1);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["pt40 pr20 u-text--right col l-24/24@phone l-24/24@tablet l-",["subexpr","css-bool-evaluator",[["get","partnerCategoriesThere",["loc",[null,[67,96],[67,118]]]],"12","18"],[],["loc",[null,[67,75],[67,131]]]],"/24@desk gutter like-h3 u-text--right"]]],
            ["inline","format-number-german-dots",[["get","totalEntries",["loc",[null,[68,34],[68,46]]]]],[],["loc",[null,[68,6],[68,48]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 0
            },
            "end": {
              "line": 73,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","filter-box grid");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[62,11],[62,24]]]]],[],["loc",[null,[62,6],[62,25]]]]],[],0,1,["loc",[null,[62,0],[70,7]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 92,
              "column": 2
            },
            "end": {
              "line": 94,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[93,4],[93,25]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 99,
                "column": 12
              },
              "end": {
                "line": 101,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","purchase-product-list",[],["linkToEditEnabled",["subexpr","@mut",[["get","linkToEditEnabled",["loc",[null,[100,56],[100,73]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[100,82],[100,89]]]]],[],[]],"orderId",["subexpr","@mut",[["get","orderId",["loc",[null,[100,98],[100,105]]]]],[],[]],"showAdd2cart",["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[100,124],[100,137]]]]],[],["loc",[null,[100,119],[100,138]]]],"toggleFavorite","toggleFavorite","add","addToCart","checkLaboklinPanel","checkLaboklinPanel"],["loc",[null,[100,14],[100,229]]]]
          ],
          locals: ["product"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 96,
              "column": 2
            },
            "end": {
              "line": 105,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table table__first_row table--small product-list-purchase");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          dom.setAttribute(el2,"id","product-list");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model",["loc",[null,[99,20],[99,25]]]]],[],0,null,["loc",[null,[99,12],[101,21]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 107,
                "column": 8
              },
              "end": {
                "line": 109,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","purchase-product-cell",[],["sidebarActive",["subexpr","@mut",[["get","sidebarActive",["loc",[null,[108,48],[108,61]]]]],[],[]],"product",["subexpr","@mut",[["get","product",["loc",[null,[108,70],[108,77]]]]],[],[]],"orderId",["subexpr","@mut",[["get","orderId",["loc",[null,[108,86],[108,93]]]]],[],[]],"showAdd2cart",["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[108,112],[108,125]]]]],[],["loc",[null,[108,107],[108,126]]]],"toggleFavorite","toggleFavorite","click","gotoDetail","add","addToCart","checkLaboklinPanel","checkLaboklinPanel"],["loc",[null,[108,10],[108,236]]]]
          ],
          locals: ["product"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 2
            },
            "end": {
              "line": 111,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","grid");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model",["loc",[null,[107,16],[107,21]]]]],[],0,null,["loc",[null,[107,8],[109,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 121,
                "column": 6
              },
              "end": {
                "line": 123,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","button-prev",["loc",[null,[122,8],[122,23]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 119,
              "column": 2
            },
            "end": {
              "line": 125,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","action-icons");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          return morphs;
        },
        statements: [
          ["block","link-to",["purchases.choose"],["class","icon-button icon-button--small"],0,null,["loc",[null,[121,6],[123,18]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 125,
              "column": 2
            },
            "end": {
              "line": 131,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","action-icons");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","icon-button icon-button--success");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element8);
          morphs[1] = dom.createMorphAt(element8,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["openPznCheckPanel",["get","order.id",["loc",[null,[127,79],[127,87]]]]],[],["loc",[null,[127,50],[127,89]]]],
          ["inline","button-add-entry",[],["content","Produkt anlegen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[128,8],[128,97]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 139,
                "column": 6
              },
              "end": {
                "line": 141,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge private");
            var el2 = dom.createTextNode("Privat");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 141,
                  "column": 6
                },
                "end": {
                  "line": 143,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge lab");
              var el2 = dom.createTextNode("Labor");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 143,
                  "column": 6
                },
                "end": {
                  "line": 145,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge practice");
              var el2 = dom.createTextNode("Praxis");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 141,
                "column": 6
              },
              "end": {
                "line": 145,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","order.laboklinOrder",["loc",[null,[141,16],[141,35]]]]],[],0,1,["loc",[null,[141,6],[145,6]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 133,
              "column": 3
            },
            "end": {
              "line": 169,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","minicartSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","cursor");
          var el3 = dom.createTextNode("Warenkorb");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","label-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","basket-quickinfos");
          dom.setAttribute(el2,"class","grid");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-16/24");
          var el4 = dom.createTextNode("\n        Artikel ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"class","badge");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        Warenwert netto\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-8/24 u-text--right");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","cartButtons");
          dom.setAttribute(el2,"class","col l-24/24");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-14/24");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","button-rework context default");
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Warenkorb wechseln");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-10/24");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","button-rework context primary");
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Warenkorb");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","scrollable");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","gotoCart");
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el3,"style","width: 18px;");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" zum Warenkorb");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(element0, [7]);
          var element4 = dom.childAt(element0, [9]);
          var element5 = dom.childAt(element4, [1, 1]);
          var element6 = dom.childAt(element4, [3, 1]);
          var element7 = dom.childAt(element0, [13]);
          var morphs = new Array(9);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [1, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]),2,2);
          morphs[5] = dom.createElementMorph(element5);
          morphs[6] = dom.createElementMorph(element6);
          morphs[7] = dom.createMorphAt(dom.childAt(element0, [11]),1,1);
          morphs[8] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[135,9],[135,40]]]],
          ["element","action",["toCart",["get","order.id",["loc",[null,[136,41],[136,49]]]]],[],["loc",[null,[136,23],[136,51]]]],
          ["block","if",[["get","order.privateOrder",["loc",[null,[139,12],[139,30]]]]],[],0,1,["loc",[null,[139,6],[145,13]]]],
          ["content","order.entryCount",["loc",[null,[149,36],[149,56]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","order.totalNet",["loc",[null,[153,37],[153,51]]]]],[],[]],"classNames","currency ml5"],["loc",[null,[153,13],[153,79]]]],
          ["element","action",["gotoCarts"],[],["loc",[null,[158,16],[158,38]]]],
          ["element","action",["gotoCart",["get","order.id",["loc",[null,[161,34],[161,42]]]]],[],["loc",[null,[161,14],[161,44]]]],
          ["inline","mini-cart",[],["model",["subexpr","@mut",[["get","order",["loc",[null,[165,24],[165,29]]]]],[],[]],"delete","deleteCartEntry","toCart","gotoCart","toProduct","toProduct","actionReceiver",["subexpr","@mut",[["get","minicart",["loc",[null,[165,110],[165,118]]]]],[],[]]],["loc",[null,[165,6],[165,120]]]],
          ["element","action",["gotoCart",["get","order.id",["loc",[null,[167,43],[167,51]]]]],[],["loc",[null,[167,23],[167,53]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 170,
                "column": 2
              },
              "end": {
                "line": 174,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[172,63],[172,198]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 169,
              "column": 0
            },
            "end": {
              "line": 175,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[170,13],[170,26]]]]],[],["loc",[null,[170,8],[170,27]]]]],[],0,null,["loc",[null,[170,2],[174,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 192,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/purchases/add-product.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Artikel bestellen");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","purchase-product-search");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper-rework no-box@phone");
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","quick-filter box@phone mt20");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4,"class","title");
        var el5 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon");
        dom.setAttribute(el4,"for","filterAll");
        var el5 = dom.createTextNode("Alle");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4,"class","selectable-item has-icon favs");
        dom.setAttribute(el4,"for","filterFavs");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      Favoriten");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"id","viewChanger");
        dom.setAttribute(el4,"class","");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","jason-icon");
        var el7 = dom.createTextNode("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        var el6 = dom.createElement("span");
        dom.setAttribute(el6,"class","jason-icon");
        var el7 = dom.createTextNode("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","pagination-wrapper is-fixed");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $( window ).resize(function() {\n      $('aside.side-bar').css('min-height', $(window).height()-80);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-300);\n    });\n    $('aside.side-bar').css('min-height', $(window).height()-80);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height()-300);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element12 = dom.childAt(fragment, [0]);
        var element13 = dom.childAt(element12, [3]);
        var element14 = dom.childAt(element13, [1]);
        var element15 = dom.childAt(fragment, [2]);
        var element16 = dom.childAt(element15, [3]);
        var element17 = dom.childAt(element16, [1]);
        var element18 = dom.childAt(element17, [11]);
        var element19 = dom.childAt(element18, [1]);
        var element20 = dom.childAt(element18, [3]);
        var morphs = new Array(22);
        morphs[0] = dom.createAttrMorph(element12, 'class');
        morphs[1] = dom.createMorphAt(element14,1,1);
        morphs[2] = dom.createMorphAt(element14,3,3);
        morphs[3] = dom.createMorphAt(element13,3,3);
        morphs[4] = dom.createAttrMorph(element15, 'class');
        morphs[5] = dom.createMorphAt(element15,1,1);
        morphs[6] = dom.createMorphAt(element17,3,3);
        morphs[7] = dom.createMorphAt(element17,7,7);
        morphs[8] = dom.createMorphAt(dom.childAt(element17, [9]),0,0);
        morphs[9] = dom.createAttrMorph(element19, 'class');
        morphs[10] = dom.createElementMorph(element19);
        morphs[11] = dom.createAttrMorph(element20, 'class');
        morphs[12] = dom.createElementMorph(element20);
        morphs[13] = dom.createMorphAt(element16,3,3);
        morphs[14] = dom.createMorphAt(element16,5,5);
        morphs[15] = dom.createMorphAt(dom.childAt(element15, [5]),1,1);
        morphs[16] = dom.createMorphAt(element15,7,7);
        morphs[17] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[18] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[19] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[20] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[21] = dom.createMorphAt(fragment,12,12,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,69],[1,80]]]],"with-filters",""],[],["loc",[null,[1,48],[1,100]]]],"  ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,123],[1,136]]]],"sidebar",""],[],["loc",[null,[1,102],[1,151]]]]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[8,30],[8,40]]]]],[],[]],"placeHolder","Artikel suchen ...","search","applyFilter"],["loc",[null,[8,6],[8,96]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[9,31],[9,42]]]]],[],[]]],["loc",[null,[9,6],[9,44]]]],
        ["block","if",[["get","showFilters",["loc",[null,[11,10],[11,21]]]]],[],0,null,["loc",[null,[11,4],[53,11]]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[58,77],[58,90]]]]],[],["loc",[null,[58,72],[58,91]]]],"24","18"],[],["loc",[null,[58,51],[58,103]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[58,162],[58,175]]]],"sidebar-active",""],[],["loc",[null,[58,141],[58,197]]]]," rework-content site-content add-product add-purchase-product"]]],
        ["block","if",[["get","showFilters",["loc",[null,[60,6],[60,17]]]]],[],1,null,["loc",[null,[60,0],[73,7]]]],
        ["inline","input",[],["type","checkbox","id","filterAll","name","filterAll","checked",["subexpr","@mut",[["get","filterAll",["loc",[null,[79,68],[79,77]]]]],[],[]]],["loc",[null,[79,4],[79,79]]]],
        ["inline","input",[],["type","checkbox","id","filterFavs","name","filterFavs","checked",["subexpr","@mut",[["get","filterFavs",["loc",[null,[81,70],[81,80]]]]],[],[]]],["loc",[null,[81,4],[81,82]]]],
        ["inline","button-favorite",[],["content","","size",18,"showStroke",false,"color","#999999","classNames",""],["loc",[null,[82,66],[82,151]]]],
        ["attribute","class",["concat",["btn-",["subexpr","css-bool-evaluator",[["get","list",["loc",[null,[86,46],[86,50]]]],"default","selected"],[],["loc",[null,[86,25],[86,73]]]]," btn icon-btn"]]],
        ["element","action",["changeView",false],[],["loc",[null,[86,88],[86,117]]]],
        ["attribute","class",["concat",["btn-",["subexpr","css-bool-evaluator",[["get","list",["loc",[null,[87,46],[87,50]]]],"selected","default"],[],["loc",[null,[87,25],[87,73]]]]," btn icon-btn"]]],
        ["element","action",["changeView",true],[],["loc",[null,[87,88],[87,116]]]],
        ["block","if",[["get","showLoading",["loc",[null,[92,8],[92,19]]]]],[],2,null,["loc",[null,[92,2],[94,9]]]],
        ["block","if",[["get","list",["loc",[null,[96,8],[96,12]]]]],[],3,4,["loc",[null,[96,2],[111,9]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[116,27],[116,34]]]]],[],[]],"numPagesToShow",7],["loc",[null,[116,4],[116,53]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[119,18],[119,31]]]]],[],["loc",[null,[119,13],[119,32]]]],["subexpr","not",[["subexpr","is-jason-network",[],[],["loc",[null,[119,38],[119,56]]]]],[],["loc",[null,[119,33],[119,57]]]]],[],["loc",[null,[119,8],[119,58]]]]],[],5,6,["loc",[null,[119,2],[131,9]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[133,19],[133,32]]]]],[],["loc",[null,[133,14],[133,33]]]],["get","sidebarActive",["loc",[null,[133,34],[133,47]]]]],[],["loc",[null,[133,9],[133,48]]]]],[],7,8,["loc",[null,[133,3],[175,7]]]],
        ["inline","purchase-order-laboklin-chooser",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[177,40],[177,45]]]]],[],[]],"orderId",["subexpr","@mut",[["get","order.id",["loc",[null,[177,54],[177,62]]]]],[],[]],"data",["subexpr","@mut",[["get","order.laboklinDatas",["loc",[null,[177,68],[177,87]]]]],[],[]],"modelName",["subexpr","@mut",[["get","model.name",["loc",[null,[177,98],[177,108]]]]],[],[]],"addNew","newLaboklinForm","addProduct","addProduct"],["loc",[null,[177,0],[177,159]]]],
        ["inline","purchase-order-laboklin-panel",[],["form",["subexpr","@mut",[["get","laboklinForm",["loc",[null,[178,37],[178,49]]]]],[],[]],"model",["subexpr","@mut",[["get","model.id",["loc",[null,[178,56],[178,64]]]]],[],[]],"orderId",["subexpr","@mut",[["get","order.id",["loc",[null,[178,73],[178,81]]]]],[],[]],"modelName",["subexpr","@mut",[["get","model.name",["loc",[null,[178,92],[178,102]]]]],[],[]],"confirm","addToCart"],["loc",[null,[178,0],[178,124]]]],
        ["inline","edit-purchase-product-panel",[],["save","saveProduct","actionReceiver",["subexpr","@mut",[["get","editPurchaseProductPanel",["loc",[null,[179,64],[179,88]]]]],[],[]]],["loc",[null,[179,0],[179,90]]]],
        ["inline","create-product-pzn-panel",[],["save","checkPzn","toCreateProduct","toCreateProduct","actionReceiver",["subexpr","@mut",[["get","checkPzn",["loc",[null,[180,92],[180,100]]]]],[],[]]],["loc",[null,[180,0],[180,102]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  }()));

});