define('jason-frontend/helpers/get-payment-moral', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports.getPaymentMoral = getPaymentMoral;

  function getPaymentMoral(params) {
    var customer = params[0],
        useModel = params[1];

    try {
      if (customer) {
        if (useModel && customer.get('sued') || !useModel && customer.sued) {
          return 'sued';
        }
        if (useModel && customer.get('paymentMoral') || !useModel && customer.paymentMoral) {
          return useModel ? customer.get('paymentMoral').get('id') : customer.paymentMoral.id;
        }
      }
    } catch (e) {}

    return 'good';
  }

  exports['default'] = Ember['default'].Helper.helper(getPaymentMoral);

});