define('jason-frontend/templates/patients/edit', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 12
              },
              "end": {
                "line": 21,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/chip.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createTextNode("Chip registrieren");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element104 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element104);
            return morphs;
          },
          statements: [
            ["element","action",["openChipPanel",["get","model",["loc",[null,[18,42],[18,47]]]]],[],["loc",[null,[18,17],[18,49]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 10
            },
            "end": {
              "line": 22,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/picture-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Tierbild ändern");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Notiz erstellen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element105 = dom.childAt(fragment, [1]);
          var element106 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element105);
          morphs[1] = dom.createElementMorph(element106);
          morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["element","action",["showImagePanel"],[],["loc",[null,[11,15],[11,42]]]],
          ["element","action",["addNewMemo"],[],["loc",[null,[14,15],[14,38]]]],
          ["block","if",[["get","model.chipId",["loc",[null,[17,18],[17,30]]]]],[],0,null,["loc",[null,[17,12],[21,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 10
            },
            "end": {
              "line": 27,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/dowload.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Ordner herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element103 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element103);
          return morphs;
        },
        statements: [
          ["element","action",["downloadFolder"],[],["loc",[null,[24,15],[24,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 10
            },
            "end": {
              "line": 32,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/dowload.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Als CSV herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element102 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element102);
          return morphs;
        },
        statements: [
          ["element","action",["downloadAppointmentCsv",["get","model.id",["loc",[null,[29,49],[29,57]]]]],[],["loc",[null,[29,15],[29,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 10
            },
            "end": {
              "line": 37,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/view.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Alle Laborwerte anzeigen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element101 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element101);
          return morphs;
        },
        statements: [
          ["element","action",["openAllLabParamsPanel",["get","model.id",["loc",[null,[34,49],[34,57]]]]],[],["loc",[null,[34,15],[34,59]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 10
            },
            "end": {
              "line": 48,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/notification-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Erinnerung planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/appointment-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Termin planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","d-flex align-items-center dropdown-item");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/more-primary.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Zukunft planen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element98 = dom.childAt(fragment, [1]);
          var element99 = dom.childAt(fragment, [3]);
          var element100 = dom.childAt(fragment, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element98);
          morphs[1] = dom.createElementMorph(element99);
          morphs[2] = dom.createElementMorph(element100);
          return morphs;
        },
        statements: [
          ["element","action",["openReminderPanel"],[],["loc",[null,[39,15],[39,45]]]],
          ["element","action",["openAppointmentPanel"],[],["loc",[null,[42,15],[42,48]]]],
          ["element","action",["addFutureAction",["get","model.id",["loc",[null,[45,42],[45,50]]]]],[],["loc",[null,[45,15],[45,52]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 6
            },
            "end": {
              "line": 59,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/upload.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neues Dokument");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element97 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element97);
          return morphs;
        },
        statements: [
          ["element","action",["openDocumentUpload"],[],["loc",[null,[55,11],[55,42]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 6
            },
            "end": {
              "line": 64,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neue Aufgabe");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element96 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element96);
          return morphs;
        },
        statements: [
          ["element","action",["openNew"],[],["loc",[null,[61,11],[61,31]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 6
            },
            "end": {
              "line": 68,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/add.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("Neuer Termin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element95 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element95);
          return morphs;
        },
        statements: [
          ["element","action",["openAppointmentPanel"],[],["loc",[null,[66,11],[66,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 6
            },
            "end": {
              "line": 72,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("eltiga Historie");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element94 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element94);
          morphs[1] = dom.createMorphAt(element94,0,0);
          return morphs;
        },
        statements: [
          ["element","action",["openLogbook"],[],["loc",[null,[70,11],[70,35]]]],
          ["inline","button-history",[],["color","#fff","size","30","showStroke",false,"content",""],["loc",[null,[70,66],[70,135]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 73,
              "column": 6
            },
            "end": {
              "line": 77,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/download.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("CSV herunterladen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element93 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element93);
          return morphs;
        },
        statements: [
          ["element","action",["downloadHistoryCsv",["get","model.id",["loc",[null,[74,41],[74,49]]]]],[],["loc",[null,[74,11],[74,51]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 78,
              "column": 6
            },
            "end": {
              "line": 82,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","button-rework context");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/messages.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("eltiga Nachricht");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element92 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element92);
          return morphs;
        },
        statements: [
          ["element","action",["openDirectMessage"],[],["loc",[null,[79,11],[79,41]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 93,
              "column": 4
            },
            "end": {
              "line": 95,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","animal-icon",[],["classNames","patient-gender me-20","animal-id",["subexpr","@mut",[["get","model.category.id",["loc",[null,[94,64],[94,81]]]]],[],[]],"race-id",["subexpr","@mut",[["get","model.digitalRace.icon",["loc",[null,[94,90],[94,112]]]]],[],[]],"size","55","color","#fff","content",""],["loc",[null,[94,6],[94,148]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 98,
                "column": 51
              },
              "end": {
                "line": 98,
                "column": 183
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Besitzer:");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.customer.firstname",["loc",[null,[98,127],[98,155]]]],
            ["content","model.customer.lastname",["loc",[null,[98,156],[98,183]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 98,
              "column": 29
            },
            "end": {
              "line": 98,
              "column": 195
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["customers.edit",["get","model.customer",["loc",[null,[98,79],[98,93]]]]],["class","is-highlighted"],0,null,["loc",[null,[98,51],[98,195]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 108,
              "column": 2
            },
            "end": {
              "line": 110,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-activities");
          var el2 = dom.createTextNode("Journal");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element91 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element91, 'class');
          morphs[1] = dom.createElementMorph(element91);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[109,53],[109,62]]]],"activities"],[],["loc",[null,[109,49],[109,76]]]],"is-active",""],[],["loc",[null,[109,28],[109,93]]]]]]],
          ["element","action",["selectTab","activities"],[],["loc",[null,[109,118],[109,153]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 111,
              "column": 2
            },
            "end": {
              "line": 113,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-history");
          var el2 = dom.createTextNode("Historie");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element90 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element90, 'class');
          morphs[1] = dom.createElementMorph(element90);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[112,53],[112,62]]]],"history"],[],["loc",[null,[112,49],[112,73]]]],"is-active",""],[],["loc",[null,[112,28],[112,90]]]]]]],
          ["element","action",["selectTab","history"],[],["loc",[null,[112,112],[112,144]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 117,
              "column": 2
            },
            "end": {
              "line": 119,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-correspondance");
          var el2 = dom.createTextNode("Korrespondenz");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element89 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element89, 'class');
          morphs[1] = dom.createElementMorph(element89);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[118,53],[118,62]]]],"correspondance"],[],["loc",[null,[118,49],[118,80]]]],"is-active",""],[],["loc",[null,[118,28],[118,97]]]]]]],
          ["element","action",["selectTab","correspondance"],[],["loc",[null,[118,126],[118,165]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 121,
              "column": 2
            },
            "end": {
              "line": 123,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-todo");
          var el2 = dom.createTextNode("TO-DO");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element88 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element88, 'class');
          morphs[1] = dom.createElementMorph(element88);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[122,53],[122,62]]]],"todo"],[],["loc",[null,[122,49],[122,70]]]],"is-active",""],[],["loc",[null,[122,28],[122,87]]]]]]],
          ["element","action",["selectTab","todo"],[],["loc",[null,[122,106],[122,135]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 124,
              "column": 2
            },
            "end": {
              "line": 126,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"href","#tab-appointments");
          var el2 = dom.createTextNode("Termine");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element87 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element87, 'class');
          morphs[1] = dom.createElementMorph(element87);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[125,53],[125,62]]]],"appointments"],[],["loc",[null,[125,49],[125,78]]]],"is-active",""],[],["loc",[null,[125,28],[125,95]]]]]]],
          ["element","action",["selectTab","appointments"],[],["loc",[null,[125,122],[125,159]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 137,
              "column": 20
            },
            "end": {
              "line": 139,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","patients-edit-additional-form",[],["etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[138,71],[138,87]]]]],[],[]],"edit",true,"openLogbook","openEtigaMasterdataLogbook","model",["subexpr","@mut",[["get","model",["loc",[null,[138,145],[138,150]]]]],[],[]],"customer",["subexpr","@mut",[["get","customer",["loc",[null,[138,160],[138,168]]]]],[],[]]],["loc",[null,[138,22],[138,170]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 143,
              "column": 16
            },
            "end": {
              "line": 145,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Gewichtsentwicklung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 248,
              "column": 14
            },
            "end": {
              "line": 251,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[249,40],[249,50]]]]],[],[]],"placeHolder","Dokument suchen","search","searchDocument"],["loc",[null,[249,16],[249,106]]]],
          ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[250,41],[250,52]]]]],[],[]]],["loc",[null,[250,16],[250,54]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child21 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 258,
                "column": 20
              },
              "end": {
                "line": 258,
                "column": 240
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element86 = dom.childAt(fragment, [0]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element86, 'class');
            morphs[1] = dom.createAttrMorph(element86, 'style');
            morphs[2] = dom.createAttrMorph(element86, 'data-id');
            morphs[3] = dom.createElementMorph(element86);
            morphs[4] = dom.createMorphAt(element86,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","tag.selected",["loc",[null,[258,96],[258,108]]]],"selected",""],[],["loc",[null,[258,75],[258,124]]]]]]],
            ["attribute","style",["concat",["background-color: ",["get","tag.color",["loc",[null,[258,153],[258,162]]]]]]],
            ["attribute","data-id",["concat",[["get","tag.id",["loc",[null,[258,177],[258,183]]]]]]],
            ["element","action",["selectSearchTag",["get","tag",["loc",[null,[258,214],[258,217]]]]],[],["loc",[null,[258,187],[258,219]]]],
            ["content","tag.name",["loc",[null,[258,220],[258,232]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 255,
              "column": 16
            },
            "end": {
              "line": 260,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter box");
          dom.setAttribute(el1,"id","tagFilters");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Tags");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","tags",["loc",[null,[258,28],[258,32]]]]],[],0,null,["loc",[null,[258,20],[258,249]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child22 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 310,
                "column": 22
              },
              "end": {
                "line": 312,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        Alle ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" um ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Uhr, ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Wiederholungen\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
            morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
            morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
            return morphs;
          },
          statements: [
            ["content","item.dueTimeUnitInterval",["loc",[null,[311,29],[311,57]]]],
            ["content","item.dueTimeUnit.name",["loc",[null,[311,58],[311,83]]]],
            ["content","item.time",["loc",[null,[311,87],[311,100]]]],
            ["content","item.dueTimeUnitIterations",["loc",[null,[311,106],[311,136]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 312,
                "column": 22
              },
              "end": {
                "line": 314,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","item.dueDate",["loc",[null,[313,38],[313,50]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[313,24],[313,73]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 321,
                "column": 28
              },
              "end": {
                "line": 321,
                "column": 102
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge success");
            var el2 = dom.createTextNode("erledigt");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 321,
                "column": 102
              },
              "end": {
                "line": 321,
                "column": 149
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge");
            var el2 = dom.createTextNode("offen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 306,
              "column": 20
            },
            "end": {
              "line": 333,
              "column": 20
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-5/24@desk");
          dom.setAttribute(el2,"data-label","Fälligkeit");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-10/24@desk");
          dom.setAttribute(el2,"data-label","Titel");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-24/24 l-4/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell buttons-2 table__cell--action l-6/24 l-5/24@desk buttons-2");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square exp");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square exp");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createTextNode("Bearbeiten");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element82 = dom.childAt(fragment, [1]);
          var element83 = dom.childAt(element82, [7]);
          var element84 = dom.childAt(element83, [1]);
          var element85 = dom.childAt(element83, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element82, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element82, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element82, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(element84,1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element84, [3]),0,0);
          morphs[5] = dom.createElementMorph(element85);
          morphs[6] = dom.createMorphAt(element85,1,1);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","item.repeat",["loc",[null,[310,32],[310,43]]]],"repeat"],[],["loc",[null,[310,28],[310,53]]]]],[],0,1,["loc",[null,[310,22],[314,29]]]],
          ["content","item.name",["loc",[null,[318,32],[318,45]]]],
          ["block","if",[["get","item.checkedDate",["loc",[null,[321,34],[321,50]]]]],[],2,3,["loc",[null,[321,28],[321,156]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[325,106],[325,110]]]]],[],[]],"confirmed","deleteTodo","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[325,28],[325,186]]]],
          ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","item",["loc",[null,[326,59],[326,63]]]]],[],[]],"confirmed","deleteTodo","textToShow","Löschen","text","Wollen Sie diesen Eintrag wirklich löschen?"],["loc",[null,[326,34],[326,160]]]],
          ["element","action",["editTodo",["get","item",["loc",[null,[328,49],[328,53]]]]],[],["loc",[null,[328,29],[328,55]]]],
          ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[329,28],[329,94]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child23 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 345,
              "column": 14
            },
            "end": {
              "line": 362,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["inline","customer-etiga",[],["patient",["subexpr","@mut",[["get","model",["loc",[null,[346,39],[346,44]]]]],[],[]],"etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[347,48],[347,64]]]]],[],[]],"activateEtiga","activateEtiga","uploadDeclaration","uploadDeclaration","removeShare","removeEtigaShare","sendEtigaSave","sendEtigaSave","downloadEtigaQr","downloadEtigaQr","hasEtigaPermissions",["subexpr","has-permission",["etiga"],[],["loc",[null,[353,51],[353,75]]]],"addEtigaShare","addEtigaShare","disconnectEtiga","disconnectEtiga","selectDocs","selectDocs","submitCredentials","sendCredentials","savePermission","saveEtigaPermission","editEtigaPermissions","editEtigaPermissions","classNames","etigaPermissionPanel patients","actionReceiver",["subexpr","@mut",[["get","etigaPanel",["loc",[null,[361,46],[361,56]]]]],[],[]]],["loc",[null,[346,14],[361,58]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child24 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 362,
              "column": 14
            },
            "end": {
              "line": 364,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                Derzeit in Arbeit\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child25 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 373,
              "column": 6
            },
            "end": {
              "line": 377,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element81 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element81);
          return morphs;
        },
        statements: [
          ["element","action",["triggerSave"],[],["loc",[null,[374,13],[374,37]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 378,
              "column": 6
            },
            "end": {
              "line": 382,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/icons/rework/save.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element80 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element80);
          return morphs;
        },
        statements: [
          ["element","action",["sendEtigaSave"],[],["loc",[null,[379,11],[379,37]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child27 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 383,
              "column": 6
            },
            "end": {
              "line": 390,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element78 = dom.childAt(fragment, [1]);
          var element79 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element78);
          morphs[1] = dom.createMorphAt(element78,1,1);
          morphs[2] = dom.createElementMorph(element79);
          morphs[3] = dom.createMorphAt(element79,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["downloadWeightCsv",["get","model.id",["loc",[null,[384,40],[384,48]]]]],[],["loc",[null,[384,11],[384,50]]]],
          ["inline","button-download-csv",[],["color","#ffffff","size","40","content","Gewichtsverlauf als CSV herunterladen"],["loc",[null,[385,10],[385,107]]]],
          ["element","action",["openWeight",null,["get","model.id",["loc",[null,[387,38],[387,46]]]]],[],["loc",[null,[387,11],[387,48]]]],
          ["inline","button-add-entry",[],["content","Neues Gewicht eintragen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[388,10],[388,108]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child28 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 391,
              "column": 6
            },
            "end": {
              "line": 395,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element77 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element77);
          morphs[1] = dom.createMorphAt(element77,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["showUploadForm"],[],["loc",[null,[392,13],[392,40]]]],
          ["inline","button-upload",[],["size","52","showStroke",false,"color","#ffffff","content","Neues Dokument hochladen"],["loc",[null,[393,12],[393,107]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child29 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 409,
                "column": 12
              },
              "end": {
                "line": 412,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","animal-icon",[],["useFilter",false,"classNames","inline-block vertical-middle","color","#fff","inverted",["subexpr","eq",["female",["get","model.gender.id",["loc",[null,[410,121],[410,136]]]]],[],["loc",[null,[410,108],[410,137]]]],"animal-id",["subexpr","@mut",[["get","model.category.id",["loc",[null,[411,38],[411,55]]]]],[],[]],"race-id",["subexpr","@mut",[["get","model.digitalRace.icon",["loc",[null,[411,64],[411,86]]]]],[],[]],"size","60","content",""],["loc",[null,[410,14],[411,109]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 412,
                "column": 12
              },
              "end": {
                "line": 414,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/eltiga-poe.png");
            dom.setAttribute(el1,"style","width: 55px;padding-top: 11px;padding-left: 6px;");
            dom.setAttribute(el1,"class","inline-block vertical-middle");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 418,
                "column": 12
              },
              "end": {
                "line": 418,
                "column": 95
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","dead-icon");
            dom.setAttribute(el1,"src","assets/images/icons/died-black.svg");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 419,
                "column": 55
              },
              "end": {
                "line": 419,
                "column": 108
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("- ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.digitalRace.name",["loc",[null,[419,82],[419,108]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 419,
                "column": 108
              },
              "end": {
                "line": 419,
                "column": 132
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("- ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","model.race",["loc",[null,[419,118],[419,132]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 420,
                "column": 8
              },
              "end": {
                "line": 420,
                "column": 71
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","subName small");
            var el2 = dom.createTextNode("Freigänger:in");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child6 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 423,
                  "column": 10
                },
                "end": {
                  "line": 425,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","cursor info-badge");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/sun.svg");
              dom.setAttribute(el2,"style","width: 20px");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 425,
                  "column": 10
                },
                "end": {
                  "line": 427,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","cursor info-badge");
              var el2 = dom.createTextNode("CAVE");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 421,
                "column": 8
              },
              "end": {
                "line": 429,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","caveLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","eq",[["subexpr","get-current-mandant-id",[],[],["loc",[null,[423,20],[423,44]]]],"153"],[],["loc",[null,[423,16],[423,51]]]]],[],0,1,["loc",[null,[423,10],[427,17]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child7 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 432,
                "column": 10
              },
              "end": {
                "line": 436,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","insured");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","textBelow");
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","assets/images/icons/insured.svg");
            dom.setAttribute(el3,"style","width: 20px");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Versicherung");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child8 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 436,
                "column": 10
              },
              "end": {
                "line": 440,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","not-insured");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","textBelow");
            var el3 = dom.createElement("img");
            dom.setAttribute(el3,"src","assets/images/icons/no.svg");
            dom.setAttribute(el3,"style","width: 20px;padding-bottom: 3px");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Versicherung");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child9 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 445,
                  "column": 12
                },
                "end": {
                  "line": 447,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("               ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/eltiga-poe.png");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element18 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element18);
              return morphs;
            },
            statements: [
              ["element","action",["openEmailEtigaPanel",["get","model",["loc",[null,[446,86],[446,91]]]]],[],["loc",[null,[446,55],[446,93]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 447,
                  "column": 12
                },
                "end": {
                  "line": 449,
                  "column": 12
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","etigaicon-with-confirm",[],["small",true,"color","00AAC6","item",["subexpr","@mut",[["get","model",["loc",[null,[448,72],[448,77]]]]],[],[]],"confirmed","activateEtiga","textToShow","etiga","text","eltiga für Tier aktivieren?","classNames","inline-block"],["loc",[null,[448,16],[448,185]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 443,
                "column": 8
              },
              "end": {
                "line": 451,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","etigaLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.etigaActive",["loc",[null,[445,18],[445,35]]]]],[],0,1,["loc",[null,[445,12],[449,19]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child10 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 456,
                "column": 14
              },
              "end": {
                "line": 458,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"style","width: 27px;");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element17 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element17, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",["assets/images/icons/",["get","model.gender.id",["loc",[null,[457,69],[457,84]]]],".svg"]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child11 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 458,
                "column": 14
              },
              "end": {
                "line": 460,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("N/A");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child12 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 461,
                "column": 38
              },
              "end": {
                "line": 461,
                "column": 70
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Kastriert");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child13 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 461,
                "column": 70
              },
              "end": {
                "line": 461,
                "column": 88
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Geschlecht");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child14 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 466,
                "column": 14
              },
              "end": {
                "line": 468,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("em");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element16,0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element16, [2]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.ageShort",["loc",[null,[467,39],[467,57]]]],
            ["content","model.ageShortUnit",["loc",[null,[467,62],[467,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child15 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 468,
                "column": 14
              },
              "end": {
                "line": 470,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("N/A");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child16 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 471,
                "column": 38
              },
              "end": {
                "line": 471,
                "column": 105
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","model.birthday",["loc",[null,[471,74],[471,88]]]],"DD. MM. YYYY"],[],["loc",[null,[471,60],[471,105]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child17 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 471,
                "column": 105
              },
              "end": {
                "line": 471,
                "column": 118
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Alter");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child18 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 476,
                "column": 14
              },
              "end": {
                "line": 478,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("kg");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-number-german-3",[["get","model.currentWeight",["loc",[null,[477,64],[477,83]]]]],[],["loc",[null,[477,39],[477,85]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child19 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 478,
                "column": 14
              },
              "end": {
                "line": 480,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1,"class","infoText");
            var el2 = dom.createElement("em");
            var el3 = dom.createTextNode("N/A");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child20 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 504,
                  "column": 16
                },
                "end": {
                  "line": 509,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class","btn tiny");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/eltiga-poe.png");
              dom.setAttribute(el2,"style","width: 17px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    eltiga\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element15);
              return morphs;
            },
            statements: [
              ["element","action",["openEmailEtigaPanel",["get","model",["loc",[null,[505,69],[505,74]]]]],[],["loc",[null,[505,38],[505,76]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 509,
                  "column": 16
                },
                "end": {
                  "line": 512,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","activate-eltiga-with-confirm",[],["item",["subexpr","@mut",[["get","model",["loc",[null,[510,56],[510,61]]]]],[],[]],"confirmed","activateEtiga","textToShow","eltiga","text","eltiga für Tier aktivieren?","classNames","inline-block"],["loc",[null,[510,20],[511,102]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 503,
                "column": 14
              },
              "end": {
                "line": 513,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.etigaActive",["loc",[null,[504,22],[504,39]]]]],[],0,1,["loc",[null,[504,16],[512,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child21 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 523,
                "column": 14
              },
              "end": {
                "line": 528,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","btn tiny");
            dom.setAttribute(el1,"style","padding-left:2px");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/archive.svg");
            dom.setAttribute(el2,"style","width: 15px;");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  Archivieren\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element14);
            return morphs;
          },
          statements: [
            ["element","action",["setDeathDate","dead"],[],["loc",[null,[524,61],[524,93]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child22 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 538,
                "column": 10
              },
              "end": {
                "line": 540,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"href","#tab-activity");
            var el2 = dom.createTextNode("Aktivitäten");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element13, 'class');
            morphs[1] = dom.createElementMorph(element13);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[539,67],[539,76]]]],"activity"],[],["loc",[null,[539,63],[539,88]]]],"health","deselected"],[],["loc",[null,[539,42],[539,112]]]]]]],
            ["element","action",["selectHealthTab","activity"],[],["loc",[null,[539,135],[539,174]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child23 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 546,
                    "column": 98
                  },
                  "end": {
                    "line": 546,
                    "column": 156
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [
                ["inline","get-html",[["get","model.allergies",["loc",[null,[546,133],[546,148]]]]],[],["loc",[null,[546,121],[546,151]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 546,
                  "column": 35
                },
                "end": {
                  "line": 546,
                  "column": 213
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createUnsafeMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","model.allergies",["loc",[null,[546,104],[546,119]]]]],[],0,null,["loc",[null,[546,98],[546,163]]]],
              ["inline","get-html",[["get","model.medicalIncompatibilityNotice",["loc",[null,[546,176],[546,210]]]]],[],["loc",[null,[546,164],[546,213]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 546,
                  "column": 213
                },
                "end": {
                  "line": 546,
                  "column": 241
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Dosierungen einsehen");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 545,
                "column": 12
              },
              "end": {
                "line": 547,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","pb10 pt10");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[546,45],[546,79]]]],["get","model.allergies",["loc",[null,[546,80],[546,95]]]]],[],["loc",[null,[546,41],[546,96]]]]],[],0,1,["loc",[null,[546,35],[546,248]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child24 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 547,
                "column": 12
              },
              "end": {
                "line": 549,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","pb10 pt10");
            var el2 = dom.createTextNode("Derzeit keine Erkrankungen / Dosierungen hinterlegt.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child25 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 572,
                "column": 10
              },
              "end": {
                "line": 574,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"href","#tab-documents");
            var el2 = dom.createTextNode("Docs");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element12, 'class');
            morphs[1] = dom.createElementMorph(element12);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[573,67],[573,77]]]],"documents"],[],["loc",[null,[573,63],[573,90]]]],"masterdata","deselected"],[],["loc",[null,[573,42],[573,118]]]]]]],
            ["element","action",["selectPatientTab","documents"],[],["loc",[null,[573,143],[573,184]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child26 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 583,
                "column": 16
              },
              "end": {
                "line": 586,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Farbe");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.color",["loc",[null,[585,21],[585,36]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child27 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 587,
                "column": 16
              },
              "end": {
                "line": 590,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Geburtstag");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","model.birthday",["loc",[null,[589,35],[589,49]]]],"DD. MM. YYYY"],[],["loc",[null,[589,21],[589,66]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child28 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 591,
                "column": 16
              },
              "end": {
                "line": 594,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Todestag");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element11);
            morphs[1] = dom.createMorphAt(element11,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["setDeathDate","dead"],[],["loc",[null,[593,36],[593,68]]]],
            ["inline","time-format",[["get","model.deathday",["loc",[null,[593,83],[593,97]]]],"DD. MM. YYYY"],[],["loc",[null,[593,69],[593,114]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child29 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 595,
                "column": 16
              },
              "end": {
                "line": 598,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Archiviert");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element10);
            morphs[1] = dom.createMorphAt(element10,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["setDeathDate","giveaway"],[],["loc",[null,[597,36],[597,72]]]],
            ["inline","time-format",[["get","model.soldDate",["loc",[null,[597,87],[597,101]]]],"DD. MM. YYYY"],[],["loc",[null,[597,73],[597,118]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child30 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 603,
                "column": 14
              },
              "end": {
                "line": 606,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Chip-Nr");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","cursor flex-center");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"class","ml5");
            dom.setAttribute(el2,"src","assets/images/icons/external-link.svg");
            dom.setAttribute(el2,"style","max-width: 18px;");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element9);
            morphs[1] = dom.createMorphAt(element9,0,0);
            return morphs;
          },
          statements: [
            ["element","action",["openChipPanel",["get","model",["loc",[null,[605,46],[605,51]]]]],[],["loc",[null,[605,21],[605,53]]]],
            ["content","model.chipId",["loc",[null,[605,81],[605,97]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child31 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 607,
                "column": 14
              },
              "end": {
                "line": 610,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Heimtier-Nr");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.homeanimalNumber",["loc",[null,[609,19],[609,45]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child32 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 611,
                "column": 14
              },
              "end": {
                "line": 616,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Versicherung");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Versicherungs-Nr");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1,"class","");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [7]),0,0);
            return morphs;
          },
          statements: [
            ["content","model.insurer.name",["loc",[null,[613,28],[613,50]]]],
            ["content","model.insuranceNumber",["loc",[null,[615,28],[615,53]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child33 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 626,
                "column": 10
              },
              "end": {
                "line": 631,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"style","padding-top: 5px;padding-left: 4px;");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","gender-icon",[],["classNames","owner-gender","useFilter",false,"size","55","color","#fff","gender",["subexpr","@mut",[["get","model.customer.gender.id",["loc",[null,[629,35],[629,59]]]]],[],[]],"content",""],["loc",[null,[628,14],[629,72]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child34 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 631,
                "column": 10
              },
              "end": {
                "line": 636,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/jason-poe-grey.svg");
            dom.setAttribute(el2,"style","width: 55px;padding-top: 11px;padding-left: 6px;");
            dom.setAttribute(el2,"class","inline-block vertical-middle");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child35 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 640,
                "column": 27
              },
              "end": {
                "line": 641,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          , ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["content","model.customer.street",["loc",[null,[640,56],[640,81]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child36 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 648,
                "column": 8
              },
              "end": {
                "line": 652,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","appLabel");
            dom.setAttribute(el1,"class","cursor");
            var el2 = dom.createTextNode("\n             ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/eltiga-poe.png");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child37 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 652,
                  "column": 8
                },
                "end": {
                  "line": 656,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"id","appLabel");
              dom.setAttribute(el1,"class","cursor");
              var el2 = dom.createTextNode("\n             ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/eltiga-poe-dark.png");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 652,
                "column": 8
              },
              "end": {
                "line": 656,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","model.customer.etigaCodeRequested",["loc",[null,[652,18],[652,51]]]]],[],0,null,["loc",[null,[652,8],[656,8]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child38 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 674,
                  "column": 16
                },
                "end": {
                  "line": 678,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/car.svg");
              dom.setAttribute(el1,"style","width: 22px;margin-top: -3px;margin-bottom: -3px;");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                  Anfahrt\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 673,
                "column": 14
              },
              "end": {
                "line": 679,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["customers.edit",["get","model.customer.id",["loc",[null,[674,44],[674,61]]]],["subexpr","query-params",[],["selectTab","accounting"],["loc",[null,[674,62],[674,99]]]]],["class","btn tiny"],0,null,["loc",[null,[674,16],[678,28]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child39 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 679,
                "column": 14
              },
              "end": {
                "line": 684,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class","btn tiny");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/car.svg");
            dom.setAttribute(el2,"style","width: 22px;margin-top: -3px;margin-bottom: -3px;");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  Anfahrt\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element8);
            return morphs;
          },
          statements: [
            ["element","action",["addDistance"],[],["loc",[null,[680,36],[680,60]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child40 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 706,
                    "column": 152
                  },
                  "end": {
                    "line": 706,
                    "column": 193
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","entry.comment",["loc",[null,[706,175],[706,192]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 705,
                  "column": 14
                },
                "end": {
                  "line": 707,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/phone.svg");
              dom.setAttribute(el2,"style","width: 15px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 2]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element7, 'href');
              morphs[1] = dom.createMorphAt(element7,0,0);
              morphs[2] = dom.createMorphAt(element7,1,1);
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",["tel:",["get","entry.value",["loc",[null,[706,122],[706,133]]]]]]],
              ["content","entry.value",["loc",[null,[706,137],[706,152]]]],
              ["block","if",[["get","entry.comment",["loc",[null,[706,158],[706,171]]]]],[],0,null,["loc",[null,[706,152],[706,200]]]]
            ],
            locals: ["entry"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 702,
                "column": 10
              },
              "end": {
                "line": 709,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Telefon");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model.customer.phones",["loc",[null,[705,22],[705,43]]]]],[],0,null,["loc",[null,[705,14],[707,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child41 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 714,
                    "column": 169
                  },
                  "end": {
                    "line": 714,
                    "column": 210
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" (");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(")");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","entry.comment",["loc",[null,[714,192],[714,209]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 713,
                  "column": 14
                },
                "end": {
                  "line": 715,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/email-white.svg");
              dom.setAttribute(el2,"style","width: 15px;");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","is-highlighted");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1, 2]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element6, 'href');
              morphs[1] = dom.createMorphAt(element6,0,0);
              morphs[2] = dom.createMorphAt(element6,1,1);
              return morphs;
            },
            statements: [
              ["attribute","href",["concat",["mailto:",["get","entry.value",["loc",[null,[714,139],[714,150]]]]]]],
              ["content","entry.value",["loc",[null,[714,154],[714,169]]]],
              ["block","if",[["get","entry.comment",["loc",[null,[714,175],[714,188]]]]],[],0,null,["loc",[null,[714,169],[714,217]]]]
            ],
            locals: ["entry"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 710,
                "column": 10
              },
              "end": {
                "line": 717,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Email");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1,"class","mb10");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","model.customer.emails",["loc",[null,[713,22],[713,43]]]]],[],0,null,["loc",[null,[713,14],[715,23]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child42 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 718,
                "column": 10
              },
              "end": {
                "line": 721,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createTextNode("Guthaben");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
            return morphs;
          },
          statements: [
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","depositSum",["loc",[null,[720,39],[720,49]]]]],[],[]]],["loc",[null,[720,15],[720,51]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child43 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 737,
                    "column": 46
                  },
                  "end": {
                    "line": 737,
                    "column": 76
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("ganztägig");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 737,
                      "column": 89
                    },
                    "end": {
                      "line": 737,
                      "column": 145
                    }
                  },
                  "moduleName": "jason-frontend/templates/patients/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" - ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.dateFrom",["loc",[null,[737,110],[737,127]]]],
                  ["content","item.dateTo",["loc",[null,[737,130],[737,145]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 737,
                    "column": 76
                  },
                  "end": {
                    "line": 737,
                    "column": 152
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","item.dateFrom",["loc",[null,[737,95],[737,108]]]]],[],0,null,["loc",[null,[737,89],[737,152]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 737,
                    "column": 159
                  },
                  "end": {
                    "line": 737,
                    "column": 245
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("bis ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.dateEnd",["loc",[null,[737,229],[737,245]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 734,
                  "column": 20
                },
                "end": {
                  "line": 743,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24@phone l-8/24@tablet l-12/24@desk");
              dom.setAttribute(el2,"data-label","Datum");
              var el3 = dom.createTextNode("\n                          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-12/24@phone l-16/24@tablet l-12/24@desk");
              dom.setAttribute(el2,"data-label","TitelKategorie");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createElement("a");
              dom.setAttribute(el4,"class","cursor is-highlighted");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("br");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var element3 = dom.childAt(element2, [1, 1]);
              var element4 = dom.childAt(element2, [3, 1]);
              var element5 = dom.childAt(element4, [0]);
              var morphs = new Array(6);
              morphs[0] = dom.createMorphAt(element3,0,0);
              morphs[1] = dom.createMorphAt(element3,2,2);
              morphs[2] = dom.createMorphAt(element3,3,3);
              morphs[3] = dom.createElementMorph(element5);
              morphs[4] = dom.createMorphAt(element5,0,0);
              morphs[5] = dom.createMorphAt(element4,2,2);
              return morphs;
            },
            statements: [
              ["content","item.date",["loc",[null,[737,32],[737,45]]]],
              ["block","if",[["get","item.wholeDay",["loc",[null,[737,52],[737,65]]]]],[],0,1,["loc",[null,[737,46],[737,159]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","item.dateEnd",["loc",[null,[737,179],[737,191]]]],["get","item.date",["loc",[null,[737,192],[737,201]]]]],[],["loc",[null,[737,175],[737,202]]]]],[],["loc",[null,[737,170],[737,203]]]],["get","item.multiDay",["loc",[null,[737,204],[737,217]]]]],[],["loc",[null,[737,165],[737,218]]]]],[],2,null,["loc",[null,[737,159],[737,252]]]],
              ["element","action",["showAppointment",["get","item",["loc",[null,[740,90],[740,94]]]]],[],["loc",[null,[740,63],[740,96]]]],
              ["content","item.title",["loc",[null,[740,97],[740,111]]]],
              ["content","item.category.name",["loc",[null,[740,120],[740,142]]]]
            ],
            locals: ["item"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 726,
                "column": 12
              },
              "end": {
                "line": 747,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table-wrapper table--small box no-box@phone mb0 pl0 pr0");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table no-box@phone");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__head");
            var el4 = dom.createComment("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-12/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Datum");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-12/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Titel/Kategorie");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__body");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","customerAppointments",["loc",[null,[734,28],[734,48]]]]],[],0,null,["loc",[null,[734,20],[743,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child44 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 747,
                "column": 12
              },
              "end": {
                "line": 751,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","ml10");
            var el2 = dom.createTextNode("\n                Derzeit keine Termine hinterlegt\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child45 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 772,
                      "column": 224
                    },
                    "end": {
                      "line": 772,
                      "column": 273
                    }
                  },
                  "moduleName": "jason-frontend/templates/patients/edit.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["content","item.name",["loc",[null,[772,260],[772,273]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 769,
                    "column": 22
                  },
                  "end": {
                    "line": 781,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/patients/edit.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","table__row");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Name");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","is-highlighted");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Spezies");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-8/24");
                dom.setAttribute(el2,"data-label","Rasse");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [1, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element1,0,0);
                morphs[1] = dom.createMorphAt(element1,2,2);
                morphs[2] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
                morphs[3] = dom.createMorphAt(dom.childAt(element0, [5, 1]),0,0);
                return morphs;
              },
              statements: [
                ["inline","animal-icon",[],["classNames","inline-block vertical-middle","evil",["subexpr","@mut",[["get","item.evil",["loc",[null,[772,118],[772,127]]]]],[],[]],"animal-id",["subexpr","@mut",[["get","item.category.id",["loc",[null,[772,138],[772,154]]]]],[],[]],"race-id",["subexpr","@mut",[["get","item.digitalRace.icon",["loc",[null,[772,163],[772,184]]]]],[],[]],"size","22","content","","color","#4A4A4A"],["loc",[null,[772,57],[772,223]]]],
                ["block","link-to",["patients.edit",["get","item.id",["loc",[null,[772,251],[772,258]]]]],[],0,null,["loc",[null,[772,224],[772,285]]]],
                ["content","item.category.name",["loc",[null,[775,34],[775,56]]]],
                ["content","item.race",["loc",[null,[778,34],[778,47]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 768,
                  "column": 20
                },
                "end": {
                  "line": 782,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/patients/edit.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","item.dead",["loc",[null,[769,38],[769,47]]]]],[],["loc",[null,[769,33],[769,48]]]],["subexpr","not",[["get","item.sold",["loc",[null,[769,54],[769,63]]]]],[],["loc",[null,[769,49],[769,64]]]]],[],["loc",[null,[769,28],[769,65]]]]],[],0,null,["loc",[null,[769,22],[781,29]]]]
            ],
            locals: ["item"],
            templates: [child0]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 759,
                "column": 12
              },
              "end": {
                "line": 786,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table-wrapper table--small box no-box@phone grid mt20 mb20");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table no-box@phone no-hover");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__head");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-8/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Name");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-8/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Spezies");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4,"class","table__cell l-8/24");
            var el5 = dom.createElement("span");
            dom.setAttribute(el5,"class","table__title title");
            var el6 = dom.createTextNode("Rasse");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3,"class","table__body");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                  ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 3]),1,1);
            return morphs;
          },
          statements: [
            ["block","each",[["get","customerPatients",["loc",[null,[768,28],[768,44]]]]],[],0,null,["loc",[null,[768,20],[782,29]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child46 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 786,
                "column": 12
              },
              "end": {
                "line": 790,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/patients/edit.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","mt20 ");
            var el2 = dom.createTextNode("\n                Derzeit keine Tiere hinterlegt\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 398,
              "column": 3
            },
            "end": {
              "line": 797,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet patientSidebar");
          dom.setAttribute(el1,"id","patientEditSidebar");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","picture");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("figure");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","summary");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4,"class","subName mb0");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"id","insuredLabel");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock mt20");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","cellContainer");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","cellContainer middle");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","cellContainer cursor");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7,"class","textBelow");
          var el8 = dom.createTextNode("Gewicht");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","sidebar-button-group");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","width: 100%;padding-left:0");
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                  ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/assistant.svg");
          dom.setAttribute(el8,"style","width: 19px;margin-right: 5px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                  Digitaler\n                  Assistent\n                ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock clearfix");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","sidebar-button-group");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","middle");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/barcode.svg");
          dom.setAttribute(el8,"style","width: 17px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Etiketten\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","health");
          dom.setAttribute(el3,"style","margin-top: 25px;");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-health");
          var el6 = dom.createTextNode("Gesundheit");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-vacc");
          var el6 = dom.createTextNode("Impfpass");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-memos");
          var el6 = dom.createTextNode("Notizen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-health");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","clear: both");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Details");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-vacc");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-activity");
          var el6 = dom.createTextNode("\n            In Entwicklung\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-memos");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"style","clear: both");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Notiz erstellen");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","masterdata");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-patientmasterdata");
          var el6 = dom.createTextNode("Profil");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-lab");
          var el6 = dom.createTextNode("Labor");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-patientxray");
          var el6 = dom.createTextNode("Röntgen");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-patientmasterdata");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col l-12/24");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","dataList");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("label");
          var el9 = dom.createTextNode("Nr");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("p");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("\n        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","col l-12/24 mt10");
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("div");
          dom.setAttribute(el7,"class","dataList");
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n          ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","customerSummary");
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4,"class","subName");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"id","saldoLabel");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","textBelow");
          var el7 = dom.createElement("b");
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Saldo");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","titleBlock clearfix");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","sidebar-button-group mt0");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/revenue.svg");
          dom.setAttribute(el8,"style","width: 15px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Umsatz\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","middle");
          var el7 = dom.createTextNode("\n              ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("a");
          dom.setAttribute(el7,"class","btn tiny");
          var el8 = dom.createTextNode("\n                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("img");
          dom.setAttribute(el8,"src","assets/images/icons/discount.svg");
          dom.setAttribute(el8,"style","width: 15px;");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                Rabatt\n              ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("            ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","sidebar-card");
          dom.setAttribute(el3,"id","customerdata");
          dom.setAttribute(el3,"style","margin-bottom: 120px");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","category-tab-bar");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customermasterdata");
          var el6 = dom.createTextNode("Profil");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerapps");
          var el6 = dom.createTextNode("Termine");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerpatients");
          var el6 = dom.createTextNode("Tiere");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5,"href","#tab-customerdocs");
          var el6 = dom.createTextNode("Docs");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          dom.setAttribute(el4,"class","tabs__body pl0 pr0 mb0");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customermasterdata");
          var el6 = dom.createTextNode("\n\n          ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","dataList ");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Details");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#customerapps");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Termine");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customerdocs");
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Dokumente");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          dom.setAttribute(el5,"data-for","#tab-customerpatients");
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("            ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6,"class","showMoreBottom");
          var el7 = dom.createElement("img");
          dom.setAttribute(el7,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el7,"style","width: 15px;");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode(" Alle Tiere");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n          ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [0]);
          var element20 = dom.childAt(element19, [1]);
          var element21 = dom.childAt(element19, [3]);
          var element22 = dom.childAt(element21, [1, 1]);
          var element23 = dom.childAt(element21, [3]);
          var element24 = dom.childAt(element23, [1]);
          var element25 = dom.childAt(element23, [3]);
          var element26 = dom.childAt(element23, [5]);
          var element27 = dom.childAt(element23, [15]);
          var element28 = dom.childAt(element27, [1, 1]);
          var element29 = dom.childAt(element27, [3, 1]);
          var element30 = dom.childAt(element27, [5]);
          var element31 = dom.childAt(element23, [17, 1, 1, 1]);
          var element32 = dom.childAt(element23, [19, 1]);
          var element33 = dom.childAt(element32, [3, 1]);
          var element34 = dom.childAt(element21, [5]);
          var element35 = dom.childAt(element34, [1]);
          var element36 = dom.childAt(element35, [1]);
          var element37 = dom.childAt(element35, [3]);
          var element38 = dom.childAt(element35, [7]);
          var element39 = dom.childAt(element34, [3]);
          var element40 = dom.childAt(element39, [1]);
          var element41 = dom.childAt(element39, [3]);
          var element42 = dom.childAt(element39, [5]);
          var element43 = dom.childAt(element39, [7]);
          var element44 = dom.childAt(element43, [5]);
          var element45 = dom.childAt(element21, [7]);
          var element46 = dom.childAt(element45, [1]);
          var element47 = dom.childAt(element46, [1]);
          var element48 = dom.childAt(element46, [3]);
          var element49 = dom.childAt(element46, [5]);
          var element50 = dom.childAt(element45, [3, 1]);
          var element51 = dom.childAt(element50, [1, 1]);
          var element52 = dom.childAt(element50, [3, 1]);
          var element53 = dom.childAt(element21, [9]);
          var element54 = dom.childAt(element53, [1]);
          var element55 = dom.childAt(element53, [3]);
          var element56 = dom.childAt(element53, [5]);
          var element57 = dom.childAt(element53, [7, 1]);
          var element58 = dom.childAt(element53, [11, 1]);
          var element59 = dom.childAt(element58, [1, 1]);
          var element60 = dom.childAt(element58, [3, 1]);
          var element61 = dom.childAt(element21, [11]);
          var element62 = dom.childAt(element61, [1]);
          var element63 = dom.childAt(element62, [1]);
          var element64 = dom.childAt(element62, [3]);
          var element65 = dom.childAt(element62, [5]);
          var element66 = dom.childAt(element62, [7]);
          var element67 = dom.childAt(element61, [3]);
          var element68 = dom.childAt(element67, [1]);
          var element69 = dom.childAt(element68, [1]);
          var element70 = dom.childAt(element68, [3]);
          var element71 = dom.childAt(element67, [3]);
          var element72 = dom.childAt(element71, [3]);
          var element73 = dom.childAt(element67, [5]);
          var element74 = dom.childAt(element73, [3]);
          var element75 = dom.childAt(element67, [7]);
          var element76 = dom.childAt(element75, [3]);
          var morphs = new Array(93);
          morphs[0] = dom.createElementMorph(element20);
          morphs[1] = dom.createAttrMorph(element22, 'class');
          morphs[2] = dom.createAttrMorph(element22, 'style');
          morphs[3] = dom.createElementMorph(element22);
          morphs[4] = dom.createAttrMorph(element24, 'class');
          morphs[5] = dom.createMorphAt(dom.childAt(element24, [1]),1,1);
          morphs[6] = dom.createMorphAt(element25,0,0);
          morphs[7] = dom.createMorphAt(element25,2,2);
          morphs[8] = dom.createMorphAt(element26,0,0);
          morphs[9] = dom.createMorphAt(element26,2,2);
          morphs[10] = dom.createMorphAt(element23,7,7);
          morphs[11] = dom.createMorphAt(element23,9,9);
          morphs[12] = dom.createMorphAt(dom.childAt(element23, [11]),1,1);
          morphs[13] = dom.createMorphAt(element23,13,13);
          morphs[14] = dom.createMorphAt(element28,1,1);
          morphs[15] = dom.createMorphAt(dom.childAt(element28, [3]),0,0);
          morphs[16] = dom.createMorphAt(element29,1,1);
          morphs[17] = dom.createMorphAt(dom.childAt(element29, [3]),0,0);
          morphs[18] = dom.createElementMorph(element30);
          morphs[19] = dom.createMorphAt(dom.childAt(element30, [1]),1,1);
          morphs[20] = dom.createElementMorph(element31);
          morphs[21] = dom.createMorphAt(dom.childAt(element32, [1]),1,1);
          morphs[22] = dom.createElementMorph(element33);
          morphs[23] = dom.createMorphAt(dom.childAt(element32, [5]),1,1);
          morphs[24] = dom.createAttrMorph(element36, 'class');
          morphs[25] = dom.createElementMorph(element36);
          morphs[26] = dom.createAttrMorph(element37, 'class');
          morphs[27] = dom.createElementMorph(element37);
          morphs[28] = dom.createMorphAt(element35,5,5);
          morphs[29] = dom.createAttrMorph(element38, 'class');
          morphs[30] = dom.createElementMorph(element38);
          morphs[31] = dom.createAttrMorph(element40, 'class');
          morphs[32] = dom.createElementMorph(element40);
          morphs[33] = dom.createMorphAt(element40,1,1);
          morphs[34] = dom.createAttrMorph(element41, 'class');
          morphs[35] = dom.createMorphAt(element41,1,1);
          morphs[36] = dom.createAttrMorph(element42, 'class');
          morphs[37] = dom.createAttrMorph(element43, 'class');
          morphs[38] = dom.createMorphAt(element43,1,1);
          morphs[39] = dom.createElementMorph(element44);
          morphs[40] = dom.createAttrMorph(element47, 'class');
          morphs[41] = dom.createElementMorph(element47);
          morphs[42] = dom.createAttrMorph(element48, 'class');
          morphs[43] = dom.createElementMorph(element48);
          morphs[44] = dom.createAttrMorph(element49, 'class');
          morphs[45] = dom.createElementMorph(element49);
          morphs[46] = dom.createMorphAt(element46,7,7);
          morphs[47] = dom.createAttrMorph(element50, 'class');
          morphs[48] = dom.createMorphAt(dom.childAt(element51, [3]),0,0);
          morphs[49] = dom.createMorphAt(element51,5,5);
          morphs[50] = dom.createMorphAt(element51,6,6);
          morphs[51] = dom.createMorphAt(element51,7,7);
          morphs[52] = dom.createMorphAt(element51,8,8);
          morphs[53] = dom.createMorphAt(element52,1,1);
          morphs[54] = dom.createMorphAt(element52,2,2);
          morphs[55] = dom.createMorphAt(element52,3,3);
          morphs[56] = dom.createElementMorph(element53);
          morphs[57] = dom.createAttrMorph(element54, 'class');
          morphs[58] = dom.createMorphAt(element54,1,1);
          morphs[59] = dom.createMorphAt(element55,0,0);
          morphs[60] = dom.createMorphAt(element55,2,2);
          morphs[61] = dom.createMorphAt(element55,4,4);
          morphs[62] = dom.createMorphAt(element56,0,0);
          morphs[63] = dom.createMorphAt(element56,1,1);
          morphs[64] = dom.createMorphAt(element56,3,3);
          morphs[65] = dom.createAttrMorph(element57, 'class');
          morphs[66] = dom.createMorphAt(dom.childAt(element57, [1, 0]),0,0);
          morphs[67] = dom.createMorphAt(element53,9,9);
          morphs[68] = dom.createElementMorph(element59);
          morphs[69] = dom.createElementMorph(element60);
          morphs[70] = dom.createMorphAt(dom.childAt(element58, [5]),1,1);
          morphs[71] = dom.createAttrMorph(element63, 'class');
          morphs[72] = dom.createElementMorph(element63);
          morphs[73] = dom.createAttrMorph(element64, 'class');
          morphs[74] = dom.createElementMorph(element64);
          morphs[75] = dom.createAttrMorph(element65, 'class');
          morphs[76] = dom.createElementMorph(element65);
          morphs[77] = dom.createAttrMorph(element66, 'class');
          morphs[78] = dom.createElementMorph(element66);
          morphs[79] = dom.createAttrMorph(element68, 'class');
          morphs[80] = dom.createMorphAt(element69,1,1);
          morphs[81] = dom.createMorphAt(element69,2,2);
          morphs[82] = dom.createMorphAt(element69,3,3);
          morphs[83] = dom.createElementMorph(element70);
          morphs[84] = dom.createAttrMorph(element71, 'class');
          morphs[85] = dom.createMorphAt(element71,1,1);
          morphs[86] = dom.createElementMorph(element72);
          morphs[87] = dom.createAttrMorph(element73, 'class');
          morphs[88] = dom.createMorphAt(element73,1,1);
          morphs[89] = dom.createElementMorph(element74);
          morphs[90] = dom.createAttrMorph(element75, 'class');
          morphs[91] = dom.createMorphAt(element75,1,1);
          morphs[92] = dom.createElementMorph(element76);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[399,7],[399,38]]]],
          ["attribute","class",["concat",["cursor frame ",["subexpr","css-bool-evaluator",[["subexpr","or",[["get","model.sold",["loc",[null,[402,61],[402,71]]]],["get","model.dead",["loc",[null,[402,72],[402,82]]]]],[],["loc",[null,[402,57],[402,83]]]],"dead",""],[],["loc",[null,[402,36],[402,95]]]]]]],
          ["attribute","style",["concat",["background-image: url(",["subexpr","get-avatar",[["get","model.picture",["loc",[null,[402,139],[402,152]]]]],[],["loc",[null,[402,126],[402,154]]]],");"]]],
          ["element","action",["gotoPatient",["get","model.id",["loc",[null,[402,181],[402,189]]]]],[],["loc",[null,[402,158],[402,191]]]],
          ["attribute","class",["concat",["avatar ",["get","model.gender.id",["loc",[null,[407,31],[407,46]]]]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","eq",[["get","model.category.id",["loc",[null,[409,32],[409,49]]]],"misc"],[],["loc",[null,[409,28],[409,57]]]]],[],["loc",[null,[409,23],[409,58]]]],["get","model.category",["loc",[null,[409,59],[409,73]]]]],[],["loc",[null,[409,18],[409,74]]]]],[],0,1,["loc",[null,[409,12],[414,19]]]],
          ["block","if",[["get","model.dead",["loc",[null,[418,18],[418,28]]]]],[],2,null,["loc",[null,[418,12],[418,102]]]],
          ["content","model.name",["loc",[null,[418,103],[418,117]]]],
          ["content","model.category.name",["loc",[null,[419,31],[419,54]]]],
          ["block","if",[["get","model.digitalRace",["loc",[null,[419,61],[419,78]]]]],[],3,4,["loc",[null,[419,55],[419,139]]]],
          ["block","if",[["get","model.outdoor",["loc",[null,[420,14],[420,27]]]]],[],5,null,["loc",[null,[420,8],[420,78]]]],
          ["block","if",[["get","model.evil",["loc",[null,[421,14],[421,24]]]]],[],6,null,["loc",[null,[421,8],[429,15]]]],
          ["block","if",[["get","model.insured",["loc",[null,[432,16],[432,29]]]]],[],7,8,["loc",[null,[432,10],[440,17]]]],
          ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[443,14],[443,31]]]]],[],9,null,["loc",[null,[443,8],[451,15]]]],
          ["block","if",[["get","model.gender",["loc",[null,[456,20],[456,32]]]]],[],10,11,["loc",[null,[456,14],[460,21]]]],
          ["block","if",[["get","model.castrated",["loc",[null,[461,44],[461,59]]]]],[],12,13,["loc",[null,[461,38],[461,95]]]],
          ["block","if",[["get","model.ageShort",["loc",[null,[466,20],[466,34]]]]],[],14,15,["loc",[null,[466,14],[470,21]]]],
          ["block","if",[["get","model.birthday",["loc",[null,[471,44],[471,58]]]]],[],16,17,["loc",[null,[471,38],[471,125]]]],
          ["element","action",["openWeightPanel"],[],["loc",[null,[474,44],[474,72]]]],
          ["block","if",[["get","model.currentWeight",["loc",[null,[476,20],[476,39]]]]],[],18,19,["loc",[null,[476,14],[480,21]]]],
          ["element","action",["openDigitalHelperPanel",["get","model.id",["loc",[null,[490,70],[490,78]]]]],[],["loc",[null,[490,36],[490,80]]]],
          ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[503,20],[503,37]]]]],[],20,null,["loc",[null,[503,14],[513,21]]]],
          ["element","action",["selectBarcode",["get","model",["loc",[null,[517,59],[517,64]]]]],[],["loc",[null,[517,34],[517,66]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","model.dead",["loc",[null,[523,30],[523,40]]]]],[],["loc",[null,[523,25],[523,41]]]],["subexpr","not",[["get","model.sold",["loc",[null,[523,47],[523,57]]]]],[],["loc",[null,[523,42],[523,58]]]]],[],["loc",[null,[523,20],[523,59]]]]],[],21,null,["loc",[null,[523,14],[528,21]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[536,65],[536,74]]]],"health"],[],["loc",[null,[536,61],[536,84]]]],"health","deselected"],[],["loc",[null,[536,40],[536,108]]]]]]],
          ["element","action",["selectHealthTab","health"],[],["loc",[null,[536,129],[536,166]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[537,65],[537,74]]]],"vacc"],[],["loc",[null,[537,61],[537,82]]]],"health","deselected"],[],["loc",[null,[537,40],[537,106]]]]]]],
          ["element","action",["selectHealthTab","vacc"],[],["loc",[null,[537,125],[537,160]]]],
          ["block","if",[["subexpr","is-test-mandant",[],[],["loc",[null,[538,16],[538,33]]]]],[],22,null,["loc",[null,[538,10],[540,17]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[541,65],[541,74]]]],"memos"],[],["loc",[null,[541,61],[541,83]]]],"health","deselected"],[],["loc",[null,[541,40],[541,107]]]]]]],
          ["element","action",["selectHealthTab","memos"],[],["loc",[null,[541,127],[541,163]]]],
          ["attribute","class",["concat",["pl0 pb0 pt10 pr0 tabs__content content cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[544,92],[544,101]]]],"health"],[],["loc",[null,[544,88],[544,111]]]],"is-active",""],[],["loc",[null,[544,67],[544,128]]]]]]],
          ["element","action",["openMedicalProblemsPanel",["get","model.id",["loc",[null,[544,189],[544,197]]]],"infinity"],[],["loc",[null,[544,153],[544,210]]]],
          ["block","if",[["subexpr","or",[["get","model.medicalIncompatibilityNotice",["loc",[null,[545,22],[545,56]]]],["get","model.allergies",["loc",[null,[545,57],[545,72]]]]],[],["loc",[null,[545,18],[545,73]]]]],[],23,24,["loc",[null,[545,12],[549,19]]]],
          ["attribute","class",["concat",["tabs__content content pl0 pr0 pb0  ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[553,81],[553,90]]]],"vacc"],[],["loc",[null,[553,77],[553,98]]]],"is-active",""],[],["loc",[null,[553,56],[553,115]]]]]]],
          ["inline","patient-vaccinations",[],["gotoService","gotoService","gotoProduct","gotoProduct","items",["subexpr","@mut",[["get","vaccinations",["loc",[null,[554,93],[554,105]]]]],[],[]]],["loc",[null,[554,12],[554,107]]]],
          ["attribute","class",["concat",["tabs__content content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[556,68],[556,77]]]],"activity"],[],["loc",[null,[556,64],[556,89]]]],"is-active",""],[],["loc",[null,[556,43],[556,106]]]]]]],
          ["attribute","class",["concat",["tabs__content content pl0 pr0 pb0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","healthTab",["loc",[null,[559,80],[559,89]]]],"memos"],[],["loc",[null,[559,76],[559,98]]]],"is-active",""],[],["loc",[null,[559,55],[559,115]]]]]]],
          ["inline","patient-memos",[],["patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[560,38],[560,46]]]]],[],[]],"items",["subexpr","@mut",[["get","memos",["loc",[null,[560,53],[560,58]]]]],[],[]],"reload","reloadMemos","actionReceiver",["subexpr","@mut",[["get","patientMemoPanel",["loc",[null,[560,96],[560,112]]]]],[],[]]],["loc",[null,[560,12],[560,114]]]],
          ["element","action",["addNewMemo"],[],["loc",[null,[562,40],[562,63]]]],
          ["attribute","class",["concat",["category cursor masterdata ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[569,76],[569,86]]]],"masterdata"],[],["loc",[null,[569,72],[569,100]]]],"masterdata","deselected"],[],["loc",[null,[569,51],[569,128]]]]]]],
          ["element","action",["selectPatientTab","masterdata"],[],["loc",[null,[569,160],[569,202]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[570,65],[570,75]]]],"lab"],[],["loc",[null,[570,61],[570,82]]]],"masterdata","deselected"],[],["loc",[null,[570,40],[570,110]]]]]]],
          ["element","action",["selectPatientTab","lab"],[],["loc",[null,[570,128],[570,163]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[571,65],[571,75]]]],"xray"],[],["loc",[null,[571,61],[571,83]]]],"masterdata","deselected"],[],["loc",[null,[571,40],[571,111]]]]]]],
          ["element","action",["selectPatientTab","xray"],[],["loc",[null,[571,137],[571,173]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","model.patient.dead",["loc",[null,[572,26],[572,44]]]]],[],["loc",[null,[572,21],[572,45]]]],["subexpr","not",[["get","model.patient.sold",["loc",[null,[572,51],[572,69]]]]],[],["loc",[null,[572,46],[572,70]]]]],[],["loc",[null,[572,16],[572,71]]]]],[],25,null,["loc",[null,[572,10],[574,17]]]],
          ["attribute","class",["concat",["pl0 pr0 pb0 tabs__content content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","patientTab",["loc",[null,[578,80],[578,90]]]],"masterdata"],[],["loc",[null,[578,76],[578,104]]]],"is-active",""],[],["loc",[null,[578,55],[578,121]]]]]]],
          ["content","model.id",["loc",[null,[582,19],[582,31]]]],
          ["block","if",[["get","model.color",["loc",[null,[583,22],[583,33]]]]],[],26,null,["loc",[null,[583,16],[586,23]]]],
          ["block","if",[["get","model.birthday",["loc",[null,[587,22],[587,36]]]]],[],27,null,["loc",[null,[587,16],[590,23]]]],
          ["block","if",[["get","model.dead",["loc",[null,[591,22],[591,32]]]]],[],28,null,["loc",[null,[591,16],[594,23]]]],
          ["block","if",[["get","model.sold",["loc",[null,[595,22],[595,32]]]]],[],29,null,["loc",[null,[595,16],[598,23]]]],
          ["block","if",[["get","model.chipId",["loc",[null,[603,20],[603,32]]]]],[],30,null,["loc",[null,[603,14],[606,21]]]],
          ["block","if",[["get","model.homeanimalNumber",["loc",[null,[607,20],[607,42]]]]],[],31,null,["loc",[null,[607,14],[610,21]]]],
          ["block","if",[["get","model.insurer",["loc",[null,[611,20],[611,33]]]]],[],32,null,["loc",[null,[611,14],[616,21]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[623,78],[623,95]]]],""],[],["loc",[null,[623,54],[623,100]]]],
          ["attribute","class",["concat",["avatar ",["get","model.customer.gender.id",["loc",[null,[625,29],[625,53]]]]]]],
          ["block","if",[["subexpr","and",[["get","model.customer.gender",["loc",[null,[626,21],[626,42]]]],["subexpr","not",[["subexpr","eq",[["get","model.customer.gender.id",["loc",[null,[626,52],[626,76]]]],"male/female/company"],[],["loc",[null,[626,48],[626,99]]]]],[],["loc",[null,[626,43],[626,100]]]]],[],["loc",[null,[626,16],[626,101]]]]],[],33,34,["loc",[null,[626,10],[636,17]]]],
          ["content","model.customer.title.name",["loc",[null,[639,12],[639,41]]]],
          ["content","model.customer.firstname",["loc",[null,[639,42],[639,70]]]],
          ["content","model.customer.lastname",["loc",[null,[639,71],[639,98]]]],
          ["block","if",[["get","model.customer.street",["loc",[null,[640,33],[640,54]]]]],[],35,null,["loc",[null,[640,27],[641,19]]]],
          ["content","model.customer.postalCode",["loc",[null,[641,19],[641,48]]]],
          ["content","model.customer.town",["loc",[null,[641,49],[641,72]]]],
          ["attribute","class",["concat",["insured ",["subexpr","css-bool-evaluator",[["subexpr","lt",[["get","balanceSum",["loc",[null,[643,57],[643,67]]]],0],[],["loc",[null,[643,53],[643,70]]]],"is-negative",""],[],["loc",[null,[643,32],[643,89]]]]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","balanceSum",["loc",[null,[644,65],[644,75]]]]],[],[]],"classNames","inline-block"],["loc",[null,[644,41],[644,103]]]],
          ["block","if",[["get","model.customer.etigaAppConnected",["loc",[null,[648,14],[648,46]]]]],[],36,37,["loc",[null,[648,8],[656,15]]]],
          ["element","action",["openRevenue",["get","model.customer.id",["loc",[null,[661,57],[661,74]]]]],["bubbles",false],["loc",[null,[661,34],[661,90]]]],
          ["element","action",["openCustomerReductions",["get","model.customer.id",["loc",[null,[667,68],[667,85]]]]],["bubbles",false],["loc",[null,[667,34],[667,101]]]],
          ["block","if",[["subexpr","not",[["get","model.customer.driveAddressAvailable",["loc",[null,[673,25],[673,61]]]]],[],["loc",[null,[673,20],[673,62]]]]],[],38,39,["loc",[null,[673,14],[684,21]]]],
          ["attribute","class",["concat",["category cursor masterdata ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[692,76],[692,87]]]],"masterdata"],[],["loc",[null,[692,72],[692,101]]]],"customerdata","deselected"],[],["loc",[null,[692,51],[692,131]]]]]]],
          ["element","action",["selectCustomerTab","masterdata"],[],["loc",[null,[692,164],[692,207]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[693,65],[693,76]]]],"appointments"],[],["loc",[null,[693,61],[693,92]]]],"customerdata","deselected"],[],["loc",[null,[693,40],[693,122]]]]]]],
          ["element","action",["selectCustomerTab","appointments"],[],["loc",[null,[693,149],[693,194]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[694,65],[694,76]]]],"patients"],[],["loc",[null,[694,61],[694,88]]]],"customerdata","deselected"],[],["loc",[null,[694,40],[694,118]]]]]]],
          ["element","action",["selectCustomerTab","patients"],[],["loc",[null,[694,149],[694,190]]]],
          ["attribute","class",["concat",["category cursor ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[695,65],[695,76]]]],"documents"],[],["loc",[null,[695,61],[695,89]]]],"customerdata","deselected"],[],["loc",[null,[695,40],[695,119]]]]]]],
          ["element","action",["selectCustomerTab","documents"],[],["loc",[null,[695,147],[695,189]]]],
          ["attribute","class",["concat",["tabs__content pl0 pr0 pb0 content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[699,80],[699,91]]]],"masterdata"],[],["loc",[null,[699,76],[699,105]]]],"is-active",""],[],["loc",[null,[699,55],[699,122]]]]]]],
          ["block","if",[["get","model.customer.phones",["loc",[null,[702,16],[702,37]]]]],[],40,null,["loc",[null,[702,10],[709,17]]]],
          ["block","if",[["get","model.customer.emails",["loc",[null,[710,16],[710,37]]]]],[],41,null,["loc",[null,[710,10],[717,17]]]],
          ["block","if",[["subexpr","gt",[["get","depositSum",["loc",[null,[718,20],[718,30]]]],0],[],["loc",[null,[718,16],[718,33]]]]],[],42,null,["loc",[null,[718,10],[721,17]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[723,60],[723,77]]]],""],[],["loc",[null,[723,36],[723,82]]]],
          ["attribute","class",["concat",["tabs__content pl0 pt0 pb0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[725,76],[725,87]]]],"appointments"],[],["loc",[null,[725,72],[725,103]]]],"is-active",""],[],["loc",[null,[725,51],[725,120]]]]]]],
          ["block","if",[["get","customerAppointments",["loc",[null,[726,18],[726,38]]]]],[],43,44,["loc",[null,[726,12],[751,19]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[752,64],[752,81]]]],"appointments"],[],["loc",[null,[752,40],[752,98]]]],
          ["attribute","class",["concat",["tabs__content pb0 pt0 pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[754,76],[754,87]]]],"documents"],[],["loc",[null,[754,72],[754,100]]]],"is-active",""],[],["loc",[null,[754,51],[754,117]]]]]]],
          ["inline","documents-panel",[],["prefix","cdocs","loadPanels",false,"smallView",true,"showSearch",false,"useHover",false,"showBreadcrumbs",false,"actionReceiver",["subexpr","@mut",[["get","customerDocumentsPanel",["loc",[null,[755,146],[755,168]]]]],[],[]]],["loc",[null,[755,12],[755,170]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[756,64],[756,81]]]],"documents"],[],["loc",[null,[756,40],[756,95]]]],
          ["attribute","class",["concat",["tabs__content pb0 pt0 pl0 pr0 ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","customerTab",["loc",[null,[758,76],[758,87]]]],"patients"],[],["loc",[null,[758,72],[758,99]]]],"is-active",""],[],["loc",[null,[758,51],[758,116]]]]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["get","loading",["loc",[null,[759,28],[759,35]]]]],[],["loc",[null,[759,23],[759,36]]]],["get","customerPatients",["loc",[null,[759,37],[759,53]]]]],[],["loc",[null,[759,18],[759,54]]]]],[],45,46,["loc",[null,[759,12],[790,19]]]],
          ["element","action",["gotoCustomer",["get","model.customer.id",["loc",[null,[791,64],[791,81]]]],"patients"],[],["loc",[null,[791,40],[791,94]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31, child32, child33, child34, child35, child36, child37, child38, child39, child40, child41, child42, child43, child44, child45, child46]
      };
    }());
    var child30 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 797,
              "column": 0
            },
            "end": {
              "line": 801,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/patients/edit.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar-hidden");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2,"type","button");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"id","showSidebar");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[799,61],[799,196]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 962,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/patients/edit.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Patientenstamm");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","dropdown");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","button-rework context mr10 dropdown d-flex align-items-center");
        dom.setAttribute(el5,"data-toggle","dropdown");
        dom.setAttribute(el5,"aria-haspopup","true");
        dom.setAttribute(el5,"aria-expanded","false");
        dom.setAttribute(el5,"style","line-height: 20px");
        var el6 = dom.createElement("span");
        var el7 = dom.createTextNode("Weitere Aktionen");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","dropdown-menu larger");
        dom.setAttribute(el5,"aria-labelledby","dropdownMenuButton");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6,"class","d-flex align-items-center dropdown-item");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7,"src","assets/images/icons/rework/merge.svg");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" Tier zusammenführen\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/admission.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Check-In");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","card patient-card mb-0 min-h-130px");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex flex-row");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4,"class","mb0 d-flex align-items-center");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","mt-0 mb-0");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","ml-auto");
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","rework-content tabs-rework-wrapper grid");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-master-data");
        var el3 = dom.createTextNode("Stammdaten");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-future");
        var el3 = dom.createTextNode("Zukunft");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-lab");
        var el3 = dom.createTextNode("Labor & Röntgen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-documents");
        var el3 = dom.createTextNode("Dokumente");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-tasks");
        var el3 = dom.createTextNode("Aufgaben");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"href","#tab-etiga");
        var el3 = dom.createTextNode("eltiga");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","patientEditPage");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","js-tabs tabs");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3,"class","tabs__body");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4,"id","patientEdit");
        dom.setAttribute(el4,"class","mb0 admin-form");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-master-data");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-weight");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Gewicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-activities");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-history");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","mb30 mt30");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","quick-filter standalone col l-12/24");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Schnellfilter");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Alle anzeigen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Nur eingeblendete");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Nur gelöschte");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","quick-filter col l-12/24");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Tags");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Ernährung");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Leistungen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Produkte");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        var el8 = dom.createTextNode("Labor");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-future");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-lab");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-medias");
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-documents");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-correspondance");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-tasks");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-todo");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table-wrapper-rework");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","quick-filter-wrapper mt-30 col l-12/24");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","form-field-rework mb-0");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","select-placeholder");
        var el9 = dom.createTextNode("Status");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table grid table--large");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__head");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Fällig");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-10/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Titel");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-4/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Status");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__body");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                  ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","pagination-wrapper is-fixed");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-appointments");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        dom.setAttribute(el4,"data-for","#tab-etiga");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","action-icons");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"class","icon-button icon-button--small");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("    ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n    $(function() {\n        $( window ).resize(function() {\n          $('aside.side-bar ').css('min-height', $(window).height()-375);\n          $('aside.side-bar div.scrollable').css('max-height', $(window).height()-375);\n        });\n        $('aside.side-bar').css('min-height', $(window).height()-375);\n        $('aside.side-bar div.scrollable').css('max-height', $(window).height()-375);\n        $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {\n            $(this).val(picker.startDate.format('DD. MM. YYYY'));\n            $(this).focus();\n        });\n        $('.datetimepicker').daterangepicker({\n            singleDatePicker: true,\n            autoUpdateInput: false,\n            \"locale\": {\n                \"format\": \"DD. MM. YYYY\",\n                \"daysOfWeek\": [\n                    \"So\",\n                    \"Mo\",\n                    \"Di\",\n                    \"Mi\",\n                    \"Do\",\n                    \"Fr\",\n                    \"Sa\"\n                ],\n                \"monthNames\": [\n                    \"Jänner\",\n                    \"Februar\",\n                    \"März\",\n                    \"April\",\n                    \"Mai\",\n                    \"Juni\",\n                    \"Juli\",\n                    \"August\",\n                    \"September\",\n                    \"Oktober\",\n                    \"November\",\n                    \"Dezember\"\n                ],\n                \"firstDay\": 1\n            },\n        });\n    });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element107 = dom.childAt(fragment, [0, 1, 3]);
        var element108 = dom.childAt(element107, [1, 3]);
        var element109 = dom.childAt(element108, [7]);
        var element110 = dom.childAt(element107, [10]);
        var element111 = dom.childAt(fragment, [2, 1]);
        var element112 = dom.childAt(element111, [3]);
        var element113 = dom.childAt(fragment, [4]);
        var element114 = dom.childAt(element113, [1]);
        var element115 = dom.childAt(element113, [6]);
        var element116 = dom.childAt(element113, [8]);
        var element117 = dom.childAt(element113, [10]);
        var element118 = dom.childAt(element113, [14]);
        var element119 = dom.childAt(element113, [19]);
        var element120 = dom.childAt(fragment, [6]);
        var element121 = dom.childAt(element120, [1, 1]);
        var element122 = dom.childAt(element121, [1]);
        var element123 = dom.childAt(element122, [1]);
        var element124 = dom.childAt(element121, [3]);
        var element125 = dom.childAt(element121, [5]);
        var element126 = dom.childAt(element121, [7]);
        var element127 = dom.childAt(element126, [1]);
        var element128 = dom.childAt(element127, [1]);
        var element129 = dom.childAt(element128, [3]);
        var element130 = dom.childAt(element128, [5]);
        var element131 = dom.childAt(element128, [7]);
        var element132 = dom.childAt(element127, [3]);
        var element133 = dom.childAt(element132, [3]);
        var element134 = dom.childAt(element132, [5]);
        var element135 = dom.childAt(element132, [7]);
        var element136 = dom.childAt(element132, [9]);
        var element137 = dom.childAt(element121, [9]);
        var element138 = dom.childAt(element121, [11]);
        var element139 = dom.childAt(element121, [13]);
        var element140 = dom.childAt(element121, [15]);
        var element141 = dom.childAt(element121, [17]);
        var element142 = dom.childAt(element121, [19]);
        var element143 = dom.childAt(element121, [21]);
        var element144 = dom.childAt(element143, [1]);
        var element145 = dom.childAt(element121, [23]);
        var element146 = dom.childAt(element121, [25]);
        var element147 = dom.childAt(element120, [3]);
        var element148 = dom.childAt(element147, [1]);
        var morphs = new Array(130);
        morphs[0] = dom.createMorphAt(element108,1,1);
        morphs[1] = dom.createMorphAt(element108,2,2);
        morphs[2] = dom.createMorphAt(element108,3,3);
        morphs[3] = dom.createMorphAt(element108,4,4);
        morphs[4] = dom.createMorphAt(element108,5,5);
        morphs[5] = dom.createElementMorph(element109);
        morphs[6] = dom.createMorphAt(element107,3,3);
        morphs[7] = dom.createMorphAt(element107,4,4);
        morphs[8] = dom.createMorphAt(element107,5,5);
        morphs[9] = dom.createMorphAt(element107,6,6);
        morphs[10] = dom.createMorphAt(element107,7,7);
        morphs[11] = dom.createMorphAt(element107,8,8);
        morphs[12] = dom.createElementMorph(element110);
        morphs[13] = dom.createMorphAt(element111,1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element112, [1]),0,0);
        morphs[15] = dom.createMorphAt(dom.childAt(element112, [3]),0,0);
        morphs[16] = dom.createAttrMorph(element114, 'class');
        morphs[17] = dom.createElementMorph(element114);
        morphs[18] = dom.createMorphAt(element113,3,3);
        morphs[19] = dom.createMorphAt(element113,4,4);
        morphs[20] = dom.createAttrMorph(element115, 'class');
        morphs[21] = dom.createElementMorph(element115);
        morphs[22] = dom.createAttrMorph(element116, 'class');
        morphs[23] = dom.createElementMorph(element116);
        morphs[24] = dom.createAttrMorph(element117, 'class');
        morphs[25] = dom.createElementMorph(element117);
        morphs[26] = dom.createMorphAt(element113,12,12);
        morphs[27] = dom.createAttrMorph(element118, 'class');
        morphs[28] = dom.createElementMorph(element118);
        morphs[29] = dom.createMorphAt(element113,16,16);
        morphs[30] = dom.createMorphAt(element113,17,17);
        morphs[31] = dom.createAttrMorph(element119, 'class');
        morphs[32] = dom.createElementMorph(element119);
        morphs[33] = dom.createAttrMorph(element120, 'class');
        morphs[34] = dom.createElementMorph(element122);
        morphs[35] = dom.createAttrMorph(element123, 'class');
        morphs[36] = dom.createMorphAt(element123,1,1);
        morphs[37] = dom.createMorphAt(element123,3,3);
        morphs[38] = dom.createAttrMorph(element124, 'class');
        morphs[39] = dom.createMorphAt(element124,1,1);
        morphs[40] = dom.createMorphAt(element124,3,3);
        morphs[41] = dom.createMorphAt(element124,7,7);
        morphs[42] = dom.createAttrMorph(element125, 'class');
        morphs[43] = dom.createMorphAt(element125,1,1);
        morphs[44] = dom.createAttrMorph(element126, 'class');
        morphs[45] = dom.createAttrMorph(element129, 'class');
        morphs[46] = dom.createElementMorph(element129);
        morphs[47] = dom.createAttrMorph(element130, 'class');
        morphs[48] = dom.createElementMorph(element130);
        morphs[49] = dom.createAttrMorph(element131, 'class');
        morphs[50] = dom.createElementMorph(element131);
        morphs[51] = dom.createAttrMorph(element133, 'class');
        morphs[52] = dom.createElementMorph(element133);
        morphs[53] = dom.createAttrMorph(element134, 'class');
        morphs[54] = dom.createElementMorph(element134);
        morphs[55] = dom.createAttrMorph(element135, 'class');
        morphs[56] = dom.createElementMorph(element135);
        morphs[57] = dom.createAttrMorph(element136, 'class');
        morphs[58] = dom.createElementMorph(element136);
        morphs[59] = dom.createMorphAt(element126,3,3);
        morphs[60] = dom.createAttrMorph(element137, 'class');
        morphs[61] = dom.createMorphAt(element137,1,1);
        morphs[62] = dom.createAttrMorph(element138, 'class');
        morphs[63] = dom.createMorphAt(element138,1,1);
        morphs[64] = dom.createAttrMorph(element139, 'class');
        morphs[65] = dom.createMorphAt(element139,1,1);
        morphs[66] = dom.createMorphAt(element139,3,3);
        morphs[67] = dom.createAttrMorph(element140, 'class');
        morphs[68] = dom.createMorphAt(element140,1,1);
        morphs[69] = dom.createMorphAt(element140,3,3);
        morphs[70] = dom.createAttrMorph(element141, 'class');
        morphs[71] = dom.createMorphAt(element141,1,1);
        morphs[72] = dom.createAttrMorph(element142, 'class');
        morphs[73] = dom.createMorphAt(element142,1,1);
        morphs[74] = dom.createAttrMorph(element143, 'class');
        morphs[75] = dom.createMorphAt(dom.childAt(element144, [1, 1]),3,3);
        morphs[76] = dom.createMorphAt(dom.childAt(element144, [3, 3]),1,1);
        morphs[77] = dom.createMorphAt(dom.childAt(element144, [5]),1,1);
        morphs[78] = dom.createAttrMorph(element145, 'class');
        morphs[79] = dom.createMorphAt(element145,1,1);
        morphs[80] = dom.createAttrMorph(element146, 'class');
        morphs[81] = dom.createMorphAt(element146,1,1);
        morphs[82] = dom.createElementMorph(element148);
        morphs[83] = dom.createMorphAt(element148,1,1);
        morphs[84] = dom.createMorphAt(element147,3,3);
        morphs[85] = dom.createMorphAt(element147,4,4);
        morphs[86] = dom.createMorphAt(element147,5,5);
        morphs[87] = dom.createMorphAt(element147,6,6);
        morphs[88] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[89] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[90] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[91] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[92] = dom.createMorphAt(fragment,17,17,contextualElement);
        morphs[93] = dom.createMorphAt(fragment,19,19,contextualElement);
        morphs[94] = dom.createMorphAt(fragment,21,21,contextualElement);
        morphs[95] = dom.createMorphAt(fragment,24,24,contextualElement);
        morphs[96] = dom.createMorphAt(fragment,26,26,contextualElement);
        morphs[97] = dom.createMorphAt(fragment,28,28,contextualElement);
        morphs[98] = dom.createMorphAt(fragment,30,30,contextualElement);
        morphs[99] = dom.createMorphAt(fragment,32,32,contextualElement);
        morphs[100] = dom.createMorphAt(fragment,34,34,contextualElement);
        morphs[101] = dom.createMorphAt(fragment,36,36,contextualElement);
        morphs[102] = dom.createMorphAt(fragment,38,38,contextualElement);
        morphs[103] = dom.createMorphAt(fragment,40,40,contextualElement);
        morphs[104] = dom.createMorphAt(fragment,42,42,contextualElement);
        morphs[105] = dom.createMorphAt(fragment,44,44,contextualElement);
        morphs[106] = dom.createMorphAt(fragment,46,46,contextualElement);
        morphs[107] = dom.createMorphAt(fragment,48,48,contextualElement);
        morphs[108] = dom.createMorphAt(fragment,50,50,contextualElement);
        morphs[109] = dom.createMorphAt(fragment,52,52,contextualElement);
        morphs[110] = dom.createMorphAt(fragment,54,54,contextualElement);
        morphs[111] = dom.createMorphAt(fragment,56,56,contextualElement);
        morphs[112] = dom.createMorphAt(fragment,58,58,contextualElement);
        morphs[113] = dom.createMorphAt(fragment,60,60,contextualElement);
        morphs[114] = dom.createMorphAt(fragment,62,62,contextualElement);
        morphs[115] = dom.createMorphAt(fragment,64,64,contextualElement);
        morphs[116] = dom.createMorphAt(fragment,66,66,contextualElement);
        morphs[117] = dom.createMorphAt(fragment,68,68,contextualElement);
        morphs[118] = dom.createMorphAt(fragment,70,70,contextualElement);
        morphs[119] = dom.createMorphAt(fragment,72,72,contextualElement);
        morphs[120] = dom.createMorphAt(fragment,74,74,contextualElement);
        morphs[121] = dom.createMorphAt(fragment,76,76,contextualElement);
        morphs[122] = dom.createMorphAt(fragment,78,78,contextualElement);
        morphs[123] = dom.createMorphAt(fragment,80,80,contextualElement);
        morphs[124] = dom.createMorphAt(fragment,82,82,contextualElement);
        morphs[125] = dom.createMorphAt(fragment,84,84,contextualElement);
        morphs[126] = dom.createMorphAt(fragment,86,86,contextualElement);
        morphs[127] = dom.createMorphAt(fragment,88,88,contextualElement);
        morphs[128] = dom.createMorphAt(fragment,90,90,contextualElement);
        morphs[129] = dom.createMorphAt(fragment,92,92,contextualElement);
        return morphs;
      },
      statements: [
        ["block","if",[["subexpr","is-etiga-editable",["masterData",["get","etigaPermissions",["loc",[null,[10,48],[10,64]]]],["get","model",["loc",[null,[10,65],[10,70]]]]],[],["loc",[null,[10,16],[10,71]]]]],[],0,null,["loc",[null,[10,10],[22,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[23,20],[23,29]]]],"documents"],[],["loc",[null,[23,16],[23,42]]]]],[],1,null,["loc",[null,[23,10],[27,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[28,20],[28,29]]]],"appointments"],[],["loc",[null,[28,16],[28,45]]]]],[],2,null,["loc",[null,[28,10],[32,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[33,20],[33,29]]]],"lab"],[],["loc",[null,[33,16],[33,36]]]]],[],3,null,["loc",[null,[33,10],[37,17]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[38,20],[38,29]]]],"future"],[],["loc",[null,[38,16],[38,39]]]]],[],4,null,["loc",[null,[38,10],[48,17]]]],
        ["element","action",["openMergePatient"],[],["loc",[null,[49,13],[49,42]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[54,16],[54,25]]]],"documents"],[],["loc",[null,[54,12],[54,38]]]]],[],5,null,["loc",[null,[54,6],[59,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[60,16],[60,25]]]],"tasks"],[],["loc",[null,[60,12],[60,34]]]]],[],6,null,["loc",[null,[60,6],[64,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[65,16],[65,25]]]],"appointments"],[],["loc",[null,[65,12],[65,41]]]]],[],7,null,["loc",[null,[65,6],[68,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[69,16],[69,25]]]],"etiga"],[],["loc",[null,[69,12],[69,34]]]]],[],8,null,["loc",[null,[69,6],[72,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[73,16],[73,25]]]],"history"],[],["loc",[null,[73,12],[73,36]]]]],[],9,null,["loc",[null,[73,6],[77,13]]]],
        ["block","if",[["get","model.etigaActive",["loc",[null,[78,12],[78,29]]]]],[],10,null,["loc",[null,[78,6],[82,13]]]],
        ["element","action",["admissionPatient",["get","model.customerId",["loc",[null,[83,37],[83,53]]]],["get","model.id",["loc",[null,[83,54],[83,62]]]]],[],["loc",[null,[83,9],[83,65]]]],
        ["block","if",[["get","model.gender",["loc",[null,[93,10],[93,22]]]]],[],11,null,["loc",[null,[93,4],[95,11]]]],
        ["content","model.name",["loc",[null,[97,48],[97,62]]]],
        ["block","if",[["get","model.customer",["loc",[null,[98,35],[98,49]]]]],[],12,null,["loc",[null,[98,29],[98,202]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[107,51],[107,60]]]],"masterdata"],[],["loc",[null,[107,47],[107,74]]]],"is-active",""],[],["loc",[null,[107,26],[107,91]]]]]]],
        ["element","action",["selectTab","masterdata"],[],["loc",[null,[107,117],[107,152]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-journal-enabled",[],[],["loc",[null,[108,12],[108,32]]]],["subexpr","is-debug-user",[],[],["loc",[null,[108,33],[108,48]]]]],[],["loc",[null,[108,8],[108,49]]]]],[],13,null,["loc",[null,[108,2],[110,9]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-test-mandant",[],[],["loc",[null,[111,12],[111,29]]]],["subexpr","not",[["subexpr","is-journal-enabled",[],[],["loc",[null,[111,35],[111,55]]]]],[],["loc",[null,[111,30],[111,56]]]],["subexpr","is-debug-user",[],[],["loc",[null,[111,57],[111,72]]]]],[],["loc",[null,[111,8],[111,73]]]]],[],14,null,["loc",[null,[111,2],[113,9]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[114,51],[114,60]]]],"future"],[],["loc",[null,[114,47],[114,70]]]],"is-active",""],[],["loc",[null,[114,26],[114,87]]]]]]],
        ["element","action",["selectTab","future"],[],["loc",[null,[114,108],[114,139]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[115,51],[115,60]]]],"lab"],[],["loc",[null,[115,47],[115,67]]]],"is-active",""],[],["loc",[null,[115,26],[115,84]]]]]]],
        ["element","action",["selectTab","lab"],[],["loc",[null,[115,102],[115,130]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[116,51],[116,60]]]],"documents"],[],["loc",[null,[116,47],[116,73]]]],"is-active",""],[],["loc",[null,[116,26],[116,90]]]]]]],
        ["element","action",["selectTab","documents"],[],["loc",[null,[116,114],[116,148]]]],
        ["block","if",[["subexpr","or",[["subexpr","not",[["subexpr","is-journal-enabled",[],[],["loc",[null,[117,17],[117,37]]]]],[],["loc",[null,[117,12],[117,38]]]],["subexpr","is-debug-user",[],[],["loc",[null,[117,39],[117,54]]]]],[],["loc",[null,[117,8],[117,55]]]]],[],15,null,["loc",[null,[117,2],[119,9]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[120,51],[120,60]]]],"tasks"],[],["loc",[null,[120,47],[120,69]]]],"is-active",""],[],["loc",[null,[120,26],[120,86]]]]]]],
        ["element","action",["selectTab","tasks"],[],["loc",[null,[120,106],[120,136]]]],
        ["block","if",[["subexpr","is-test-mandant",[],[],["loc",[null,[121,8],[121,25]]]]],[],16,null,["loc",[null,[121,2],[123,9]]]],
        ["block","if",[["subexpr","has-access",["calendar"],[],["loc",[null,[124,8],[124,31]]]]],[],17,null,["loc",[null,[124,2],[126,9]]]],
        ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[127,51],[127,60]]]],"etiga"],[],["loc",[null,[127,47],[127,69]]]],"is-active",""],[],["loc",[null,[127,26],[127,86]]]]]]],
        ["element","action",["selectTab","etiga"],[],["loc",[null,[127,106],[127,136]]]],
        ["attribute","class",["concat",["col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[130,48],[130,61]]]]],[],["loc",[null,[130,43],[130,62]]]],"24","18"],[],["loc",[null,[130,22],[130,74]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[130,133],[130,146]]]],"sidebar-active",""],[],["loc",[null,[130,112],[130,168]]]]," fadeIn"]]],
        ["element","action",["save"],["on","submit"],["loc",[null,[134,58],[134,87]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[135,79],[135,88]]]],"masterdata"],[],["loc",[null,[135,75],[135,102]]]],"is-active",""],[],["loc",[null,[135,54],[135,119]]]]]]],
        ["inline","patients-edit-form",[],["edit",true,"digitalRaceEnabled",true,"customerOverride",["subexpr","@mut",[["get","customerOverride",["loc",[null,[136,92],[136,108]]]]],[],[]],"model",["subexpr","@mut",[["get","model",["loc",[null,[136,115],[136,120]]]]],[],[]],"openChipPanel","openChipPanel","openLogbook","openEtigaMasterdataLogbook","changeUser","changeUser","etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[136,233],[136,249]]]]],[],[]],"genders",["subexpr","@mut",[["get","genders",["loc",[null,[136,258],[136,265]]]]],[],[]],"castrationOptions",["subexpr","@mut",[["get","castrationOptions",["loc",[null,[136,284],[136,301]]]]],[],[]],"categories",["subexpr","@mut",[["get","categories",["loc",[null,[136,313],[136,323]]]]],[],[]],"customer",["subexpr","@mut",[["get","customer",["loc",[null,[136,333],[136,341]]]]],[],[]],"insurers",["subexpr","@mut",[["get","insurers",["loc",[null,[136,351],[136,359]]]]],[],[]],"patientImage",["subexpr","@mut",[["get","patientImage",["loc",[null,[136,373],[136,385]]]]],[],[]],"imageChanged","changeImage","actionReceiver",["subexpr","@mut",[["get","patientEditForm",["loc",[null,[136,428],[136,443]]]]],[],[]]],["loc",[null,[136,20],[136,445]]]],
        ["block","if",[["subexpr","eq",[["get","model.category.id",["loc",[null,[137,30],[137,47]]]],"pferd"],[],["loc",[null,[137,26],[137,56]]]]],[],18,null,["loc",[null,[137,20],[139,27]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[142,75],[142,84]]]],"weight"],[],["loc",[null,[142,71],[142,94]]]],"is-active",""],[],["loc",[null,[142,50],[142,111]]]]]]],
        ["block","if",[["get","weightInfos",["loc",[null,[143,22],[143,33]]]]],[],19,null,["loc",[null,[143,16],[145,23]]]],
        ["inline","patient-weight-chart",[],["patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[146,49],[146,57]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","weightChart",["loc",[null,[146,73],[146,84]]]]],[],[]]],["loc",[null,[146,16],[146,86]]]],
        ["inline","patient-weight",[],["weightInfos",["subexpr","@mut",[["get","weightInfos",["loc",[null,[148,45],[148,56]]]]],[],[]],"edit","openWeight","delete","deleteWeight","editable",["subexpr","is-etiga-editable",["addWeight",["get","etigaPermissions",["loc",[null,[148,137],[148,153]]]],["get","model",["loc",[null,[148,154],[148,159]]]]],[],["loc",[null,[148,106],[148,160]]]],"patientId",["subexpr","@mut",[["get","model.id",["loc",[null,[148,171],[148,179]]]]],[],[]]],["loc",[null,[148,16],[148,181]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[150,73],[150,82]]]],"activities"],[],["loc",[null,[150,69],[150,96]]]],"is-active",""],[],["loc",[null,[150,48],[150,113]]]]]]],
        ["inline","patient-activities",[],["editNote","editMemo","editTreatment","editFullTreatment","openReportChooser","openReportChooser","openSlopingInfos","openSlopingInfos","openDocument","openDocument","gotoReport","gotoReport","gotoTemplate","gotoTemplate","openLab","openLab","openApp","openEditAppointment","doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[161,24],[161,31]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[162,27],[162,37]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[163,22],[163,27]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","patientActivities",["loc",[null,[164,31],[164,48]]]]],[],[]]],["loc",[null,[151,14],[164,50]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[166,73],[166,82]]]],"history"],[],["loc",[null,[166,69],[166,93]]]],"is-active",""],[],["loc",[null,[166,48],[166,110]]]]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[170,73],[170,84]]]],"all"],[],["loc",[null,[170,69],[170,91]]]],"selected",""],[],["loc",[null,[170,48],[170,108]]]]]]],
        ["element","action",["toggleQuickFilter","all"],[],["loc",[null,[170,110],[170,146]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[171,73],[171,84]]]],"visible"],[],["loc",[null,[171,69],[171,95]]]],"selected",""],[],["loc",[null,[171,48],[171,112]]]]]]],
        ["element","action",["toggleQuickFilter","visible"],[],["loc",[null,[171,114],[171,154]]]],
        ["attribute","class",["concat",["selectable-item ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","quickFilter",["loc",[null,[172,73],[172,84]]]],"deleted"],[],["loc",[null,[172,69],[172,95]]]],"selected",""],[],["loc",[null,[172,48],[172,112]]]]]]],
        ["element","action",["toggleQuickFilter","deleted"],[],["loc",[null,[172,114],[172,154]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterFood",["loc",[null,[176,69],[176,79]]]],"selected",""],[],["loc",[null,[176,48],[176,95]]]]]]],
        ["element","action",["toggleTabFilter","filterFood"],[],["loc",[null,[176,97],[176,138]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterServices",["loc",[null,[177,69],[177,83]]]],"selected",""],[],["loc",[null,[177,48],[177,99]]]]]]],
        ["element","action",["toggleTabFilter","filterServices"],[],["loc",[null,[177,101],[177,146]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterProducts",["loc",[null,[178,69],[178,83]]]],"selected",""],[],["loc",[null,[178,48],[178,99]]]]]]],
        ["element","action",["toggleTabFilter","filterProducts"],[],["loc",[null,[178,101],[178,146]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","filterLab",["loc",[null,[179,69],[179,78]]]],"selected",""],[],["loc",[null,[179,48],[179,94]]]]]]],
        ["element","action",["toggleTabFilter","filterLab"],[],["loc",[null,[179,96],[179,136]]]],
        ["inline","treatment-history",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[184,22],[184,27]]]]],[],[]],"showCopy",false,"showEdit",true,"showHide",true,"showHideApp",true,"showFilter",false,"users",["subexpr","@mut",[["get","users",["loc",[null,[190,22],[190,27]]]]],[],[]],"etigaPermissions",["subexpr","@mut",[["get","etigaPermissions",["loc",[null,[191,33],[191,49]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[192,24],[192,31]]]]],[],[]],"reload","reloadHistory","gotoReport","gotoReport","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[195,22],[195,27]]]]],[],[]],"showPager",true,"reload","reloadHistory","assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[198,27],[198,37]]]]],[],[]],"openDigitalForm","openDigitalForm","employeesSaved","employeesSaved","openSlopingInfos","openSlopingInfos","roomSaved","roomSaved","downloadSlopingBarcode","selectSlopingBarcodeForInvoice","deleteTreatment","deleteTreatment","editTreatmentPanel","editTreatmentPanel","treatmentSaved","saveTreatment","openTemplateEdit","openTemplateEdit","forwardToBilling","forwardToBilling","openLaboklinChoosePanel","openLaboklinChoosePanel","items",["subexpr","@mut",[["get","historicTreatments",["loc",[null,[210,22],[210,40]]]]],[],[]]],["loc",[null,[183,14],[210,42]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[212,62],[212,71]]]],"future"],[],["loc",[null,[212,58],[212,81]]]],"is-active",""],[],["loc",[null,[212,37],[212,98]]]]]]],
        ["inline","treatment-future",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[214,22],[214,27]]]]],[],[]],"items",["subexpr","@mut",[["get","futureItems",["loc",[null,[215,22],[215,33]]]]],[],[]],"newMode",true,"crud",false,"editFuture","editFuture","reload","reloadFuture","moveFuture","moveFuture","addDirectNotification","openAddDirectNotification","addDirectAppNotification","openAddAppDirectNotification","addFutureAction","addFutureAction","deleteFutureEntry","deleteFuture","actionReceiver",["subexpr","@mut",[["get","treatmentFuturePanel",["loc",[null,[225,29],[225,49]]]]],[],[]]],["loc",[null,[213,14],[225,51]]]],
        ["attribute","class",["concat",["card-content tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[227,75],[227,84]]]],"lab"],[],["loc",[null,[227,71],[227,91]]]],"is-active",""],[],["loc",[null,[227,50],[227,108]]]]]]],
        ["inline","laboklin-historic-forms",[],["actionReceiver",["subexpr","@mut",[["get","historicItems",["loc",[null,[228,57],[228,70]]]]],[],[]],"downloadBarcode","selectLaboklinBarcode","editable",["subexpr","is-etiga-editable",["addLabor",["get","etigaPermissions",["loc",[null,[230,81],[230,97]]]],["get","model",["loc",[null,[230,98],[230,103]]]]],[],["loc",[null,[230,51],[230,104]]]],"showSendAll",false,"showEdit",true,"size",40,"downloadMedia","downloadMedia","editLaboklinForm","editLaboklinForm","downloadVetconnectResult","downloadVetconnectResult","openResult","openResult","downloadResult","downloadResult","downloadPdf","downloadPdf","forwardEmail","openEmailForwardPanel","deleteReport","deleteReport","submitLaboklin","submitLaboklin","reload","reloadLaboklin","labItems",["subexpr","@mut",[["get","labItems",["loc",[null,[244,51],[244,59]]]]],[],[]]],["loc",[null,[228,16],[244,61]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[246,75],[246,84]]]],"medias"],[],["loc",[null,[246,71],[246,94]]]],"is-active",""],[],["loc",[null,[246,50],[246,111]]]]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[248,24],[248,33]]]],"documents"],[],["loc",[null,[248,20],[248,46]]]]],[],20,null,["loc",[null,[248,14],[251,21]]]],
        ["inline","patient-medias",[],["items",["subexpr","@mut",[["get","medias",["loc",[null,[252,39],[252,45]]]]],[],[]],"deleteMedia","deleteMedia","editable",["subexpr","is-etiga-editable",["addDocs",["get","etigaPermissions",["loc",[null,[252,110],[252,126]]]],["get","model",["loc",[null,[252,127],[252,132]]]]],[],["loc",[null,[252,81],[252,133]]]],"forwardEmail","openDocumentForwardPanel","reloadMedias","reloadMedias"],["loc",[null,[252,16],[252,204]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[254,75],[254,84]]]],"documents"],[],["loc",[null,[254,71],[254,97]]]],"is-active",""],[],["loc",[null,[254,50],[254,114]]]]]]],
        ["block","if",[["get","showFilters",["loc",[null,[255,22],[255,33]]]]],[],21,null,["loc",[null,[255,16],[260,23]]]],
        ["inline","documents-panel",[],["showSearch",false,"showBreadcrumbs",true,"updateSearch","updateSearch","forwardLab","openEmailForwardPanel","actionReceiver",["subexpr","@mut",[["get","documentsPanel",["loc",[null,[266,31],[266,45]]]]],[],[]]],["loc",[null,[261,14],[266,47]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[268,62],[268,71]]]],"correspondance"],[],["loc",[null,[268,58],[268,89]]]],"is-active",""],[],["loc",[null,[268,37],[268,106]]]]]]],
        ["inline","customer-correspondance",[],["items",["subexpr","@mut",[["get","logbookEntries",["loc",[null,[269,48],[269,62]]]]],[],[]]],["loc",[null,[269,16],[269,64]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[271,62],[271,71]]]],"tasks"],[],["loc",[null,[271,58],[271,80]]]],"is-active",""],[],["loc",[null,[271,37],[271,97]]]]]]],
        ["inline","patient-tasks",[],["items",["subexpr","@mut",[["get","tasks",["loc",[null,[272,36],[272,41]]]]],[],[]],"startTask","startTask","createNotification","addMessage","showNotification","showNotification","finishTask","finishTask","showTask","showTask","startTask","startTask","changeOwner","changeOwner","setSortDir","setSortDir"],["loc",[null,[272,14],[280,55]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[282,60],[282,69]]]],"todo"],[],["loc",[null,[282,56],[282,77]]]],"is-active",""],[],["loc",[null,[282,35],[282,94]]]]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","todoStatus",["loc",[null,[288,30],[288,40]]]]],[],[]],"value",["subexpr","@mut",[["get","filterTodoStatus",["loc",[null,[289,28],[289,44]]]]],[],[]],"cssClass","custom-select2 newStyle tuned","optionLabelPath","value","optionValuePath","id","allowClear",true,"placeholder","Alle"],["loc",[null,[287,20],[295,22]]]],
        ["block","each",[["get","todos",["loc",[null,[306,28],[306,33]]]]],[],22,null,["loc",[null,[306,20],[333,29]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","todos",["loc",[null,[337,41],[337,46]]]]],[],[]],"numPagesToShow",7],["loc",[null,[337,18],[337,65]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[341,62],[341,71]]]],"appointments"],[],["loc",[null,[341,58],[341,87]]]],"is-active",""],[],["loc",[null,[341,37],[341,104]]]]]]],
        ["inline","patient-appointments",[],["startTreatment","admissionPatient","referer","patient","delete","deleteAppointment","edit","openEditAppointment","showAppointment","showAppointment","stickyHeader",false,"items",["subexpr","@mut",[["get","appointments",["loc",[null,[342,202],[342,214]]]]],[],[]]],["loc",[null,[342,14],[342,216]]]],
        ["attribute","class",["concat",["tabs__content card-content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[344,75],[344,84]]]],"etiga"],[],["loc",[null,[344,71],[344,93]]]],"is-active",""],[],["loc",[null,[344,50],[344,110]]]]]]],
        ["block","if",[["subexpr","is-etiga-active",[],[],["loc",[null,[345,20],[345,37]]]]],[],23,24,["loc",[null,[345,14],[364,21]]]],
        ["element","action",["back"],[],["loc",[null,[370,48],[370,65]]]],
        ["content","button-prev",["loc",[null,[371,8],[371,23]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","selectTab",["loc",[null,[373,21],[373,30]]]],"masterdata"],[],["loc",[null,[373,17],[373,44]]]],["subexpr","is-etiga-editable",["masterData",["get","etigaPermissions",["loc",[null,[373,77],[373,93]]]],["get","model",["loc",[null,[373,94],[373,99]]]]],[],["loc",[null,[373,45],[373,100]]]]],[],["loc",[null,[373,12],[373,101]]]]],[],25,null,["loc",[null,[373,6],[377,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[378,16],[378,25]]]],"etiga"],[],["loc",[null,[378,12],[378,34]]]]],[],26,null,["loc",[null,[378,6],[382,13]]]],
        ["block","if",[["subexpr","and",[["subexpr","is-etiga-editable",["addWeight",["get","etigaPermissions",["loc",[null,[383,48],[383,64]]]],["get","model",["loc",[null,[383,65],[383,70]]]]],[],["loc",[null,[383,17],[383,71]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[383,76],[383,85]]]],"weight"],[],["loc",[null,[383,72],[383,95]]]]],[],["loc",[null,[383,12],[383,96]]]]],[],27,null,["loc",[null,[383,6],[390,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[391,16],[391,25]]]],"medias"],[],["loc",[null,[391,12],[391,35]]]]],[],28,null,["loc",[null,[391,6],[395,13]]]],
        ["block","if",[["get","sidebarActive",["loc",[null,[398,9],[398,22]]]]],[],29,30,["loc",[null,[398,3],[801,7]]]],
        ["inline","customer-revenue-panel",[],["actionReceiver",["subexpr","@mut",[["get","customerRevenuePanel",["loc",[null,[803,40],[803,60]]]]],[],[]]],["loc",[null,[803,0],[803,62]]]],
        ["inline","media-upload-panel",[],["actionReceiver",["subexpr","@mut",[["get","mediaPanel",["loc",[null,[804,36],[804,46]]]]],[],[]],"patient",["subexpr","@mut",[["get","model",["loc",[null,[804,55],[804,60]]]]],[],[]],"mediaCategories",["subexpr","@mut",[["get","mediaCategories",["loc",[null,[804,77],[804,92]]]]],[],[]],"reloadMedias","reloadMedias"],["loc",[null,[804,0],[804,122]]]],
        ["inline","customer-reduction-panel",[],["actionReceiver",["subexpr","@mut",[["get","setCustomer",["loc",[null,[805,42],[805,53]]]]],[],[]]],["loc",[null,[805,0],[805,55]]]],
        ["inline","email-invoice-panel",[],["actionReceiver",["subexpr","@mut",[["get","forwardEmailPanel",["loc",[null,[808,37],[808,54]]]]],[],[]],"confirm","sendEmail"],["loc",[null,[808,0],[808,76]]]],
        ["inline","finish-task-panel",[],["confirm","finishTaskSend","actionReceiver",["subexpr","@mut",[["get","finishTaskPanel",["loc",[null,[810,60],[810,75]]]]],[],[]]],["loc",[null,[810,0],[810,77]]]],
        ["inline","change-task-owner-panel",[],["confirm","changedTaskOwner","users",["subexpr","@mut",[["get","users",["loc",[null,[811,59],[811,64]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","changeTaskOwnerPanel",["loc",[null,[811,80],[811,100]]]]],[],[]]],["loc",[null,[811,0],[811,102]]]],
        ["inline","add-appointment-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[815,34],[815,39]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[815,48],[815,55]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[815,67],[815,77]]]]],[],[]],"resources",["subexpr","@mut",[["get","resources",["loc",[null,[815,88],[815,97]]]]],[],[]],"rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[815,104],[815,109]]]]],[],[]],"stations",["subexpr","@mut",[["get","stations",["loc",[null,[815,119],[815,127]]]]],[],[]],"create","createAppointment","actionReceiver",["subexpr","@mut",[["get","addAppointmentPanel",["loc",[null,[815,170],[815,189]]]]],[],[]]],["loc",[null,[815,4],[815,191]]]],
        ["inline","add-task-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[817,23],[817,28]]]]],[],[]],"create","create","reloadPatients","reloadPatients","actionReceiver",["subexpr","@mut",[["get","addTaskPanel",["loc",[null,[817,92],[817,104]]]]],[],[]]],["loc",[null,[817,0],[817,106]]]],
        ["inline","show-task-panel",[],["actionReceiver",["subexpr","@mut",[["get","showTaskPanel",["loc",[null,[818,33],[818,46]]]]],[],[]],"linkTo","linkTo","goto","goto","create","createNotification","addReply","addReply","startTask","startTask","finishTask","finishTask","addMessage","addMessage","changeOwner","changeOwner"],["loc",[null,[818,0],[818,220]]]],
        ["inline","show-appointment-panel",[],["actionReceiver",["subexpr","@mut",[["get","showAppointmentPanel",["loc",[null,[819,40],[819,60]]]]],[],[]]],["loc",[null,[819,0],[819,62]]]],
        ["inline","show-notification-panel",[],["actionReceiver",["subexpr","@mut",[["get","showNotificationPanel",["loc",[null,[821,41],[821,62]]]]],[],[]]],["loc",[null,[821,0],[821,64]]]],
        ["inline","add-notification-panel",[],["users",["subexpr","@mut",[["get","users",["loc",[null,[823,31],[823,36]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[823,46],[823,54]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[823,63],[823,70]]]]],[],[]],"create","createNotification","forward","sendForward","actionReceiver",["subexpr","@mut",[["get","addNotificationPanel",["loc",[null,[823,136],[823,156]]]]],[],[]]],["loc",[null,[823,0],[823,158]]]],
        ["inline","etiga-logbook-panel",[],["actionReceiver",["subexpr","@mut",[["get","etigaLogbookPanel",["loc",[null,[824,37],[824,54]]]]],[],[]]],["loc",[null,[824,0],[824,56]]]],
        ["inline","patient-select-barcode-panel",[],["download","downloadPatientBarcode","actionReceiver",["subexpr","@mut",[["get","patientSelectBarcodePanel",["loc",[null,[826,80],[826,105]]]]],[],[]]],["loc",[null,[826,0],[826,107]]]],
        ["inline","laboklin-select-barcode-panel",[],["download","downloadLaboklinBarcode","actionReceiver",["subexpr","@mut",[["get","laboklinSelectBarcodePanel",["loc",[null,[827,82],[827,108]]]]],[],[]]],["loc",[null,[827,0],[827,110]]]],
        ["inline","choose-existing-etiga-panel",[],["createNew","createNew","select","connectToExistingEtigaCustomer","actionReceiver",["subexpr","@mut",[["get","existingEtigaPanel",["loc",[null,[828,107],[828,125]]]]],[],[]]],["loc",[null,[828,0],[828,127]]]],
        ["inline","sloping-info-panel",[],["save","saveSlopingInfo","gotoProduct","gotoProduct","gotoService","gotoService","selectBarcode","downloadSlopingBarcode","actionReceiver",["subexpr","@mut",[["get","slopingInfoPanel",["loc",[null,[829,150],[829,166]]]]],[],[]]],["loc",[null,[829,0],[829,168]]]],
        ["inline","edit-medical-problems-panel",[],["confirm","saveMedicalProblemsInfo","openEditDosing","openEditDosing","refresh","refreshPastDosings","actionReceiver",["subexpr","@mut",[["get","medicalProblemsPanel",["loc",[null,[830,140],[830,160]]]]],[],[]]],["loc",[null,[830,0],[830,162]]]],
        ["inline","edit-past-actions-panel",[],["save","savePastAction","actionReceiver",["subexpr","@mut",[["get","editPastActionsPanel",["loc",[null,[831,63],[831,83]]]]],[],[]]],["loc",[null,[831,0],[831,85]]]],
        ["inline","edit-lab-date-panel",[],["confirm","changeLabDate","actionReceiver",["subexpr","@mut",[["get","changeLabDatePanel",["loc",[null,[833,61],[833,79]]]]],[],[]]],["loc",[null,[833,0],[833,81]]]],
        ["inline","digital-form-panel",[],["confirm","updateDigitalForm","actionReceiver",["subexpr","@mut",[["get","digitalFormPanel",["loc",[null,[837,25],[837,41]]]]],[],[]]],["loc",[null,[835,0],[837,43]]]],
        ["inline","add-etiga-share",[],["etigaShareInfos",["subexpr","@mut",[["get","etigaShareInfos",["loc",[null,[840,18],[840,33]]]]],[],[]],"select","addEtigaPermisison","remove","removeEtigaShare","actionReceiver",["subexpr","@mut",[["get","addEtigaShare",["loc",[null,[843,17],[843,30]]]]],[],[]]],["loc",[null,[839,0],[843,32]]]],
        ["inline","digital-helper-qr-panel",[],["actionReceiver",["subexpr","@mut",[["get","digitalHelperPanel",["loc",[null,[845,41],[845,59]]]]],[],[]]],["loc",[null,[845,0],[845,61]]]],
        ["inline","laboklin-treatment-panel",[],["treatment",true,"allowUnlinking",false,"removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","laboklinTreatmentPanel",["loc",[null,[858,17],[858,39]]]]],[],[]]],["loc",[null,[847,0],[858,41]]]],
        ["inline","private-treatment-panel",[],["treatment",true,"allowUnlinking",false,"removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","downloadResult","downloadResult","openLabResult","openLabResult","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","privateTreatmentPanel",["loc",[null,[873,17],[873,38]]]]],[],[]]],["loc",[null,[860,0],[873,40]]]],
        ["inline","lab-result-panel",[],["changeParamValue","changeParamValue","downloadResult","downloadResult","openChart","openChart","selectBarcode","selectBarcode","dateChanged","openChangeDate","save","saveLabResult","actionReceiver",["subexpr","@mut",[["get","labResultPanel",["loc",[null,[881,17],[881,31]]]]],[],[]]],["loc",[null,[874,0],[881,33]]]],
        ["inline","all-lab-result-panel",[],["openChart","openChart","actionReceiver",["subexpr","@mut",[["get","allLabResultPanel",["loc",[null,[884,17],[884,34]]]]],[],[]]],["loc",[null,[882,0],[884,36]]]],
        ["inline","lab-result-chart-panel",[],["back","openResult","actionReceiver",["subexpr","@mut",[["get","labResultChartPanel",["loc",[null,[887,17],[887,36]]]]],[],[]]],["loc",[null,[885,0],[887,38]]]],
        ["inline","etiga-masterdata-logbook-panel",[],["actionReceiver",["subexpr","@mut",[["get","etigaMasterdataLogbookPanel",["loc",[null,[889,17],[889,44]]]]],[],[]]],["loc",[null,[888,0],[889,46]]]],
        ["inline","patient-chip-panel",[],["genders",["subexpr","@mut",[["get","genders",["loc",[null,[892,12],[892,19]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","patientChipPanel",["loc",[null,[893,19],[893,35]]]]],[],[]]],["loc",[null,[891,2],[893,37]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[899,8],[899,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[900,10],[900,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[901,13],[901,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[902,17],[902,36]]]]],[],[]]],["loc",[null,[895,2],[902,38]]]],
        ["inline","set-death-day-panel",[],["confirm","updateDeathDate","remove","removeDeathDate","actionReceiver",["subexpr","@mut",[["get","deathDayPanel",["loc",[null,[904,88],[904,101]]]]],[],[]]],["loc",[null,[904,0],[904,103]]]],
        ["inline","treatment-weight-panel",[],["weightInfos",["subexpr","@mut",[["get","weightInfos",["loc",[null,[905,37],[905,48]]]]],[],[]],"patient",["subexpr","@mut",[["get","model",["loc",[null,[905,57],[905,62]]]]],[],[]],"reloadWeightData","reloadWeightData","reloadPatient","reloadPatient","reopen","openWeightPanel","actionReceiver",["subexpr","@mut",[["get","weightChart",["loc",[null,[905,169],[905,180]]]]],[],[]]],["loc",[null,[905,0],[905,182]]]],
        ["inline","add-direct-app-notification-panel",[],["showTitle",false,"reload","reload","create","addDirectAppNotification","actionReceiver",["subexpr","@mut",[["get","addDirectAppNotification",["loc",[null,[906,117],[906,141]]]]],[],[]]],["loc",[null,[906,0],[906,143]]]],
        ["inline","add-direct-notification-panel",[],["reload","reload","create","addDirectNotification","users",["subexpr","@mut",[["get","users",["loc",[null,[907,85],[907,90]]]]],[],[]],"mandants",["subexpr","@mut",[["get","mandants",["loc",[null,[907,100],[907,108]]]]],[],[]],"mandant",["subexpr","@mut",[["get","mandant",["loc",[null,[907,117],[907,124]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","addDirectNotification",["loc",[null,[907,140],[907,161]]]]],[],[]]],["loc",[null,[907,0],[907,163]]]],
        ["inline","treatment-info-form-clone",[],["treatmentSaved","saveTreatment","actionReceiver",["subexpr","@mut",[["get","getTreatmentInfoForm",["loc",[null,[908,74],[908,94]]]]],[],[]]],["loc",[null,[908,0],[908,96]]]],
        ["inline","template-edit-panel",[],["download","downloadTemplate","reloadTemplate","reloadTemplate","actionReceiver",["subexpr","@mut",[["get","templateEditPanel",["loc",[null,[909,97],[909,114]]]]],[],[]]],["loc",[null,[909,0],[909,116]]]],
        ["inline","select-customer-panel",[],["select","changePatientOwner","actionReceiver",["subexpr","@mut",[["get","selectCustomerPanel",["loc",[null,[910,67],[910,86]]]]],[],[]]],["loc",[null,[910,0],[910,88]]]],
        ["inline","edit-todo-panel",[],["confirm","saveTodoInstance","actionReceiver",["subexpr","@mut",[["get","editTodoPanel",["loc",[null,[911,60],[911,73]]]]],[],[]]],["loc",[null,[911,0],[911,75]]]],
        ["inline","email-etiga-panel",[],["confirm","sendEtigaMail","openAddAppDirectNotification","openDirectMessage","qrDownload","downloadEtigaQr","actionReceiver",["subexpr","@mut",[["get","emailEtigaPanel",["loc",[null,[913,137],[913,152]]]]],[],[]]],["loc",[null,[913,0],[913,154]]]],
        ["inline","merge-patient-panel",[],["gotoPatient","gotoPatient","actionReceiver",["subexpr","@mut",[["get","mergePatientPanel",["loc",[null,[915,63],[915,80]]]]],[],[]]],["loc",[null,[915,0],[915,82]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30]
    };
  }()));

});