define('jason-frontend/routes/profile', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    setupController: function setupController(controller, model, params) {
      controller.set('selectedTab', 'theme');
      controller.set('resources', this.store.find('appointment-resource'));
      controller.set('categories', this.store.find('patient-appointment-category', { filterActive: true }));

      controller.set('users', this.store.find('user', { per_page: 100, addAll: false }));
      controller.set('rooms', this.store.find('treatment-room'));
      controller.set('stations', this.store.find('treatment-station'));
      controller.set('password', null);
      controller.set('patientCategories', this.store.find('patient-appointment-category', { filterActive: true }));

      controller.set('warehouses', this.store.find('inventory-warehouse'));
      controller.set('passwordConfirmation', null);

      $.ajax({
        url: "/api/cashRegisters?hideInactive=true",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        controller.set('cashRegisters', data.cashRegister);
      });
      if (isNaN(API['default'].getPrimaryWarehouseId())) {
        controller.set('primaryWarehouseId', null);
      } else {
        controller.set('primaryWarehouseId', API['default'].getPrimaryWarehouseId());
      }

      if (API['default'].getMandant() === "1") {
        controller.get('themes').push({
          id: "tpe", name: 'TPE (gelb)'
        });
      }

      $.ajax({
        url: "/api/profile",
        method: "GET",
        contentType: "application/json",
        dataType: "json",
        headers: {
          'X-AUTH-TOKEN': API['default'].getToken()
        }
      }).then(function (data) {
        if (!data.profile.theme) {
          data.profile.theme = 'elovet';
        }
        if (!data.profile.landingPage) {
          data.profile.landingPage = 'dashboard';
        }
        controller.set('model', data.profile);

        if (controller.get('patientCategories')) {
          controller.get('patientCategories').forEach(function (category) {
            if (category.id === data.profile.calCategory) {
              controller.set('calCategory', data.profile.calCategory);
            }
          });
        }

        controller.set('defaultSlotDuration', data.profile.defaultSlotDuration);
        controller.set('preselectFavorites', data.profile.preselectFavorites);
        controller.set('showUserBadges', data.profile.showUserBadges);
      }, function (data) {
        if (!data.profile.theme) {
          data.profile.theme = 'elovet';
        }
        if (!data.profile.landingPage) {
          data.profile.landingPage = 'dashboard';
        }
        controller.set('model', data.profile);
        controller.set('defaultSlotDuration', data.profile.defaultSlotDuration);
        controller.set('preselectFavorites', data.profile.preselectFavorites);
        controller.set('showUserBadges', data.profile.showUserBadges);
      });
    },
    adjustRefresh: function adjustRefresh(checkboxName, prefix, referer) {
      if (referer === 'all') {
        if ($('#' + prefix + '-9999').prop('checked')) {
          $('input.' + checkboxName).each(function (index, val) {
            if (val.id !== prefix + '-9999') {
              $(this).prop('checked', false);
            }
          });
        }
      } else {
        var atLeastOne = false;
        $('input.' + checkboxName + ':checked').each(function (index, val) {
          atLeastOne = true;
        });
        if (atLeastOne) {
          $('#' + prefix + '-9999').prop('checked', false);
        }
      }
    },
    actions: {
      changeExpired: function changeExpired() {
        var controller = this.get('controller');

        var data = {
          userId: API['default'].getUserId(),
          password: controller.get('password'),
          passwordConfirmation: controller.get('passwordConfirmation')
        };

        controller.set('inProgress', true);

        $.ajax({
          url: "/api/users/changePassword/0",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function () {
          jason.notifiction.notifiy('Erfolg', 'Passwort wurde erfolgreich geändert');
        }, function (data) {
          controller.set('inProgress', false);
          if (data.responseJSON) {
            var error = data.responseJSON.message;

            if (error === 'same') {
              jason.notifiction.error('Fehler', 'Das neue Passwort darf nicht mit dem alten übereinstimmen');
            }
          } else {
            jason.notifiction.notifiy('Erfolg', 'Passwort wurde erfolgreich geändert');
            controller.set('inProgress', false);
            controller.set('password', null);
            controller.set('passwordConfirmation', null);
          }
        });
      },
      triggerRefreshUser: function triggerRefreshUser(referer) {
        this.adjustRefresh('checkboxUser', 'user', referer);
      },
      triggerRefreshStation: function triggerRefreshStation(referer) {
        this.adjustRefresh('checkboxStation', 'station', referer);
      },
      triggerRefreshRoom: function triggerRefreshRoom(referer) {
        this.adjustRefresh('checkboxLocation', 'room', referer);
      },
      triggerRefreshCat: function triggerRefreshCat(referer) {
        this.adjustRefresh('checkboxCat', 'cat', referer);
      },
      selectTab: function selectTab(selected) {
        this.get('controller').set('selectedTab', selected);
      },
      saveWarehouse: function saveWarehouse() {

        var data = {
          warehouseId: this.get('controller').get('primaryWarehouseId')
        };

        API['default'].setPrimaryWarehouseId(data.warehouseId);

        $.ajax({
          url: "/api/profile/warehouse",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        });
      },
      savePractice: function savePractice() {

        var data = {
          preselectFavorites: this.get('controller').get('preselectFavorites'),
          landingPage: this.get('controller').get('model').landingPage,
          primaryCashRegisterId: this.get('controller').get('model').primaryCashRegisterId
        };

        API['default'].setPreselectFavorites(data.preselectFavorites);
        API['default'].setPrimaryCashRegisterId(data.primaryCashRegisterId);

        $.ajax({
          url: "/api/profile/practice",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        });
      },
      save: function save() {
        var self = this;
        var cats = new Array();
        $('input.checkboxCat:checked').each(function (index, val) {
          cats.push(val.id);
        });
        var types = new Array();
        $('input.checkboxType:checked').each(function (index, val) {
          types.push(val.id);
        });
        var users = new Array();
        $('input.checkboxUser:checked').each(function (index, val) {
          users.push(val.id);
        });
        var rooms = new Array();
        $('input.checkboxLocation:checked').each(function (index, val) {
          rooms.push(val.id);
        });
        var stations = new Array();
        $('input.checkboxStation:checked').each(function (index, val) {
          stations.push(val.id);
        });

        var data = {
          cats: cats,
          types: types,
          users: users,
          rooms: rooms,
          stations: stations,
          calCategory: this.get('controller').get('calCategory'),
          showUserBadges: this.get('controller').get('showUserBadges'),
          defaultSlotDuration: this.get('controller').get('defaultSlotDuration'),
          theme: this.get('controller').get('model').theme
        };

        $.ajax({
          url: "/api/profile",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify(data),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data2) {
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        }, function (data2) {
          $('body').removeClass('theme-eltiga');
          $('body').removeClass('theme-elovet');
          $('body').removeClass('theme-barbie');
          $('body').removeClass('theme-tpe');
          $('body').removeClass('theme-elord');
          $('body').removeClass('theme-pride');
          $('body').addClass('theme-' + data.theme);
          API['default'].setTheme(data.theme);
          API['default'].setCalDefaultSlotDuration(self.get('controller').get('defaultSlotDuration'));
          API['default'].setShowUserBadges(self.get('controller').get('showUserBadges'));
          jason.notifiction.notifiy('Erfolgreich', 'gespeichert');
        });
      }
    }
  });

});