define('jason-frontend/templates/components/edit-treatment-infos-panel', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 71,
              "column": 177
            },
            "end": {
              "line": 72,
              "column": 295
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"id","update_introReasons");
          dom.setAttribute(el1,"class","is-highlighted-alternative");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'data-ids');
          morphs[1] = dom.createUnsafeMorphAt(element1,0,0);
          morphs[2] = dom.createMorphAt(element1,2,2);
          return morphs;
        },
        statements: [
          ["attribute","data-ids",["concat",[["subexpr","print-list-of-id",[["get","patientInfo.info.introductionReasons",["loc",[null,[72,115],[72,151]]]]],[],["loc",[null,[72,96],[72,153]]]]]]],
          ["inline","print-list-of-name",[["get","patientInfo.info.introductionReasons",["loc",[null,[72,177],[72,213]]]]],[],["loc",[null,[72,155],[72,216]]]],
          ["inline","icon-plus",[],["color","#ff3399","classNames","inline-block vertical-middle"],["loc",[null,[72,217],[72,288]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 295
            },
            "end": {
              "line": 73,
              "column": 101
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    auswählen ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[73,30],[73,101]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 167
            },
            "end": {
              "line": 81,
              "column": 263
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"id","update_adminTasks");
          dom.setAttribute(el1,"class","is-highlighted");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'data-ids');
          morphs[1] = dom.createUnsafeMorphAt(element0,0,0);
          morphs[2] = dom.createMorphAt(element0,2,2);
          return morphs;
        },
        statements: [
          ["attribute","data-ids",["concat",[["subexpr","print-list-of-id",[["get","patientInfo.info.adminTasks",["loc",[null,[81,101],[81,128]]]]],[],["loc",[null,[81,82],[81,130]]]]]]],
          ["inline","print-list-of-name",[["get","patientInfo.info.adminTasks",["loc",[null,[81,154],[81,181]]]]],[],["loc",[null,[81,132],[81,184]]]],
          ["inline","icon-plus",[],["color","#ADCCD4","classNames","inline-block vertical-middle"],["loc",[null,[81,185],[81,256]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 263
            },
            "end": {
              "line": 82,
              "column": 101
            }
          },
          "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                    auswählen ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","icon-plus",[],["color","#999999","classNames","inline-block vertical-middle"],["loc",[null,[82,30],[82,101]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 118,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/components/edit-treatment-infos-panel.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","modal-edit-treatment-infos-panel");
        dom.setAttribute(el1,"class","popup-basic popup-extremely-large admin-form mfp-with-anim mfp-hide");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","panel");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3,"id","editTreatmentInfosForm");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-heading");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","panel-title");
        var el6 = dom.createTextNode("\n                Aufnahme bearbeiten");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","box pt0 pb0 mb20");
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","toggle-container patient-title");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h2");
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","cursor");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","grid infos");
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col gutter l-24/24 l-8/24@desk");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"id","update_wishDoctor");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col gutter l-24/24 l-8/24@desk");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"id","update_wishAssistant");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col gutter l-24/24 l-8/24@desk");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","");
        dom.setAttribute(el7,"id","update_room");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class"," col gutter l-24/24 l-8/24@desk");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","textarea textarea--inline clearfix");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","textarea__body");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col gutter l-24/24 l-12/24@tablet l-8/24@desk text-center");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Vorstellungsgründe ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","pick-list");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","cursor");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col gutter l-24/24 l-12/24@tablet l-8/24@desk text-center");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("span");
        dom.setAttribute(el7,"class","title");
        var el8 = dom.createTextNode("Administrative Tätigkeiten");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","pick-list");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("a");
        dom.setAttribute(el8,"class","cursor");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","panel-footer text-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5,"class","icon-button icon-button--success");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $(\"body\").delegate(\"#editTreatmentInfosForm textarea\", \"keyup\", function() {\n      $(this).height(30);\n      $(this).height(this.scrollHeight + parseFloat($(this).css(\"borderTopWidth\")) + parseFloat($(this).css(\"borderBottomWidth\")));\n    });\n\n    $(\"body\").delegate(\"input.readonly-hack\", \"focusin\", function() {\n      $(this).removeAttr('readonly');\n    });\n\n    // setTimeout(function() {\n    //   $(\"#editTreatmentInfosForm textarea\").each(function() {\n    //     $(this).height(this.scrollHeight + parseFloat($(this).css(\"borderTopWidth\")) + parseFloat($(this).css(\"borderBottomWidth\")));\n    //   });\n    // }, 500);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0, 1, 1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [1, 1]);
        var element5 = dom.childAt(element4, [1, 0]);
        var element6 = dom.childAt(element3, [3]);
        var element7 = dom.childAt(element6, [7, 1]);
        var element8 = dom.childAt(element6, [9]);
        var element9 = dom.childAt(element8, [3, 1]);
        var element10 = dom.childAt(element6, [11, 3, 1]);
        var element11 = dom.childAt(element2, [5, 1]);
        var morphs = new Array(16);
        morphs[0] = dom.createElementMorph(element5);
        morphs[1] = dom.createMorphAt(element5,0,0);
        morphs[2] = dom.createMorphAt(element4,3,3);
        morphs[3] = dom.createMorphAt(dom.childAt(element6, [1, 1]),1,1);
        morphs[4] = dom.createMorphAt(dom.childAt(element6, [3, 1]),1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element6, [5, 1]),1,1);
        morphs[6] = dom.createAttrMorph(element7, 'id');
        morphs[7] = dom.createMorphAt(dom.childAt(element7, [1]),1,1);
        morphs[8] = dom.createMorphAt(dom.childAt(element8, [1]),1,1);
        morphs[9] = dom.createElementMorph(element9);
        morphs[10] = dom.createMorphAt(element9,0,0);
        morphs[11] = dom.createElementMorph(element10);
        morphs[12] = dom.createMorphAt(element10,0,0);
        morphs[13] = dom.createElementMorph(element11);
        morphs[14] = dom.createMorphAt(element11,1,1);
        morphs[15] = dom.createMorphAt(fragment,4,4,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["gotoPatient",["get","patientInfo.patient.id",["loc",[null,[13,61],[13,83]]]]],[],["loc",[null,[13,38],[13,85]]]],
        ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[13,105],[13,129]]]]],[],[]],"content",["subexpr","@mut",[["get","patient.id",["loc",[null,[13,138],[13,148]]]]],[],[]]],["loc",[null,[13,86],[13,150]]]],
        ["inline","animal-icon",[],["color","#999999","animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[14,56],[14,87]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[14,96],[14,132]]]]],[],[]],"size","32","classNames","pb3 vertical-middle","content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[14,184],[14,217]]]]],[],[]]],["loc",[null,[14,16],[14,219]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","doctors",["loc",[null,[23,34],[23,41]]]]],[],[]],"value",["subexpr","@mut",[["get","patientInfo.doctor",["loc",[null,[24,32],[24,50]]]]],[],[]],"cssClass","input--dropdown room","optionLabelPath","shortName","allowClear",true,"name","wish-doctor","placeholder","Tierärztin wählen"],["loc",[null,[22,18],[30,20]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","assistants",["loc",[null,[36,34],[36,44]]]]],[],[]],"value",["subexpr","@mut",[["get","patientInfo.assistant",["loc",[null,[37,32],[37,53]]]]],[],[]],"cssClass","input--dropdown room","optionLabelPath","shortName","allowClear",true,"name","wishAssistant","placeholder","Assistentin wählen"],["loc",[null,[35,18],[43,20]]]],
        ["inline","select-2",[],["content",["subexpr","@mut",[["get","rooms",["loc",[null,[49,34],[49,39]]]]],[],[]],"value",["subexpr","@mut",[["get","patientInfo.room",["loc",[null,[50,32],[50,48]]]]],[],[]],"cssClass","input--dropdown room","optionLabelPath","name","allowClear",true,"name","treatment-room","placeholder","Behandlungslokation wählen"],["loc",[null,[48,18],[56,20]]]],
        ["attribute","id",["concat",[["get","patientInfo.patient.id",["loc",[null,[61,72],[61,94]]]],"_anamnese"]]],
        ["inline","textarea",[],["rows","3","name","symptom","value",["subexpr","@mut",[["get","patientInfo.info.medicalHistory",["loc",[null,[63,61],[63,92]]]]],[],[]],"class","input textarea__input full","placeholder","Anamnese beschreiben"],["loc",[null,[63,20],[63,164]]]],
        ["inline","button-etiga-small",[],["content","","classNames","pb2 vertical-middle inline-block","size","18","width",34],["loc",[null,[69,55],[69,153]]]],
        ["element","action",["selectTreatmentDetailInfoMulti","introductionReasons",["get","patientInfo.info.introductionReasons",["loc",[null,[71,100],[71,136]]]],"Vorstellungsgründe",["get","patientInfo.info",["loc",[null,[71,158],[71,174]]]]],[],["loc",[null,[71,36],[71,176]]]],
        ["block","if",[["get","patientInfo.info.introductionReasons",["loc",[null,[71,183],[71,219]]]]],[],0,1,["loc",[null,[71,177],[73,108]]]],
        ["element","action",["selectTreatmentDetailInfoMulti","adminTasks",["get","patientInfo.info.adminTasks",["loc",[null,[80,91],[80,118]]]],"Administrative Tätigkeiten",["get","patientInfo.info",["loc",[null,[80,148],[80,164]]]]],[],["loc",[null,[80,36],[80,166]]]],
        ["block","if",[["get","patientInfo.info.adminTasks",["loc",[null,[80,173],[80,200]]]]],[],2,3,["loc",[null,[80,167],[82,108]]]],
        ["element","action",["save"],[],["loc",[null,[89,11],[89,28]]]],
        ["inline","button-save",[],["size","44","color","#ffffff","content","Speichern"],["loc",[null,[90,10],[90,71]]]],
        ["inline","treatment-select-panel",[],["prefix","update","confirm","updateSelectsUpdatePanel","actionReceiver",["subexpr","@mut",[["get","treatmentSelectPanelUpdate",["loc",[null,[117,91],[117,117]]]]],[],[]]],["loc",[null,[117,0],[117,119]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});