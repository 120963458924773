define('jason-frontend/components/customer-searchform', ['exports', 'ember', 'jason-frontend/api', 'jason-frontend/helpers/is-journal-enabled'], function (exports, Ember, API, is_journal_enabled) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    quickFilter: 'visible',
    filterDead: false,
    showFilters: false,
    showCashSaleButton: false,
    filterAll: true,
    filterName: null,
    showSearch: true,
    showStickyTableHeader: false,
    showQuickFilters: true,
    hideContacts: false,
    myStickyOptions: {
      topSpacing: 60,
      zIndex: 9
    },
    myStickyOptionsTableHead: {
      topSpacing: API['default'].stickyTopSpacing,
      zIndex: 9
    },
    myStickyOptionsTableHeadCollapsed: {
      topSpacing: API['default'].stickyTopSpacingCollapsed,
      zIndex: 9
    },
    actions: {
      undelete: function undelete(id) {
        var self = this;
        $.ajax({
          url: "/api/customers/" + id + "/undelete",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          jason.notifiction.notifiy('Erfolgreich', 'wiederhergestellt');
          var searchString = self.get('filterName');
          self.sendAction('applyFilter', searchString, true);
        });
      },
      toggleQuickFilter: function toggleQuickFilter(filter) {
        var controller = this;
        this.set('filterDead', true);
        var searchString = this.get('filterName');
        if (filter === 'all') {
          controller.set('quickFilter', 'all');
          this.sendAction('applyFilter', searchString);
        } else if (filter === 'visible') {
          controller.set('quickFilter', 'visible');
          this.set('filterDead', false);
          this.sendAction('applyFilter', searchString);
        } else if (filter === 'deleted') {
          controller.set('quickFilter', 'deleted');
          this.sendAction('applyFilter', searchString, true);
        } else if (filter === 'stable') {
          controller.set('quickFilter', 'stable');
          this.sendAction('applyFilter', searchString, false, true);
        }
      },
      contact: function contact(customerId, type, value) {

        var data = {
          type: type,
          value: value
        };
        return $.ajax({
          url: "/api/customers/" + customerId + "/trackContact",
          method: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (error) {
          if (type === 'phone') {
            window.open("tel:" + value, '_self');
          } else if (type === 'email') {
            window.open("mailto://" + value, '_self');
          }
        });
      },
      clickPatient: function clickPatient(patient, reference) {
        if (reference && reference === 'stable') {
          this.sendAction('selectPatient', patient.id);
        } else {
          var tab = 'history';
          if (is_journal_enabled.isJournalEnabled()) {
            tab = 'activities';
          }
          this.sendAction('gotoPatient', patient.id, tab);
        }
      },
      setCashsale: function setCashsale() {
        this.sendAction('setCashsale');
      },
      "delete": function _delete(id) {
        this.sendAction('delete', id);
      },
      applyFilter: function applyFilter() {
        var searchString = this.get('filterName');
        this.sendAction('applyFilter', searchString);
      },
      openScanEtigaQr: function openScanEtigaQr() {
        this.sendAction('openScanEtigaQr');
      },
      addPatient: function addPatient(id) {
        this.sendAction('addPatient', id);
      },
      selectCustomer: function selectCustomer(customerId) {
        var patients = Array();
        $('#patientsForCustomer' + customerId + ' input.patient:checked').each(function (e) {
          var id = $(this).attr('data-id');
          patients.push(id);
        });
        this.sendAction('selectCustomer', customerId, patients);
      }
    }
  });

});