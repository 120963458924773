define('jason-frontend/templates/inventory/agg-stock', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 34,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/inventory/agg-stock.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","div-horizontal");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","filter-lane grid");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-field-rework");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"class","select-placeholder");
          var el4 = dom.createTextNode("Lager");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),3,3);
          return morphs;
        },
        statements: [
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","warehouses",["loc",[null,[25,20],[25,30]]]]],[],[]],"value",["subexpr","@mut",[["get","filterWarehouse",["loc",[null,[26,18],[26,33]]]]],[],[]],"optionLabelPath","name","allowClear",true,"placeholder","Alle","cssClass","custom-select2 newStyle tuned"],["loc",[null,[24,10],[31,12]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 4
            },
            "end": {
              "line": 44,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/inventory/agg-stock.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","loading-animation",["loc",[null,[43,6],[43,27]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 8
              },
              "end": {
                "line": 55,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/inventory/agg-stock.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__head");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-2/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Produkt");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Lagerbestand");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-3/24 table__cell--center");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("Lager");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-4/24 table__cell--center");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","table__title title");
            var el4 = dom.createTextNode("VPE");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-4/24");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 63,
                  "column": 22
                },
                "end": {
                  "line": 63,
                  "column": 85
                }
              },
              "moduleName": "jason-frontend/templates/inventory/agg-stock.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.productName",["loc",[null,[63,65],[63,85]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 10
              },
              "end": {
                "line": 79,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/inventory/agg-stock.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--icon l-4/24 l-2/24@desk");
            dom.setAttribute(el2,"data-label","");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createElement("img");
            dom.setAttribute(el4,"src","assets/images/icons/product-dark.svg");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--name l-20/24 l-8/24@desk");
            dom.setAttribute(el2,"data-label","Produkt");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-3/24@desk table__cell--center text-center");
            dom.setAttribute(el2,"data-label","Lagerbestand");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 l-3/24@desk text-center");
            dom.setAttribute(el2,"data-label","Lager");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24 text-center l-4/24@desk");
            dom.setAttribute(el2,"data-label","VPE");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell table__cell--action l-6/24 l-4/24@desk");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3,"class","icon-button icon-button--default list-action-square");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [9, 1]);
            var element2 = dom.childAt(element0, [11, 1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [5, 1]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [7, 1]),0,0);
            morphs[3] = dom.createMorphAt(element1,0,0);
            morphs[4] = dom.createMorphAt(element1,2,2);
            morphs[5] = dom.createElementMorph(element2);
            morphs[6] = dom.createMorphAt(element2,1,1);
            return morphs;
          },
          statements: [
            ["block","link-to",["products.edit",["get","item.productId",["loc",[null,[63,49],[63,63]]]]],[],0,null,["loc",[null,[63,22],[63,97]]]],
            ["inline","stock-status",[],["stocks",["subexpr","@mut",[["get","item.stockLevels",["loc",[null,[66,44],[66,60]]]]],[],[]]],["loc",[null,[66,22],[66,62]]]],
            ["content","item.warehouseName",["loc",[null,[69,22],[69,44]]]],
            ["content","item.packageQuantity",["loc",[null,[72,22],[72,46]]]],
            ["content","item.unit.name",["loc",[null,[72,47],[72,65]]]],
            ["element","action",["showStock",["get","item.prodId",["loc",[null,[75,40],[75,51]]]],["get","item.productName",["loc",[null,[75,52],[75,68]]]]],[],["loc",[null,[75,19],[75,70]]]],
            ["inline","button-view",[],["size","32","color","#fff","showStroke",false],["loc",[null,[76,18],[76,73]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 4
            },
            "end": {
              "line": 82,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/inventory/agg-stock.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table grid table--large box no-box@phone");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element3,1,1);
          morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),1,1);
          return morphs;
        },
        statements: [
          ["block","sticky-container",[],["options",["subexpr","@mut",[["get","myStickyOptionsTableHead",["loc",[null,[46,36],[46,60]]]]],[],[]]],0,null,["loc",[null,[46,8],[55,29]]]],
          ["block","each",[["get","model",["loc",[null,[57,18],[57,23]]]]],[],1,null,["loc",[null,[57,10],[79,19]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 96,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/inventory/agg-stock.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Lagerbestand");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/qr.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Artikel scannen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"class","rework-content transaction-filter with-context-bar");
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table-wrapper-rework no-box@phone");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","pagination-wrapper is-fixed");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [1]);
        var element5 = dom.childAt(element4, [1, 3, 1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(fragment, [3, 1]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createElementMorph(element5);
        morphs[2] = dom.createMorphAt(element7,1,1);
        morphs[3] = dom.createMorphAt(element7,3,3);
        morphs[4] = dom.createMorphAt(element6,3,3);
        morphs[5] = dom.createMorphAt(element8,1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element8, [3]),1,1);
        morphs[7] = dom.createMorphAt(fragment,5,5,contextualElement);
        morphs[8] = dom.createMorphAt(fragment,7,7,contextualElement);
        morphs[9] = dom.createMorphAt(fragment,9,9,contextualElement);
        morphs[10] = dom.createMorphAt(fragment,11,11,contextualElement);
        morphs[11] = dom.createMorphAt(fragment,13,13,contextualElement);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar sticky with-searchbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[3,69],[3,80]]]],"with-filters",""],[],["loc",[null,[3,48],[3,100]]]]]]],
        ["element","action",["scan"],[],["loc",[null,[7,9],[7,26]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterCode",["loc",[null,[15,30],[15,40]]]]],[],[]],"placeHolder","Artikel-Name, Inventar-Nr suchen","search","applyFilter"],["loc",[null,[15,6],[15,110]]]],
        ["inline","filter-box",[],["showFilters",["subexpr","@mut",[["get","showFilters",["loc",[null,[16,31],[16,42]]]]],[],[]]],["loc",[null,[16,6],[16,44]]]],
        ["block","if",[["get","showFilters",["loc",[null,[18,10],[18,21]]]]],[],0,null,["loc",[null,[18,4],[34,11]]]],
        ["block","if",[["get","showLoading",["loc",[null,[42,10],[42,21]]]]],[],1,2,["loc",[null,[42,4],[82,11]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[85,29],[85,36]]]]],[],[]],"numPagesToShow",7],["loc",[null,[85,6],[85,55]]]],
        ["inline","quick-scan-product-panel",[],["confirm","scanProduct","addArticle","scanProductId","actionReceiver",["subexpr","@mut",[["get","quickScanProductPanel",["loc",[null,[91,91],[91,112]]]]],[],[]]],["loc",[null,[91,0],[91,114]]]],
        ["inline","show-stock-panel",[],["openOutgoing","openOutgoing","openCorrection","openCorrectionPanel","updateBatchNumber","updateBatchNumber","updateExpiry","updateExpiry","updateLocation","updateLocation","printBarcode","printBarcode","showPatient","showPatient","showInvoice","showInvoice","bookoutMovement","bookoutMovement","transfer","transfer","bookout","bookout","actionReceiver",["subexpr","@mut",[["get","stockStockDetailPanel",["loc",[null,[92,350],[92,371]]]]],[],[]]],["loc",[null,[92,0],[92,373]]]],
        ["inline","change-warehouse-panel",[],["changeWarehouse","changeWarehouse","actionReceiver",["subexpr","@mut",[["get","changeWarehousePanel",["loc",[null,[93,74],[93,94]]]]],[],[]]],["loc",[null,[93,0],[93,96]]]],
        ["inline","inventory-correction-panel",[],["save","saveCorrection","actionReceiver",["subexpr","@mut",[["get","inventoryCorrectionPanel",["loc",[null,[94,66],[94,90]]]]],[],[]]],["loc",[null,[94,0],[94,92]]]],
        ["inline","inventory-outgoing-panel",[],["warehouses",["subexpr","@mut",[["get","warehouses",["loc",[null,[95,38],[95,48]]]]],[],[]],"save","saveOutgoing","actionReceiver",["subexpr","@mut",[["get","inventoryOutgoingPanel",["loc",[null,[95,84],[95,106]]]]],[],[]]],["loc",[null,[95,0],[95,108]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});