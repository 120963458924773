define('jason-frontend/templates/purchases/detail', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","with-icon button context");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("QR-Code drucken");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element54 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element54);
          morphs[1] = dom.createMorphAt(element54,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["printQr",["get","model.id",["loc",[null,[4,28],[4,36]]]]],[],["loc",[null,[4,9],[4,38]]]],
          ["inline","button-qr",[],["content","","color","#FFF","size","32","showStroke",false],["loc",[null,[5,8],[5,72]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 2
            },
            "end": {
              "line": 18,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"id","favorite");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element53 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element53, 'class');
          morphs[1] = dom.createElementMorph(element53);
          morphs[2] = dom.createMorphAt(element53,1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["selectable-item favs cursor ",["subexpr","css-bool-evaluator",[["get","model.favorite",["loc",[null,[14,134],[14,148]]]],"selected",""],[],["loc",[null,[14,113],[14,164]]]]]]],
          ["element","action",["toggleFavorite",["get","model",["loc",[null,[14,35],[14,40]]]],["subexpr","not",[["get","model.favorite",["loc",[null,[14,46],[14,60]]]]],[],["loc",[null,[14,41],[14,61]]]]],["bubbles",false],["loc",[null,[14,9],[14,77]]]],
          ["inline","button-favorite",[],["size","22","color",["subexpr","css-bool-evaluator",[["get","model.favorite",["loc",[null,[15,62],[15,76]]]],"#ff3399","#999999"],[],["loc",[null,[15,42],[15,97]]]],"showStroke",false,"content",""],["loc",[null,[15,8],[15,127]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 10
            },
            "end": {
              "line": 24,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","max-height: 300px");
          dom.setAttribute(el1,"class","media-object");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element52 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element52, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["subexpr","get-host-path",[["get","model.picture.path",["loc",[null,[23,85],[23,103]]]]],[],["loc",[null,[23,69],[23,105]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 10
            },
            "end": {
              "line": 26,
              "column": 10
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"style","max-height: 300px;margin-top:30px");
          dom.setAttribute(el1,"class","media-object");
          dom.setAttribute(el1,"src","assets/images/icons/noimage.svg");
          dom.setAttribute(el1,"alt","");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 29
            },
            "end": {
              "line": 32,
              "column": 106
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("Art.-Nr:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.rpaId",["loc",[null,[32,90],[32,101]]]],["get","-",["loc",[null,[32,102],[32,103]]]]],[],["loc",[null,[32,64],[32,106]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child5 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 114
            },
            "end": {
              "line": 32,
              "column": 183
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("PZN:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.pzn",["loc",[null,[32,169],[32,178]]]],["get","-",["loc",[null,[32,179],[32,180]]]]],[],["loc",[null,[32,143],[32,183]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child6 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 191
            },
            "end": {
              "line": 32,
              "column": 277
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("b");
          var el2 = dom.createTextNode("EAN:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["inline","get-value-or-empty-sign",[["get","model.gtin",["loc",[null,[32,262],[32,272]]]],["get","-",["loc",[null,[32,273],[32,274]]]]],[],["loc",[null,[32,236],[32,277]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child7 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 12
            },
            "end": {
              "line": 42,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Verpackungseinheit");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element51 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element51,0,0);
          morphs[1] = dom.createMorphAt(element51,2,2);
          return morphs;
        },
        statements: [
          ["content","model.packageQuantity",["loc",[null,[41,17],[41,42]]]],
          ["content","model.altUnit.name",["loc",[null,[41,43],[41,65]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child8 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 15
            },
            "end": {
              "line": 46,
              "column": 39
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Ja");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child9 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 39
            },
            "end": {
              "line": 46,
              "column": 51
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Nein");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 20
                },
                "end": {
                  "line": 59,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","label stock-label label-danger");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              return morphs;
            },
            statements: [
              ["content","stock.amount",["loc",[null,[57,24],[57,40]]]],
              ["content","stock.warehouseName",["loc",[null,[58,30],[58,53]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 59,
                    "column": 20
                  },
                  "end": {
                    "line": 61,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","label stock-label label-warning");
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                return morphs;
              },
              statements: [
                ["content","stock.amount",["loc",[null,[60,69],[60,85]]]],
                ["content","stock.warehouseName",["loc",[null,[60,92],[60,115]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 61,
                      "column": 20
                    },
                    "end": {
                      "line": 63,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","label stock-label label-success");
                  var el2 = dom.createTextNode(" ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                    ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                  morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","stock.amount",["loc",[null,[62,69],[62,85]]]],
                  ["content","stock.warehouseName",["loc",[null,[62,92],[62,115]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 61,
                    "column": 20
                  },
                  "end": {
                    "line": 63,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","gt",[["get","stock.amount",["loc",[null,[61,34],[61,46]]]],2],[],["loc",[null,[61,30],[61,50]]]]],[],0,null,["loc",[null,[61,20],[63,20]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 20
                },
                "end": {
                  "line": 63,
                  "column": 20
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["subexpr","lte",[["get","stock.amount",["loc",[null,[59,35],[59,47]]]],2],[],["loc",[null,[59,30],[59,51]]]]],[],0,1,["loc",[null,[59,20],[63,20]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 16
              },
              "end": {
                "line": 65,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","block mb5");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","lte",[["get","stock.amount",["loc",[null,[55,31],[55,43]]]],0],[],["loc",[null,[55,26],[55,46]]]]],[],0,1,["loc",[null,[55,20],[63,27]]]]
          ],
          locals: ["stock"],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 12
            },
            "end": {
              "line": 66,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","mb10");
          var el2 = dom.createTextNode("Lagerstand");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.stockLevels",["loc",[null,[53,24],[53,41]]]]],[],0,null,["loc",[null,[53,16],[65,25]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child11 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 32
              },
              "end": {
                "line": 69,
                "column": 135
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge info-badge-very-small");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["content","tag.name",["loc",[null,[69,115],[69,127]]]]
          ],
          locals: ["tag"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 67,
              "column": 12
            },
            "end": {
              "line": 70,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","mb10 mt20");
          var el2 = dom.createTextNode("Tags");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","tags");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["block","each",[["get","model.systemTags",["loc",[null,[69,40],[69,56]]]]],[],0,null,["loc",[null,[69,32],[69,144]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 83,
              "column": 12
            },
            "end": {
              "line": 87,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","js-tabs__label tabs__label title");
          dom.setAttribute(el2,"href","#tab-5");
          var el3 = dom.createTextNode("Verkauf");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element50 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element50);
          return morphs;
        },
        statements: [
          ["element","action",["gotoSale",["get","model.id",["loc",[null,[85,94],[85,102]]]]],[],["loc",[null,[85,74],[85,104]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 91,
              "column": 12
            },
            "end": {
              "line": 95,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","js-tabs__label tabs__label title");
          dom.setAttribute(el2,"href","#tab-3");
          var el3 = dom.createTextNode("Bestellhistorie");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element49 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element49);
          return morphs;
        },
        statements: [
          ["element","action",["selectTab","history"],[],["loc",[null,[93,74],[93,106]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 96,
              "column": 12
            },
            "end": {
              "line": 103,
              "column": 9
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-6");
          var el3 = dom.createTextNode("Nachbestellung");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-4");
          var el3 = dom.createTextNode("Lager");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element47 = dom.childAt(fragment, [1, 1]);
          var element48 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element47, 'class');
          morphs[1] = dom.createElementMorph(element47);
          morphs[2] = dom.createAttrMorph(element48, 'class');
          morphs[3] = dom.createElementMorph(element48);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[98,82],[98,91]]]],"reorder"],[],["loc",[null,[98,78],[98,102]]]],"is-active",""],[],["loc",[null,[98,57],[98,119]]]]]]],
          ["element","action",["selectTab","reorder"],[],["loc",[null,[98,135],[98,167]]]],
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[101,82],[101,91]]]],"inventory"],[],["loc",[null,[101,78],[101,104]]]],"is-active",""],[],["loc",[null,[101,57],[101,121]]]]]]],
          ["element","action",["selectTab","inventory"],[],["loc",[null,[101,137],[101,171]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child15 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 104,
              "column": 12
            },
            "end": {
              "line": 108,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"href","#tab-debug");
          var el3 = dom.createTextNode("Debug");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element46 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element46, 'class');
          morphs[1] = dom.createElementMorph(element46);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[106,84],[106,93]]]],"debug"],[],["loc",[null,[106,80],[106,102]]]],"is-active",""],[],["loc",[null,[106,59],[106,119]]]]]]],
          ["element","action",["selectTab","debug"],[],["loc",[null,[106,139],[106,169]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child16 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 118,
              "column": 15
            },
            "end": {
              "line": 118,
              "column": 106
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"src","assets/images/icons/icon-check.svg");
          dom.setAttribute(el1,"style","height: 24px");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child17 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 118,
              "column": 106
            },
            "end": {
              "line": 118,
              "column": 115
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("-");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child18 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 118,
              "column": 123
            },
            "end": {
              "line": 118,
              "column": 176
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("(case ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(")");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          return morphs;
        },
        statements: [
          ["content","model.richterCase",["loc",[null,[118,154],[118,175]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child19 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 132,
                    "column": 26
                  },
                  "end": {
                    "line": 135,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                dom.setAttribute(el1,"class","info-badge top-offer small cursor");
                var el2 = dom.createTextNode("Top\n                              Angebot");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element39 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element39);
                return morphs;
              },
              statements: [
                ["element","action",["openActionInfos",["get","entry",["loc",[null,[133,104],[133,109]]]]],[],["loc",[null,[133,77],[133,111]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 135,
                      "column": 26
                    },
                    "end": {
                      "line": 138,
                      "column": 26
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("label");
                  dom.setAttribute(el1,"class","info-badge offer small cursor");
                  var el2 = dom.createTextNode("Angebot");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element38 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createElementMorph(element38);
                  return morphs;
                },
                statements: [
                  ["element","action",["openActionInfos",["get","entry",["loc",[null,[137,82],[137,87]]]]],[],["loc",[null,[136,73],[137,89]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 138,
                        "column": 26
                      },
                      "end": {
                        "line": 140,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("label");
                    dom.setAttribute(el1,"class","info-badge cashback small");
                    var el2 = dom.createTextNode("Cashback");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 140,
                          "column": 26
                        },
                        "end": {
                          "line": 142,
                          "column": 26
                        }
                      },
                      "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("label");
                      dom.setAttribute(el1,"class","info-badge small");
                      var el2 = dom.createTextNode("Ihr eLord Preis");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                          ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 140,
                        "column": 26
                      },
                      "end": {
                        "line": 142,
                        "column": 26
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","entry.richterInfo",["loc",[null,[140,36],[140,53]]]]],[],0,null,["loc",[null,[140,26],[142,26]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 138,
                      "column": 26
                    },
                    "end": {
                      "line": 142,
                      "column": 26
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","entry.cashbackActive",["loc",[null,[138,36],[138,56]]]]],[],0,1,["loc",[null,[138,26],[142,26]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 135,
                    "column": 26
                  },
                  "end": {
                    "line": 142,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","entry.promotionActive",["loc",[null,[135,36],[135,57]]]]],[],0,1,["loc",[null,[135,26],[142,26]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child2 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 146,
                    "column": 26
                  },
                  "end": {
                    "line": 152,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small delivery-info is-warning");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","inline-icon");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                                  Nicht verfügbar\n                              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]),0,0);
                return morphs;
              },
              statements: [
                ["inline","icon-delivery",[],["size","26","content","","color","#d15444","strokeColor","#d15444"],["loc",[null,[147,28],[147,104]]]],
                ["inline","icon-info",[],["color","#d15444","size","16"],["loc",[null,[149,58],[149,97]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child3 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 155,
                        "column": 69
                      },
                      "end": {
                        "line": 156,
                        "column": 72
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["content","entry.deliveryInfo",["loc",[null,[156,50],[156,72]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 156,
                        "column": 72
                      },
                      "end": {
                        "line": 156,
                        "column": 89
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Lieferbar");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 153,
                      "column": 28
                    },
                    "end": {
                      "line": 157,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(" ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","small delivery-info is-success");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","icon-delivery",[],["size","26","content","","color","#429321","strokeColor","#3B6182"],["loc",[null,[154,30],[154,106]]]],
                  ["block","if",[["get","entry.deliveryInfo",["loc",[null,[156,30],[156,48]]]]],[],0,1,["loc",[null,[155,69],[156,96]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 157,
                        "column": 28
                      },
                      "end": {
                        "line": 160,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","small delivery-info is-orange");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","icon-delivery",[],["size","26","content","","color","#F9B066","strokeColor","#F9B066"],["loc",[null,[158,30],[158,107]]]],
                    ["content","entry.deliveryInfo",["loc",[null,[159,68],[159,90]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 165,
                          "column": 34
                        },
                        "end": {
                          "line": 165,
                          "column": 82
                        }
                      },
                      "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["content","entry.deliveryInfo",["loc",[null,[165,60],[165,82]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 165,
                            "column": 90
                          },
                          "end": {
                            "line": 166,
                            "column": 78
                          }
                        },
                        "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n                                    Lieferbar");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("br");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("ab ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["content","entry.deliverableFrom",["loc",[null,[166,53],[166,78]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 166,
                            "column": 78
                          },
                          "end": {
                            "line": 167,
                            "column": 63
                          }
                        },
                        "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("\n                                    Derzeit");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("br");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("nicht lieferbar");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 165,
                          "column": 82
                        },
                        "end": {
                          "line": 167,
                          "column": 70
                        }
                      },
                      "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","entry.deliverableFrom",["loc",[null,[165,96],[165,117]]]]],[],0,1,["loc",[null,[165,90],[167,70]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 160,
                        "column": 28
                      },
                      "end": {
                        "line": 170,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","small delivery-info is-warning");
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2,"class","inline-icon");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","cursor");
                    var el3 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                              ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                            ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element36 = dom.childAt(fragment, [3]);
                    var element37 = dom.childAt(element36, [3]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(element36, [1]),0,0);
                    morphs[2] = dom.createElementMorph(element37);
                    morphs[3] = dom.createMorphAt(element37,1,1);
                    return morphs;
                  },
                  statements: [
                    ["inline","icon-delivery",[],["size","26","content","","color","#d15444","strokeColor","#d15444"],["loc",[null,[161,30],[161,106]]]],
                    ["inline","icon-info",[],["color","#d15444","size","16","content",""],["loc",[null,[163,58],[163,108]]]],
                    ["element","action",["openDeliveryInfos",["get","entry",["loc",[null,[164,79],[164,84]]]]],[],["loc",[null,[164,50],[164,86]]]],
                    ["block","if",[["get","entry.deliveryInfo",["loc",[null,[165,40],[165,58]]]]],[],0,1,["loc",[null,[165,34],[167,77]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 157,
                      "column": 28
                    },
                    "end": {
                      "line": 170,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","eq",[["get","entry.deliveryStatus.id",["loc",[null,[157,42],[157,65]]]],"maydeliverable"],[],["loc",[null,[157,38],[157,83]]]]],[],0,1,["loc",[null,[157,28],[170,28]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 152,
                    "column": 26
                  },
                  "end": {
                    "line": 171,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","entry.deliveryStatus.id",["loc",[null,[153,38],[153,61]]]],"deliverable"],[],["loc",[null,[153,34],[153,76]]]]],[],0,1,["loc",[null,[153,28],[170,35]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 177,
                    "column": 26
                  },
                  "end": {
                    "line": 178,
                    "column": 131
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small price-devided block");
                var el2 = dom.createTextNode("(");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" / VE)");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
                return morphs;
              },
              statements: [
                ["inline","format-currency",[],["value",["subexpr","@mut",[["get","entry.jasonPriceDivided",["loc",[null,[178,93],[178,116]]]]],[],[]]],["loc",[null,[178,69],[178,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 183,
                    "column": 28
                  },
                  "end": {
                    "line": 186,
                    "column": 28
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","price-label");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["inline","format-currency",[],["classNames","inline-block","value",["subexpr","@mut",[["get","entry.jasonPrice",["loc",[null,[185,80],[185,96]]]]],[],[]]],["loc",[null,[184,56],[185,98]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child6 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 189,
                      "column": 99
                    },
                    "end": {
                      "line": 191,
                      "column": 82
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n                                (-");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("%)");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["inline","format-number-german",[["get","entry.savingInPercent",["loc",[null,[191,57],[191,78]]]]],[],["loc",[null,[191,34],[191,80]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 187,
                    "column": 28
                  },
                  "end": {
                    "line": 191,
                    "column": 96
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","small price-devided");
                var el2 = dom.createTextNode("statt ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element35 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element35,1,1);
                morphs[1] = dom.createMorphAt(element35,3,3);
                return morphs;
              },
              statements: [
                ["inline","format-currency",[],["classNames","inline-block u-line-through","value",["subexpr","@mut",[["get","entry.retailPrice",["loc",[null,[189,79],[189,96]]]]],[],[]]],["loc",[null,[188,70],[189,98]]]],
                ["block","if",[["subexpr","gt",[["get","entry.reduction",["loc",[null,[190,36],[190,51]]]],0],[],["loc",[null,[190,32],[190,54]]]]],[],0,null,["loc",[null,[189,99],[191,89]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child7 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 195,
                    "column": 26
                  },
                  "end": {
                    "line": 197,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","entry.selectedQty",["loc",[null,[196,58],[196,75]]]]],[],[]],"id",["subexpr","@mut",[["get","entry.id",["loc",[null,[196,79],[196,87]]]]],[],[]]],["loc",[null,[196,28],[196,89]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child8 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 204,
                      "column": 28
                    },
                    "end": {
                      "line": 212,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--delete list-action-square exp");
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element33 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(element33,1,1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element33, [3]),0,0);
                  return morphs;
                },
                statements: [
                  ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","entry",["loc",[null,[206,110],[206,115]]]]],[],[]],"confirmed","delete","text","Wollen Sie diese Zeile wirklich löschen?"],["loc",[null,[206,32],[208,110]]]],
                  ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","entry",["loc",[null,[209,63],[209,68]]]]],[],[]],"confirmed","deletePriceRow","textToShow","Löschen","text","Wollen Sie diese Zeile wirklich löschen?"],["loc",[null,[209,38],[210,107]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 198,
                    "column": 26
                  },
                  "end": {
                    "line": 213,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square exp");
                var el2 = dom.createTextNode("\n                              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createTextNode("Bearbeiten");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element34 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element34);
                morphs[1] = dom.createMorphAt(element34,1,1);
                morphs[2] = dom.createMorphAt(fragment,3,3,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["element","action",["edit",["get","model",["loc",[null,[199,111],[199,116]]]],["get","entry.id",["loc",[null,[200,104],[200,112]]]],["get","order.id",["loc",[null,[201,104],[201,112]]]]],[],["loc",[null,[199,95],[201,114]]]],
                ["inline","button-edit",[],["color","#fff","showStroke",false,"size","36","content",""],["loc",[null,[202,30],[202,96]]]],
                ["block","if",[["subexpr","gt",[["get","model.activeRowSize",["loc",[null,[204,38],[204,57]]]],1],[],["loc",[null,[204,34],[204,60]]]]],[],0,null,["loc",[null,[204,28],[212,35]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child9 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 214,
                      "column": 28
                    },
                    "end": {
                      "line": 219,
                      "column": 28
                    }
                  },
                  "moduleName": "jason-frontend/templates/purchases/detail.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1,"class","icon-button icon-button--success list-action-square exp");
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  var el3 = dom.createTextNode("In den Warenkorb");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element32 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element32);
                  morphs[1] = dom.createMorphAt(element32,1,1);
                  return morphs;
                },
                statements: [
                  ["element","action",["checkLaboklinPanel",["get","model",["loc",[null,[216,53],[216,58]]]],["get","order.id",["loc",[null,[216,59],[216,67]]]],["get","entry",["loc",[null,[216,68],[216,73]]]],["get","entry.supplier.id",["loc",[null,[216,74],[216,91]]]],["get","model.name",["loc",[null,[216,92],[216,102]]]]],[],["loc",[null,[215,97],[216,104]]]],
                  ["inline","button-add-cart",[],["content","","showStroke",false,"color","#fff","size","40"],["loc",[null,[217,32],[217,102]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 213,
                    "column": 26
                  },
                  "end": {
                    "line": 220,
                    "column": 26
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","and",[["subexpr","not",[["get","entry.richterNav",["loc",[null,[214,44],[214,60]]]]],[],["loc",[null,[214,39],[214,61]]]],["subexpr","or",[["get","entry.jasonPrice",["loc",[null,[214,66],[214,82]]]],["get","entry.free",["loc",[null,[214,83],[214,93]]]]],[],["loc",[null,[214,62],[214,94]]]]],[],["loc",[null,[214,34],[214,95]]]]],[],0,null,["loc",[null,[214,28],[219,35]]]]
              ],
              locals: [],
              templates: [child0]
            };
          }());
          var child10 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 223,
                    "column": 22
                  },
                  "end": {
                    "line": 229,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","col l-24/24 small__cell");
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","small pl35");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1, 1]),0,0);
                return morphs;
              },
              statements: [
                ["content","entry.richterInfo",["loc",[null,[226,53],[226,76]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child11 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 231,
                    "column": 20
                  },
                  "end": {
                    "line": 237,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/purchases/detail.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","table__cell l-24/24 small__cell");
                var el3 = dom.createTextNode("\n                          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3,"class","small is-highlighted");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element31 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element31, 'class');
                morphs[1] = dom.createMorphAt(dom.childAt(element31, [1, 1]),0,0);
                return morphs;
              },
              statements: [
                ["attribute","class",["concat",["table__row second ",["subexpr","get-even",[["get","index",["loc",[null,[232,63],[232,68]]]]],[],["loc",[null,[232,52],[232,70]]]]]]],
                ["content","info.partnerText",["loc",[null,[234,61],[234,81]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 125,
                  "column": 18
                },
                "end": {
                  "line": 238,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class"," sellingUnitContainer");
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","grid align-items-center");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","supplier-info");
              var el4 = dom.createTextNode("\n                          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("img");
              dom.setAttribute(el4,"class","mw130");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col label-bar l-4/24@desk l-10/24 pt10");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col l-8/24 l-4/24@desk availability");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col l-4/24@desk ve l-6/24");
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("\n                          ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("span");
              var el6 = dom.createComment("");
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode(" VE");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                        ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","prices col l-4/24@desk l-12/24");
              var el4 = dom.createTextNode("\n                          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                            ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3,"class","col l-11/24@desk l-12/24 actions");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element40 = dom.childAt(fragment, [1]);
              var element41 = dom.childAt(element40, [1]);
              var element42 = dom.childAt(element41, [1, 1]);
              var element43 = dom.childAt(element41, [7, 1]);
              var element44 = dom.childAt(element41, [9, 1]);
              var element45 = dom.childAt(element41, [11]);
              var morphs = new Array(11);
              morphs[0] = dom.createAttrMorph(element42, 'src');
              morphs[1] = dom.createMorphAt(dom.childAt(element41, [3]),1,1);
              morphs[2] = dom.createMorphAt(dom.childAt(element41, [5, 1]),1,1);
              morphs[3] = dom.createMorphAt(dom.childAt(element43, [1]),0,0);
              morphs[4] = dom.createMorphAt(element43,3,3);
              morphs[5] = dom.createMorphAt(element44,1,1);
              morphs[6] = dom.createMorphAt(element44,2,2);
              morphs[7] = dom.createMorphAt(element45,1,1);
              morphs[8] = dom.createMorphAt(element45,2,2);
              morphs[9] = dom.createMorphAt(element40,3,3);
              morphs[10] = dom.createMorphAt(fragment,3,3,contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["attribute","src",["concat",["assets/images/suppliers/detail/",["get","supplierEntry.supplier.id",["loc",[null,[129,83],[129,108]]]],".png"]]],
              ["block","if",[["get","entry.topPromotion",["loc",[null,[132,32],[132,50]]]]],[],0,1,["loc",[null,[132,26],[142,33]]]],
              ["block","if",[["get","entry.richterNav",["loc",[null,[146,32],[146,48]]]]],[],2,3,["loc",[null,[146,26],[171,33]]]],
              ["content","entry.sellingAmount",["loc",[null,[176,32],[176,55]]]],
              ["block","if",[["get","entry.jasonPrice",["loc",[null,[177,32],[177,48]]]]],[],4,null,["loc",[null,[177,26],[178,138]]]],
              ["block","if",[["subexpr","or",[["get","entry.jasonPrice",["loc",[null,[183,38],[183,54]]]],["get","entry.free",["loc",[null,[183,55],[183,65]]]]],[],["loc",[null,[183,34],[183,66]]]]],[],5,null,["loc",[null,[183,28],[186,35]]]],
              ["block","if",[["subexpr","and",[["subexpr","gt",[["get","entry.retailPrice",["loc",[null,[187,43],[187,60]]]],["get","entry.jasonPrice",["loc",[null,[187,61],[187,77]]]]],[],["loc",[null,[187,39],[187,78]]]],["get","entry.retailPrice",["loc",[null,[187,79],[187,96]]]],["get","entry.jasonPrice",["loc",[null,[187,97],[187,113]]]]],[],["loc",[null,[187,34],[187,114]]]]],[],6,null,["loc",[null,[187,28],[191,103]]]],
              ["block","if",[["subexpr","and",[["subexpr","not",[["get","entry.richterNav",["loc",[null,[195,42],[195,58]]]]],[],["loc",[null,[195,37],[195,59]]]],["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[195,65],[195,78]]]]],[],["loc",[null,[195,60],[195,79]]]],["subexpr","or",[["get","entry.jasonPrice",["loc",[null,[195,84],[195,100]]]],["get","entry.free",["loc",[null,[195,101],[195,111]]]]],[],["loc",[null,[195,80],[195,112]]]]],[],["loc",[null,[195,32],[195,113]]]]],[],7,null,["loc",[null,[195,26],[197,33]]]],
              ["block","if",[["subexpr","or",[["subexpr","is-jason-network",[],[],["loc",[null,[198,36],[198,54]]]],["subexpr","is-supplier",[],[],["loc",[null,[198,55],[198,68]]]]],[],["loc",[null,[198,32],[198,69]]]]],[],8,9,["loc",[null,[198,26],[220,33]]]],
              ["block","if",[["get","entry.richterInfo",["loc",[null,[223,28],[223,45]]]]],[],10,null,["loc",[null,[223,22],[229,29]]]],
              ["block","if",[["get","info.partnerText",["loc",[null,[231,26],[231,42]]]]],[],11,null,["loc",[null,[231,20],[237,27]]]]
            ],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 124,
                "column": 16
              },
              "end": {
                "line": 239,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","or",[["get","entry.active",["loc",[null,[125,28],[125,40]]]],["subexpr","or",[["subexpr","is-supplier",[["get","is-jason-network",["loc",[null,[125,58],[125,74]]]]],[],["loc",[null,[125,45],[125,75]]]]],[],["loc",[null,[125,41],[125,76]]]]],[],["loc",[null,[125,24],[125,77]]]]],[],0,null,["loc",[null,[125,18],[238,25]]]]
          ],
          locals: ["entry"],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 241,
                "column": 16
              },
              "end": {
                "line": 243,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","p20");
            var el2 = dom.createTextNode("Keine Lieferanteninformationen verfügbar");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 244,
                "column": 16
              },
              "end": {
                "line": 246,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","p20");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2,"class","is-highlighted");
            var el3 = dom.createElement("b");
            var el4 = dom.createTextNode("Achtung:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Um Ihre individuellen Preise der Richter Pharma AG zu erhalten, müssen Sie Ihre ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2,"class","cursor is-highlighted");
            var el3 = dom.createTextNode("eLord Zugangsdaten");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" hinterlegen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element30 = dom.childAt(fragment, [1, 2]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element30);
            return morphs;
          },
          statements: [
            ["element","action",["toMandant"],[],["loc",[null,[245,170],[245,192]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 121,
              "column": 8
            },
            "end": {
              "line": 247,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","sellingUnitContainers");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),1,1);
          morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,4,4,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","each",[["get","supplierEntry.entries",["loc",[null,[124,24],[124,45]]]]],[],0,null,["loc",[null,[124,16],[239,25]]]],
          ["block","if",[["subexpr","not",[["subexpr","list-not-empty",[["get","model.purchaseSupplierInfos",["loc",[null,[241,43],[241,70]]]]],[],["loc",[null,[241,27],[241,71]]]]],[],["loc",[null,[241,22],[241,72]]]]],[],1,null,["loc",[null,[241,16],[243,23]]]],
          ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[244,32],[244,45]]]]],[],["loc",[null,[244,27],[244,46]]]],["get","model.elordCredentialsRequired",["loc",[null,[244,47],[244,77]]]]],[],["loc",[null,[244,22],[244,78]]]]],[],2,null,["loc",[null,[244,16],[246,23]]]]
        ],
        locals: ["supplierEntry"],
        templates: [child0, child1, child2]
      };
    }());
    var child20 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 254,
              "column": 94
            },
            "end": {
              "line": 254,
              "column": 156
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("> ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["content","model.partnerSubcategory",["loc",[null,[254,128],[254,156]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child21 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 255,
              "column": 14
            },
            "end": {
              "line": 257,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("PZN:");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),2,2);
          return morphs;
        },
        statements: [
          ["content","model.foreignId",["loc",[null,[256,50],[256,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child22 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 258,
              "column": 14
            },
            "end": {
              "line": 260,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Laboklin-Nr:");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),2,2);
          return morphs;
        },
        statements: [
          ["content","model.partnerNumber",["loc",[null,[259,60],[259,83]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child23 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 268,
              "column": 14
            },
            "end": {
              "line": 271,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (EU): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberEu",["loc",[null,[270,19],[270,44]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child24 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 272,
              "column": 14
            },
            "end": {
              "line": 275,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (Ages): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberAges",["loc",[null,[274,19],[274,46]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child25 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 276,
              "column": 14
            },
            "end": {
              "line": 279,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zulassungsnr (Apothekerverlag): ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.licenceNumberApo",["loc",[null,[278,19],[278,45]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child26 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 280,
              "column": 14
            },
            "end": {
              "line": 283,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Dosierungsinformationen / Analytische Bestandteile");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link",[["get","model.slopingInfo",["loc",[null,[282,27],[282,44]]]],"Link"],[],["loc",[null,[282,19],[282,54]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child27 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 285,
              "column": 14
            },
            "end": {
              "line": 288,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Fachinformationen / Zusammensetzung");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["inline","link",[["get","model.domainInfo",["loc",[null,[287,27],[287,43]]]],"Link",["get","model.supplier.id",["loc",[null,[287,51],[287,68]]]]],[],["loc",[null,[287,19],[287,71]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child28 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 290,
              "column": 14
            },
            "end": {
              "line": 293,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Infos");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element29 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element29,0,0);
          morphs[1] = dom.createUnsafeMorphAt(element29,1,1);
          return morphs;
        },
        statements: [
          ["content","model.info",["loc",[null,[292,19],[292,33]]]],
          ["inline","link",[["get","model.detailUrl",["loc",[null,[292,41],[292,56]]]],"Download"],[],["loc",[null,[292,33],[292,70]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child29 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 294,
              "column": 14
            },
            "end": {
              "line": 300,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Fachinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          dom.setAttribute(el2,"class","link2");
          var el3 = dom.createTextNode("Download");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("Gebrauchsinformationen");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"target","_blank");
          dom.setAttribute(el2,"class","link2");
          var el3 = dom.createTextNode("Download");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [3, 0]);
          var element28 = dom.childAt(fragment, [7, 0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element27, 'href');
          morphs[1] = dom.createAttrMorph(element28, 'href');
          return morphs;
        },
        statements: [
          ["attribute","href",["concat",["https://aspregister.basg.gv.at/document/servlet?action=show&zulnr=",["get","model.licenceNumber",["loc",[null,[296,96],[296,115]]]],"&type=DOTC_FACH_INFO"]]],
          ["attribute","href",["concat",["https://aspregister.basg.gv.at/document/servlet?action=show&zulnr=",["get","model.licenceNumber",["loc",[null,[299,96],[299,115]]]],"&type=DOTC_GEBR_INFO"]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child30 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 302,
              "column": 14
            },
            "end": {
              "line": 305,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","");
          var el2 = dom.createTextNode("Zielspezies");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]),0,0);
          return morphs;
        },
        statements: [
          ["content","model.targetRace",["loc",[null,[304,19],[304,39]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child31 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 306,
              "column": 14
            },
            "end": {
              "line": 356,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1,"class","mt20 small");
          var el2 = dom.createTextNode("Bei Fragen wenden Sie sich bitte an Ihren Ceva Außendienstmitarbeiter!");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table table--small pr30");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__body");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__row");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Kleintier");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Harald Valenta");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-7/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("0676 9285225");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__row");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Kleintier");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Silvia Harra");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-7/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("0676 4104367");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__row");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Kleintier");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Thomas Stegfellner");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-7/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("0676 4105260");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table__row");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Nutztier");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-8/24 ");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("Jochen Perr");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__cell l-7/24");
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          var el6 = dom.createTextNode("0676 7704648");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child32 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 362,
              "column": 14
            },
            "end": {
              "line": 369,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1,"class","textarea textarea--inline clearfix");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Bestell-Notiz");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","textarea__body input--full-width pl0");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","textarea",[],["rows","3","name","historyNotice","value",["subexpr","@mut",[["get","model.historyNotice",["loc",[null,[366,65],[366,84]]]]],[],[]],"class","input textarea__input full","placeholder",""],["loc",[null,[366,18],[366,136]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child33 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 371,
                "column": 16
              },
              "end": {
                "line": 373,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","model.historyNotice",["loc",[null,[372,18],[372,41]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 373,
                "column": 16
              },
              "end": {
                "line": 375,
                "column": 16
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("Derzeit keine Notiz hinterlegt");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 369,
              "column": 14
            },
            "end": {
              "line": 376,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1,"class","title");
          var el2 = dom.createTextNode("Bestell-Notiz");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,3,3,contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","model.historyNotice",["loc",[null,[371,22],[371,41]]]]],[],0,1,["loc",[null,[371,16],[375,23]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    var child34 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 396,
                "column": 20
              },
              "end": {
                "line": 396,
                "column": 104
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.code",["loc",[null,[396,91],[396,104]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 399,
                "column": 20
              },
              "end": {
                "line": 399,
                "column": 200
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"class","mw100");
            dom.setAttribute(el1,"style","max-height: 50px;");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element25 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element25, 'src');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",["assets/images/suppliers/small_",["get","item.supplier.id",["loc",[null,[399,176],[399,192]]]],".png"]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 411,
                "column": 22
              },
              "end": {
                "line": 413,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","button-view",[],["size","32","color","#3B6182","showStroke",true],["loc",[null,[412,24],[412,81]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 390,
              "column": 16
            },
            "end": {
              "line": 416,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-24/24@phone l-24/24@tablet l-4/24@desk");
          dom.setAttribute(el2,"data-label","Datum");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-4/24@desk");
          dom.setAttribute(el2,"data-label","Bestell-Nr");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-4/24@desk");
          dom.setAttribute(el2,"data-label","Lieferant");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone table__cell--center l-8/24@tablet l-3/24@desk");
          dom.setAttribute(el2,"data-label","Menge");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone table__cell--center l-8/24@tablet l-3/24@desk");
          dom.setAttribute(el2,"data-label","VE");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                   ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-3/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-3/24@desk");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element26 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element26, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element26, [3]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element26, [5]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element26, [7, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element26, [9, 1]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element26, [11, 1]),0,0);
          morphs[6] = dom.createMorphAt(dom.childAt(element26, [13]),1,1);
          return morphs;
        },
        statements: [
          ["inline","time-format",[["get","item.created",["loc",[null,[393,42],[393,54]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[393,28],[393,77]]]],
          ["block","link-to",["purchases.show",["get","item.orderId",["loc",[null,[396,48],[396,60]]]]],["title","Öffnen","class","link2"],0,null,["loc",[null,[396,20],[396,116]]]],
          ["block","link-to",["purchases.supplierdetails",["get","item.supplier.id",["loc",[null,[399,59],[399,75]]]]],["title","Anzeigen"],1,null,["loc",[null,[399,20],[399,212]]]],
          ["content","item.entryQuantity",["loc",[null,[402,26],[402,48]]]],
          ["content","item.packageQuantity",["loc",[null,[405,26],[405,50]]]],
          ["content","item.orderStatus",["loc",[null,[408,26],[408,46]]]],
          ["block","link-to",["purchases.show",["get","item",["loc",[null,[411,50],[411,54]]]]],["title","Anzeigen"],2,null,["loc",[null,[411,22],[413,34]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2]
      };
    }());
    var child35 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 433,
              "column": 16
            },
            "end": {
              "line": 451,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24@phone l-8/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Inventar-Nr");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center l-8/24@phone l-8/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Verfügbar");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-5/24@desk");
          dom.setAttribute(el2,"data-label","Lager");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-8/24@phone l-8/24@tablet l-4/24@desk");
          dom.setAttribute(el2,"data-label","Eingebucht");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element24, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element24, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element24, [5, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element24, [7, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element24, [9, 1]),0,0);
          return morphs;
        },
        statements: [
          ["content","item.status.name",["loc",[null,[436,26],[436,46]]]],
          ["content","item.id",["loc",[null,[439,26],[439,37]]]],
          ["content","item.flatStock",["loc",[null,[442,26],[442,44]]]],
          ["content","item.warehouseName",["loc",[null,[445,26],[445,48]]]],
          ["inline","time-format",[["get","item.created",["loc",[null,[448,40],[448,52]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[448,26],[448,75]]]]
        ],
        locals: ["item"],
        templates: []
      };
    }());
    var child36 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 472,
                "column": 26
              },
              "end": {
                "line": 472,
                "column": 77
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","reorder.warehouse.name",["loc",[null,[472,51],[472,77]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 472,
                "column": 77
              },
              "end": {
                "line": 472,
                "column": 95
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Alle Lager");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 526,
                "column": 18
              },
              "end": {
                "line": 531,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","switch switch-info round switch-inline mt5");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"checked","checked");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element16 = dom.childAt(fragment, [1]);
            var element17 = dom.childAt(element16, [1]);
            if (this.cachedFragment) { dom.repairClonedNode(element17,[],true); }
            var element18 = dom.childAt(element16, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element17, 'id');
            morphs[1] = dom.createElementMorph(element17);
            morphs[2] = dom.createAttrMorph(element18, 'for');
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[528,47],[528,70]]]]]]],
            ["element","action",["setReorderActive",["get","reorder",["loc",[null,[528,136],[528,143]]]]],["on","change"],["loc",[null,[528,108],[528,157]]]],
            ["attribute","for",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[529,48],[529,71]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 531,
                "column": 18
              },
              "end": {
                "line": 536,
                "column": 18
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","switch switch-info round switch-inline mt5");
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2,"type","checkbox");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1]);
            var element14 = dom.childAt(element13, [1]);
            var element15 = dom.childAt(element13, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element14, 'id');
            morphs[1] = dom.createElementMorph(element14);
            morphs[2] = dom.createAttrMorph(element15, 'for');
            return morphs;
          },
          statements: [
            ["attribute","id",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[533,47],[533,70]]]]]]],
            ["element","action",["setReorderActive",["get","reorder",["loc",[null,[533,118],[533,125]]]]],["on","change"],["loc",[null,[533,90],[533,139]]]],
            ["attribute","for",["concat",["activeSwitch",["get","reorder.amountSpinnerId",["loc",[null,[534,48],[534,71]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 469,
              "column": 12
            },
            "end": {
              "line": 539,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-3/24@desk");
          dom.setAttribute(el2,"data-label","Lager");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24@desk");
          dom.setAttribute(el2,"data-label","Benachrichtigung");
          var el3 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field number-input");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","product-infoMinimalServiceunit");
          dom.setAttribute(el3,"class","select--inline input-element");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-6/24@desk");
          dom.setAttribute(el2,"data-label","Automatisch Nachbestellen bei");
          var el3 = dom.createTextNode("\n\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field number-input");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","product-reorderMinimalServiceunit");
          dom.setAttribute(el3,"class","select--inline input-element");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-7/24@desk pb5");
          dom.setAttribute(el2,"data-label","Nachbestellen von");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","field number-input");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3,"for","product-priceLine");
          dom.setAttribute(el3,"class","select--inline input-element");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","block mtm5");
          var el4 = dom.createTextNode("\n                    Einzelpreis: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("b");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(", Gesamtpreis: ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("b");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right l-2/24@desk");
          dom.setAttribute(el2,"data-label","");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var element20 = dom.childAt(element19, [3]);
          var element21 = dom.childAt(element19, [5]);
          var element22 = dom.childAt(element19, [7]);
          var element23 = dom.childAt(element22, [5]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element19, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element19, [1, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element20, [3]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element21, [1]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element21, [3]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element22, [1]),1,1);
          morphs[7] = dom.createMorphAt(dom.childAt(element22, [3]),1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element23, [1]),0,0);
          morphs[9] = dom.createMorphAt(dom.childAt(element23, [3]),0,0);
          morphs[10] = dom.createMorphAt(dom.childAt(element19, [9]),1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["table__row ",["subexpr","css-bool-evaluator",[["get","reorder.deleted",["loc",[null,[470,58],[470,73]]]],"hidden",""],[],["loc",[null,[470,37],[470,87]]]]]]],
          ["block","if",[["get","reorder.warehouse",["loc",[null,[472,32],[472,49]]]]],[],0,1,["loc",[null,[472,26],[472,102]]]],
          ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.infoMinimalAmount",["loc",[null,[477,52],[477,77]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.infoSpinnerId",["loc",[null,[477,81],[477,102]]]]],[],[]]],["loc",[null,[477,22],[477,104]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","matchedUnits",["loc",[null,[481,32],[481,44]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.infoMinimalServiceunit",["loc",[null,[482,30],[482,60]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Einheit wählen","name","product-infoMinimalServiceunit"],["loc",[null,[480,22],[488,24]]]],
          ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.reorderMinimalAmount",["loc",[null,[494,52],[494,80]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.reorderSpinnerId",["loc",[null,[494,84],[494,108]]]]],[],[]]],["loc",[null,[494,22],[494,110]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","matchedUnits",["loc",[null,[497,30],[497,42]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.reorderMinimalServiceunit",["loc",[null,[498,28],[498,61]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","name","allowClear",true,"placeholder","Einheit wählen","name","product-reorderMinimalServiceunit"],["loc",[null,[496,20],[504,22]]]],
          ["inline","simple-number-spinner",[],["value",["subexpr","@mut",[["get","reorder.reorderAmount",["loc",[null,[509,52],[509,73]]]]],[],[]],"id",["subexpr","@mut",[["get","reorder.amountSpinnerId",["loc",[null,[509,77],[509,100]]]]],[],[]]],["loc",[null,[509,22],[509,102]]]],
          ["inline","select-2",[],["content",["subexpr","@mut",[["get","model.reorderPurchaseSupplierInfos",["loc",[null,[512,30],[512,64]]]]],[],[]],"value",["subexpr","@mut",[["get","reorder.reorderPurchaseSupplierInfo",["loc",[null,[513,28],[513,63]]]]],[],[]],"cssClass","input--dropdown","optionLabelPath","title","allowClear",true,"placeholder","Lieferant & VE wählen","name","product-priceLine"],["loc",[null,[511,20],[519,22]]]],
          ["inline","format-currency",[],["value",["subexpr","@mut",[["get","reorder.reorderPurchaseSupplierInfo.jasonPrice",["loc",[null,[522,60],[522,106]]]]],[],[]],"classNames","inline"],["loc",[null,[522,36],[522,128]]]],
          ["inline","format-currency",[],["classNames","inline","value",["subexpr","multiply",[["get","reorder.reorderPurchaseSupplierInfo.jasonPrice",["loc",[null,[522,204],[522,250]]]],["get","reorder.reorderAmount",["loc",[null,[522,251],[522,272]]]]],[],["loc",[null,[522,194],[522,273]]]]],["loc",[null,[522,150],[522,275]]]],
          ["block","if",[["get","reorder.active",["loc",[null,[526,24],[526,38]]]]],[],2,3,["loc",[null,[526,18],[536,25]]]]
        ],
        locals: ["reorder"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child37 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 571,
                "column": 6
              },
              "end": {
                "line": 573,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge private");
            var el2 = dom.createTextNode("Privat");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 573,
                  "column": 6
                },
                "end": {
                  "line": 575,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge lab");
              var el2 = dom.createTextNode("Labor");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 575,
                  "column": 6
                },
                "end": {
                  "line": 577,
                  "column": 6
                }
              },
              "moduleName": "jason-frontend/templates/purchases/detail.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1,"class","info-badge practice");
              var el2 = dom.createTextNode("Praxis");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 573,
                "column": 6
              },
              "end": {
                "line": 577,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","order.laboklinOrder",["loc",[null,[573,16],[573,35]]]]],[],0,1,["loc",[null,[573,6],[577,6]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 579,
                "column": 4
              },
              "end": {
                "line": 589,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"id","basket-quickinfos");
            dom.setAttribute(el1,"class","grid");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-16/24");
            var el3 = dom.createTextNode("\n          Artikel ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","badge");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          Warenwert netto\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","col l-8/24 u-text--right");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]),2,2);
            return morphs;
          },
          statements: [
            ["content","order.entryCount",["loc",[null,[582,38],[582,58]]]],
            ["inline","format-currency",[],["value",["subexpr","@mut",[["get","order.totalNet",["loc",[null,[586,39],[586,53]]]]],[],[]],"classNames","currency ml5"],["loc",[null,[586,15],[586,81]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 566,
              "column": 3
            },
            "end": {
              "line": 603,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("aside");
          dom.setAttribute(el1,"class","side-bar card col l-6/24@desk l-24/24@phone l-24/24@tablet");
          dom.setAttribute(el1,"id","minicartSidebar");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"class","mfp-close");
          dom.setAttribute(el2,"src","assets/images/icons/rework/sidebar-close.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2,"class","cursor");
          var el3 = dom.createTextNode("Warenkorb");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","label-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","cartButtons");
          dom.setAttribute(el2,"class","col l-24/24");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-14/24");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","button context default");
          var el5 = dom.createTextNode("Warenkorb wechseln");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","col l-10/24");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4,"class","button context primary");
          var el5 = dom.createTextNode("Warenkorb");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","scrollable");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"id","gotoCart");
          var el3 = dom.createElement("img");
          dom.setAttribute(el3,"src","assets/images/icons/arrow-right.svg");
          dom.setAttribute(el3,"style","width: 18px;");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" zum Warenkorb");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element6, [3]);
          var element9 = dom.childAt(element6, [9]);
          var element10 = dom.childAt(element9, [1, 1]);
          var element11 = dom.childAt(element9, [3, 1]);
          var element12 = dom.childAt(element6, [13]);
          var morphs = new Array(8);
          morphs[0] = dom.createElementMorph(element7);
          morphs[1] = dom.createElementMorph(element8);
          morphs[2] = dom.createMorphAt(dom.childAt(element6, [5]),1,1);
          morphs[3] = dom.createMorphAt(element6,7,7);
          morphs[4] = dom.createElementMorph(element10);
          morphs[5] = dom.createElementMorph(element11);
          morphs[6] = dom.createMorphAt(dom.childAt(element6, [11]),1,1);
          morphs[7] = dom.createElementMorph(element12);
          return morphs;
        },
        statements: [
          ["element","action",["toggleSidebar",true],[],["loc",[null,[568,9],[568,40]]]],
          ["element","action",["toCart",["get","order.id",["loc",[null,[569,41],[569,49]]]]],[],["loc",[null,[569,23],[569,51]]]],
          ["block","if",[["get","order.privateOrder",["loc",[null,[571,12],[571,30]]]]],[],0,1,["loc",[null,[571,6],[577,13]]]],
          ["block","if",[["subexpr","gt",[["get","order.entryCount",["loc",[null,[579,14],[579,30]]]],0],[],["loc",[null,[579,10],[579,33]]]]],[],2,null,["loc",[null,[579,4],[589,11]]]],
          ["element","action",["gotoCarts"],[],["loc",[null,[592,16],[592,38]]]],
          ["element","action",["gotoCart",["get","order.id",["loc",[null,[595,34],[595,42]]]]],[],["loc",[null,[595,14],[595,44]]]],
          ["inline","mini-cart",[],["model",["subexpr","@mut",[["get","order",["loc",[null,[599,24],[599,29]]]]],[],[]],"delete","deleteCartEntry","toCart","gotoCart","toProduct","toProduct","actionReceiver",["subexpr","@mut",[["get","minicart",["loc",[null,[599,110],[599,118]]]]],[],[]]],["loc",[null,[599,6],[599,120]]]],
          ["element","action",["gotoCart",["get","order.id",["loc",[null,[601,43],[601,51]]]]],[],["loc",[null,[601,23],[601,53]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child38 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 604,
                "column": 2
              },
              "end": {
                "line": 608,
                "column": 2
              }
            },
            "moduleName": "jason-frontend/templates/purchases/detail.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("aside");
            dom.setAttribute(el1,"class","side-bar-hidden");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2,"type","button");
            dom.setAttribute(el2,"class","mfp-close");
            dom.setAttribute(el2,"id","showSidebar");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),0,0);
            return morphs;
          },
          statements: [
            ["inline","button-toggle-sidebar",[],["size",34,"color","#fff","toggle","toggleSidebar","strokeWidth",1,"classNames","","active",false,"content","Ein/Ausblenden"],["loc",[null,[606,63],[606,198]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 603,
              "column": 0
            },
            "end": {
              "line": 609,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[604,13],[604,26]]]]],[],["loc",[null,[604,8],[604,27]]]]],[],0,null,["loc",[null,[604,2],[608,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child39 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 640,
              "column": 4
            },
            "end": {
              "line": 647,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createMorphAt(element4,1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]),1,1);
          return morphs;
        },
        statements: [
          ["element","action",["editProduct",["get","model",["loc",[null,[641,52],[641,57]]]],["get","order.id",["loc",[null,[641,58],[641,66]]]]],[],["loc",[null,[641,29],[641,68]]]],
          ["inline","button-edit",[],["size","52","color","#FFFFFF","showStroke",false,"content","Produkt bearbeiten"],["loc",[null,[642,8],[642,96]]]],
          ["inline","button-delete-with-confirm",[],["item",["subexpr","@mut",[["get","model",["loc",[null,[645,42],[645,47]]]]],[],[]],"confirmed","deleteArticle","color","#FFFFFF","showStroke",false,"size",52,"content","Produkt und alle Preise löschen","text","Wollen Sie diesen Artikel wirklich löschen?"],["loc",[null,[645,8],[645,209]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child40 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 648,
              "column": 2
            },
            "end": {
              "line": 655,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--promotion");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2,1,1);
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createMorphAt(element3,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["editPromotion",["get","model",["loc",[null,[649,75],[649,80]]]],0,["get","order.id",["loc",[null,[649,83],[649,91]]]]],[],["loc",[null,[649,50],[649,93]]]],
          ["inline","button-add-promotion",[],["content","Aktion hinzufügen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[650,6],[650,101]]]],
          ["element","action",["edit",["get","model",["loc",[null,[652,64],[652,69]]]],0,["get","order.id",["loc",[null,[652,72],[652,80]]]]],[],["loc",[null,[652,48],[652,82]]]],
          ["inline","button-add-entry",[],["content","Weitere Artikelzeile hinzufügen","color","#FFFFFF","size","52","showStroke",false],["loc",[null,[653,6],[653,111]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child41 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 656,
              "column": 2
            },
            "end": {
              "line": 660,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["saveHistory",["get","model",["loc",[null,[657,71],[657,76]]]]],[],["loc",[null,[657,48],[657,78]]]],
          ["inline","button-save",[],["content","Speichern","color","#FFFFFF","size","60","showStroke",false],["loc",[null,[658,6],[658,84]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child42 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 661,
              "column": 2
            },
            "end": {
              "line": 665,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/purchases/detail.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button icon-button--success");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["saveReorder",["get","model",["loc",[null,[662,71],[662,76]]]]],[],["loc",[null,[662,48],[662,78]]]],
          ["inline","button-save",[],["content","Speichern","color","#FFFFFF","size","60","showStroke",false],["loc",[null,[663,6],[663,84]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 681,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/purchases/detail.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","purchaseDetailPage");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-8/24@desk l-24/24");
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","u-text--center mt10 pr30");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","col l-16/24@desk box@phone l-24/24");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3,"class","headline");
        dom.setAttribute(el3,"style","padding-right: 50px");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3,"class","sub-headline");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","product-description");
        dom.setAttribute(el3,"class","");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","dataList grid");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createTextNode("Verpackungsmenge");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        var el7 = dom.createTextNode("Kategorie");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createTextNode("Suchtgiftmittel");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createTextNode("Zulassungsnummer");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col l-12/24");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","product-details");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","js-tabs tabs");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","tabs__head");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5,"class","u-mb0 clearfix");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"href","#tab-purchase");
        var el8 = dom.createTextNode("Einkauf");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7,"class","js-tabs__label tabs__label title");
        dom.setAttribute(el7,"href","#tab-2");
        var el8 = dom.createTextNode("Artikelinformationen");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4,"class","tabs__body");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-debug");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Aktualisiert");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","mt20");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("Datenlieferant");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("label");
        dom.setAttribute(el6,"class","");
        var el7 = dom.createElement("b");
        var el8 = dom.createTextNode("RPA-Migration");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode(" ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-purchase");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-1");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Mwst:");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" %");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        dom.setAttribute(el7,"class","mt20");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("ATC-Code:");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("ATC-Code Beschreibung:");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Lieferanten-Kategorie:");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode(" ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-2");
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","dataList");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"class","tabs__content");
        dom.setAttribute(el5,"data-for","#tab-3");
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","mt20 pl5 col l-12/24");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table no-box@phone mt20");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__head");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-4/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Bestell-Datum");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-4/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Bestell-Nr");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-4/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Lieferant");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-3/24 table__cell--center");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Menge");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-3/24 table__cell--center");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("VE");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-3/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Status");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-1/24");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__body");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-4");
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        dom.setAttribute(el6,"class","mt20");
        var el7 = dom.createTextNode("Lagerbestände");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table  table--small no-box@phone mt20");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__head");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Status");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24 table__cell--center");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Inventar-Nr");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24 table__cell--center");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Verfügbar");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-5/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Lager");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__cell l-4/24");
        var el9 = dom.createElement("span");
        dom.setAttribute(el9,"class","table__title title");
        var el10 = dom.createTextNode("Eingebucht");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__body");
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5,"data-for","#tab-6");
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Nachbestellungs-Regeln");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table-wrapper mb40");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table mt20 table--ve  table--small");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__head");
        var el9 = dom.createComment("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-3/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Lager");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-6/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Benachrichtigung");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" für Nachbestellungen bei");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-6/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Automatische Nachbestellung bei");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell l-7/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Nachbestellung von");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("\n          ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9,"class","table__cell  table__cell--right l-2/24");
        var el10 = dom.createElement("span");
        dom.setAttribute(el10,"class","table__title title");
        var el11 = dom.createTextNode("Aktiv");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","table__body");
        var el9 = dom.createTextNode("\n");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h2");
        var el7 = dom.createTextNode("Automatische Nachbestellung");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col mt10 l-12/24");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","checkbox-custom checkbox-primary field-checkbox");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("label");
        dom.setAttribute(el8,"class","productAutosendReorder");
        dom.setAttribute(el8,"for","product-autosendReorder");
        var el9 = dom.createTextNode("Warenkorb automatisch abschicken");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","col block mt20 l-12/24");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7,"class","textarea textarea--inline clearfix");
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","title");
        var el9 = dom.createTextNode("Nachbestellungs-Notiz");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("\n                   ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8,"class","textarea__body input--full-width pl0");
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2,"class","icon-button icon-button--small");
        dom.setAttribute(el2,"onclick","history.back()");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("script");
        var el2 = dom.createTextNode("\n  $(function() {\n    $( window ).resize(function() {\n      $('aside.side-bar').css('min-height', $(window).height()-80);\n      $('aside.side-bar div.scrollable').css('max-height', $(window).height()-300);\n    });\n    $('aside.side-bar').css('min-height', $(window).height()-80);\n    $('aside.side-bar div.scrollable').css('max-height', $(window).height()-300);\n  });\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element55 = dom.childAt(fragment, [0]);
        var element56 = dom.childAt(element55, [1]);
        var element57 = dom.childAt(fragment, [2]);
        var element58 = dom.childAt(element57, [5]);
        var element59 = dom.childAt(element58, [3]);
        var element60 = dom.childAt(element58, [5]);
        var element61 = dom.childAt(element60, [3]);
        var element62 = dom.childAt(element61, [1]);
        var element63 = dom.childAt(element62, [3]);
        var element64 = dom.childAt(element61, [3]);
        var element65 = dom.childAt(element57, [7, 1]);
        var element66 = dom.childAt(element65, [1, 1]);
        var element67 = dom.childAt(element66, [1, 1]);
        var element68 = dom.childAt(element66, [5, 1]);
        var element69 = dom.childAt(element65, [3]);
        var element70 = dom.childAt(element69, [1]);
        var element71 = dom.childAt(element70, [7]);
        var element72 = dom.childAt(element70, [11]);
        var element73 = dom.childAt(element69, [3]);
        var element74 = dom.childAt(element69, [5]);
        var element75 = dom.childAt(element74, [1]);
        var element76 = dom.childAt(element75, [7]);
        var element77 = dom.childAt(element69, [7]);
        var element78 = dom.childAt(element77, [3]);
        var element79 = dom.childAt(element69, [9]);
        var element80 = dom.childAt(element69, [11]);
        var element81 = dom.childAt(element69, [13]);
        var element82 = dom.childAt(fragment, [21]);
        var morphs = new Array(74);
        morphs[0] = dom.createAttrMorph(element55, 'class');
        morphs[1] = dom.createAttrMorph(element56, 'class');
        morphs[2] = dom.createMorphAt(element56,1,1);
        morphs[3] = dom.createAttrMorph(element57, 'class');
        morphs[4] = dom.createAttrMorph(element57, 'data-jac-avail');
        morphs[5] = dom.createMorphAt(element57,1,1);
        morphs[6] = dom.createMorphAt(dom.childAt(element57, [3, 1]),1,1);
        morphs[7] = dom.createMorphAt(dom.childAt(element58, [1]),0,0);
        morphs[8] = dom.createMorphAt(element59,0,0);
        morphs[9] = dom.createMorphAt(element59,2,2);
        morphs[10] = dom.createMorphAt(element59,4,4);
        morphs[11] = dom.createUnsafeMorphAt(dom.childAt(element60, [1]),0,0);
        morphs[12] = dom.createMorphAt(element63,0,0);
        morphs[13] = dom.createMorphAt(element63,2,2);
        morphs[14] = dom.createMorphAt(element62,5,5);
        morphs[15] = dom.createMorphAt(dom.childAt(element62, [9]),0,0);
        morphs[16] = dom.createMorphAt(dom.childAt(element62, [13]),0,0);
        morphs[17] = dom.createMorphAt(dom.childAt(element62, [17]),0,0);
        morphs[18] = dom.createMorphAt(element64,1,1);
        morphs[19] = dom.createMorphAt(element64,2,2);
        morphs[20] = dom.createAttrMorph(element67, 'class');
        morphs[21] = dom.createElementMorph(element67);
        morphs[22] = dom.createMorphAt(element66,3,3);
        morphs[23] = dom.createElementMorph(element68);
        morphs[24] = dom.createMorphAt(element66,7,7);
        morphs[25] = dom.createMorphAt(element66,8,8);
        morphs[26] = dom.createMorphAt(element66,9,9);
        morphs[27] = dom.createAttrMorph(element70, 'class');
        morphs[28] = dom.createMorphAt(dom.childAt(element70, [3]),0,0);
        morphs[29] = dom.createMorphAt(element71,0,0);
        morphs[30] = dom.createMorphAt(element71,2,2);
        morphs[31] = dom.createMorphAt(element72,0,0);
        morphs[32] = dom.createMorphAt(element72,2,2);
        morphs[33] = dom.createAttrMorph(element73, 'class');
        morphs[34] = dom.createMorphAt(element73,1,1);
        morphs[35] = dom.createAttrMorph(element74, 'class');
        morphs[36] = dom.createMorphAt(dom.childAt(element75, [1]),2,2);
        morphs[37] = dom.createMorphAt(dom.childAt(element75, [3]),2,2);
        morphs[38] = dom.createMorphAt(dom.childAt(element75, [5]),2,2);
        morphs[39] = dom.createMorphAt(element76,2,2);
        morphs[40] = dom.createMorphAt(element76,4,4);
        morphs[41] = dom.createMorphAt(element75,9,9);
        morphs[42] = dom.createMorphAt(element75,10,10);
        morphs[43] = dom.createUnsafeMorphAt(dom.childAt(element77, [1]),0,0);
        morphs[44] = dom.createMorphAt(element78,1,1);
        morphs[45] = dom.createMorphAt(element78,2,2);
        morphs[46] = dom.createMorphAt(element78,3,3);
        morphs[47] = dom.createMorphAt(element78,4,4);
        morphs[48] = dom.createMorphAt(element78,6,6);
        morphs[49] = dom.createMorphAt(element78,8,8);
        morphs[50] = dom.createMorphAt(element78,9,9);
        morphs[51] = dom.createMorphAt(element78,11,11);
        morphs[52] = dom.createMorphAt(element78,12,12);
        morphs[53] = dom.createMorphAt(dom.childAt(element79, [1]),1,1);
        morphs[54] = dom.createMorphAt(dom.childAt(element79, [3, 3]),1,1);
        morphs[55] = dom.createAttrMorph(element80, 'class');
        morphs[56] = dom.createMorphAt(dom.childAt(element80, [3, 3]),1,1);
        morphs[57] = dom.createAttrMorph(element81, 'class');
        morphs[58] = dom.createMorphAt(dom.childAt(element81, [3, 1, 3]),1,1);
        morphs[59] = dom.createMorphAt(dom.childAt(element81, [7, 1]),1,1);
        morphs[60] = dom.createMorphAt(dom.childAt(element81, [9, 1, 3]),1,1);
        morphs[61] = dom.createMorphAt(fragment,4,4,contextualElement);
        morphs[62] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[63] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[64] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[65] = dom.createMorphAt(fragment,12,12,contextualElement);
        morphs[66] = dom.createMorphAt(fragment,14,14,contextualElement);
        morphs[67] = dom.createMorphAt(fragment,16,16,contextualElement);
        morphs[68] = dom.createMorphAt(fragment,18,18,contextualElement);
        morphs[69] = dom.createMorphAt(dom.childAt(element82, [1]),1,1);
        morphs[70] = dom.createMorphAt(element82,4,4);
        morphs[71] = dom.createMorphAt(element82,5,5);
        morphs[72] = dom.createMorphAt(element82,6,6);
        morphs[73] = dom.createMorphAt(element82,7,7);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["context-bar grid  ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[1,51],[1,64]]]],"sidebar","sidebar-inactive"],[],["loc",[null,[1,30],[1,95]]]]]]],
        ["attribute","class",["concat",["buttons-box col l-",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[2,53],[2,66]]]],"18","24"],[],["loc",[null,[2,32],[2,78]]]],"/24"]]],
        ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[3,15],[3,28]]]]],[],["loc",[null,[3,10],[3,29]]]]],[],0,null,["loc",[null,[3,4],[7,11]]]],
        ["attribute","class",["concat",["with-context-bar col l-",["subexpr","css-bool-evaluator",[["subexpr","not",[["get","sidebarActive",["loc",[null,[11,89],[11,102]]]]],[],["loc",[null,[11,84],[11,103]]]],"24","18"],[],["loc",[null,[11,63],[11,115]]]],"/24@desk l-24/24@phone l-24/24@tablet ",["subexpr","css-bool-evaluator",[["get","sidebarActive",["loc",[null,[11,174],[11,187]]]],"sidebar-active",""],[],["loc",[null,[11,153],[11,209]]]]," card site-content"]]],
        ["attribute","data-jac-avail",["concat",[["get","model.hasJacobyRow",["loc",[null,[11,247],[11,265]]]]]]],
        ["block","if",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[12,13],[12,26]]]]],[],["loc",[null,[12,8],[12,27]]]]],[],1,null,["loc",[null,[12,2],[18,9]]]],
        ["block","if",[["get","model.picture",["loc",[null,[22,16],[22,29]]]]],[],2,3,["loc",[null,[22,10],[26,17]]]],
        ["content","model.name",["loc",[null,[31,55],[31,69]]]],
        ["block","if",[["get","model.rpaId",["loc",[null,[32,35],[32,46]]]]],[],4,null,["loc",[null,[32,29],[32,113]]]],
        ["block","if",[["get","model.pzn",["loc",[null,[32,120],[32,129]]]]],[],5,null,["loc",[null,[32,114],[32,190]]]],
        ["block","if",[["subexpr","or",[["get","model.gtin",["loc",[null,[32,201],[32,211]]]],["get","model.ean",["loc",[null,[32,212],[32,221]]]]],[],["loc",[null,[32,197],[32,222]]]]],[],6,null,["loc",[null,[32,191],[32,284]]]],
        ["inline","truncate",[["get","model.description",["loc",[null,[34,21],[34,38]]]],300],[],["loc",[null,[34,9],[34,45]]]],
        ["content","model.quantity",["loc",[null,[38,15],[38,33]]]],
        ["content","model.unit.name",["loc",[null,[38,34],[38,53]]]],
        ["block","if",[["get","model.altUnit",["loc",[null,[39,18],[39,31]]]]],[],7,null,["loc",[null,[39,12],[42,19]]]],
        ["inline","get-value-or-empty-sign",[["get","model.category.name",["loc",[null,[44,41],[44,60]]]],"-"],[],["loc",[null,[44,15],[44,66]]]],
        ["block","if",[["get","model.narcotic",["loc",[null,[46,21],[46,35]]]]],[],8,9,["loc",[null,[46,15],[46,58]]]],
        ["inline","get-value-or-empty-sign",[["get","model.licenceNumber",["loc",[null,[48,41],[48,60]]]],["get","-",["loc",[null,[48,61],[48,62]]]]],[],["loc",[null,[48,15],[48,65]]]],
        ["block","if",[["subexpr","is-ehapo-active",[],[],["loc",[null,[51,18],[51,35]]]]],[],10,null,["loc",[null,[51,12],[66,19]]]],
        ["block","if",[["get","model.systemTags",["loc",[null,[67,18],[67,34]]]]],[],11,null,["loc",[null,[67,12],[70,19]]]],
        ["attribute","class",["concat",["js-tabs__label tabs__label title ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[81,82],[81,91]]]],"purchase"],[],["loc",[null,[81,78],[81,103]]]],"is-active",""],[],["loc",[null,[81,57],[81,120]]]]]]],
        ["element","action",["selectTab","purchase"],[],["loc",[null,[81,143],[81,176]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[83,18],[83,43]]]]],[],12,null,["loc",[null,[83,12],[87,19]]]],
        ["element","action",["selectTab","additional"],[],["loc",[null,[89,72],[89,107]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[91,18],[91,43]]]]],[],13,null,["loc",[null,[91,12],[95,19]]]],
        ["block","if",[["subexpr","is-ehapo-active",[],[],["loc",[null,[96,18],[96,35]]]]],[],14,null,["loc",[null,[96,12],[103,16]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-test-mandant",[],[],["loc",[null,[104,22],[104,39]]]],["subexpr","is-debug-user",[],[],["loc",[null,[104,40],[104,55]]]]],[],["loc",[null,[104,18],[104,56]]]]],[],15,null,["loc",[null,[104,12],[108,19]]]],
        ["attribute","class",["concat",["admin-form tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[112,71],[112,80]]]],"debug"],[],["loc",[null,[112,67],[112,89]]]],"is-active",""],[],["loc",[null,[112,46],[112,106]]]]," "]]],
        ["inline","time-format",[["get","model.lastImport",["loc",[null,[114,29],[114,45]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[114,15],[114,68]]]],
        ["content","model.primarySupplierId",["loc",[null,[116,15],[116,42]]]],
        ["content","model.importDetails",["loc",[null,[116,43],[116,66]]]],
        ["block","if",[["get","model.migrated",["loc",[null,[118,21],[118,35]]]]],[],16,17,["loc",[null,[118,15],[118,122]]]],
        ["block","if",[["get","model.richterCase",["loc",[null,[118,129],[118,146]]]]],[],18,null,["loc",[null,[118,123],[118,183]]]],
        ["attribute","class",["concat",["mt30 pl0 pr0 tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[120,73],[120,82]]]],"purchase"],[],["loc",[null,[120,69],[120,94]]]],"is-active",""],[],["loc",[null,[120,48],[120,111]]]]]]],
        ["block","each",[["get","model.purchaseSupplierEntries",["loc",[null,[121,16],[121,45]]]]],[],19,null,["loc",[null,[121,8],[247,9]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[249,60],[249,69]]]],"general"],[],["loc",[null,[249,56],[249,80]]]],"is-active",""],[],["loc",[null,[249,35],[249,97]]]]]]],
        ["content","model.tax.percentage",["loc",[null,[251,51],[251,75]]]],
        ["content","model.atcCode",["loc",[null,[252,68],[252,85]]]],
        ["content","model.atcCodeDescription",["loc",[null,[253,68],[253,96]]]],
        ["content","model.partnerCategory",["loc",[null,[254,68],[254,93]]]],
        ["block","if",[["get","model.partnerSubcategory",["loc",[null,[254,100],[254,124]]]]],[],20,null,["loc",[null,[254,94],[254,163]]]],
        ["block","if",[["subexpr","is-test-mandant",[],[],["loc",[null,[255,20],[255,37]]]]],[],21,null,["loc",[null,[255,14],[257,21]]]],
        ["block","if",[["subexpr","eq",[["get","model.supplier.id",["loc",[null,[258,24],[258,41]]]],"laboklin"],[],["loc",[null,[258,20],[258,53]]]]],[],22,null,["loc",[null,[258,14],[260,21]]]],
        ["content","model.description",["loc",[null,[265,15],[265,38]]]],
        ["block","if",[["get","model.licenceNumberEu",["loc",[null,[268,20],[268,41]]]]],[],23,null,["loc",[null,[268,14],[271,21]]]],
        ["block","if",[["get","model.licenceNumberAges",["loc",[null,[272,20],[272,43]]]]],[],24,null,["loc",[null,[272,14],[275,21]]]],
        ["block","if",[["get","model.licenceNumberApo",["loc",[null,[276,20],[276,42]]]]],[],25,null,["loc",[null,[276,14],[279,21]]]],
        ["block","if",[["get","model.slopingInfo",["loc",[null,[280,20],[280,37]]]]],[],26,null,["loc",[null,[280,14],[283,21]]]],
        ["block","if",[["get","model.domainInfo",["loc",[null,[285,20],[285,36]]]]],[],27,null,["loc",[null,[285,14],[288,21]]]],
        ["block","if",[["subexpr","or",[["get","model.info",["loc",[null,[290,24],[290,34]]]],["get","model.detailUrl",["loc",[null,[290,35],[290,50]]]]],[],["loc",[null,[290,20],[290,51]]]]],[],28,null,["loc",[null,[290,14],[293,21]]]],
        ["block","if",[["subexpr","and",[["subexpr","eq",[["get","model.supplier.id",["loc",[null,[294,29],[294,46]]]],"jac"],[],["loc",[null,[294,25],[294,53]]]],["get","model.licenceNumber",["loc",[null,[294,54],[294,73]]]]],[],["loc",[null,[294,20],[294,74]]]]],[],29,null,["loc",[null,[294,14],[300,21]]]],
        ["block","if",[["get","model.targetRace",["loc",[null,[302,20],[302,36]]]]],[],30,null,["loc",[null,[302,14],[305,21]]]],
        ["block","if",[["subexpr","eq",[["get","model.supplier.id",["loc",[null,[306,24],[306,41]]]],"ceva"],[],["loc",[null,[306,20],[306,49]]]]],[],31,null,["loc",[null,[306,14],[356,21]]]],
        ["block","if",[["subexpr","has-permission",["ru_purchase_history_notice"],[],["loc",[null,[362,20],[362,65]]]]],[],32,33,["loc",[null,[362,14],[376,21]]]],
        ["block","each",[["get","history",["loc",[null,[390,24],[390,31]]]]],[],34,null,["loc",[null,[390,16],[416,27]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[420,60],[420,69]]]],"inventory"],[],["loc",[null,[420,56],[420,82]]]],"is-active",""],[],["loc",[null,[420,35],[420,99]]]]]]],
        ["block","each",[["get","stocks",["loc",[null,[433,24],[433,30]]]]],[],35,null,["loc",[null,[433,16],[451,27]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[455,60],[455,69]]]],"reorder"],[],["loc",[null,[455,56],[455,80]]]],"is-active",""],[],["loc",[null,[455,35],[455,97]]]]]]],
        ["block","each",[["get","model.reorderSettings",["loc",[null,[469,20],[469,41]]]]],[],36,null,["loc",[null,[469,12],[539,21]]]],
        ["inline","input",[],["type","checkbox","id","product-autosendReorder","name","product.autosendReorder","checked",["subexpr","@mut",[["get","model.autosendReorder",["loc",[null,[548,108],[548,129]]]]],[],[]]],["loc",[null,[548,16],[548,131]]]],
        ["inline","textarea",[],["rows","3","name","orderNotice","value",["subexpr","@mut",[["get","model.orderNotice",["loc",[null,[557,63],[557,80]]]]],[],[]],"class","input textarea__input full","placeholder",""],["loc",[null,[557,18],[557,132]]]],
        ["block","if",[["subexpr","and",[["subexpr","not",[["subexpr","is-supplier",[],[],["loc",[null,[566,19],[566,32]]]]],[],["loc",[null,[566,14],[566,33]]]],["get","sidebarActive",["loc",[null,[566,34],[566,47]]]]],[],["loc",[null,[566,9],[566,48]]]]],[],37,38,["loc",[null,[566,3],[609,7]]]],
        ["inline","purchase-order-laboklin-chooser",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[611,40],[611,45]]]]],[],[]],"orderId",["subexpr","@mut",[["get","order.id",["loc",[null,[611,54],[611,62]]]]],[],[]],"data",["subexpr","@mut",[["get","order.laboklinDatas",["loc",[null,[611,68],[611,87]]]]],[],[]],"modelName",["subexpr","@mut",[["get","model.name",["loc",[null,[611,98],[611,108]]]]],[],[]],"addNew","newLaboklinForm","addProduct","addProduct","actionReceiver",["subexpr","@mut",[["get","laboklinChooser",["loc",[null,[611,173],[611,188]]]]],[],[]]],["loc",[null,[611,0],[611,190]]]],
        ["inline","edit-purchase-price-panel",[],["save","savePrice","actionReceiver",["subexpr","@mut",[["get","editPurchasePricePanel",["loc",[null,[612,60],[612,82]]]]],[],[]]],["loc",[null,[612,0],[612,84]]]],
        ["inline","edit-purchase-product-panel",[],["save","saveProduct","actionReceiver",["subexpr","@mut",[["get","editPurchaseProductPanel",["loc",[null,[613,64],[613,88]]]]],[],[]]],["loc",[null,[613,0],[613,90]]]],
        ["inline","promotion-info-panel",[],["actionReceiver",["subexpr","@mut",[["get","promotionInfoPanel",["loc",[null,[614,38],[614,56]]]]],[],[]]],["loc",[null,[614,0],[614,58]]]],
        ["inline","delivery-info-panel",[],["actionReceiver",["subexpr","@mut",[["get","deliveryInfoPanel",["loc",[null,[615,37],[615,54]]]]],[],[]]],["loc",[null,[615,0],[615,56]]]],
        ["inline","notdeliverable-confirm",[],["orderAnyway","addProduct","actionReceiver",["subexpr","@mut",[["get","notDeliverableConfirm",["loc",[null,[616,65],[616,86]]]]],[],[]]],["loc",[null,[616,0],[616,88]]]],
        ["inline","laboklin-treatment-panel",[],["treatment",false,"orderId",["subexpr","@mut",[["get","order.id",["loc",[null,[619,10],[619,18]]]]],[],[]],"confirm","addProduct","removeEntry","unlinkLaboklin","editLab","editLab","delete","deleteLab","unlink","unlinkLaboklin","submitLaboklin","submitLaboklin","downloadMedia","downloadMedia","downloadBarcode","downloadBarcode","downloadPdf","downloadPdf","actionReceiver",["subexpr","@mut",[["get","laboklinTreatmentPanel",["loc",[null,[629,17],[629,39]]]]],[],[]]],["loc",[null,[617,0],[629,41]]]],
        ["content","button-prev",["loc",[null,[634,4],[634,19]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-jason-network",[],[],["loc",[null,[640,14],[640,32]]]],["subexpr","is-supplier",[],[],["loc",[null,[640,33],[640,46]]]]],[],["loc",[null,[640,10],[640,47]]]]],[],39,null,["loc",[null,[640,4],[647,11]]]],
        ["block","if",[["subexpr","or",[["subexpr","is-jason-network",[],[],["loc",[null,[648,12],[648,30]]]],["subexpr","is-supplier",[],[],["loc",[null,[648,31],[648,44]]]]],[],["loc",[null,[648,8],[648,45]]]]],[],40,null,["loc",[null,[648,2],[655,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[656,12],[656,21]]]],"history"],[],["loc",[null,[656,8],[656,32]]]]],[],41,null,["loc",[null,[656,2],[660,9]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[661,12],[661,21]]]],"reorder"],[],["loc",[null,[661,8],[661,32]]]]],[],42,null,["loc",[null,[661,2],[665,9]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14, child15, child16, child17, child18, child19, child20, child21, child22, child23, child24, child25, child26, child27, child28, child29, child30, child31, child32, child33, child34, child35, child36, child37, child38, child39, child40, child41, child42]
    };
  }()));

});