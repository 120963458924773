define('jason-frontend/templates/practice/index', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 8
              },
              "end": {
                "line": 8,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/add.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Neue Behandlungslokation");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 9,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["treatmentrooms.create"],["class","button-rework context primary"],0,null,["loc",[null,[6,8],[8,20]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 13,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/add.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("Neue Station");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 6
            },
            "end": {
              "line": 14,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","link-to",["treatmentstations.create"],["class","button-rework context primary"],0,null,["loc",[null,[11,8],[13,20]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 10
                },
                "end": {
                  "line": 19,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practice/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/rework/add.svg");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("Neues Lager");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 8
              },
              "end": {
                "line": 20,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["inventorywarehouses.create"],["class","button-rework context primary"],0,null,["loc",[null,[17,10],[19,22]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 10
                },
                "end": {
                  "line": 24,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practice/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1,"src","assets/images/icons/rework/add.svg");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("Neue Ressource");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 8
              },
              "end": {
                "line": 25,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","link-to",["resources.create"],["class","button-rework context primary"],0,null,["loc",[null,[22,10],[24,22]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 10
                },
                "end": {
                  "line": 31,
                  "column": 10
                }
              },
              "moduleName": "jason-frontend/templates/practice/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"href","https://service.vetnative.com/en/slides/slide/eregistrierkassen-webservice-user-via-finanzonline-97");
              dom.setAttribute(el1,"target","_blank");
              dom.setAttribute(el1,"class","button-rework context");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("Online Hilfe");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 8
              },
              "end": {
                "line": 32,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[27,16],[27,43]]]]],[],0,null,["loc",[null,[27,10],[31,17]]]]
          ],
          locals: [],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 6
            },
            "end": {
              "line": 33,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[16,18],[16,27]]]],"warehouses"],[],["loc",[null,[16,14],[16,41]]]]],[],0,null,["loc",[null,[16,8],[20,15]]]],
          ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[21,18],[21,27]]]],"resources"],[],["loc",[null,[21,14],[21,40]]]]],[],1,null,["loc",[null,[21,8],[25,15]]]],
          ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[26,18],[26,27]]]],"cashregisters"],[],["loc",[null,[26,14],[26,44]]]]],[],2,null,["loc",[null,[26,8],[32,15]]]]
        ],
        locals: [],
        templates: [child0, child1, child2]
      };
    }());
    var child3 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 2
            },
            "end": {
              "line": 41,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1,"class","float-left mr30 max-h-80px");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element24, 'src');
          return morphs;
        },
        statements: [
          ["attribute","src",["concat",[["get","mandant.logo.path",["loc",[null,[40,51],[40,68]]]]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 4
              },
              "end": {
                "line": 52,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#tab-treatmentrooms");
            var el2 = dom.createTextNode("Behandlungslokationen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element23 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element23, 'class');
            morphs[1] = dom.createElementMorph(element23);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[51,55],[51,64]]]],"treatmentrooms"],[],["loc",[null,[51,51],[51,82]]]],"is-active",""],[],["loc",[null,[51,30],[51,99]]]]]]],
            ["element","action",["selectTab","treatmentrooms"],[],["loc",[null,[51,128],[51,167]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 4
              },
              "end": {
                "line": 55,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#tab-cashregisters");
            var el2 = dom.createTextNode("Kassen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element22 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element22, 'class');
            morphs[1] = dom.createElementMorph(element22);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[54,55],[54,64]]]],"cashregisters"],[],["loc",[null,[54,51],[54,81]]]],"is-active",""],[],["loc",[null,[54,30],[54,98]]]]]]],
            ["element","action",["selectTab","cashregisters"],[],["loc",[null,[54,126],[54,164]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 4
              },
              "end": {
                "line": 59,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#tab-labequipments");
            var el2 = dom.createTextNode("Geräte");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#tab-labprofiles");
            var el2 = dom.createTextNode("Profile");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element20 = dom.childAt(fragment, [1]);
            var element21 = dom.childAt(fragment, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element20, 'class');
            morphs[1] = dom.createElementMorph(element20);
            morphs[2] = dom.createAttrMorph(element21, 'class');
            morphs[3] = dom.createElementMorph(element21);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[57,55],[57,64]]]],"labequipments"],[],["loc",[null,[57,51],[57,81]]]],"is-active",""],[],["loc",[null,[57,30],[57,98]]]]]]],
            ["element","action",["selectTab","labequipments"],[],["loc",[null,[57,126],[57,164]]]],
            ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[58,55],[58,64]]]],"labprofiles"],[],["loc",[null,[58,51],[58,79]]]],"is-active",""],[],["loc",[null,[58,30],[58,96]]]]]]],
            ["element","action",["selectTab","labprofiles"],[],["loc",[null,[58,122],[58,158]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 4
              },
              "end": {
                "line": 62,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#tab-warehouses");
            var el2 = dom.createTextNode("Lager");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element19 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element19, 'class');
            morphs[1] = dom.createElementMorph(element19);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[61,55],[61,64]]]],"warehouses"],[],["loc",[null,[61,51],[61,78]]]],"is-active",""],[],["loc",[null,[61,30],[61,95]]]]]]],
            ["element","action",["selectTab","warehouses"],[],["loc",[null,[61,120],[61,155]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 4
              },
              "end": {
                "line": 65,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#tab-resources");
            var el2 = dom.createTextNode("Ressourcen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element18, 'class');
            morphs[1] = dom.createElementMorph(element18);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[64,55],[64,64]]]],"resources"],[],["loc",[null,[64,51],[64,77]]]],"is-active",""],[],["loc",[null,[64,30],[64,94]]]]]]],
            ["element","action",["selectTab","resources"],[],["loc",[null,[64,118],[64,152]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 66,
                "column": 4
              },
              "end": {
                "line": 68,
                "column": 4
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"href","#tab-treatmentstations");
            var el2 = dom.createTextNode("Stationen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element17 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element17, 'class');
            morphs[1] = dom.createElementMorph(element17);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["tabs__label ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[67,55],[67,64]]]],"treatmentstations"],[],["loc",[null,[67,51],[67,85]]]],"is-active",""],[],["loc",[null,[67,30],[67,102]]]]]]],
            ["element","action",["selectTab","treatmentstations"],[],["loc",[null,[67,134],[67,176]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 2
            },
            "end": {
              "line": 69,
              "column": 2
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[2] = dom.createMorphAt(fragment,2,2,contextualElement);
          morphs[3] = dom.createMorphAt(fragment,3,3,contextualElement);
          morphs[4] = dom.createMorphAt(fragment,4,4,contextualElement);
          morphs[5] = dom.createMorphAt(fragment,5,5,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["subexpr","has-permission",["crud_treatment_rooms"],[],["loc",[null,[50,10],[50,49]]]]],[],0,null,["loc",[null,[50,4],[52,11]]]],
          ["block","if",[["subexpr","and",[["subexpr","has-permission",["c_payments"],[],["loc",[null,[53,15],[53,44]]]],["subexpr","has-permission",["ru_mandant"],[],["loc",[null,[53,45],[53,74]]]]],[],["loc",[null,[53,10],[53,75]]]]],[],1,null,["loc",[null,[53,4],[55,11]]]],
          ["block","if",[["subexpr","has-permission",["lab"],[],["loc",[null,[56,10],[56,32]]]]],[],2,null,["loc",[null,[56,4],[59,11]]]],
          ["block","if",[["subexpr","has-permission",["ehapo"],[],["loc",[null,[60,10],[60,34]]]]],[],3,null,["loc",[null,[60,4],[62,11]]]],
          ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[63,10],[63,38]]]]],[],4,null,["loc",[null,[63,4],[65,11]]]],
          ["block","if",[["subexpr","has-permission",["crud_treatment_stations"],[],["loc",[null,[66,10],[66,52]]]]],[],5,null,["loc",[null,[66,4],[68,11]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }());
    var child5 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 26
              },
              "end": {
                "line": 85,
                "column": 81
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.name",["loc",[null,[85,68],[85,81]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 93,
                "column": 20
              },
              "end": {
                "line": 96,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 82,
              "column": 14
            },
            "end": {
              "line": 99,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-20/24");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk l-6/24@tablet l-6/24@phone buttons-2");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [1]);
          var element16 = dom.childAt(element15, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element15, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element16, [1]),1,1);
          morphs[2] = dom.createMorphAt(element16,3,3);
          return morphs;
        },
        statements: [
          ["block","link-to",["treatmentrooms.edit",["get","item.id",["loc",[null,[85,59],[85,66]]]]],[],0,null,["loc",[null,[85,26],[85,93]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[89,100],[89,104]]]]],[],[]],"confirmed","deleteLocation","text","Wollen Sie diese Behandlungslokation wirklich löschen?"],["loc",[null,[89,22],[91,114]]]],
          ["block","link-to",["treatmentrooms.edit",["get","item.id",["loc",[null,[93,53],[93,60]]]]],["class"," icon-button icon-button--default list-action-square"],1,null,["loc",[null,[93,20],[96,32]]]]
        ],
        locals: ["item"],
        templates: [child0, child1]
      };
    }());
    var child6 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 115,
                "column": 26
              },
              "end": {
                "line": 115,
                "column": 84
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.name",["loc",[null,[115,71],[115,84]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 123,
                "column": 20
              },
              "end": {
                "line": 126,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 112,
              "column": 14
            },
            "end": {
              "line": 129,
              "column": 14
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-20/24");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk l-6/24@tablet l-6/24@phone buttons-2");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3,"class","icon-button icon-button--delete list-action-square");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var element14 = dom.childAt(element13, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element13, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element14, [1]),1,1);
          morphs[2] = dom.createMorphAt(element14,3,3);
          return morphs;
        },
        statements: [
          ["block","link-to",["treatmentstations.edit",["get","item.id",["loc",[null,[115,62],[115,69]]]]],[],0,null,["loc",[null,[115,26],[115,96]]]],
          ["inline","button-delete-with-confirm",[],["color","#fff","showStroke",false,"classNames","","item",["subexpr","@mut",[["get","item",["loc",[null,[119,100],[119,104]]]]],[],[]],"confirmed","deleteStation","text","Wollen Sie diese Station wirklich löschen?"],["loc",[null,[119,22],[121,102]]]],
          ["block","link-to",["treatmentstations.edit",["get","item.id",["loc",[null,[123,56],[123,63]]]]],["class","icon-button icon-button--default list-action-square"],1,null,["loc",[null,[123,20],[126,32]]]]
        ],
        locals: ["item"],
        templates: [child0, child1]
      };
    }());
    var child7 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 146,
                "column": 30
              },
              "end": {
                "line": 146,
                "column": 87
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.name",["loc",[null,[146,74],[146,87]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 149,
                "column": 30
              },
              "end": {
                "line": 149,
                "column": 54
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Ja");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 149,
                "column": 54
              },
              "end": {
                "line": 149,
                "column": 66
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Nein");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 152,
                "column": 24
              },
              "end": {
                "line": 154,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 143,
              "column": 18
            },
            "end": {
              "line": 157,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-10/24@desk l-24/24@tablet l-24/24@phone");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center@desk l-10/24@desk l-24/24@tablet l-24/24@phone");
          dom.setAttribute(el2,"data-label","Lagerstand anzeigen");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-4/24@desk l-6/24@tablet l-6/24@phone buttons-1");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element12, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element12, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element12, [5]),1,1);
          return morphs;
        },
        statements: [
          ["block","link-to",["inventorywarehouses.edit",["get","item",["loc",[null,[146,68],[146,72]]]]],[],0,null,["loc",[null,[146,30],[146,99]]]],
          ["block","if",[["get","item.showStock",["loc",[null,[149,36],[149,50]]]]],[],1,2,["loc",[null,[149,30],[149,73]]]],
          ["block","link-to",["inventorywarehouses.edit",["get","item",["loc",[null,[152,62],[152,66]]]]],["class"," icon-button icon-button--default list-action-square"],3,null,["loc",[null,[152,24],[154,36]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child8 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 178,
                "column": 30
              },
              "end": {
                "line": 178,
                "column": 78
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.name",["loc",[null,[178,65],[178,78]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 181,
                "column": 30
              },
              "end": {
                "line": 181,
                "column": 89
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Raum: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.treatmentRoom.name",["loc",[null,[181,62],[181,89]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 181,
                "column": 97
              },
              "end": {
                "line": 181,
                "column": 166
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Lokation: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.treatmentStation.name",["loc",[null,[181,136],[181,166]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 181,
                "column": 174
              },
              "end": {
                "line": 181,
                "column": 237
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Laborgerät: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["content","item.labEquipment.name",["loc",[null,[181,211],[181,237]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 186,
                  "column": 34
                },
                "end": {
                  "line": 186,
                  "column": 91
                }
              },
              "moduleName": "jason-frontend/templates/practice/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(", ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 185,
                "column": 20
              },
              "end": {
                "line": 187,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
            return morphs;
          },
          statements: [
            ["content","cat.name",["loc",[null,[186,22],[186,34]]]],
            ["block","unless",[["subexpr","eq",[["get","index",["loc",[null,[186,48],[186,53]]]],["subexpr","minus",[["get","item.categories.length",["loc",[null,[186,61],[186,83]]]],1],[],["loc",[null,[186,54],[186,86]]]]],[],["loc",[null,[186,44],[186,87]]]]],[],0,null,["loc",[null,[186,34],[186,102]]]]
          ],
          locals: ["cat","index"],
          templates: [child0]
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 191,
                "column": 24
              },
              "end": {
                "line": 193,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 175,
              "column": 18
            },
            "end": {
              "line": 196,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-7/24@desk l-12/24@tablet l-12/24@phone");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-7/24@desk l-12/24@tablet l-12/24@phone");
          dom.setAttribute(el2,"data-label","Verknüpft");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-7/24@desk l-24/24@tablet l-24/24@phone");
          dom.setAttribute(el2,"data-label","Geeignet für Kategorie");
          var el3 = dom.createTextNode("\n                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-3/24@desk l-6/24@tablet l-6/24@phone buttons-1");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var element11 = dom.childAt(element10, [3, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element10, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(element11,0,0);
          morphs[2] = dom.createMorphAt(element11,2,2);
          morphs[3] = dom.createMorphAt(element11,4,4);
          morphs[4] = dom.createMorphAt(dom.childAt(element10, [5, 1]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element10, [7]),1,1);
          return morphs;
        },
        statements: [
          ["block","link-to",["resources.edit",["get","item",["loc",[null,[178,58],[178,62]]]]],[],0,null,["loc",[null,[178,30],[178,90]]]],
          ["block","if",[["get","item.treatmentRoom",["loc",[null,[181,36],[181,54]]]]],[],1,null,["loc",[null,[181,30],[181,96]]]],
          ["block","if",[["get","item.treatmentStation",["loc",[null,[181,103],[181,124]]]]],[],2,null,["loc",[null,[181,97],[181,173]]]],
          ["block","if",[["get","item.labEquipment",["loc",[null,[181,180],[181,197]]]]],[],3,null,["loc",[null,[181,174],[181,244]]]],
          ["block","each",[["get","item.categories",["loc",[null,[185,28],[185,43]]]]],[],4,null,["loc",[null,[185,20],[187,29]]]],
          ["block","link-to",["resources.edit",["get","item",["loc",[null,[191,52],[191,56]]]]],["class","icon-button icon-button--default list-action-square"],5,null,["loc",[null,[191,24],[193,36]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }());
    var child9 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 218,
                "column": 28
              },
              "end": {
                "line": 218,
                "column": 96
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]),0,0);
            return morphs;
          },
          statements: [
            ["content","item.status",["loc",[null,[218,73],[218,88]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 211,
              "column": 16
            },
            "end": {
              "line": 227,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-12/24@desk l-20/24@tablet l-20/24@phone");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24@desk l-4/24");
          dom.setAttribute(el2,"data-label","Status");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24@desk l-12/24");
          dom.setAttribute(el2,"data-label","Seriennummer");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--right text-right l-4/24@desk l-12/24");
          dom.setAttribute(el2,"data-label","Letztes Update");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var element9 = dom.childAt(element8, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element8, [3, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element8, [5, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element8, [7, 1]),0,0);
          return morphs;
        },
        statements: [
          ["content","item.name",["loc",[null,[214,28],[214,41]]]],
          ["content","item.manu",["loc",[null,[215,40],[215,53]]]],
          ["block","if",[["get","item.status",["loc",[null,[218,34],[218,45]]]]],[],0,null,["loc",[null,[218,28],[218,103]]]],
          ["content","item.serialNumber",["loc",[null,[221,28],[221,49]]]],
          ["inline","time-format",[["get","item.lastUpdate",["loc",[null,[224,42],[224,57]]]],"DD. MM. YYYY HH:mm"],[],["loc",[null,[224,28],[224,80]]]]
        ],
        locals: ["item"],
        templates: [child0]
      };
    }());
    var child10 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 249,
                "column": 28
              },
              "end": {
                "line": 249,
                "column": 55
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("ja");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 249,
                "column": 55
              },
              "end": {
                "line": 249,
                "column": 67
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("nein");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 252,
                "column": 28
              },
              "end": {
                "line": 252,
                "column": 66
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(",");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [
            ["content","this.name",["loc",[null,[252,52],[252,65]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 255,
                "column": 22
              },
              "end": {
                "line": 257,
                "column": 22
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1,"src","assets/images/icons/rework/edit.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 242,
              "column": 16
            },
            "end": {
              "line": 260,
              "column": 16
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-8/24@desk l-18/24@tablet l-18/24@phone");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3,"class","sub");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--center text-center l-4/24@desk l-6/24@tablet l-6/24@phone");
          dom.setAttribute(el2,"data-label","Eigene Referenzwerte");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell  l-9/24@desk l-24/24@tablet l-24/24@phone");
          dom.setAttribute(el2,"data-label","Parameter");
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-3/24@desk l-6/24@tablet l-6/24@phone buttons-1");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element6, [3, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element6, [5, 1]),0,0);
          morphs[4] = dom.createMorphAt(dom.childAt(element6, [7]),1,1);
          return morphs;
        },
        statements: [
          ["content","item.name",["loc",[null,[245,28],[245,41]]]],
          ["content","item.labEquipment.name",["loc",[null,[246,40],[246,66]]]],
          ["block","if",[["get","item.customParams",["loc",[null,[249,34],[249,51]]]]],[],0,1,["loc",[null,[249,28],[249,74]]]],
          ["block","each",[["get","profile.params",["loc",[null,[252,36],[252,50]]]]],[],2,null,["loc",[null,[252,28],[252,75]]]],
          ["block","link-to",["labprofiles.edit",["get","item.id",["loc",[null,[255,52],[255,59]]]]],["class","icon-button icon-button--default list-action-square"],3,null,["loc",[null,[255,22],[257,34]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3]
      };
    }());
    var child11 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 272,
              "column": 103
            },
            "end": {
              "line": 272,
              "column": 176
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Registrierkassen Sicherheitsverordnung");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child12 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 272,
              "column": 176
            },
            "end": {
              "line": 272,
              "column": 194
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("TSE-Status");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child13 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 286,
                "column": 26
              },
              "end": {
                "line": 286,
                "column": 98
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge label-success");
            var el2 = dom.createTextNode("aktiv");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 286,
                "column": 98
              },
              "end": {
                "line": 286,
                "column": 161
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge  label-danger");
            var el2 = dom.createTextNode("inaktiv");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child2 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 291,
                "column": 26
              },
              "end": {
                "line": 291,
                "column": 102
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge label-success");
            var el2 = dom.createTextNode("aktiv");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child3 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 291,
                "column": 102
              },
              "end": {
                "line": 291,
                "column": 165
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("label");
            dom.setAttribute(el1,"class","info-badge  label-danger");
            var el2 = dom.createTextNode("inaktiv");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() { return []; },
          statements: [

          ],
          locals: [],
          templates: []
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 297,
                    "column": 24
                  },
                  "end": {
                    "line": 303,
                    "column": 24
                  }
                },
                "moduleName": "jason-frontend/templates/practice/index.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element3,1,1);
                morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]),0,0);
                return morphs;
              },
              statements: [
                ["inline","button-sign",[],["item",["subexpr","@mut",[["get","item",["loc",[null,[299,47],[299,51]]]]],[],[]],"showStroke",false,"confirmed","startRksv","size","32","color","#fff"],["loc",[null,[299,28],[299,115]]]],
                ["inline","text-with-confirm",[],["item",["subexpr","@mut",[["get","item",["loc",[null,[300,59],[300,63]]]]],[],[]],"confirmed","startRksv","textToShow","Aktivieren","text","Hiermit aktivieren Sie die elektronische Sicherheitsvorrichtung Ihrer Registrierkassa"],["loc",[null,[300,34],[301,148]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 296,
                  "column": 22
                },
                "end": {
                  "line": 304,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/practice/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","item.rksvPossible",["loc",[null,[297,30],[297,47]]]]],[],0,null,["loc",[null,[297,24],[303,31]]]]
            ],
            locals: [],
            templates: [child0]
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 304,
                  "column": 22
                },
                "end": {
                  "line": 309,
                  "column": 22
                }
              },
              "moduleName": "jason-frontend/templates/practice/index.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1,"class"," icon-button icon-button--default list-action-square ");
              var el2 = dom.createTextNode("\n                          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.setAttribute(el2,"src","assets/images/icons/rework/printer.svg");
              dom.setAttribute(el2,"class","tooltipstered");
              dom.setAttribute(el2,"title","Nullbeleg drucken");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element2);
              return morphs;
            },
            statements: [
              ["element","action",["printNullReciept",["get","item.id",["loc",[null,[305,55],[305,62]]]]],[],["loc",[null,[305,27],[305,64]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 295,
                "column": 20
              },
              "end": {
                "line": 310,
                "column": 20
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","not",[["get","item.rksvActive",["loc",[null,[296,33],[296,48]]]]],[],["loc",[null,[296,28],[296,49]]]]],[],0,1,["loc",[null,[296,22],[309,29]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      var child5 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 311,
                "column": 24
              },
              "end": {
                "line": 316,
                "column": 24
              }
            },
            "moduleName": "jason-frontend/templates/practice/index.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1,"class"," icon-button icon-button--default list-action-square tooltipstered");
            dom.setAttribute(el1,"title","Nullbeleg drucken");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2,"src","assets/images/icons/rework/printer.svg");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [
            ["element","action",["printNullReciept",["get","item.id",["loc",[null,[312,57],[312,64]]]]],[],["loc",[null,[312,29],[312,66]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 276,
              "column": 18
            },
            "end": {
              "line": 319,
              "column": 18
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","table__row");
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--name l-7/24@desk l-12/24@tablet l-12/24@phone");
          dom.setAttribute(el2,"data-label","Kassenidentifikationsnummer");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-5/24@desk l-12/24@tablet l-12/24@phone");
          dom.setAttribute(el2,"data-label","Name");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-4/24@desk l-12/24@tablet l-12/24@phone table__cell--center text-center");
          dom.setAttribute(el2,"data-label","Aktiv");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell l-5/24@desk l-12/24@tablet l-12/24@phone table__cell--center text-center");
          dom.setAttribute(el2,"data-label","RKSV");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n                          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","table__cell table__cell--action l-3/24@desk l-6/24@tablet l-6/24@phone buttons-1");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [9]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 1]),0,0);
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [5, 1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element4, [7, 1]),1,1);
          morphs[4] = dom.createMorphAt(element5,1,1);
          morphs[5] = dom.createMorphAt(element5,2,2);
          return morphs;
        },
        statements: [
          ["content","item.identificationNumber",["loc",[null,[279,30],[279,59]]]],
          ["content","item.name",["loc",[null,[282,30],[282,43]]]],
          ["block","if",[["get","item.active",["loc",[null,[286,32],[286,43]]]]],[],0,1,["loc",[null,[286,26],[286,168]]]],
          ["block","if",[["get","item.rksvActive",["loc",[null,[291,32],[291,47]]]]],[],2,3,["loc",[null,[291,26],[291,172]]]],
          ["block","if",[["subexpr","and",[["get","item.active",["loc",[null,[295,31],[295,42]]]],["subexpr","is-operating-country",["AT"],[],["loc",[null,[295,43],[295,70]]]]],[],["loc",[null,[295,26],[295,71]]]]],[],4,null,["loc",[null,[295,20],[310,27]]]],
          ["block","if",[["subexpr","and",[["get","item.active",["loc",[null,[311,35],[311,46]]]],["subexpr","is-operating-country",["DE"],[],["loc",[null,[311,47],[311,74]]]]],[],["loc",[null,[311,30],[311,75]]]]],[],5,null,["loc",[null,[311,24],[316,31]]]]
        ],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    }());
    var child14 = (function() {
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 327,
              "column": 4
            },
            "end": {
              "line": 331,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/practice/index.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1,"class","icon-button--success icon-button");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0,1,1);
          return morphs;
        },
        statements: [
          ["element","action",["save"],[],["loc",[null,[328,9],[328,26]]]],
          ["inline","button-save",[],["color","#ffffff","size","60","content","Speichern"],["loc",[null,[329,8],[329,69]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 333,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practice/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Meine Praxis");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","card mb-0 min-h-130px");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","float-left");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3,"class","mb0");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3,"class","mt0");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","tabs-rework-wrapper grid");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","rework-content js-tabs tabs");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2,"class","tabs__body mt20");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-treatmentrooms");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table grid table--large box no-box@phone");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-20/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-treatmentstations");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table grid table--large box no-box@phone table--large");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-20/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-warehouses");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table grid table--large box no-box@phone");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-10/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell table__cell--center@desk l-10/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Lagerstand anzeigen");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","pagination-wrapper is-fixed");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-resources");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table grid table--large box no-box@phone");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Verknüpft");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Geeignet für Kategorie");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-3/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-labequipments");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table grid table--large box no-box@phone");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-12/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Status");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Seriennummer");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell table__cell--right l-4/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Letztes Update");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-labprofiles");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table grid table--large box no-box@phone");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-8/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24 table__cell--center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Eigene Referenzwerte");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-9/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Parameter");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-3/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"data-for","#tab-cashregisters");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","table-wrapper-rework");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","table grid table--large box no-box@phone");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__head");
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-7/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Kassen-ID");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-5/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Name");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-4/24 table__cell--center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createTextNode("Status");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-5/24 table__cell--center");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                  ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7,"class","table__cell l-3/24");
        var el8 = dom.createElement("span");
        dom.setAttribute(el8,"class","table__title title");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6,"class","table__body");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("                ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","action-icons");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element25 = dom.childAt(fragment, [0]);
        var element26 = dom.childAt(element25, [1, 3]);
        var element27 = dom.childAt(fragment, [2]);
        var element28 = dom.childAt(element27, [3]);
        var element29 = dom.childAt(fragment, [6, 1]);
        var element30 = dom.childAt(element29, [1]);
        var element31 = dom.childAt(element29, [3]);
        var element32 = dom.childAt(element29, [5]);
        var element33 = dom.childAt(element32, [1]);
        var element34 = dom.childAt(element29, [7]);
        var element35 = dom.childAt(element29, [9]);
        var element36 = dom.childAt(element29, [11]);
        var element37 = dom.childAt(element29, [13]);
        var element38 = dom.childAt(element37, [1, 1]);
        var morphs = new Array(25);
        morphs[0] = dom.createAttrMorph(element25, 'class');
        morphs[1] = dom.createMorphAt(element26,1,1);
        morphs[2] = dom.createMorphAt(element26,2,2);
        morphs[3] = dom.createMorphAt(element26,3,3);
        morphs[4] = dom.createMorphAt(element27,1,1);
        morphs[5] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
        morphs[6] = dom.createMorphAt(dom.childAt(element28, [3]),0,0);
        morphs[7] = dom.createMorphAt(dom.childAt(fragment, [4]),1,1);
        morphs[8] = dom.createAttrMorph(element30, 'class');
        morphs[9] = dom.createMorphAt(dom.childAt(element30, [1, 1, 3]),1,1);
        morphs[10] = dom.createAttrMorph(element31, 'class');
        morphs[11] = dom.createMorphAt(dom.childAt(element31, [1, 1, 3]),1,1);
        morphs[12] = dom.createAttrMorph(element32, 'class');
        morphs[13] = dom.createMorphAt(dom.childAt(element33, [1, 3]),1,1);
        morphs[14] = dom.createMorphAt(dom.childAt(element33, [3]),1,1);
        morphs[15] = dom.createAttrMorph(element34, 'class');
        morphs[16] = dom.createMorphAt(dom.childAt(element34, [1, 1, 3]),1,1);
        morphs[17] = dom.createAttrMorph(element35, 'class');
        morphs[18] = dom.createMorphAt(dom.childAt(element35, [1, 1, 3]),1,1);
        morphs[19] = dom.createAttrMorph(element36, 'class');
        morphs[20] = dom.createMorphAt(dom.childAt(element36, [1, 1, 3]),1,1);
        morphs[21] = dom.createAttrMorph(element37, 'class');
        morphs[22] = dom.createMorphAt(dom.childAt(element38, [1, 7, 0]),0,0);
        morphs[23] = dom.createMorphAt(dom.childAt(element38, [3]),1,1);
        morphs[24] = dom.createMorphAt(dom.childAt(fragment, [8]),1,1);
        return morphs;
      },
      statements: [
        ["attribute","class",["concat",["rework-navbar ",["subexpr","css-bool-evaluator",[["get","showFilters",["loc",[null,[1,47],[1,58]]]],"with-filters",""],[],["loc",[null,[1,26],[1,78]]]]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[5,16],[5,25]]]],"treatmentrooms"],[],["loc",[null,[5,12],[5,43]]]]],[],0,null,["loc",[null,[5,6],[9,13]]]],
        ["block","if",[["subexpr","eq",[["get","selectTab",["loc",[null,[10,16],[10,25]]]],"treatmentstations"],[],["loc",[null,[10,12],[10,46]]]]],[],1,null,["loc",[null,[10,6],[14,13]]]],
        ["block","if",[["subexpr","is-at-least",["professional"],[],["loc",[null,[15,12],[15,40]]]]],[],2,null,["loc",[null,[15,6],[33,13]]]],
        ["block","if",[["get","mandant.logo",["loc",[null,[39,8],[39,20]]]]],[],3,null,["loc",[null,[39,2],[41,9]]]],
        ["content","mandant.name",["loc",[null,[43,20],[43,36]]]],
        ["content","mandant.operators",["loc",[null,[44,19],[44,40]]]],
        ["block","if",[["subexpr","is-at-least",["essential"],[],["loc",[null,[49,8],[49,33]]]]],[],4,null,["loc",[null,[49,2],[69,9]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[74,56],[74,65]]]],"treatmentrooms"],[],["loc",[null,[74,52],[74,83]]]],"is-active",""],[],["loc",[null,[74,31],[74,100]]]]]]],
        ["block","each",[["get","model",["loc",[null,[82,22],[82,27]]]]],[],5,null,["loc",[null,[82,14],[99,23]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[104,56],[104,65]]]],"treatmentstations"],[],["loc",[null,[104,52],[104,86]]]],"is-active",""],[],["loc",[null,[104,31],[104,103]]]]]]],
        ["block","each",[["get","model",["loc",[null,[112,22],[112,27]]]]],[],6,null,["loc",[null,[112,14],[129,23]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[134,56],[134,65]]]],"warehouses"],[],["loc",[null,[134,52],[134,79]]]],"is-active",""],[],["loc",[null,[134,31],[134,96]]]]]]],
        ["block","each",[["get","model",["loc",[null,[143,26],[143,31]]]]],[],7,null,["loc",[null,[143,18],[157,27]]]],
        ["inline","page-numbers",[],["content",["subexpr","@mut",[["get","content",["loc",[null,[161,39],[161,46]]]]],[],[]],"numPagesToShow",7],["loc",[null,[161,16],[161,65]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[165,56],[165,65]]]],"resources"],[],["loc",[null,[165,52],[165,78]]]],"is-active",""],[],["loc",[null,[165,31],[165,95]]]]]]],
        ["block","each",[["get","model",["loc",[null,[175,26],[175,31]]]]],[],8,null,["loc",[null,[175,18],[196,27]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[201,56],[201,65]]]],"labequipments"],[],["loc",[null,[201,52],[201,82]]]],"is-active",""],[],["loc",[null,[201,31],[201,99]]]]]]],
        ["block","each",[["get","model",["loc",[null,[211,24],[211,29]]]]],[],9,null,["loc",[null,[211,16],[227,25]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[232,56],[232,65]]]],"labprofiles"],[],["loc",[null,[232,52],[232,80]]]],"is-active",""],[],["loc",[null,[232,31],[232,97]]]]]]],
        ["block","each",[["get","model",["loc",[null,[242,24],[242,29]]]]],[],10,null,["loc",[null,[242,16],[260,25]]]],
        ["attribute","class",["concat",["tabs__content ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","selectTab",["loc",[null,[265,56],[265,65]]]],"cashregisters"],[],["loc",[null,[265,52],[265,82]]]],"is-active",""],[],["loc",[null,[265,31],[265,99]]]]]]],
        ["block","if",[["subexpr","is-operating-country",["AT"],[],["loc",[null,[272,109],[272,136]]]]],[],11,12,["loc",[null,[272,103],[272,201]]]],
        ["block","each",[["get","model",["loc",[null,[276,26],[276,31]]]]],[],13,null,["loc",[null,[276,18],[319,27]]]],
        ["block","if",[["subexpr","or",[["subexpr","eq",[["get","selectTab",["loc",[null,[327,18],[327,27]]]],"invoice"],[],["loc",[null,[327,14],[327,38]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[327,43],[327,52]]]],"reminder"],[],["loc",[null,[327,39],[327,64]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[327,69],[327,78]]]],"app"],[],["loc",[null,[327,65],[327,85]]]],["subexpr","eq",[["get","selectTab",["loc",[null,[327,90],[327,99]]]],"warning"],[],["loc",[null,[327,86],[327,110]]]]],[],["loc",[null,[327,10],[327,111]]]]],[],14,null,["loc",[null,[327,4],[331,11]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12, child13, child14]
    };
  }()));

});