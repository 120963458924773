define('jason-frontend/templates/practicemgmt/admission', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 8
                  },
                  "end": {
                    "line": 35,
                    "column": 8
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2,"class","symbol-label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element55 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createAttrMorph(element55, 'title');
                morphs[1] = dom.createAttrMorph(element55, 'class');
                morphs[2] = dom.createAttrMorph(element55, 'style');
                morphs[3] = dom.createElementMorph(element55);
                morphs[4] = dom.createMorphAt(dom.childAt(element55, [1]),0,0);
                return morphs;
              },
              statements: [
                ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[31,24],[31,38]]]]]]],
                ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[31,117],[31,130]]]],"selected",""],[],["loc",[null,[31,96],[31,146]]]]," symbol symbol-circle symbol-40px"]]],
                ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[31,208],[31,218]]]]," !important;"]]],
                ["element","action",["toggleResource",["get","user",["loc",[null,[31,68],[31,72]]]]],[],["loc",[null,[31,42],[31,74]]]],
                ["content","user.capitals",["loc",[null,[32,38],[32,55]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 37,
                      "column": 12
                    },
                    "end": {
                      "line": 41,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","symbol-label");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() { return []; },
                statements: [

                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 41,
                      "column": 12
                    },
                    "end": {
                      "line": 44,
                      "column": 12
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1,"class","symbol-label");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                  return morphs;
                },
                statements: [
                  ["content","user.capitals",["loc",[null,[42,40],[42,57]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 8
                  },
                  "end": {
                    "line": 46,
                    "column": 8
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element54 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createAttrMorph(element54, 'title');
                morphs[1] = dom.createAttrMorph(element54, 'class');
                morphs[2] = dom.createElementMorph(element54);
                morphs[3] = dom.createMorphAt(element54,1,1);
                return morphs;
              },
              statements: [
                ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[36,24],[36,38]]]]]]],
                ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[36,117],[36,130]]]],"selected",""],[],["loc",[null,[36,96],[36,146]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[36,182],[36,192]]]]]]],
                ["element","action",["toggleResource",["get","user",["loc",[null,[36,68],[36,72]]]]],[],["loc",[null,[36,42],[36,74]]]],
                ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[37,22],[37,29]]]],"9998"],[],["loc",[null,[37,18],[37,37]]]]],[],0,1,["loc",[null,[37,12],[44,19]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 8
                },
                "end": {
                  "line": 47,
                  "column": 8
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["block","if",[["get","user.colorHex",["loc",[null,[30,14],[30,27]]]]],[],0,1,["loc",[null,[30,8],[46,15]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 48,
                "column": 6
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.active",["loc",[null,[29,14],[29,25]]]]],[],0,null,["loc",[null,[29,8],[47,15]]]]
          ],
          locals: ["user"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 0
            },
            "end": {
              "line": 51,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter mb10 mtm10 col l-24/24");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Mitarbeiter:innen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","symbol-group symbol-hover flex-shrink-0 me-2");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]),1,1);
          return morphs;
        },
        statements: [
          ["block","each",[["get","users",["loc",[null,[28,14],[28,19]]]]],[],0,null,["loc",[null,[28,6],[48,15]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 24
                },
                "end": {
                  "line": 76,
                  "column": 118
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [
              ["content","item.customer.firstname",["loc",[null,[76,64],[76,91]]]],
              ["content","item.customer.lastname",["loc",[null,[76,92],[76,118]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 79,
                    "column": 22
                  },
                  "end": {
                    "line": 84,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[80,56],[80,87]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","patientInfo.patient.etigaActive",["loc",[null,[81,51],[81,82]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[82,46],[82,82]]]]],[],[]],"evil",["subexpr","@mut",[["get","patientInfo.patient.evil",["loc",[null,[82,88],[82,112]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[83,46],[83,79]]]]],[],[]],"classNames","animal-icon mr5"],["loc",[null,[80,24],[83,110]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 85,
                    "column": 22
                  },
                  "end": {
                    "line": 85,
                    "column": 149
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[85,92],[85,116]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[85,125],[85,147]]]]],[],[]]],["loc",[null,[85,73],[85,149]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 90,
                        "column": 28
                      },
                      "end": {
                        "line": 95,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element42 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element42, 'title');
                    morphs[1] = dom.createAttrMorph(element42, 'style');
                    morphs[2] = dom.createMorphAt(dom.childAt(element42, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[91,44],[91,72]]]]]]],
                    ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[91,162],[91,186]]]]," !important;"]]],
                    ["content","patientInfo.doctor.capitals",["loc",[null,[92,60],[92,91]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 95,
                        "column": 28
                      },
                      "end": {
                        "line": 100,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element41 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element41, 'title');
                    morphs[1] = dom.createAttrMorph(element41, 'class');
                    morphs[2] = dom.createMorphAt(dom.childAt(element41, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[96,44],[96,72]]]]]]],
                    ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[96,122],[96,149]]]],"selected",""],[],["loc",[null,[96,101],[96,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[96,201],[96,225]]]]]]],
                    ["content","patientInfo.doctor.capitals",["loc",[null,[97,60],[97,91]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 89,
                      "column": 26
                    },
                    "end": {
                      "line": 101,
                      "column": 26
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[90,34],[90,61]]]]],[],0,1,["loc",[null,[90,28],[100,35]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 103,
                        "column": 28
                      },
                      "end": {
                        "line": 108,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                    var el2 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element40 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element40, 'title');
                    morphs[1] = dom.createAttrMorph(element40, 'style');
                    morphs[2] = dom.createMorphAt(dom.childAt(element40, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[104,44],[104,75]]]]]]],
                    ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[104,165],[104,192]]]]," !important;"]]],
                    ["content","patientInfo.assistant.capitals",["loc",[null,[105,62],[105,96]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 28
                      },
                      "end": {
                        "line": 113,
                        "column": 28
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                                    ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element39 = dom.childAt(fragment, [1]);
                    var morphs = new Array(3);
                    morphs[0] = dom.createAttrMorph(element39, 'title');
                    morphs[1] = dom.createAttrMorph(element39, 'class');
                    morphs[2] = dom.createMorphAt(dom.childAt(element39, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[109,44],[109,75]]]]]]],
                    ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[109,125],[109,155]]]],"selected",""],[],["loc",[null,[109,104],[109,171]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[109,207],[109,234]]]]]]],
                    ["content","patientInfo.assistant.capitals",["loc",[null,[110,62],[110,96]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 102,
                      "column": 26
                    },
                    "end": {
                      "line": 114,
                      "column": 26
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[103,34],[103,64]]]]],[],0,1,["loc",[null,[103,28],[113,35]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 24
                  },
                  "end": {
                    "line": 115,
                    "column": 24
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","patientInfo.doctor",["loc",[null,[89,32],[89,50]]]]],[],0,null,["loc",[null,[89,26],[101,33]]]],
                ["block","if",[["get","patientInfo.assistant",["loc",[null,[102,32],[102,53]]]]],[],1,null,["loc",[null,[102,26],[114,33]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 18
                },
                "end": {
                  "line": 122,
                  "column": 18
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1,"class","flex-center mb5 mt5");
              dom.setAttribute(el1,"style","flex-direction: row");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-group");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("img");
              dom.setAttribute(el3,"src","assets/images/icons/rework/admission.svg");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element43 = dom.childAt(fragment, [1]);
              var element44 = dom.childAt(element43, [7]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element43,1,1);
              morphs[1] = dom.createMorphAt(element43,3,3);
              morphs[2] = dom.createMorphAt(dom.childAt(element43, [5]),1,1);
              morphs[3] = dom.createElementMorph(element44);
              return morphs;
            },
            statements: [
              ["block","if",[["get","patientInfo.patient.category",["loc",[null,[79,28],[79,56]]]]],[],0,null,["loc",[null,[79,22],[84,29]]]],
              ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[85,49],[85,71]]]]],[],1,null,["loc",[null,[85,22],[85,161]]]],
              ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[87,34],[87,55]]]],["get","patientInfo.doctor",["loc",[null,[87,56],[87,74]]]]],[],["loc",[null,[87,30],[87,75]]]]],[],2,null,["loc",[null,[87,24],[115,31]]]],
              ["element","action",["admissionPatient",["get","item.customer.id",["loc",[null,[118,133],[118,149]]]],["get","patientInfo.patient.id",["loc",[null,[118,150],[118,172]]]],["get","item.appointmentId",["loc",[null,[118,173],[118,191]]]]],[],["loc",[null,[118,105],[118,193]]]]
            ],
            locals: ["patientInfo"],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 12
              },
              "end": {
                "line": 125,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1,"class","table__row");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell l-8/24");
            dom.setAttribute(el2,"data-label","Termin");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3,"class","sub");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" Uhr");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2,"class","table__cell  l-16/24");
            dom.setAttribute(el2,"data-label","Kund:in");
            var el3 = dom.createTextNode("\n                  ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element45 = dom.childAt(fragment, [1]);
            var element46 = dom.childAt(element45, [1]);
            var element47 = dom.childAt(element45, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element46, [1]),0,0);
            morphs[1] = dom.createMorphAt(dom.childAt(element46, [3]),0,0);
            morphs[2] = dom.createMorphAt(dom.childAt(element47, [1]),0,0);
            morphs[3] = dom.createMorphAt(element47,3,3);
            return morphs;
          },
          statements: [
            ["inline","time-format",[["get","item.created",["loc",[null,[71,97],[71,109]]]],"DD. MM. YYYY"],[],["loc",[null,[71,83],[71,126]]]],
            ["inline","time-format",[["get","item.created",["loc",[null,[72,50],[72,62]]]],"HH:mm"],[],["loc",[null,[72,36],[72,72]]]],
            ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[75,52],[75,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[75,24],[76,130]]]],
            ["block","each",[["get","item.treatmentPatients",["loc",[null,[77,26],[77,48]]]]],[],1,null,["loc",[null,[77,18],[122,27]]]]
          ],
          locals: ["item"],
          templates: [child0, child1]
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 148,
                    "column": 22
                  },
                  "end": {
                    "line": 155,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","sub block mt5");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","sub");
                dom.setAttribute(el2,"style","display: inline");
                var el3 = dom.createTextNode("Termin: ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                dom.setAttribute(el2,"for","time");
                dom.setAttribute(el2,"class","input-element");
                var el3 = dom.createTextNode("\n                             ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("input");
                dom.setAttribute(el3,"style","font-size: 14px");
                dom.setAttribute(el3,"autocomplete","false");
                dom.setAttribute(el3,"type","text");
                dom.setAttribute(el3,"placeholder","");
                dom.setAttribute(el3,"class","input date-picker__input time time-small");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element35 = dom.childAt(fragment, [1, 3, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element35, 'id');
                morphs[1] = dom.createAttrMorph(element35, 'value');
                morphs[2] = dom.createAttrMorph(element35, 'onclick');
                return morphs;
              },
              statements: [
                ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[152,108],[152,115]]]]]]],
                ["attribute","value",["concat",[["get","item.time",["loc",[null,[152,143],[152,152]]]]]]],
                ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[152,233],[152,237]]]]],[],["loc",[null,[152,213],[152,239]]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 158,
                    "column": 28
                  },
                  "end": {
                    "line": 159,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[159,64],[159,91]]]],
                ["content","item.customer.lastname",["loc",[null,[159,92],[159,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 164,
                          "column": 28
                        },
                        "end": {
                          "line": 169,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[165,62],[165,93]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","patientInfo.patient.etigaActive",["loc",[null,[166,57],[166,88]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[167,52],[167,88]]]]],[],[]],"evil",["subexpr","@mut",[["get","patientInfo.patient.evil",["loc",[null,[167,94],[167,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[168,52],[168,85]]]]],[],[]],"classNames","animal-icon mr5"],["loc",[null,[165,30],[168,116]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 170,
                          "column": 28
                        },
                        "end": {
                          "line": 171,
                          "column": 92
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[171,35],[171,59]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[171,68],[171,90]]]]],[],[]]],["loc",[null,[170,79],[171,92]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 176,
                              "column": 28
                            },
                            "end": {
                              "line": 181,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element32 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element32, 'title');
                          morphs[1] = dom.createAttrMorph(element32, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element32, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[177,44],[177,72]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[177,162],[177,186]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[178,60],[178,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 181,
                              "column": 28
                            },
                            "end": {
                              "line": 186,
                              "column": 28
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element31 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element31, 'title');
                          morphs[1] = dom.createAttrMorph(element31, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element31, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[182,44],[182,72]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[182,122],[182,149]]]],"selected",""],[],["loc",[null,[182,101],[182,165]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[182,201],[182,225]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[183,60],[183,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 175,
                            "column": 26
                          },
                          "end": {
                            "line": 187,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[176,34],[176,61]]]]],[],0,1,["loc",[null,[176,28],[186,35]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 189,
                              "column": 34
                            },
                            "end": {
                              "line": 194,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element30 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element30, 'title');
                          morphs[1] = dom.createAttrMorph(element30, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element30, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[190,50],[190,81]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[190,171],[190,198]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[191,62],[191,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 194,
                              "column": 34
                            },
                            "end": {
                              "line": 199,
                              "column": 34
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element29 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element29, 'title');
                          morphs[1] = dom.createAttrMorph(element29, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element29, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[195,50],[195,81]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[195,131],[195,161]]]],"selected",""],[],["loc",[null,[195,110],[195,177]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[195,213],[195,240]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[196,62],[196,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 188,
                            "column": 32
                          },
                          "end": {
                            "line": 200,
                            "column": 32
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[189,40],[189,70]]]]],[],0,1,["loc",[null,[189,34],[199,41]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 173,
                          "column": 28
                        },
                        "end": {
                          "line": 201,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[175,32],[175,50]]]]],[],0,null,["loc",[null,[175,26],[187,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[188,38],[188,59]]]]],[],1,null,["loc",[null,[188,32],[200,39]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 162,
                        "column": 24
                      },
                      "end": {
                        "line": 208,
                        "column": 24
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ember-view icon-button icon-button--success list-action-square mr0 ml10");
                    var el3 = dom.createTextNode("\n                              ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("img");
                    dom.setAttribute(el3,"class","tooltipstered");
                    dom.setAttribute(el3,"title","Behandeln");
                    dom.setAttribute(el3,"src","assets/images/icons/rework/treatment.svg");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element33 = dom.childAt(fragment, [1]);
                    var element34 = dom.childAt(element33, [7]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element33,1,1);
                    morphs[1] = dom.createMorphAt(element33,3,3);
                    morphs[2] = dom.createMorphAt(dom.childAt(element33, [5]),1,1);
                    morphs[3] = dom.createElementMorph(element34);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[164,34],[164,62]]]]],[],0,null,["loc",[null,[164,28],[169,35]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[170,55],[170,77]]]]],[],1,null,["loc",[null,[170,28],[171,104]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[173,38],[173,59]]]],["get","patientInfo.doctor",["loc",[null,[173,60],[173,78]]]]],[],["loc",[null,[173,34],[173,79]]]]],[],2,null,["loc",[null,[173,28],[201,35]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[204,149],[204,163]]]]],[],["loc",[null,[204,111],[204,165]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 161,
                      "column": 24
                    },
                    "end": {
                      "line": 209,
                      "column": 24
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[162,59],[162,70]]]],["get","selectedUsers",["loc",[null,[162,71],[162,84]]]],["get","filterName",["loc",[null,[162,85],[162,95]]]],["get","changeTrigger",["loc",[null,[162,96],[162,109]]]]],[],["loc",[null,[162,30],[162,110]]]]],[],0,null,["loc",[null,[162,24],[208,31]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 160,
                    "column": 22
                  },
                  "end": {
                    "line": 210,
                    "column": 22
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[161,34],[161,54]]]],"in_waitingroom"],[],["loc",[null,[161,30],[161,72]]]]],[],0,null,["loc",[null,[161,24],[209,31]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 143,
                  "column": 14
                },
                "end": {
                  "line": 214,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element36 = dom.childAt(fragment, [1]);
              var element37 = dom.childAt(element36, [1]);
              var element38 = dom.childAt(element36, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element37, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element37, [3]),0,0);
              morphs[2] = dom.createMorphAt(element37,5,5);
              morphs[3] = dom.createMorphAt(dom.childAt(element38, [1]),0,0);
              morphs[4] = dom.createMorphAt(element38,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[146,101],[146,113]]]],"DD. MM. YYYY"],[],["loc",[null,[146,87],[146,130]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[147,54],[147,66]]]],"HH:mm"],[],["loc",[null,[147,40],[147,76]]]],
              ["block","if",[["get","item.time",["loc",[null,[148,28],[148,37]]]]],[],0,null,["loc",[null,[148,22],[155,29]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[158,56],[158,72]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[158,28],[159,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[160,30],[160,52]]]]],[],2,null,["loc",[null,[160,22],[210,31]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 142,
                "column": 12
              },
              "end": {
                "line": 215,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[143,41],[143,45]]]],["get","selectedUsers",["loc",[null,[143,46],[143,59]]]],["get","filterName",["loc",[null,[143,60],[143,70]]]],["get","changeTrigger",["loc",[null,[143,71],[143,84]]]]],[],["loc",[null,[143,20],[143,85]]]]],[],0,null,["loc",[null,[143,14],[214,21]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      var child2 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 238,
                    "column": 18
                  },
                  "end": {
                    "line": 245,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1,"class","sub block mt5");
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2,"class","sub");
                dom.setAttribute(el2,"style","display: inline");
                var el3 = dom.createTextNode("Termin: ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                dom.setAttribute(el2,"for","time");
                dom.setAttribute(el2,"class","input-element");
                var el3 = dom.createTextNode("\n                             ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("input");
                dom.setAttribute(el3,"style","font-size: 14px");
                dom.setAttribute(el3,"autocomplete","false");
                dom.setAttribute(el3,"type","text");
                dom.setAttribute(el3,"placeholder","");
                dom.setAttribute(el3,"class","input date-picker__input time time-small");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element25 = dom.childAt(fragment, [1, 3, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element25, 'id');
                morphs[1] = dom.createAttrMorph(element25, 'value');
                morphs[2] = dom.createAttrMorph(element25, 'onclick');
                return morphs;
              },
              statements: [
                ["attribute","id",["concat",["timeTile_",["get","item.id",["loc",[null,[242,108],[242,115]]]]]]],
                ["attribute","value",["concat",[["get","item.time",["loc",[null,[242,143],[242,152]]]]]]],
                ["attribute","onclick",["subexpr","action",["openTime",["get","item",["loc",[null,[242,233],[242,237]]]]],[],["loc",[null,[242,213],[242,239]]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 248,
                    "column": 24
                  },
                  "end": {
                    "line": 249,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[249,64],[249,91]]]],
                ["content","item.customer.lastname",["loc",[null,[249,92],[249,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 254,
                          "column": 28
                        },
                        "end": {
                          "line": 259,
                          "column": 28
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[255,62],[255,93]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","patientInfo.patient.etigaActive",["loc",[null,[256,57],[256,88]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[257,52],[257,88]]]]],[],[]],"evil",["subexpr","@mut",[["get","patientInfo.patient.evil",["loc",[null,[257,94],[257,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[258,52],[258,85]]]]],[],[]],"classNames","animal-icon mr5"],["loc",[null,[255,30],[258,116]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 260,
                          "column": 24
                        },
                        "end": {
                          "line": 260,
                          "column": 151
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[260,94],[260,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[260,127],[260,149]]]]],[],[]]],["loc",[null,[260,75],[260,151]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 265,
                              "column": 30
                            },
                            "end": {
                              "line": 270,
                              "column": 44
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element23 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element23, 'title');
                          morphs[1] = dom.createAttrMorph(element23, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element23, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[266,46],[266,74]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[266,164],[266,188]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[267,60],[267,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 270,
                              "column": 44
                            },
                            "end": {
                              "line": 275,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                              ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element22 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element22, 'title');
                          morphs[1] = dom.createAttrMorph(element22, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element22, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[271,60],[271,88]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[271,138],[271,165]]]],"selected",""],[],["loc",[null,[271,117],[271,181]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[271,217],[271,241]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[272,60],[272,91]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 264,
                            "column": 26
                          },
                          "end": {
                            "line": 276,
                            "column": 26
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[265,36],[265,63]]]]],[],0,1,["loc",[null,[265,30],[275,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 278,
                              "column": 30
                            },
                            "end": {
                              "line": 283,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element21 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element21, 'title');
                          morphs[1] = dom.createAttrMorph(element21, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element21, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[279,46],[279,77]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[279,167],[279,194]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[280,62],[280,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 283,
                              "column": 30
                            },
                            "end": {
                              "line": 288,
                              "column": 30
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                  ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element20 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element20, 'title');
                          morphs[1] = dom.createAttrMorph(element20, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element20, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[284,46],[284,77]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[284,127],[284,157]]]],"selected",""],[],["loc",[null,[284,106],[284,173]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[284,209],[284,236]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[285,62],[285,96]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 277,
                            "column": 28
                          },
                          "end": {
                            "line": 289,
                            "column": 28
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[278,36],[278,66]]]]],[],0,1,["loc",[null,[278,30],[288,37]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 263,
                          "column": 24
                        },
                        "end": {
                          "line": 290,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[264,32],[264,50]]]]],[],0,null,["loc",[null,[264,26],[276,33]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[277,34],[277,55]]]]],[],1,null,["loc",[null,[277,28],[289,35]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                var child3 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 293,
                          "column": 24
                        },
                        "end": {
                          "line": 295,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1,"class","tooltipstered");
                      dom.setAttribute(el1,"title","Behandlungsdetails");
                      dom.setAttribute(el1,"src","assets/images/icons/rework/treatment.svg");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 252,
                        "column": 22
                      },
                      "end": {
                        "line": 297,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element24 = dom.childAt(fragment, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createMorphAt(element24,1,1);
                    morphs[1] = dom.createMorphAt(element24,3,3);
                    morphs[2] = dom.createMorphAt(dom.childAt(element24, [5]),1,1);
                    morphs[3] = dom.createMorphAt(element24,7,7);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[254,34],[254,62]]]]],[],0,null,["loc",[null,[254,28],[259,35]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[260,51],[260,73]]]]],[],1,null,["loc",[null,[260,24],[260,163]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[263,34],[263,55]]]],["get","patientInfo.doctor",["loc",[null,[263,56],[263,74]]]]],[],["loc",[null,[263,30],[263,75]]]]],[],2,null,["loc",[null,[263,24],[290,31]]]],
                    ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[293,68],[293,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[293,83],[293,110]]]]],["class","mr0 ml10 icon-button icon-button--default list-action-square"],3,null,["loc",[null,[293,24],[295,36]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 251,
                      "column": 22
                    },
                    "end": {
                      "line": 298,
                      "column": 22
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[252,57],[252,68]]]],["get","selectedUsers",["loc",[null,[252,69],[252,82]]]],["get","filterName",["loc",[null,[252,83],[252,93]]]],["get","changeTrigger",["loc",[null,[252,94],[252,107]]]]],[],["loc",[null,[252,28],[252,108]]]]],[],0,null,["loc",[null,[252,22],[297,29]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 250,
                    "column": 20
                  },
                  "end": {
                    "line": 299,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[251,32],[251,52]]]],"in_treatment"],[],["loc",[null,[251,28],[251,68]]]]],[],0,null,["loc",[null,[251,22],[298,29]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 233,
                  "column": 14
                },
                "end": {
                  "line": 302,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element26 = dom.childAt(fragment, [1]);
              var element27 = dom.childAt(element26, [1]);
              var element28 = dom.childAt(element26, [3]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(dom.childAt(element27, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element27, [3]),0,0);
              morphs[2] = dom.createMorphAt(element27,5,5);
              morphs[3] = dom.createMorphAt(dom.childAt(element28, [1]),0,0);
              morphs[4] = dom.createMorphAt(element28,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[236,97],[236,109]]]],"DD. MM. YYYY"],[],["loc",[null,[236,83],[236,126]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[237,50],[237,62]]]],"HH:mm"],[],["loc",[null,[237,36],[237,72]]]],
              ["block","if",[["get","item.time",["loc",[null,[238,24],[238,33]]]]],[],0,null,["loc",[null,[238,18],[245,25]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[248,52],[248,68]]]]],["classNames","is-highlighted"],1,null,["loc",[null,[248,24],[249,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[250,28],[250,50]]]]],[],2,null,["loc",[null,[250,20],[299,29]]]]
            ],
            locals: [],
            templates: [child0, child1, child2]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 232,
                "column": 12
              },
              "end": {
                "line": 303,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[233,41],[233,45]]]],["get","selectedUsers",["loc",[null,[233,46],[233,59]]]],["get","filterName",["loc",[null,[233,60],[233,70]]]],["get","changeTrigger",["loc",[null,[233,71],[233,84]]]]],[],["loc",[null,[233,20],[233,85]]]]],[],0,null,["loc",[null,[233,14],[302,21]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      var child3 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 326,
                    "column": 24
                  },
                  "end": {
                    "line": 327,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[327,64],[327,91]]]],
                ["content","item.customer.lastname",["loc",[null,[327,92],[327,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 333,
                          "column": 22
                        },
                        "end": {
                          "line": 338,
                          "column": 22
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      return morphs;
                    },
                    statements: [
                      ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[334,56],[334,87]]]]],[],[]],"eltigaActive",["subexpr","@mut",[["get","patientInfo.patient.etigaActive",["loc",[null,[335,51],[335,82]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[336,46],[336,82]]]]],[],[]],"evil",["subexpr","@mut",[["get","patientInfo.patient.evil",["loc",[null,[336,88],[336,112]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[337,46],[337,79]]]]],[],[]],"classNames","animal-icon mr5"],["loc",[null,[334,24],[337,110]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child1 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 340,
                          "column": 0
                        },
                        "end": {
                          "line": 340,
                          "column": 127
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[340,70],[340,94]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[340,103],[340,125]]]]],[],[]]],["loc",[null,[340,51],[340,127]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child2 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 341,
                          "column": 24
                        },
                        "end": {
                          "line": 343,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1,"class","block small");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                      return morphs;
                    },
                    statements: [
                      ["content","patientInfo.room.name",["loc",[null,[342,52],[342,77]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child3 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 345,
                          "column": 22
                        },
                        "end": {
                          "line": 345,
                          "column": 190
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[345,61],[345,190]]]]
                    ],
                    locals: [],
                    templates: []
                  };
                }());
                var child4 = (function() {
                  var child0 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 350,
                              "column": 32
                            },
                            "end": {
                              "line": 355,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element13 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element13, 'title');
                          morphs[1] = dom.createAttrMorph(element13, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element13, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[351,48],[351,76]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.doctor.color",["loc",[null,[351,166],[351,190]]]]," !important;"]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[352,64],[352,95]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 355,
                              "column": 32
                            },
                            "end": {
                              "line": 360,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                    ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element12 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element12, 'title');
                          morphs[1] = dom.createAttrMorph(element12, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element12, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.doctor.shortName",["loc",[null,[356,48],[356,76]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.doctor.selected",["loc",[null,[356,126],[356,153]]]],"selected",""],[],["loc",[null,[356,105],[356,169]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.doctor.color",["loc",[null,[356,205],[356,229]]]]]]],
                          ["content","patientInfo.doctor.capitals",["loc",[null,[357,64],[357,95]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 349,
                            "column": 30
                          },
                          "end": {
                            "line": 361,
                            "column": 30
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.doctor.colorHex",["loc",[null,[350,38],[350,65]]]]],[],0,1,["loc",[null,[350,32],[360,39]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  var child1 = (function() {
                    var child0 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 363,
                              "column": 32
                            },
                            "end": {
                              "line": 368,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          dom.setAttribute(el1,"class","tooltipstered mr2 symbol symbol-circle symbol-40px");
                          var el2 = dom.createTextNode("\n                                        ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                        ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element11 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element11, 'title');
                          morphs[1] = dom.createAttrMorph(element11, 'style');
                          morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[364,48],[364,79]]]]]]],
                          ["attribute","style",["concat",["background-color: ",["get","patientInfo.assistant.color",["loc",[null,[364,169],[364,196]]]]," !important;"]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[365,66],[365,100]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    var child1 = (function() {
                      return {
                        meta: {
                          "revision": "Ember@1.13.5",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 368,
                              "column": 32
                            },
                            "end": {
                              "line": 373,
                              "column": 32
                            }
                          },
                          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                        },
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("div");
                          var el2 = dom.createTextNode("\n                                        ");
                          dom.appendChild(el1, el2);
                          var el2 = dom.createElement("div");
                          dom.setAttribute(el2,"class","symbol-label");
                          var el3 = dom.createComment("");
                          dom.appendChild(el2, el3);
                          var el3 = dom.createTextNode("\n                                        ");
                          dom.appendChild(el2, el3);
                          dom.appendChild(el1, el2);
                          var el2 = dom.createTextNode("\n                                      ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element10 = dom.childAt(fragment, [1]);
                          var morphs = new Array(3);
                          morphs[0] = dom.createAttrMorph(element10, 'title');
                          morphs[1] = dom.createAttrMorph(element10, 'class');
                          morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]),0,0);
                          return morphs;
                        },
                        statements: [
                          ["attribute","title",["concat",[["get","patientInfo.assistant.shortName",["loc",[null,[369,48],[369,79]]]]]]],
                          ["attribute","class",["concat",["tooltipstered mr2 ",["subexpr","css-bool-evaluator",[["get","patientInfo.assistant.selected",["loc",[null,[369,129],[369,159]]]],"selected",""],[],["loc",[null,[369,108],[369,175]]]]," symbol symbol-circle symbol-40px ",["get","patientInfo.assistant.color",["loc",[null,[369,211],[369,238]]]]]]],
                          ["content","patientInfo.assistant.capitals",["loc",[null,[370,66],[370,100]]]]
                        ],
                        locals: [],
                        templates: []
                      };
                    }());
                    return {
                      meta: {
                        "revision": "Ember@1.13.5",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 362,
                            "column": 30
                          },
                          "end": {
                            "line": 374,
                            "column": 30
                          }
                        },
                        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                      },
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [
                        ["block","if",[["get","patientInfo.assistant.colorHex",["loc",[null,[363,38],[363,68]]]]],[],0,1,["loc",[null,[363,32],[373,39]]]]
                      ],
                      locals: [],
                      templates: [child0, child1]
                    };
                  }());
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 347,
                          "column": 22
                        },
                        "end": {
                          "line": 375,
                          "column": 26
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                      morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","patientInfo.doctor",["loc",[null,[349,36],[349,54]]]]],[],0,null,["loc",[null,[349,30],[361,37]]]],
                      ["block","if",[["get","patientInfo.assistant",["loc",[null,[362,36],[362,57]]]]],[],1,null,["loc",[null,[362,30],[374,37]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 330,
                        "column": 20
                      },
                      "end": {
                        "line": 381,
                        "column": 20
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1,"class","flex-center mb5 mt5");
                    dom.setAttribute(el1,"style","flex-direction: row");
                    var el2 = dom.createTextNode("\n\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                      ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2,"class","symbol-group");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    dom.setAttribute(el2,"class","ml5 exp icon-button icon-button--default list-action-square");
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("img");
                    dom.setAttribute(el3,"class","tooltipstered");
                    dom.setAttribute(el3,"title","Behandeln");
                    dom.setAttribute(el3,"src","assets/images/icons/rework/treatment.svg");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element14 = dom.childAt(fragment, [1]);
                    var element15 = dom.childAt(element14, [3]);
                    var element16 = dom.childAt(element14, [9]);
                    var morphs = new Array(6);
                    morphs[0] = dom.createMorphAt(element14,1,1);
                    morphs[1] = dom.createMorphAt(element15,1,1);
                    morphs[2] = dom.createMorphAt(element15,3,3);
                    morphs[3] = dom.createMorphAt(element14,5,5);
                    morphs[4] = dom.createMorphAt(dom.childAt(element14, [7]),1,1);
                    morphs[5] = dom.createElementMorph(element16);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","patientInfo.patient.category",["loc",[null,[333,28],[333,56]]]]],[],0,null,["loc",[null,[333,22],[338,29]]]],
                    ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[340,27],[340,49]]]]],[],1,null,["loc",[null,[340,0],[340,139]]]],
                    ["block","if",[["get","patientInfo.room",["loc",[null,[341,30],[341,46]]]]],[],2,null,["loc",[null,[341,24],[343,31]]]],
                    ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[345,28],[345,59]]]]],[],3,null,["loc",[null,[345,22],[345,197]]]],
                    ["block","if",[["subexpr","or",[["get","patientInfo.assistant",["loc",[null,[347,32],[347,53]]]],["get","patientInfo.doctor",["loc",[null,[347,54],[347,72]]]]],[],["loc",[null,[347,28],[347,73]]]]],[],4,null,["loc",[null,[347,22],[375,33]]]],
                    ["element","action",["movePatientToTreatmentRoom",["get","patientInfo.id",["loc",[null,[377,131],[377,145]]]]],[],["loc",[null,[377,93],[377,147]]]]
                  ],
                  locals: [],
                  templates: [child0, child1, child2, child3, child4]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 329,
                      "column": 20
                    },
                    "end": {
                      "line": 382,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["subexpr","show-treatment-patient-tile",[["get","patientInfo",["loc",[null,[330,55],[330,66]]]],["get","selectedUsers",["loc",[null,[330,67],[330,80]]]],["get","filterName",["loc",[null,[330,81],[330,91]]]],["get","changeTrigger",["loc",[null,[330,92],[330,105]]]]],[],["loc",[null,[330,26],[330,106]]]]],[],0,null,["loc",[null,[330,20],[381,27]]]]
                ],
                locals: [],
                templates: [child0]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 328,
                    "column": 18
                  },
                  "end": {
                    "line": 383,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[329,30],[329,50]]]],"in_station"],[],["loc",[null,[329,26],[329,64]]]]],[],0,null,["loc",[null,[329,20],[382,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 319,
                  "column": 14
                },
                "end": {
                  "line": 386,
                  "column": 14
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var element18 = dom.childAt(element17, [1]);
              var element19 = dom.childAt(element17, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element18, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element18, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element19, [1]),0,0);
              morphs[3] = dom.createMorphAt(element19,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[322,97],[322,109]]]],"DD. MM. YYYY"],[],["loc",[null,[322,83],[322,126]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[323,50],[323,62]]]],"HH:mm"],[],["loc",[null,[323,36],[323,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[326,52],[326,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[326,24],[327,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[328,26],[328,48]]]]],[],1,null,["loc",[null,[328,18],[383,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 318,
                "column": 12
              },
              "end": {
                "line": 387,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[319,41],[319,45]]]],["get","selectedUsers",["loc",[null,[319,46],[319,59]]]],["get","filterName",["loc",[null,[319,60],[319,70]]]],["get","changeTrigger",["loc",[null,[319,71],[319,84]]]]],[],["loc",[null,[319,20],[319,85]]]]],[],0,null,["loc",[null,[319,14],[386,21]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      var child4 = (function() {
        var child0 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 413,
                    "column": 24
                  },
                  "end": {
                    "line": 414,
                    "column": 118
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["content","item.customer.firstname",["loc",[null,[414,64],[414,91]]]],
                ["content","item.customer.lastname",["loc",[null,[414,92],[414,118]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            var child0 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 419,
                        "column": 26
                      },
                      "end": {
                        "line": 423,
                        "column": 87
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1,"class","animal-icon");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                    return morphs;
                  },
                  statements: [
                    ["inline","animal-icon",[],["size",28,"animal-id",["subexpr","@mut",[["get","patientInfo.patient.category.id",["loc",[null,[420,83],[420,114]]]]],[],[]],"race-id",["subexpr","@mut",[["get","patientInfo.patient.digitalRace.icon",["loc",[null,[421,73],[421,109]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.category.name",["loc",[null,[422,73],[422,106]]]]],[],[]],"classNames","mr5"],["loc",[null,[420,51],[423,83]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 424,
                        "column": 24
                      },
                      "end": {
                        "line": 425,
                        "column": 121
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-text",[],["text",["subexpr","@mut",[["get","patientInfo.patient.name",["loc",[null,[424,94],[424,118]]]]],[],[]],"content",["subexpr","@mut",[["get","patientInfo.patient.id",["loc",[null,[425,97],[425,119]]]]],[],[]]],["loc",[null,[424,75],[425,121]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child2 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 426,
                        "column": 24
                      },
                      "end": {
                        "line": 428,
                        "column": 109
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["inline","button-etiga-small",[],["classNames","inline-icon vertical-middle","size","16","width",50,"color","00AAC6","classNames","ml5","connected",true],["loc",[null,[426,63],[428,109]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child3 = (function() {
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 430,
                        "column": 22
                      },
                      "end": {
                        "line": 434,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","exp icon-button icon-button--success list-action-square mr0 ml0 ");
                    dom.setAttribute(el1,"style","margin-left: auto !important;");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"class","tooltipstered");
                    dom.setAttribute(el2,"title","Übergabe an Ausgangsrechnung");
                    dom.setAttribute(el2,"src","assets/images/icons/rework/cashdesk.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() { return []; },
                  statements: [

                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child4 = (function() {
                var child0 = (function() {
                  return {
                    meta: {
                      "revision": "Ember@1.13.5",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 435,
                          "column": 24
                        },
                        "end": {
                          "line": 437,
                          "column": 24
                        }
                      },
                      "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1,"class","tooltipstered");
                      dom.setAttribute(el1,"title","Behandeln");
                      dom.setAttribute(el1,"src","assets/images/icons/rework/treatment.svg");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() { return []; },
                    statements: [

                    ],
                    locals: [],
                    templates: []
                  };
                }());
                return {
                  meta: {
                    "revision": "Ember@1.13.5",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 434,
                        "column": 22
                      },
                      "end": {
                        "line": 443,
                        "column": 22
                      }
                    },
                    "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    dom.setAttribute(el1,"class","ember-view icon-button icon-button--success list-action-square mr0 ml5");
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("img");
                    dom.setAttribute(el2,"class","tooltipstered");
                    dom.setAttribute(el2,"title","Übergabe an Ausgangsrechnung");
                    dom.setAttribute(el2,"src","assets/images/icons/rework/cashdesk.svg");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [2]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    morphs[1] = dom.createElementMorph(element4);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [
                    ["block","link-to",["practicemgmt.treatment-details",["get","patientInfo.id",["loc",[null,[435,68],[435,82]]]],["subexpr","query-params",[],["entry","true"],["loc",[null,[435,83],[435,110]]]]],["class","icon-button icon-button--default list-action-square ml-auto"],0,null,["loc",[null,[435,24],[437,36]]]],
                    ["element","action",["openExportInvoicePanel",["get","patientInfo.id",["loc",[null,[439,153],[439,167]]]],["get","patientInfo.info.invoiceId",["loc",[null,[440,153],[440,179]]]]],[],["loc",[null,[438,106],[440,181]]]]
                  ],
                  locals: [],
                  templates: [child0]
                };
              }());
              return {
                meta: {
                  "revision": "Ember@1.13.5",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 416,
                      "column": 20
                    },
                    "end": {
                      "line": 445,
                      "column": 20
                    }
                  },
                  "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1,"class","flex-center mb5 mt5");
                  dom.setAttribute(el1,"style","flex-direction: row");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2,"class","flex-center");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                        ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var element6 = dom.childAt(element5, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createMorphAt(element6,1,1);
                  morphs[1] = dom.createMorphAt(element6,3,3);
                  morphs[2] = dom.createMorphAt(element6,5,5);
                  morphs[3] = dom.createMorphAt(element5,3,3);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","patientInfo.patient.category",["loc",[null,[419,32],[419,60]]]]],[],0,null,["loc",[null,[419,26],[423,94]]]],
                  ["block","link-to",["patients.edit",["get","patientInfo.patient.id",["loc",[null,[424,51],[424,73]]]]],[],1,null,["loc",[null,[424,24],[425,133]]]],
                  ["block","if",[["get","patientInfo.patient.etigaActive",["loc",[null,[426,30],[426,61]]]]],[],2,null,["loc",[null,[426,24],[428,116]]]],
                  ["block","if",[["get","inProgress",["loc",[null,[430,28],[430,38]]]]],[],3,4,["loc",[null,[430,22],[443,29]]]]
                ],
                locals: [],
                templates: [child0, child1, child2, child3, child4]
              };
            }());
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 415,
                    "column": 18
                  },
                  "end": {
                    "line": 446,
                    "column": 18
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["subexpr","eq",[["get","patientInfo.state.id",["loc",[null,[416,30],[416,50]]]],"ready_for_payment"],[],["loc",[null,[416,26],[416,71]]]]],[],0,null,["loc",[null,[416,20],[445,27]]]]
              ],
              locals: ["patientInfo"],
              templates: [child0]
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 405,
                  "column": 14
                },
                "end": {
                  "line": 449,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1,"class","table__row");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell l-8/24");
              dom.setAttribute(el2,"data-label","Check-In");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"style","display: block;margin-top: 0px;margin-bottom: 0px;");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3,"class","sub");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" Uhr");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","table__cell  l-16/24");
              dom.setAttribute(el2,"data-label","Kund:in");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var element8 = dom.childAt(element7, [1]);
              var element9 = dom.childAt(element7, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]),0,0);
              morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]),0,0);
              morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]),0,0);
              morphs[3] = dom.createMorphAt(element9,3,3);
              return morphs;
            },
            statements: [
              ["inline","time-format",[["get","item.created",["loc",[null,[408,97],[408,109]]]],"DD. MM. YYYY"],[],["loc",[null,[408,83],[409,113]]]],
              ["inline","time-format",[["get","item.created",["loc",[null,[410,50],[410,62]]]],"HH:mm"],[],["loc",[null,[410,36],[410,72]]]],
              ["block","link-to",["customers.edit",["get","item.customer.id",["loc",[null,[413,52],[413,68]]]]],["classNames","is-highlighted"],0,null,["loc",[null,[413,24],[414,130]]]],
              ["block","each",[["get","item.treatmentPatients",["loc",[null,[415,26],[415,48]]]]],[],1,null,["loc",[null,[415,18],[446,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 404,
                "column": 12
              },
              "end": {
                "line": 450,
                "column": 12
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","show-treatment-tile",[["get","item",["loc",[null,[405,41],[405,45]]]],["get","selectedUsers",["loc",[null,[405,46],[405,59]]]],["get","filterName",["loc",[null,[405,60],[405,70]]]],["get","changeTrigger",["loc",[null,[405,71],[405,84]]]]],[],["loc",[null,[405,20],[405,85]]]]],[],0,null,["loc",[null,[405,14],[449,23]]]]
          ],
          locals: ["item"],
          templates: [child0]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 0
            },
            "end": {
              "line": 455,
              "column": 0
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","header d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
          dom.setAttribute(el4,"class","ml5");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("Demnächst");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table grid table--small");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Termin");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","header d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
          dom.setAttribute(el4,"class","ml5");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("Wartezimmer");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table grid table--small");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Check-In");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk pr10 ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","header d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
          dom.setAttribute(el4,"class","ml5");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("In Behandlung");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table table--small grid");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Check-In");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          dom.setAttribute(el2,"id","stationsCard");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","header d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
          dom.setAttribute(el4,"class","ml5");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("Auf Station");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table table--small grid");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Check-In");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","col u-hide@phone l-24/24 l-12/24@tablet l-6/24@desk ");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","card treatment-card");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","header d-flex align-items-center");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4,"style","font-size: 36px !important;font-weight: 500");
          dom.setAttribute(el4,"class","ml5");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4,"class","mb0 ml10");
          var el5 = dom.createTextNode("Zur Abrechnung");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","table table--small grid");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__head");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-8/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Check-In");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5,"class","table__cell l-16/24");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6,"class","table__title title very-small");
          var el7 = dom.createTextNode("Kund:in");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","table__body no-scrollbar");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element48 = dom.childAt(fragment, [1, 1]);
          var element49 = dom.childAt(fragment, [3, 1]);
          var element50 = dom.childAt(fragment, [5]);
          var element51 = dom.childAt(element50, [1]);
          var element52 = dom.childAt(element50, [3]);
          var element53 = dom.childAt(fragment, [7, 1]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(dom.childAt(element48, [1, 1]),0,0);
          morphs[1] = dom.createMorphAt(dom.childAt(element48, [3, 3]),1,1);
          morphs[2] = dom.createMorphAt(dom.childAt(element49, [1, 1]),0,0);
          morphs[3] = dom.createMorphAt(dom.childAt(element49, [3, 3]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element51, [1, 1]),0,0);
          morphs[5] = dom.createMorphAt(dom.childAt(element51, [3, 3]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element52, [1, 1]),0,0);
          morphs[7] = dom.createMorphAt(dom.childAt(element52, [3, 3]),1,1);
          morphs[8] = dom.createMorphAt(dom.childAt(element53, [1, 1]),0,0);
          morphs[9] = dom.createMorphAt(dom.childAt(element53, [3, 3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","get-size",[["get","upcomingItems",["loc",[null,[59,91],[59,104]]]]],[],["loc",[null,[59,80],[59,106]]]],
          ["block","each",[["get","upcomingItems",["loc",[null,[68,20],[68,33]]]]],[],0,null,["loc",[null,[68,12],[125,21]]]],
          ["inline","get-size",[["get","waitingroomItems",["loc",[null,[133,91],[133,107]]]]],[],["loc",[null,[133,80],[133,109]]]],
          ["block","each",[["get","waitingroomItems",["loc",[null,[142,20],[142,36]]]]],[],1,null,["loc",[null,[142,12],[215,21]]]],
          ["inline","get-size",[["get","treatmentItems",["loc",[null,[223,91],[223,105]]]]],[],["loc",[null,[223,80],[223,107]]]],
          ["block","each",[["get","treatmentItems",["loc",[null,[232,20],[232,34]]]]],[],2,null,["loc",[null,[232,12],[303,21]]]],
          ["inline","get-size",[["get","stationItems",["loc",[null,[309,91],[309,103]]]]],[],["loc",[null,[309,80],[309,105]]]],
          ["block","each",[["get","stationItems",["loc",[null,[318,20],[318,32]]]]],[],3,null,["loc",[null,[318,12],[387,21]]]],
          ["inline","get-size",[["get","paymentItems",["loc",[null,[395,91],[395,103]]]]],[],["loc",[null,[395,80],[395,105]]]],
          ["block","each",[["get","paymentItems",["loc",[null,[404,20],[404,32]]]]],[],4,null,["loc",[null,[404,12],[450,21]]]]
        ],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 472,
                "column": 10
              },
              "end": {
                "line": 474,
                "column": 10
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            morphs[1] = dom.createElementMorph(element3);
            morphs[2] = dom.createMorphAt(element3,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[473,65],[473,81]]]],"selected",""],[],["loc",[null,[473,44],[473,97]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[473,124],[473,131]]]]],[],["loc",[null,[473,99],[473,133]]]],
            ["content","station.name",["loc",[null,[473,134],[473,150]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 469,
              "column": 6
            },
            "end": {
              "line": 476,
              "column": 6
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter mb0 col l-12/24");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Stationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","stations",["loc",[null,[472,18],[472,26]]]]],[],0,null,["loc",[null,[472,10],[474,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 483,
                  "column": 16
                },
                "end": {
                  "line": 491,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2,"class","symbol-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element2, 'title');
              morphs[1] = dom.createAttrMorph(element2, 'class');
              morphs[2] = dom.createAttrMorph(element2, 'style');
              morphs[3] = dom.createElementMorph(element2);
              morphs[4] = dom.createMorphAt(dom.childAt(element2, [1]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[484,32],[484,46]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[485,65],[485,78]]]],"selected",""],[],["loc",[null,[485,44],[486,69]]]]," symbol symbol-circle symbol-40px"]]],
              ["attribute","style",["concat",["background-color: ",["get","user.color",["loc",[null,[487,50],[487,60]]]]," !important;"]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[484,76],[484,80]]]]],[],["loc",[null,[484,50],[484,82]]]],
              ["content","user.capitals",["loc",[null,[488,46],[488,63]]]]
            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 495,
                    "column": 20
                  },
                  "end": {
                    "line": 499,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2,"src","assets/images/icons/notassigned.svg");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "revision": "Ember@1.13.5",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 499,
                    "column": 20
                  },
                  "end": {
                    "line": 502,
                    "column": 20
                  }
                },
                "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1,"class","symbol-label");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
                return morphs;
              },
              statements: [
                ["content","user.capitals",["loc",[null,[500,48],[500,65]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "revision": "Ember@1.13.5",
              "loc": {
                "source": null,
                "start": {
                  "line": 491,
                  "column": 16
                },
                "end": {
                  "line": 504,
                  "column": 16
                }
              },
              "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element1, 'title');
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createElementMorph(element1);
              morphs[3] = dom.createMorphAt(element1,1,1);
              return morphs;
            },
            statements: [
              ["attribute","title",["concat",[["get","user.shortName",["loc",[null,[492,32],[492,46]]]]]]],
              ["attribute","class",["concat",["tooltipstered ",["subexpr","css-bool-evaluator",[["get","user.selected",["loc",[null,[493,65],[493,78]]]],"selected",""],[],["loc",[null,[493,44],[494,69]]]]," symbol symbol-circle symbol-40px ",["get","user.color",["loc",[null,[494,105],[494,115]]]]]]],
              ["element","action",["toggleResource",["get","user",["loc",[null,[492,76],[492,80]]]]],[],["loc",[null,[492,50],[492,82]]]],
              ["block","if",[["subexpr","eq",[["get","user.id",["loc",[null,[495,30],[495,37]]]],"9998"],[],["loc",[null,[495,26],[495,45]]]]],[],0,1,["loc",[null,[495,20],[502,27]]]]
            ],
            locals: [],
            templates: [child0, child1]
          };
        }());
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 482,
                "column": 14
              },
              "end": {
                "line": 505,
                "column": 14
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["get","user.colorHex",["loc",[null,[483,22],[483,35]]]]],[],0,1,["loc",[null,[483,16],[504,23]]]]
          ],
          locals: [],
          templates: [child0, child1]
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 481,
              "column": 12
            },
            "end": {
              "line": 506,
              "column": 12
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","user.active",["loc",[null,[482,20],[482,31]]]]],[],0,null,["loc",[null,[482,14],[505,21]]]]
        ],
        locals: ["user"],
        templates: [child0]
      };
    }());
    var child4 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.5",
            "loc": {
              "source": null,
              "start": {
                "line": 514,
                "column": 8
              },
              "end": {
                "line": 516,
                "column": 8
              }
            },
            "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0,0,0);
            return morphs;
          },
          statements: [
            ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","station.selected",["loc",[null,[515,63],[515,79]]]],"selected",""],[],["loc",[null,[515,42],[515,95]]]]]]],
            ["element","action",["toggleStation",["get","station",["loc",[null,[515,122],[515,129]]]]],[],["loc",[null,[515,97],[515,131]]]],
            ["content","station.name",["loc",[null,[515,132],[515,148]]]]
          ],
          locals: ["station"],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.5",
          "loc": {
            "source": null,
            "start": {
              "line": 511,
              "column": 4
            },
            "end": {
              "line": 518,
              "column": 4
            }
          },
          "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","quick-filter mb0 col l-12/24");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","title");
          var el3 = dom.createTextNode("Stationen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),3,3);
          return morphs;
        },
        statements: [
          ["block","each",[["get","stations",["loc",[null,[514,16],[514,24]]]]],[],0,null,["loc",[null,[514,8],[516,17]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.5",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 561,
            "column": 0
          }
        },
        "moduleName": "jason-frontend/templates/practicemgmt/admission.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1,"class","rework-navbar reduced");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","d-flex align-items-center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        var el4 = dom.createTextNode("Praxisübersicht");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","d-flex flex-grow-1 justify-content-end");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","button-rework context primary");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("img");
        dom.setAttribute(el5,"src","assets/images/icons/rework/add.svg");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        var el6 = dom.createTextNode("Neue Behandlung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","search-container card-rework ");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","main-lane");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","mt-0 tab-container btn-group");
        dom.setAttribute(el4,"style","margin-left: auto");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        var el6 = dom.createTextNode("Kombinierte Ansicht");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        var el6 = dom.createTextNode(" Nur Kacheln");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        dom.setAttribute(el5,"type","button");
        var el6 = dom.createTextNode("Nur Liste");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1,"id","treatmentOverview");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","grid");
        dom.setAttribute(el2,"id","topTiles");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("section");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","table-wrapper mb30 mt20 grid");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 col l-12/24");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Filter");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Alle");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Wartezimmer");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Behandlung");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Bereit zum Bezahlen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("Station");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","quick-filter mb0 mt10 col l-24/24");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","title");
        var el6 = dom.createTextNode("Mitarbeiter:innen");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","symbol-group symbol-hover flex-shrink-0 me-2");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element56 = dom.childAt(fragment, [0]);
        var element57 = dom.childAt(element56, [1, 3, 1]);
        var element58 = dom.childAt(element56, [3, 1]);
        var element59 = dom.childAt(element58, [3]);
        var element60 = dom.childAt(element59, [1]);
        var element61 = dom.childAt(element59, [3]);
        var element62 = dom.childAt(element59, [5]);
        var element63 = dom.childAt(fragment, [4]);
        var element64 = dom.childAt(element63, [3]);
        var element65 = dom.childAt(element64, [1]);
        var element66 = dom.childAt(element65, [1]);
        var element67 = dom.childAt(element66, [3]);
        var element68 = dom.childAt(element66, [5]);
        var element69 = dom.childAt(element66, [7]);
        var element70 = dom.childAt(element66, [9]);
        var element71 = dom.childAt(element66, [11]);
        var morphs = new Array(30);
        morphs[0] = dom.createElementMorph(element57);
        morphs[1] = dom.createMorphAt(element58,1,1);
        morphs[2] = dom.createAttrMorph(element60, 'class');
        morphs[3] = dom.createElementMorph(element60);
        morphs[4] = dom.createAttrMorph(element61, 'class');
        morphs[5] = dom.createElementMorph(element61);
        morphs[6] = dom.createAttrMorph(element62, 'class');
        morphs[7] = dom.createElementMorph(element62);
        morphs[8] = dom.createMorphAt(fragment,2,2,contextualElement);
        morphs[9] = dom.createAttrMorph(element63, 'class');
        morphs[10] = dom.createMorphAt(dom.childAt(element63, [1]),1,1);
        morphs[11] = dom.createAttrMorph(element64, 'class');
        morphs[12] = dom.createAttrMorph(element67, 'class');
        morphs[13] = dom.createElementMorph(element67);
        morphs[14] = dom.createAttrMorph(element68, 'class');
        morphs[15] = dom.createElementMorph(element68);
        morphs[16] = dom.createAttrMorph(element69, 'class');
        morphs[17] = dom.createElementMorph(element69);
        morphs[18] = dom.createAttrMorph(element70, 'class');
        morphs[19] = dom.createElementMorph(element70);
        morphs[20] = dom.createAttrMorph(element71, 'class');
        morphs[21] = dom.createElementMorph(element71);
        morphs[22] = dom.createMorphAt(element65,3,3);
        morphs[23] = dom.createMorphAt(dom.childAt(element65, [5, 3]),1,1);
        morphs[24] = dom.createMorphAt(element64,3,3);
        morphs[25] = dom.createMorphAt(element64,5,5);
        morphs[26] = dom.createMorphAt(fragment,6,6,contextualElement);
        morphs[27] = dom.createMorphAt(fragment,8,8,contextualElement);
        morphs[28] = dom.createMorphAt(fragment,10,10,contextualElement);
        morphs[29] = dom.createMorphAt(fragment,12,12,contextualElement);
        return morphs;
      },
      statements: [
        ["element","action",["selectCustomerForTreatment"],[],["loc",[null,[5,9],[5,49]]]],
        ["inline","search-box",[],["filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[13,30],[13,40]]]]],[],[]],"placeHolder","Kunde suchen","search","applyFilter"],["loc",[null,[13,6],[13,90]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[16,108],[16,116]]]],"splitted"],[],["loc",[null,[16,104],[16,128]]]],"selected",""],[],["loc",[null,[16,83],[16,144]]]]]]],
        ["element","action",["changeView","splitted"],[],["loc",[null,[16,16],[16,50]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[17,105],[17,113]]]],"tiles"],[],["loc",[null,[17,101],[17,122]]]],"selected",""],[],["loc",[null,[17,80],[17,138]]]]]]],
        ["element","action",["changeView","tiles"],[],["loc",[null,[17,16],[17,47]]]],
        ["attribute","class",["concat",["btn button ",["subexpr","css-bool-evaluator",[["subexpr","eq",[["get","mainView",["loc",[null,[18,104],[18,112]]]],"list"],[],["loc",[null,[18,100],[18,120]]]],"selected",""],[],["loc",[null,[18,79],[18,136]]]]]]],
        ["element","action",["changeView","list"],[],["loc",[null,[18,16],[18,46]]]],
        ["block","if",[["subexpr","eq",[["get","mainView",["loc",[null,[24,10],[24,18]]]],"tiles"],[],["loc",[null,[24,6],[24,27]]]]],[],0,null,["loc",[null,[24,0],[51,7]]]],
        ["attribute","class",["concat",[["get","mainView",["loc",[null,[53,41],[53,49]]]]]]],
        ["block","if",[["get","users",["loc",[null,[55,6],[55,11]]]]],[],1,null,["loc",[null,[55,0],[455,7]]]],
        ["attribute","class",["concat",["site-content card transaction-filter  ",["subexpr","css-bool-evaluator",[["get","showTopTiles",["loc",[null,[458,77],[458,89]]]],"","with-context-bar"],[],["loc",[null,[458,56],[458,113]]]]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showAll",["loc",[null,[463,61],[463,68]]]],"selected",""],[],["loc",[null,[463,40],[463,85]]]]]]],
        ["element","action",["toggleQuickfilter","all"],[],["loc",[null,[463,87],[463,123]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showWaiting",["loc",[null,[464,61],[464,72]]]],"selected",""],[],["loc",[null,[464,40],[464,88]]]]]]],
        ["element","action",["toggleQuickfilter","waiting"],[],["loc",[null,[464,90],[464,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showTreatment",["loc",[null,[465,61],[465,74]]]],"selected",""],[],["loc",[null,[465,40],[465,90]]]]]]],
        ["element","action",["toggleQuickfilter","treatment"],[],["loc",[null,[465,92],[465,134]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showPayment",["loc",[null,[466,61],[466,72]]]],"selected",""],[],["loc",[null,[466,40],[466,88]]]]]]],
        ["element","action",["toggleQuickfilter","payment"],[],["loc",[null,[466,90],[466,130]]]],
        ["attribute","class",["concat",["cursor info-badge ",["subexpr","css-bool-evaluator",[["get","showStation",["loc",[null,[467,61],[467,72]]]],"selected",""],[],["loc",[null,[467,40],[467,88]]]]]]],
        ["element","action",["toggleQuickfilter","station"],[],["loc",[null,[467,90],[467,130]]]],
        ["block","if",[["get","showStation",["loc",[null,[469,12],[469,23]]]]],[],2,null,["loc",[null,[469,6],[476,13]]]],
        ["block","each",[["get","users",["loc",[null,[481,20],[481,25]]]]],[],3,null,["loc",[null,[481,12],[506,21]]]],
        ["block","if",[["get","showStation",["loc",[null,[511,10],[511,21]]]]],[],4,null,["loc",[null,[511,4],[518,11]]]],
        ["inline","treatment-patient-list",[],["model",["subexpr","@mut",[["get","model",["loc",[null,[521,14],[521,19]]]]],[],[]],"type",["subexpr","@mut",[["get","type",["loc",[null,[522,13],[522,17]]]]],[],[]],"selectedUsers",["subexpr","@mut",[["get","selectedUsers",["loc",[null,[523,22],[523,35]]]]],[],[]],"filteredStations",["subexpr","@mut",[["get","stations",["loc",[null,[524,25],[524,33]]]]],[],[]],"filterName",["subexpr","@mut",[["get","filterName",["loc",[null,[525,19],[525,29]]]]],[],[]],"changeTrigger",["subexpr","@mut",[["get","changeTrigger",["loc",[null,[526,22],[526,35]]]]],[],[]],"showAll",["subexpr","@mut",[["get","showAll",["loc",[null,[527,16],[527,23]]]]],[],[]],"showAdmission",["subexpr","@mut",[["get","showAdmission",["loc",[null,[528,22],[528,35]]]]],[],[]],"showWaiting",["subexpr","@mut",[["get","showWaiting",["loc",[null,[529,20],[529,31]]]]],[],[]],"showTreatment",["subexpr","@mut",[["get","showTreatment",["loc",[null,[530,22],[530,35]]]]],[],[]],"showPayment",["subexpr","@mut",[["get","showPayment",["loc",[null,[531,20],[531,31]]]]],[],[]],"showStation",["subexpr","@mut",[["get","showStation",["loc",[null,[532,20],[532,31]]]]],[],[]],"showStationOverride",["subexpr","is-test-mandant",[],[],["loc",[null,[533,28],[533,45]]]],"abort","abort","openTime","openTime","movePatientToWaitingRoom","movePatientToWaitingRoom","movePatientToTreatmentRoom","movePatientToTreatmentRoom","movePatientToStation","movePatientToStation","exportInvoice","openExportInvoicePanel","editTreatmentInfo","editTreatmentInfo"],["loc",[null,[520,6],[541,8]]]],
        ["inline","edit-treatment-infos-panel",[],["confirm","saveEditTreatmentInfo","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[545,67],[545,72]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[545,84],[545,94]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[545,103],[545,110]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","infoTreatmentPanel",["loc",[null,[545,126],[545,144]]]]],[],[]]],["loc",[null,[545,0],[545,146]]]],
        ["inline","start-treatment-panel",[],["confirm","selectCustomerConfirm","gotoCustomer","gotoCustomer","gotoPatient","gotoPatient","rooms",["subexpr","@mut",[["get","rooms",["loc",[null,[551,8],[551,13]]]]],[],[]],"doctors",["subexpr","@mut",[["get","doctors",["loc",[null,[552,10],[552,17]]]]],[],[]],"assistants",["subexpr","@mut",[["get","assistants",["loc",[null,[553,13],[553,23]]]]],[],[]],"actionReceiver",["subexpr","@mut",[["get","startTreatmentPanel",["loc",[null,[554,17],[554,36]]]]],[],[]]],["loc",[null,[547,0],[554,38]]]],
        ["inline","export-invoice-panel",[],["exportInvoice","openExportInvoicePanel","confirm","exportInvoice","actionReceiver",["subexpr","@mut",[["get","exportInvoicePanel",["loc",[null,[559,17],[559,35]]]]],[],[]]],["loc",[null,[556,0],[559,37]]]],
        ["inline","select-app-time",[],["save","saveDate","actionReceiver",["subexpr","@mut",[["get","selectAppTime",["loc",[null,[560,49],[560,62]]]]],[],[]]],["loc",[null,[560,0],[560,64]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }()));

});